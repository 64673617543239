<template>
<div>
  <div class="top-header item17 overlay">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="wrapper">
              <h1 class="heading">vezra.com</h1>
              <h3 class="subheading">.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper sec-normal">
      <div class="content bg-colorstyle noshadow nopadding">
        <div class="inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <section id="main-body">
                <div class="container">
                  <div class="main-content ">
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="container">
                          <div class="row text-center">
                            <h2 class="section-heading mergecolor text-center w-100">Open Ticket </h2>
                          </div>
                        </div> 
                        <p class="text-center mergecolor">If you can't find a solution to your problems in our knowledgebase, you can submit a ticket by selecting the appropriate department below.</p>
                        <section class="services overview-services sec-normal pt-50 pb-0">
                          <div class="service-wrap">
                            <div class="row">
                              <div class="col-md-4 aos-init" data-aos="fade-up" data-aos-duration="1000">
                                <div class="service-section bg-seccolorstyle bg-white noshadow">
                                  <div class="plans badge feat bg-pink">Get Support</div>
                                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg" src="templates/antler/assets/fonts/svg/helpdesk.svg" alt="Support">
                                    <title>helpdesk</title>
                                    <path fill="#5e686c" d="M11.994 20.762c-0.944 0-1.713-0.769-1.713-1.712s0.769-1.712 1.713-1.712c0.944 0 1.713 0.769 1.713 1.712s-0.769 1.712-1.713 1.712zM11.994 18.194c-0.475 0-0.856 0.381-0.856 0.856s0.387 0.856 0.856 0.856c0.475 0 0.856-0.387 0.856-0.856 0-0.475-0.381-0.856-0.856-0.856z"></path>
                                    <path fill="#5e686c" d="M20.544 20.762c-0.944 0-1.712-0.769-1.712-1.712s0.769-1.712 1.712-1.712c0.944 0 1.712 0.769 1.712 1.712s-0.769 1.712-1.712 1.712zM20.544 18.194c-0.475 0-0.856 0.381-0.856 0.856s0.387 0.856 0.856 0.856c0.475 0 0.856-0.387 0.856-0.856 0-0.475-0.387-0.856-0.856-0.856z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M18.494 28.788c-1.181 0-2.144-0.962-2.144-2.144s0.962-2.144 2.144-2.144c1.181 0 2.144 0.962 2.144 2.144-0.006 1.188-0.962 2.144-2.144 2.144zM18.494 25.363c-0.706 0-1.288 0.575-1.288 1.288 0 0.706 0.575 1.288 1.288 1.288 0.706 0 1.288-0.575 1.288-1.288-0.006-0.712-0.581-1.288-1.288-1.288z"></path>
                                    <path fill="#5e686c" d="M24.831 15.381c-8.050 0-12.556-5.144-12.756-5.375-0.15-0.194-0.256-0.175-0.369-0.019l-4.594 5.225c-0.156 0.175-0.425 0.194-0.606 0.037-0.175-0.156-0.194-0.425-0.037-0.606l4.569-5.2c0.369-0.494 1.287-0.519 1.7 0.025 0.031 0.031 4.794 5.45 13.006 5.031 0.256-0.012 0.438 0.169 0.45 0.406s-0.169 0.438-0.406 0.45c-0.319 0.019-0.644 0.025-0.956 0.025z"></path>
                                    <path fill="#5e686c" d="M16.369 31.806c-6.050 0-10.438-4.556-10.438-10.837 0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431c0 5.781 4.031 9.981 9.581 9.981s9.581-4.050 9.581-9.631c0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431c-0.012 5.981-4.5 10.488-10.45 10.488z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M28.788 21.719h-1.712c-0.931 0-1.712-0.788-1.712-1.712v-6c0-0.931 0.788-1.713 1.712-1.713h1.712c0.931 0 1.712 0.787 1.712 1.713v6c0 0.931-0.788 1.712-1.712 1.712zM27.075 13.15c-0.45 0-0.856 0.406-0.856 0.856v6c0 0.45 0.406 0.856 0.856 0.856h1.712c0.45 0 0.856-0.406 0.856-0.856v-6c0-0.45-0.406-0.856-0.856-0.856h-1.712z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M20.306 27.063c-0.238 0-0.431-0.194-0.431-0.431s0.194-0.431 0.431-0.431h1.587c0.137-0.012 4.056-0.35 4.056-5.131 0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431c0 5.6-4.837 5.981-4.887 5.988l-1.619 0.006z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M5.5 21.75h-1.725c-0.931 0-1.725-0.788-1.725-1.725v-6.037c0-0.931 0.788-1.725 1.725-1.725h0.519c0.044-6.662 5.481-12.075 12.163-12.075 6.7 0 12.15 5.612 12.15 12.506 0 0.238-0.194 0.431-0.431 0.431s-0.431-0.194-0.431-0.431c0-6.425-5.069-11.65-11.294-11.65-6.2 0-11.256 5.025-11.3 11.225h0.35c0.931 0 1.725 0.787 1.725 1.725v6.038c-0.006 0.931-0.794 1.719-1.725 1.719zM3.775 13.125c-0.45 0-0.863 0.412-0.863 0.863v6.037c0 0.45 0.413 0.869 0.862 0.869h1.725c0.45 0 0.869-0.413 0.869-0.869v-6.037c0-0.45-0.412-0.863-0.869-0.863h-1.725z"></path>
                                  </svg>
                                  <div class="title mergecolor" deptid="2">Finance department</div>
                                  <p class="subtitle seccolor">Department related to billing, payment, or receivables. (Monday to Friday from 8 am to 6 pm and Saturday from 8 am to 12 pm)</p>
                                  <a href="/whmcs/submitticket.php?step=2&amp;deptid=2" class="btn btn-default-yellow-fill">Finance department</a>
                                </div>
                              </div>
                              <div class="col-md-4 aos-init" data-aos="fade-up" data-aos-duration="1000">
                                <div class="service-section bg-seccolorstyle bg-white noshadow">
                                  <div class="plans badge feat bg-pink">Get Support</div>
                                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg" src="templates/antler/assets/fonts/svg/helpdesk.svg" alt="Support">
                                    <title>helpdesk</title>
                                    <path fill="#5e686c" d="M11.994 20.762c-0.944 0-1.713-0.769-1.713-1.712s0.769-1.712 1.713-1.712c0.944 0 1.713 0.769 1.713 1.712s-0.769 1.712-1.713 1.712zM11.994 18.194c-0.475 0-0.856 0.381-0.856 0.856s0.387 0.856 0.856 0.856c0.475 0 0.856-0.387 0.856-0.856 0-0.475-0.381-0.856-0.856-0.856z"></path>
                                    <path fill="#5e686c" d="M20.544 20.762c-0.944 0-1.712-0.769-1.712-1.712s0.769-1.712 1.712-1.712c0.944 0 1.712 0.769 1.712 1.712s-0.769 1.712-1.712 1.712zM20.544 18.194c-0.475 0-0.856 0.381-0.856 0.856s0.387 0.856 0.856 0.856c0.475 0 0.856-0.387 0.856-0.856 0-0.475-0.387-0.856-0.856-0.856z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M18.494 28.788c-1.181 0-2.144-0.962-2.144-2.144s0.962-2.144 2.144-2.144c1.181 0 2.144 0.962 2.144 2.144-0.006 1.188-0.962 2.144-2.144 2.144zM18.494 25.363c-0.706 0-1.288 0.575-1.288 1.288 0 0.706 0.575 1.288 1.288 1.288 0.706 0 1.288-0.575 1.288-1.288-0.006-0.712-0.581-1.288-1.288-1.288z"></path>
                                    <path fill="#5e686c" d="M24.831 15.381c-8.050 0-12.556-5.144-12.756-5.375-0.15-0.194-0.256-0.175-0.369-0.019l-4.594 5.225c-0.156 0.175-0.425 0.194-0.606 0.037-0.175-0.156-0.194-0.425-0.037-0.606l4.569-5.2c0.369-0.494 1.287-0.519 1.7 0.025 0.031 0.031 4.794 5.45 13.006 5.031 0.256-0.012 0.438 0.169 0.45 0.406s-0.169 0.438-0.406 0.45c-0.319 0.019-0.644 0.025-0.956 0.025z"></path>
                                    <path fill="#5e686c" d="M16.369 31.806c-6.050 0-10.438-4.556-10.438-10.837 0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431c0 5.781 4.031 9.981 9.581 9.981s9.581-4.050 9.581-9.631c0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431c-0.012 5.981-4.5 10.488-10.45 10.488z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M28.788 21.719h-1.712c-0.931 0-1.712-0.788-1.712-1.712v-6c0-0.931 0.788-1.713 1.712-1.713h1.712c0.931 0 1.712 0.787 1.712 1.713v6c0 0.931-0.788 1.712-1.712 1.712zM27.075 13.15c-0.45 0-0.856 0.406-0.856 0.856v6c0 0.45 0.406 0.856 0.856 0.856h1.712c0.45 0 0.856-0.406 0.856-0.856v-6c0-0.45-0.406-0.856-0.856-0.856h-1.712z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M20.306 27.063c-0.238 0-0.431-0.194-0.431-0.431s0.194-0.431 0.431-0.431h1.587c0.137-0.012 4.056-0.35 4.056-5.131 0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431c0 5.6-4.837 5.981-4.887 5.988l-1.619 0.006z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M5.5 21.75h-1.725c-0.931 0-1.725-0.788-1.725-1.725v-6.037c0-0.931 0.788-1.725 1.725-1.725h0.519c0.044-6.662 5.481-12.075 12.163-12.075 6.7 0 12.15 5.612 12.15 12.506 0 0.238-0.194 0.431-0.431 0.431s-0.431-0.194-0.431-0.431c0-6.425-5.069-11.65-11.294-11.65-6.2 0-11.256 5.025-11.3 11.225h0.35c0.931 0 1.725 0.787 1.725 1.725v6.038c-0.006 0.931-0.794 1.719-1.725 1.719zM3.775 13.125c-0.45 0-0.863 0.412-0.863 0.863v6.037c0 0.45 0.413 0.869 0.862 0.869h1.725c0.45 0 0.869-0.413 0.869-0.869v-6.037c0-0.45-0.412-0.863-0.869-0.863h-1.725z"></path>
                                  </svg>
                                  <div class="title mergecolor" deptid="3">Technical Support</div>
                                  <p class="subtitle seccolor">Department related to technical issues or any technical questions regarding your service. (Monday to Friday from 8 am to 6 pm)</p>
                                  <a href="/whmcs/submitticket.php?step=2&amp;deptid=3" class="btn btn-default-yellow-fill">Technical Support</a>
                                </div>
                              </div>
                              <div class="col-md-4 aos-init" data-aos="fade-up" data-aos-duration="1000">
                                <div class="service-section bg-seccolorstyle bg-white noshadow">
                                  <div class="plans badge feat bg-pink">Get Support</div>
                                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg" src="templates/antler/assets/fonts/svg/helpdesk.svg" alt="Support">
                                    <title>helpdesk</title>
                                    <path fill="#5e686c" d="M11.994 20.762c-0.944 0-1.713-0.769-1.713-1.712s0.769-1.712 1.713-1.712c0.944 0 1.713 0.769 1.713 1.712s-0.769 1.712-1.713 1.712zM11.994 18.194c-0.475 0-0.856 0.381-0.856 0.856s0.387 0.856 0.856 0.856c0.475 0 0.856-0.387 0.856-0.856 0-0.475-0.381-0.856-0.856-0.856z"></path>
                                    <path fill="#5e686c" d="M20.544 20.762c-0.944 0-1.712-0.769-1.712-1.712s0.769-1.712 1.712-1.712c0.944 0 1.712 0.769 1.712 1.712s-0.769 1.712-1.712 1.712zM20.544 18.194c-0.475 0-0.856 0.381-0.856 0.856s0.387 0.856 0.856 0.856c0.475 0 0.856-0.387 0.856-0.856 0-0.475-0.387-0.856-0.856-0.856z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M18.494 28.788c-1.181 0-2.144-0.962-2.144-2.144s0.962-2.144 2.144-2.144c1.181 0 2.144 0.962 2.144 2.144-0.006 1.188-0.962 2.144-2.144 2.144zM18.494 25.363c-0.706 0-1.288 0.575-1.288 1.288 0 0.706 0.575 1.288 1.288 1.288 0.706 0 1.288-0.575 1.288-1.288-0.006-0.712-0.581-1.288-1.288-1.288z"></path>
                                    <path fill="#5e686c" d="M24.831 15.381c-8.050 0-12.556-5.144-12.756-5.375-0.15-0.194-0.256-0.175-0.369-0.019l-4.594 5.225c-0.156 0.175-0.425 0.194-0.606 0.037-0.175-0.156-0.194-0.425-0.037-0.606l4.569-5.2c0.369-0.494 1.287-0.519 1.7 0.025 0.031 0.031 4.794 5.45 13.006 5.031 0.256-0.012 0.438 0.169 0.45 0.406s-0.169 0.438-0.406 0.45c-0.319 0.019-0.644 0.025-0.956 0.025z"></path>
                                    <path fill="#5e686c" d="M16.369 31.806c-6.050 0-10.438-4.556-10.438-10.837 0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431c0 5.781 4.031 9.981 9.581 9.981s9.581-4.050 9.581-9.631c0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431c-0.012 5.981-4.5 10.488-10.45 10.488z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M28.788 21.719h-1.712c-0.931 0-1.712-0.788-1.712-1.712v-6c0-0.931 0.788-1.713 1.712-1.713h1.712c0.931 0 1.712 0.787 1.712 1.713v6c0 0.931-0.788 1.712-1.712 1.712zM27.075 13.15c-0.45 0-0.856 0.406-0.856 0.856v6c0 0.45 0.406 0.856 0.856 0.856h1.712c0.45 0 0.856-0.406 0.856-0.856v-6c0-0.45-0.406-0.856-0.856-0.856h-1.712z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M20.306 27.063c-0.238 0-0.431-0.194-0.431-0.431s0.194-0.431 0.431-0.431h1.587c0.137-0.012 4.056-0.35 4.056-5.131 0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431c0 5.6-4.837 5.981-4.887 5.988l-1.619 0.006z"></path>
                                    <path id="svg-ico" fill="#ee5586" d="M5.5 21.75h-1.725c-0.931 0-1.725-0.788-1.725-1.725v-6.037c0-0.931 0.788-1.725 1.725-1.725h0.519c0.044-6.662 5.481-12.075 12.163-12.075 6.7 0 12.15 5.612 12.15 12.506 0 0.238-0.194 0.431-0.431 0.431s-0.431-0.194-0.431-0.431c0-6.425-5.069-11.65-11.294-11.65-6.2 0-11.256 5.025-11.3 11.225h0.35c0.931 0 1.725 0.787 1.725 1.725v6.038c-0.006 0.931-0.794 1.719-1.725 1.719zM3.775 13.125c-0.45 0-0.863 0.412-0.863 0.863v6.037c0 0.45 0.413 0.869 0.862 0.869h1.725c0.45 0 0.869-0.413 0.869-0.869v-6.037c0-0.45-0.412-0.863-0.869-0.863h-1.725z"></path>
                                  </svg>
                                  <div class="title mergecolor" deptid="4">Sales Department</div>
                                  <p class="subtitle seccolor">Department related to sales of products and services. (Monday to Friday from 8 am to 6 pm and Saturday from 8 am to 12 pm)</p>
                                  <a href="/whmcs/submitticket.php?step=2&amp;deptid=4" class="btn btn-default-yellow-fill">Sales Department</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span id="gravataremail" class="hidden">Email</span>
    <div id="fullpage-overlay" class="hidden">
      <div class="outer-wrapper">
        <div class="inner-wrapper">
          <img src="/whmcs/assets/img/overlay-spinner.svg">
          <br>
          <span class="msg"></span>
        </div>
      </div>
    </div>
</div>
  </template>
  <script>
  export default{
    mounted(){
      this.$emit('closeLoading')
        // this.$emit('openLoading')
    }
  }
  </script>