<template>
  <div class="fullrock config sec-bg3 motpath bg-colorstyle">
    <router-link to="/" class="closebtn">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg closer bg-transparent" src="./assets/fonts/svg/close.svg" alt="">
        <title>close</title>
        <path fill="#5e686c" d="M27.188 4.775c-2.556-2.556-5.8-4.119-9.381-4.531-0.244-0.038-0.456 0.144-0.488 0.387-0.025 0.244 0.144 0.456 0.387 0.488 3.381 0.387 6.444 1.869 8.856 4.281 5.825 5.825 5.825 15.306 0 21.131s-15.306 5.825-21.131 0c-5.819-5.831-5.819-15.313 0.006-21.137 2.412-2.413 5.475-3.894 8.856-4.281 0.244-0.025 0.412-0.244 0.387-0.488s-0.25-0.419-0.488-0.388c-3.581 0.412-6.825 1.981-9.381 4.537-6.169 6.169-6.169 16.206 0 22.375 3.088 3.081 7.137 4.625 11.188 4.625s8.1-1.544 11.188-4.625c6.169-6.169 6.169-16.206 0-22.375z"></path>
        <path id="svg-ico" fill="#ee5586" d="M21.587 10.375c-0.169-0.169-0.45-0.169-0.619 0l-4.969 4.963-4.963-4.963c-0.169-0.169-0.45-0.169-0.619 0s-0.169 0.45 0 0.619l4.963 4.963-4.963 4.963c-0.169 0.169-0.169 0.45 0 0.619 0.088 0.087 0.2 0.131 0.313 0.131s0.225-0.044 0.313-0.131l4.963-4.962 4.962 4.962c0.087 0.087 0.2 0.131 0.313 0.131s0.225-0.044 0.313-0.131c0.169-0.169 0.169-0.45 0-0.619l-4.969-4.963 4.962-4.963c0.169-0.169 0.169-0.45 0-0.619z"></path>
      </svg>
    </router-link>
    <section class="fullrock-content">
      <div class="container">
        <Logo/>
        <div class="sec-main sec-bg1 tabs bg-seccolorstyle noshadow">
          <div class="randomline">
            <div class="bigline"></div>
            <div class="smallline"></div>
          </div>
          <h2 class="mergecolor"><b>Login or Create a New Account</b></h2>
          <p class="mb-5 mergecolor">If you are a returning customer, please Login if not, create a new account.</p>
          <div class="tabs-header btn-select-customer">
            <ul class="btn-group d-block">
              <li class="btn btn-secondary mb-2" :class="{active:$route.fullPath==='/login'}" @click="$router.push('login').catch(()=>{})">Already Registered?</li>
              <li class="btn btn-secondary" :class="{active:$route.fullPath==='/signup'}" @click="$router.push('signup').catch(()=>{})">Create a New Account</li>
            </ul>
          </div>
          <Login  v-if="$route.fullPath==='/login'" @closeLoading="$emit('closeLoading')" @openLoading="$emit('openLoading')"/>
          <Signup v-else-if="$route.fullPath==='/signup'" @closeLoading="$emit('closeLoading')" @openLoading="$emit('openLoading')"/>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Login from '@/components/AuthRegistration/Login'
import Signup from '@/components/AuthRegistration/Signup'  
import Logo from '@/components/Logo'
export default { 
  components:{ Logo, Login, Signup },
}
</script>
<style scoped>
.cd-filter-block label i { top:15px;  }
.logowhite0{fill:#FFFFFF;}
.logowhite1{fill:#EE5586;}
.logo0{fill:#E2E4E9;}
.logo1{fill:#EE5586;}
.logo2{fill:#E95585;}
</style>