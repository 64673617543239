<template>
    <div id="modal" class="modal">
      <div class="modal-content">
        <span class="close">
          <span  @click="$emit('close')">&times;</span>
        </span>
        <div class="row">
          <div class="alert alert-danger" v-if="errorMessage2"> {{ errorMessage2 }} </div>     
          <div class="alert alert-success" v-if="message2"> {{ message2 }}  Wait {{ sec }} second</div>     
          <div class="col-sm-12">
            <div class="table tabs-item active">
              <div class="cd-filter-block mb-0"> 
                <div class="cd-filter-content">
                  <div class="comments-form"> 
                    <div class="row">  
                      <div class="mb-5">
                        <span class="modal-number">+({{phone_prefix}}){{  phone }}</span>
                        <div class="col-md-12 position-relative"> 
                          <input class="mb-2" placeholder="Phone code" required v-model="phone_code">
                          <input class="mb-2" type="password" placeholder="Passwrod" required v-model="password">
                          <input class="mb-2" type="password" placeholder="Password confirmation" required v-model="password_confirmation">
                          <!-- <span style="color: #aaa">{{ tiemLeft }}</span> -->
                          <!-- <div v-if="tiemLeft === 0" class="mergecolor" style="cursor: pointer;" @click="sendMobileCodeAgain">
                            <small>{{$t('Send code again')}}</small>
                          </div> -->
                        </div>
                      </div> 
                      <button type="submit" :value="$t('Save')" @click="ChangePassword" class="btn btn-default-yellow-fill mb-1 disable-on-click spinner-on-click " >{{$t('Save')}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </template>
  <script>
  export default{
    props:['phone_prefix','phone'],
   data(){
      return{
        sec:5,
        type:"phone",  
        phone_code:"",
        password: "",
        password_confirmation: "",
        errorMessage2:"",
        message2:"", 
        // tiemLeft:60,
      }
    },
    methods:{
      ChangePassword(){
        this.$emit('openLoading')
        this.errorMessage2 = ''
        this.message2 = ''
        let data ={
          phone_code: this.phone_code,
          password: this.password,
          password_confirmation: this.password_confirmation,
          type: this.type, 
          phone_prefix:this.phone_prefix,
          phone:this.phone
        }
        this.$store.dispatch('reset_password_confirmation',data).then(()=>{  
        this.message2 = this.$store.state.auth.message
        this.$emit('closeLoading') 
        let interval = setInterval(()=>{ 
          this.sec -= 1
          if(this.sec === 0){
            clearInterval(interval)
            this.$router.push("/login").catch()
          }
        },1000) 
      }).catch(()=>{
        this.errorMessage2 = this.$store.state.auth.error_message
        this.$emit('closeLoading')
      })
      }
    },
    mounted(){ 
      // this.tiemLeft = 60
      // let interval = setInterval(()=>{
      //   if( this.tiemLeft !== 0){
      //     this.tiemLeft -= 1
      //   }else{
      //     clearInterval(interval)
      //   }
      // },1000)
    }
  }
  </script>
  <style>
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 500px;
  }
  
  .close {
    color: #aaa;
    display: block; 
    text-align: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-number{
      color:#aaa;
      margin-bottom:10px;
  }
  
  .edit-btn{
      color:#aaa;
  }
  </style>