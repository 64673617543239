import axios from 'axios'
const profile = {
  
  state: {
    message:'',
    data:[],
    user:[],
  },
  getters: { 
    user: (state) => state.user,
  },
  mutations: {
    set_success_message(state,data){
      state.message = data
    },
    set_data(state,data){
      state.data = data
    },
    update_user(state,data){
      state.user = data
    },
    set_error_message(state, response) {
      const errorMap = {
        404: "data_not_found",
        400: response.data.message,
        401: () => { this.commit('del_user') },
        403: response.data.message,
        405: "method_not_allowed",
        429: "too_many_requests",
        422: (response) =>
        response.data.success === false
          ? response.data.data.message
          : Object.values(response.data)[0][0],
        500: "please_try_again",
      };
      state.error_message = errorMap[response.status] instanceof Function
        ? errorMap[response.status](response) : errorMap[response.status] || "unknown_error";
    },
  },
  actions: {

    profile({commit}){
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_API_ENDPOINT + "account/profile", method: "GET" }).then((resp) => { 
          localStorage.setItem('profile',JSON.stringify(resp.data.data))
          commit("update_user",resp.data.data)
          resolve(resp);
        }).catch((err) => {
          commit("set_error_message", err.response);
          reject(err);
        });
      });
    },

    UpdateDetails({commit},data){
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'account/details', data:data, method:"PUT"}).then(resp => {
          // console.log(1,resp.data)
          // commit('set_user',resp.data)
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },

    update_password({commit},data){
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'account/password', data:data, method:"PUT"}).then(resp => {
          commit('set_success_message',resp.data.data)
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },  
    
    histories({commit},{url,queryString}){
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'account/'+url+"?"+queryString, method:"GET"}).then(resp => {
          commit('set_data',resp.data)
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },

    send_email_verification({commit}){
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'account/email/send-verify', method:"POST"}).then(resp => {
          commit('set_success_message',resp.data.data)
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },

    verify_email_address({commit},data){
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'account/email/verify/'+data.id+"/"+data.token+"?"+data.queryString, method:"POST"}).then(resp => {
          commit('set_success_message',resp.data.data)
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },

    enable_two_fa_google({commit},google_code){ 
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'account/enable-2fa', data:google_code, method:"POST"}).then(resp => {
          commit('set_success_message',resp.data.data)
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },  

    disable_two_fa_google({commit},google_code){ 
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'account/disable-2fa', data:google_code, method:"POST"}).then(resp => {
          commit('set_success_message',resp.data.data)
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },  

  },
  modules: {}
}

export default profile;

