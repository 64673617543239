<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="table tabs-item active">
        <div class="cd-filter-block mb-0"> 
          <div class="cd-filter-content"> 
            <div class="comments-form">
              <div class="alert alert-danger" v-if="errorMessage"> {{ errorMessage }} </div>
              <div class="row">
                <div class="col-md-6 position-relative">
                  <label><i class="fas fa-envelope"></i></label>
                  <input type="email" name="email" placeholder="Enter email" required v-model="email" @keyup.enter="login">
                </div>
                <div class="col-md-6 position-relative">
                  <label><i class="fas fa-lock"></i></label>
                  <input type="password" name="password" placeholder="Password" required v-model="password" @keyup.enter="login">
                </div>
                <div class="col-md-12 position-relative mt-3" v-if="showGoogleCode">
                  <label><i class="fas"></i></label>
                  <input type="text" placeholder="Google 2fa cide" required v-model="code" @keyup.enter="login">
                </div>
                <div class="col-md-12 mt-5 position-relative">
                  <button type="submit" value="login" id="login" @click="login" class="btn btn-default-yellow-fill mt-0 mb-3 me-3">Login <i class="fas fa-lock"></i>
                  </button>
                  <router-link to="reset" class="golink me-3 position-relative">Forgot Password?</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script> 
export default { 
  data(){
    return{
      email:'',
      password:'',
      code:"",
      errorMessage:'',
      showGoogleCode:false,
    }
  },
  computed:{
    isLoggedIn : function(){  
      return this.$store.getters.isLoggedIn
    },
  },
  watch:{
    isLoggedIn :{
      handler(n,o){
        if(n) this.$router.push("/").catch((e)=>{})
      }   
    }
  },
  methods:{
    login(){
      this.$emit('openLoading')
      let user = {
        email:this.email,
        password:this.password,
        google_code:this.code
      }
      this.$store.dispatch('login', user).then(()=>{
        this.$emit('closeLoading')
        this.$router.push("/account/my-account").catch((e) => {});       
      }).catch(()=>{
        if(this.$store.state.auth.google2fa){
          this.showGoogleCode = true
        }
        this.errorMessage = this.$store.state.auth.error_message 
        this.$emit('closeLoading')
      })
    }
  },
  mounted(){
    if(this.isLoggedIn){
      this.$router.push("/account/my-account").catch((e)=>{})
    }
    this.$emit('closeLoading')
  }
}
</script>
<style scoped>
  .cd-filter-block label i { top:15px;  }
  .logowhite0{fill:#FFFFFF;}
  .logowhite1{fill:#EE5586;}
  .logo0{fill:#E2E4E9;}
  .logo1{fill:#EE5586;}
  .logo2{fill:#E95585;}
</style>