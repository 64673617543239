<template>
  <div>
    {{$t('forgot')}}
  </div>
</template>

<script>
export default {
  name: "Forgot",
  mounted(){
    this.$emit('closeLoading')
      // this.$emit('openLoading')
  }
}
</script>