<template>
  <div>
    <div class="top-header item17 overlay">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="wrapper">
              <h1 class="heading">{{$t('Register your domain')}}</h1>
              <!-- <h3 class="subheading">.</h3> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper sec-normal">
      <div class="content bg-colorstyle noshadow nopadding">
        <div class="inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <section id="main-body">
                <div class="container">
                  <div class="main-content ">
                    <div class="row"> 
                      <div class="col-xs-12">  
                        <div id="order-standard_cart">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="header-lined">
                                <div class="text-center">
                                  <h2 class="section-heading mergecolor border-0 pt-50">Register Domain</h2>
                                  <p class="section-subheading mergecolor">Find your new domain name. Enter your name or keywords below to check availability.</p>
                                </div> 
                              </div>
                            </div>
                            <div class="col-md-12 mt-50"> 
                              <div class="domain-checker-container overlay">
                                <div class="domain-checker-bg clearfix">
                                  <div> 
                                    <div class="row text-center" style="display: block; position: relative">
                                      <div class="col-md-8 col-md-offset-2 col-xs-10 col-xs-offset-1" style="display: inline-block; position: relative">
                                        <div class="input-group input-group-lg input-group-box" style="display: block; text-align: end">
                                          <input type="text" class="form-control" placeholder="Find your new domain name" v-model="domain" @input="changeUrl"  @keyup.enter="search"> 
                                          <span class="input-group-btn">
                                            <button type="submit" class="btn btn-default-yellow-fill domain-check-availability" @click="search">Search</button>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="w-hidden" style="display: block;">
                                <div class="domain-checker-result-headline">
                                  <p v-if="searching" class="domain-lookup-loader domain-lookup-primary-loader domain-searching" style="display: block;">
                                    <i class="fas fa-spinner fa-spin"></i> Searching...
                                  </p>
                                  <div class="domain-lookup-result w-hidden" style="display: block;">
                                    <p v-if="errorMessage" class="domain-tld-unavailable domain-checker-unavailable" v-html="errorMessage"></p>
                                    <p v-if="message" class="domain-available domain-checker-available" v-html="message"></p>
                                    <p v-if="isAvaliable" class="domain-price">
                                      <span class="price mergecolor"></span>
                                      <button class="btn btn-primary btn-add-to-cart">
                                        <span v-if="mode==='add'" class="to-add" style="display: inline;" @click="addTocart(1)">
                                          Add to Cart <i class="fas fa-arrow-circle-right"></i>
                                        </span>
                                        <span v-if="mode==='loading'" class="loading" style="display: block;">
                                          <i class="fas fa-spinner fa-spin"></i> Loading... 
                                        </span>
                                        <span v-if="mode==='added'" class="added" style="display: block;">
                                          <i class="far fa-shopping-cart"></i> Checkout 
                                        </span>
                                        <span v-if="mode==='disabled'" class="unavailable" style="display: block;" disabled="disabled">
                                          Taken
                                        </span>   
                                      </button>
                                    </p>  
                                  </div>
                                </div> 
                                <div class="spotlight-tlds clearfix">
                                  <div class="spotlight-tlds-container">
                                    <div class="spotlight-tld-container spotlight-tld-container-5" v-for="(k,i) in suggested_domains " :key="i">
                                      <div class="spotlight-tld bg-seccolorstyle bg-pratalight mergecolor">
                                        {{ k.zone }}
                                        <!-- <span class="domain-lookup-loader domain-lookup-spotlight-loader" style="display: block;">
                                          <i class="fas fa-spinner fa-spin"></i>
                                        </span> -->
                                        <div class="domain-lookup-result" style="display: block;">
                                          <button type="button" class="btn unavailable w-hidden" disabled="disabled" style="display: inline-block;"> Unavailable </button>
                                          <span class="available price w-hidden" style="display: block;">{{ k.price }}{{ currency }}</span>
                                          <button type="button" class="btn btn-add-to-cart w-hidden" style="display: block;">
                                            <span class="to-add" style="display: block;">Add</span>
                                            <!-- <span class="loading" style="display: block;">
                                              <i class="fas fa-spinner fa-spin"></i> Loading... </span>
                                            <span class="added" style="display: block;">
                                              <i class="far fa-shopping-cart"></i> Checkout </span>
                                            <span class="unavailable" style="display: block;">Taken</span> -->
                                          </button>
                                          <!-- <button type="button" class="btn btn-primary domain-contact-support w-hidden" style="display: block;"> Contact Support to Purchase </button> -->
                                        </div>
                                      </div>
                                    </div> 
                                  </div>
                                </div>
                                <!-- <div class="suggested-domains w-hidden" style="display: block;">
                                  <div class="panel-heading card-header"> Suggested Domains </div>
                                  <div class="panel-body card-body domain-lookup-loader domain-lookup-suggestions-loader" style="display: block;">
                                    <i class="fas fa-spinner fa-spin"></i> Generating suggestions for you
                                  </div>
                                  <div class="domain-lookup-result list-group w-hidden" style="display: block;">
                                    <div class="domain-suggestion list-group-item w-hidden bg-seccolorstyle bg-pratalight mergecolor" style="display: block;">
                                      <span class="domain"></span>
                                      <span class="extension"></span>
                                      <span class="promo w-hidden">
                                        <span class="sales-group-hot w-hidden">Hot</span>
                                        <span class="sales-group-new w-hidden">New</span>
                                        <span class="sales-group-sale w-hidden">Sale</span>
                                      </span>
                                      <div class="actions">
                                        <span class="price"></span>
                                        <button type="button" class="btn btn-add-to-cart" >
                                          <span class="to-add" style="display: inline;">Add to Cart</span>
                                          <span class="loading" style="display: block;">
                                            <i class="fas fa-spinner fa-spin"></i> Loading... </span>
                                          <span class="added" style="display: block;">
                                            <i class="far fa-shopping-cart"></i> Checkout </span>
                                          <span class="unavailable" style="display: block;">Taken</span>
                                        </button>
                                        <button type="button" class="btn btn-primary domain-contact-support w-hidden"> Contact Support to Purchase </button>
                                      </div>
                                    </div>
                                    <div class="domain-suggestion list-group-item w-hidden bg-seccolorstyle bg-pratalight mergecolor clone" style="display: block;">
                                      <span class="domain">asd</span>
                                      <span class="extension">.co</span>
                                      <span class="promo w-hidden">
                                        <span class="sales-group-hot w-hidden">Hot</span>
                                        <span class="sales-group-new w-hidden">New</span>
                                        <span class="sales-group-sale w-hidden">Sale</span>
                                      </span>
                                      <div class="actions">
                                        <span class="price">$6.00 USD</span>
                                        <button type="button" class="btn btn-add-to-cart">
                                          <span class="to-add" style="display: inline;">Add to Cart</span>
                                          <span class="loading" style="display: block;">
                                            <i class="fas fa-spinner fa-spin"></i> Loading... </span>
                                          <span class="added" style="display: block;">
                                            <i class="far fa-shopping-cart"></i> Checkout </span>
                                          <span class="unavailable" style="display: block;">Taken</span>
                                        </button>
                                        <button type="button" class="btn btn-primary domain-contact-support w-hidden"> Contact Support to Purchase </button>
                                      </div>
                                    </div>
                                    <div class="domain-suggestion list-group-item w-hidden bg-seccolorstyle bg-pratalight mergecolor clone" style="display: block;">
                                      <span class="domain">asd</span>
                                      <span class="extension">.tel</span>
                                      <span class="promo w-hidden">
                                        <span class="sales-group-hot w-hidden">Hot</span>
                                        <span class="sales-group-new w-hidden">New</span>
                                        <span class="sales-group-sale w-hidden">Sale</span>
                                      </span>
                                      <div class="actions">
                                        <span class="price">$22.00 USD</span>
                                        <button type="button" class="btn btn-add-to-cart">
                                          <span class="to-add" style="display: inline;">Add to Cart</span>
                                          <span class="loading" style="display: block;">
                                            <i class="fas fa-spinner fa-spin"></i> Loading... </span>
                                          <span class="added" style="display: block;">
                                            <i class="far fa-shopping-cart"></i> Checkout </span>
                                          <span class="unavailable" style="display: block;">Taken</span>
                                        </button>
                                        <button type="button" class="btn btn-primary domain-contact-support w-hidden"> Contact Support to Purchase </button>
                                      </div>
                                    </div>
                                    <div class="domain-suggestion list-group-item w-hidden bg-seccolorstyle bg-pratalight mergecolor clone" style="display: block;">
                                      <span class="domain">asd</span>
                                      <span class="extension">.bank</span>
                                      <span class="promo w-hidden">
                                        <span class="sales-group-hot w-hidden">Hot</span>
                                        <span class="sales-group-new w-hidden">New</span>
                                        <span class="sales-group-sale w-hidden">Sale</span>
                                      </span>
                                      <div class="actions">
                                        <span class="price">$120.00 USD</span>
                                        <button type="button" class="btn btn-add-to-cart">
                                          <span class="to-add" style="display: inline;">Add to Cart</span>
                                          <span class="loading" style="display: block;">
                                            <i class="fas fa-spinner fa-spin"></i> Loading... </span>
                                          <span class="added" style="display: block;">
                                            <i class="far fa-shopping-cart"></i> Checkout </span>
                                          <span class="unavailable" style="display: block;">Taken</span>
                                        </button>
                                        <button type="button" class="btn btn-primary domain-contact-support w-hidden"> Contact Support to Purchase </button>
                                      </div>
                                    </div>
                                    <div class="domain-suggestion list-group-item w-hidden bg-seccolorstyle bg-pratalight mergecolor clone" style="display: block;">
                                      <span class="domain">asd</span>
                                      <span class="extension">.sexy</span>
                                      <span class="promo w-hidden">
                                        <span class="sales-group-hot w-hidden">Hot</span>
                                        <span class="sales-group-new w-hidden">New</span>
                                        <span class="sales-group-sale w-hidden">Sale</span>
                                      </span>
                                      <div class="actions">
                                        <span class="price">$122.00 USD</span>
                                        <button type="button" class="btn btn-add-to-cart">
                                          <span class="to-add" style="display: inline;">Add to Cart</span>
                                          <span class="loading" style="display: block;">
                                            <i class="fas fa-spinner fa-spin"></i> Loading... </span>
                                          <span class="added" style="display: block;">
                                            <i class="far fa-shopping-cart"></i> Checkout </span>
                                          <span class="unavailable" style="display: block;">Taken</span>
                                        </button>
                                        <button type="button" class="btn btn-primary domain-contact-support w-hidden"> Contact Support to Purchase </button>
                                      </div>
                                    </div>
                                    <div class="domain-suggestion list-group-item w-hidden bg-seccolorstyle bg-pratalight mergecolor clone" style="display: block;">
                                      <span class="domain">asd</span>
                                      <span class="extension">.domains</span>
                                      <span class="promo w-hidden">
                                        <span class="sales-group-hot w-hidden">Hot</span>
                                        <span class="sales-group-new w-hidden">New</span>
                                        <span class="sales-group-sale w-hidden">Sale</span>
                                      </span>
                                      <div class="actions">
                                        <span class="price">$22.99 USD</span>
                                        <button type="button" class="btn btn-add-to-cart">
                                          <span class="to-add" style="display: inline;">Add to Cart</span>
                                          <span class="loading" style="display: block;">
                                            <i class="fas fa-spinner fa-spin"></i> Loading... </span>
                                          <span class="added" style="display: block;">
                                            <i class="far fa-shopping-cart"></i> Checkout </span>
                                          <span class="unavailable" style="display: block;">Taken</span>
                                        </button>
                                        <button type="button" class="btn btn-primary domain-contact-support w-hidden"> Contact Support to Purchase </button>
                                      </div>
                                    </div>
                                    <div class="domain-suggestion list-group-item w-hidden bg-seccolorstyle bg-pratalight mergecolor clone" style="display: block;">
                                      <span class="domain">asd</span>
                                      <span class="extension">.info</span>
                                      <span class="promo w-hidden">
                                        <span class="sales-group-hot w-hidden">Hot</span>
                                        <span class="sales-group-new w-hidden">New</span>
                                        <span class="sales-group-sale w-hidden">Sale</span>
                                      </span>
                                      <div class="actions">
                                        <span class="price">$14.95 USD</span>
                                        <button type="button" class="btn btn-add-to-cart" >
                                          <span class="to-add" style="display: inline;">Add to Cart</span>
                                          <span class="loading" style="display: block;">
                                            <i class="fas fa-spinner fa-spin"></i> Loading... </span>
                                          <span class="added" style="display: block;">
                                            <i class="far fa-shopping-cart"></i> Checkout </span>
                                          <span class="unavailable" style="display: block;">Taken</span>
                                        </button>
                                        <button type="button" class="btn btn-primary domain-contact-support w-hidden"> Contact Support to Purchase </button>
                                      </div>
                                    </div>
                                    <div class="domain-suggestion list-group-item w-hidden bg-seccolorstyle bg-pratalight mergecolor clone" style="display: block;">
                                      <span class="domain">asd</span>
                                      <span class="extension">.com.au</span>
                                      <span class="promo w-hidden hot" style="display: inline;">
                                        <span class="sales-group-hot w-hidden" style="display: inline;">Hot</span>
                                        <span class="sales-group-new w-hidden">New</span>
                                        <span class="sales-group-sale w-hidden">Sale</span>
                                      </span>
                                      <div class="actions">
                                        <span class="price">$12.00 USD</span>
                                        <button type="button" class="btn btn-add-to-cart">
                                          <span class="to-add" style="display: inline;">Add to Cart</span>
                                          <span class="loading" style="display: block;">
                                            <i class="fas fa-spinner fa-spin"></i> Loading... </span>
                                          <span class="added" style="display: block;">
                                            <i class="far fa-shopping-cart"></i> Checkout </span>
                                          <span class="unavailable" style="display: block;">Taken</span>
                                        </button>
                                        <button type="button" class="btn btn-primary domain-contact-support w-hidden"> Contact Support to Purchase </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>  -->
                              </div>
                              <!-- <div class="domain-pricing" style="display: block;">
                                <div class="featured-tlds-container">
                                  <div class="row">
                                    <div class="col-sm-2"></div>
                                    <div class="col-lg-4 col-sm-6">
                                      <div class="featured-tld">
                                        <div class="img-container">
                                          <img src="/whmcs/assets/img/tld_logos/net.png">
                                        </div>
                                        <div class="price net"> $12.00/yr </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                      <div class="featured-tld">
                                        <div class="img-container">
                                          <img src="/whmcs/assets/img/tld_logos/com.png">
                                        </div>
                                        <div class="price com"> $14.00/yr </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> 
                              </div>   -->
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    data(){
      return{
        mode : "add", //loading added //disabled
        suggested_domains : [],
        domain : "",
        searching : false,
        message : '',
        errorMessage : '',
        isAvaliable : false,
        currency : "$",
        url : new URL(window.location.href),
      }
    },
    methods:{
      addTocart(id){
        this.mode = 'loading'
        let data = {"id":1,"type":"Domain Registration","price":"$12.00","time":"1 year","domain":this.domain}
        if(localStorage.getItem('cart_items')){ 
          const storedArray = JSON.parse(localStorage.getItem('cart_items')) 
          storedArray.forEach((item, index) => { 
            if (item.id !== id) { 
              item.push(data)
              localStorage.setItem('cart_items',JSON.stringify(item))
              return;
            }else{
              this.mode = "disabled"
            }
          })
        }else{ 
          localStorage.setItem('cart_items',JSON.stringify([data]))
          this.mode = "added"
          
        }
        this.$emit('updateCart')
      },
      changeUrl(){
        this.url.searchParams.set("domain", this.domain)
        window.history.replaceState(null, "", this.url);
      },
      search(){
        this.changeUrl()
        this.suggested_domains = []
        this.isAvaliable = false
        this.searching = true
        this.message = ''
        this.errorMessage = ''
        this.$store.dispatch('search_domain',this.domain).then(()=>{
          this.suggested_domains = [
            {id:1,price:"12.00",zone:"net",name:this.domain+"asd.net",status:true},
            {id:2,price:"11.00",zone:"us",name:this.domain+"asd.us",status:true},
            {id:3,price:"22.00",zone:"org",name:this.domain+"asd.org",status:false},
            {id:4,price:"12.00",zone:"com",name:this.domain+"asd.com",status:true},
            {id:5,price:"33.00",zone:"de",name:this.domain+"asd.de",status:true}
          ]
          this.isAvaliable = true
          this.searching = false
          this.message = '<strong>'+this.domain+'</strong> is available.'
        }).catch(()=>{
          this.suggested_domains = [
            {id:1,price:"12.00",zone:"net",name:this.domain+"asd.net",status:true},
            {id:2,price:"11.00",zone:"us",name:this.domain+"asd.us",status:true},
            {id:3,price:"22.00",zone:"org",name:this.domain+"asd.org",status:false},
            {id:4,price:"12.00",zone:"com",name:this.domain+"asd.com",status:true},
            {id:5,price:"33.00",zone:"de",name:this.domain+"asd.de",status:true}
          ]
          this.isAvaliable = false
          this.searching = false
          this.errorMessage = '<strong>'+this.domain+'</strong> domains are currently unavailable.'
        })
      }
    },
    mounted(){ 
      if(this.$route.query.domain){
        this.domain = this.$route.query.domain 
        this.search()
      }
      this.$emit('closeLoading')
      // this.$emit('openLoading')
    }
  }
  </script>