<template>
  <div id="modal" class="modal">
    <div class="modal-content">
      <span class="close">
        <span  @click="$emit('close')">&times;</span>
      </span>
      <div class="row">
        <div class="alert alert-danger" v-if="errorMessage2"> {{ errorMessage2 }} </div>     
        <div class="alert alert-success" v-if="message2"> {{ message2 }} </div>     
        <div class="col-sm-12">
          <div class="table tabs-item active">
            <div class="cd-filter-block mb-0"> 
              <div class="cd-filter-content">
                <div class="comments-form"> 
                  <div class="row">  
                    <div class="mb-5">
                      <span class="modal-number">+({{phone_prefix}}){{  phone }}</span>
                      <div class="col-md-12 position-relative"> 
                        <input class="mb-2" placeholder="Phone code" required v-model="activation_phone_code">
                        <span style="color: #aaa">{{ tiemLeft }}</span>
                        <div v-if="tiemLeft === 0" class="mergecolor" style="cursor: pointer;" @click="sendMobileCodeAgain">
                          <small>{{$t('Send code again')}}</small>
                        </div>
                      </div>
                    </div> 
                    <button type="submit" :value="$t('Save')" @click="saveCode" class="btn btn-default-yellow-fill mb-1 disable-on-click spinner-on-click " >{{$t('Save')}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>
<script>
export default{
  props:['phone_prefix','phone'],
 data(){
    return{
      error:"",
      newPhoneNumberInput:false,
      newPhoneNumber:"",
      oldMobile:"",
      errorMessage:"",
      errorMessage2:"",
      message2:"",
      activation_phone_code:"",
      tiemLeft:60,
    }
  },
  methods:{
    saveCode(){
      if(this.activation_phone_code.length >0){
        this.$emit('sendCode',this.activation_phone_code)
        this.$emit('close')
      }else{
        this.error =  this.$t('Write code')
      } 
    },
    sendMobileCodeAgain(){
      this.codeComing = true
      let phone = {
        phone_prefix:this.phone_prefix,
        phone: this.phone
      } 
    this.$store.dispatch('send_mobile_code',phone).then(()=>{
      this.message2 = this.$store.state.auth.message 
    }).catch(()=>{
      this.errorMessage2 = this.$store.state.auth.error_message
    })
    },
    editNumber(){
      this.newPhoneNumberInput = true
      this.$emit('editPhoneNumber',this.newPhoneNumber)
    }
  },
  mounted(){ 
    this.tiemLeft = 60
    let interval = setInterval(()=>{
      if( this.tiemLeft !== 0){
        this.tiemLeft -= 1
      }else{
        clearInterval(interval)
      }
    },1000)
  }
}
</script>
<style>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 500px;
}

.close {
  color: #aaa;
  display: block; 
  text-align: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-number{
    color:#aaa;
    margin-bottom:10px;
}

.edit-btn{
    color:#aaa;
}
</style>