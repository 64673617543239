<template>
  <div class="menu-item">
    <router-link to="/login" class="pe-0 me-0" v-if="!isLoggedIn"> 
      <div class="btn btn-default-yellow-fill question">
        <span>{{$t('client_area')}}</span> 
        <i class="fas fa-lock ps-1"></i> 
      </div>
    </router-link>
    <a href="javascript:void(0)" class="pe-0 me-0 dropdown-client-area" v-else> 
      <div class="btn btn-default-yellow-fill question dropbtn">
        <span>{{username}}</span> 
        <i class="fas fa-user ps-1"></i> 
        <div class="dropdown-client-area-content">
          <router-link to="/account/my-account">{{ $t('My account') }}</router-link>
          <router-link to="/account/settings">{{ $t('Settings') }}</router-link>
          <router-link to="/account/security">{{ $t('Security') }}</router-link>
          <router-link to="/account/histories">{{ $t('Histories') }}</router-link>
          <!-- <router-link to="/my-account">{{ $t('my_account') }}</router-link>
          <router-link to="/transfer-domain">{{ $t('transfer_domain') }}</router-link>
          <router-link to="/login-history">{{ $t('login_history') }}</router-link>
          <router-link to="/my-domains">{{ $t('my_domains') }}</router-link>
          <router-link to="/transaction-history">{{ $t('transaction_history') }}</router-link>
          <router-link to="/addfunds">{{ $t('addfunds') }}</router-link> -->
          <a href="javascript:void(0)"  @click="logout">{{ $t('logout') }}</a>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default{
  computed:{
    ...mapGetters(['profile','isLoggedIn']),
    username:function(){
      return typeof this.$store.getters.profile === 'string'  ? JSON.parse(this.$store.getters.profile).username : this.$store.getters.profile.username 
    }, 
  }, 
  watch:{
    $route:{
      handler(){
        this.checkVerification()
      },
      immediate:true,
      deep:true
    }
  },
  methods:{
    checkVerification(){  
      let email_verified_at = typeof this.$store.getters.profile === 'string'  ? JSON.parse(this.$store.getters.profile).email_verified_at : this.$store.getters.profile.email_verified_at
      if(this.$route.path.split('/')[1] === 'account'){
        if( this.$route.path !== "/account/settings/email-verification" && email_verified_at === null){
          this.$router.push('/account/settings/email-verification').catch(()=>{})
        }
      }
    },
    logout(){
      this.$emit('openLoading')
      this.$store.dispatch('logout').then(()=>{
        this.$emit('closeLoading') 
        this.$router.push("/login").catch(() => {});
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    }
  },
  mounted(){
    this.$emit('openLoading')
    if(this.isLoggedIn){
      this.$store.dispatch('profile').then( ()=>{
        this.$emit('closeLoading')
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    }

    this.checkVerification()
  }
}
</script>
<style scoped>
.dropdown-client-area {
  position: relative;
  display: inline-block;
}
.dropdown-client-area-content {
  display: none;
  position: absolute;
  background-color: #15212a;
  min-width: 166px;
  max-width: 200px;
  top: 30px;
  left: -18px;
}
.dropdown-client-area-content a {
  color: black;
  min-width: 166px;
  max-width: 200px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-client-area-content a:hover {
  background-color: #f1f1f1;
}
.dropdown-client-area:hover .dropdown-client-area-content {
  display: block;
}
.nav-menu .main-menu a {
  font-size: 12px;
  color: #fff;
  position: relative;
  cursor: pointer;
}
</style>