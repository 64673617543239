<template>
<div class="row">
  <div class="alert alert-danger" v-if="errorMessage"> {{ errorMessage }} </div>
  <div class="alert alert-success" v-if="message"> {{ message }} </div> 
  <div class="col-sm-12 seccolor">
    <small>Select account type : </small>
    <small>Personal <input type="radio" name="type" value="personal" v-model="type"></small>
    <small>Business <input type="radio" name="type" value="business" v-model="type"></small>
  </div>
  <div class="col-sm-12">
    <div class="table tabs-item active">
      <div class="cd-filter-block mb-0"> 
        <div class="cd-filter-content">
          <div class="comments-form">
            <div class="row">
              <div class="mb-5 d-flex">
                <div class="col-md-6 position-relative mr-5">
                  <label for="username"><i class="fas fa-user-tie"></i></label>
                  <input type="text" name="username" id="username" placeholder="Username" required v-model="username">
                </div>
                <div class="col-md-6 position-relative ml-5">
                  <label for="inputEmail"><i class="fas fa-envelope"></i></label>
                  <input type="email" name="email" id="inputEmail" placeholder="Email Address" required v-model="email">
                </div> 
              </div>
              <div class="mb-5 d-flex">
                <div class="col-md-6 position-relative mr-5">
                  <label for="inputNewPassword1"><i class="fas fa-lock"></i></label>
                  <input type="password" name="password" id="inputNewPassword1" placeholder="Password" v-model="password">
                </div>
                <div class="col-md-6 position-relative ml-5">
                  <label for="inputNewPassword2"><i class="fas fa-lock"></i></label>
                  <input type="password" name="password2" id="inputNewPassword2" placeholder="Confirm Password" v-model="password_confirmation">
                </div>
              </div>
              <div class="mb-5 d-flex">
                <div class="col-md-6 position-relative mr-5"> 
                  <select name="state" id="state" class="select-filter" v-model="phone_prefix">
                    <option v-for="(k,i) in phone_codes"  :key="i">{{k.phone_code}}</option>
                  </select> 
                </div>
                <div class="col-md-6 position-relative ml-5 d-flex">
                  <div class="col-md-6">
                    <input type="tel" name="phonenumber" 
                    style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                    id="inputPhone" placeholder="Phone Number" required v-model="phone">
                  </div>
                  <div class="col-md-6">
                    <input type="submit" :value="$t('Send code')" @click="sendMobileCode" class="btn btn-default-yellow-fill mb-1 disable-on-click spinner-on-click ">
                  </div>
                </div> 
              </div> 
              <MobileVerification v-if="codeComing" @close="codeComing=false" :phone_prefix="phone_prefix" :phone="phone" @sendCode="sendCode" />
              <div class="mb-5 d-flex">
                <div class="col-md-6 position-relative mr-5">
                  <select name="securityqid" id="inputSecurityQId" class="select-filter" v-model="security_question_id">
                    <option v-for="(k,i) in security_questions" :key="i" :value="k.id">{{k.question}}</option>
                  </select>
                </div>
                <div class="col-md-6 position-relative ml-5">
                  <label for="inputSecurityQAns"><i class="fas fa-lock"></i></label>
                  <input type="password" name="securityqans" id="inputSecurityQAns" placeholder="Please enter an answer" v-model="security_answer">
                </div> 
              </div> 
              <button type="submit" value="Submit" class="btn btn-default-yellow-fill mb-1 disable-on-click spinner-on-click " @click="signup">Register</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script> 
import MobileVerification from './MobileVerification'
  export default {
    components:{
      MobileVerification
    },
    data(){
      return{
        message:"",
        codeComing:false,
        phone_code:"test",
        errorMessage:"",
        phone_codes:[],
        security_questions:[],
        username:"",
        email:"",
        password:"",
        password_confirmation:"",
        phone_prefix:"995",
        phone:"",
        type:"personal",
        security_question_id:"",
        security_answer:"",
      }
    },
    methods:{
      sendCode(code){
        this.phone_code = code
      },
      sendMobileCode(){
        if(this.phone.length > 0){ 
          this.$emit('openLoading')
          let phone = {
            phone_prefix:this.phone_prefix,
            phone: this.phone
          }
          this.$store.dispatch('send_mobile_code',phone).then(()=>{
            this.errorMessage = ''
            this.message = this.$store.state.auth.message  
            this.codeComing = true
            this.$emit('closeLoading')
          }).catch(()=>{
            this.message ='' 
            this.errorMessage = this.$store.state.auth.error_message
            this.$emit('closeLoading')
          })
        }else{
          this.errorMessage = this.$t('Write phone number')
        }

      },
      signup(){
        this.message = ''
        this.errorMessage = ""
        this.$emit('openLoading')
        let data = {
          phone_code:this.phone_code,
          username:this.username,
          email:this.email,
          password:this.password,
          password_confirmation:this.password_confirmation,
          phone:this.phone,
          phone_prefix:this.phone_prefix,
          type:this.type,
          security_question_id:this.security_question_id,
          security_answer:this.security_answer
        }
        this.$store.dispatch('register',data).then(()=>{
          let user = {
            email:this.email,
            password:this.password
          }
          this.$store.dispatch('login', user).then(()=>{
            this.$emit('closeLoading')
            this.$router.push("/account/my-account").catch((e) => {});       
          })
        }).catch(()=>{
          this.errorMessage = this.$store.state.auth.error_message
          this.$emit('closeLoading')
        })
      }
    },
    mounted(){ 
      this.$emit('openLoading')
      this.$store.dispatch('register_data').then(()=>{ 
        this.phone_codes = this.$store.state.register_data.data.phone_codes
        this.security_questions = this.$store.state.register_data.data.security_questions
        this.security_question_id = this.security_questions[0].id
        this.$emit('closeLoading')
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    }
  }
  </script>
  <style scoped>
  .cd-filter-block label i { top:15px;  }
  .logowhite0{fill:#FFFFFF;}
  .logowhite1{fill:#EE5586;}
  .logo0{fill:#E2E4E9;}
  .logo1{fill:#EE5586;}
  .logo2{fill:#E95585;}
  </style>