const menu = [
  { id: 1, type:"internal", name:"home", url:"/", isLoggedIn:false },
  { id: 2, type:"internal", name:"domains", url:"#", isLoggedIn:false,
    childrens:[
      { id: 1, type:"internal", name:"register_domain", url:"/domains/register-domain", description:"Claim your online identity effortlessly with our simple and user-friendly domain registration process."},
      { id: 3, type:"internal", name:"domain_prices", url:"/domains/domain-prices", description:"Vezra offers transparent and competitive domain pricing plans to suit every budget."},
      { id: 3, type:"internal", name:"domain_transfer", url:"/domains/domain-transfer", description:"Ensure a secure and hassle-free transition with our platform for seamless domain transfers."}
    ]
  },
  { id: 4, type:"internal", name:"whois_inquiry", url:"/domains/whois-inquiry", description:"whois_inquiry_description"},
  { id: 3, type:"internal", name:"guide_center", url:"#", isLoggedIn:false,
    childrens:[ 
      { id: 1, type: "internal", name: "Domain Deletion : Renewal Procedures", slug:"deletion-renewal-procedures", url:"/guide-center/deletion-renewal-procedures"},
      { id: 2, type: "internal", name: "Return Policy", slug:"return-policy", url:"/guide-center/return-policy"},
      { id: 3, type: "internal", name: "Privacy Policy", slug:"privacy-polocy", url:"/guide-center/privacy-polocy"},
      { id: 4, type: "internal", name: "Cookie Policy", slug:"cookie-policy", url:"/guide-center/cookie-policy"},
      { id: 5, type: "internal", name: "Registrants\' Responsibilities", slug:"registrants-responsibilities", url:"/guide-center/registrants-responsibilities"},
      { id: 6, type: "internal", name: "User Agreement", slug:"user-agreement", url:"/guide-center/user-agreement"},
      { id: 7, type: "external", name: "WHOIS Data Reminder Policy", slug: "", url: "https://www.icann.org/registrants"},
      { id: 8, type: "external", name: "Registrant Information", slug: "",  url:"https://www.icann.org/registrants"},
      { id: 9, type: "external", name: "Expired Registration Recovery Policy", slug: "", url:"https://www.icann.org/resources/pages/errp-2013-02-28-en"} ,
      { id: 10, type: "external", name: "Dispute Resolution Policy", slug: "", url:"https://www.icann.org/resources/pages/help/dndr/udrp-en?search_text=abuse&previous_text=&commit=%EF%80%82"},
    ]
  },
  {
    id: 5, type:"internal", name:"contact_us", url:"#", isLoggedIn:false,
    childrens:[
      { id: 1, type:"internal", name:"about_us", url:"/about-us", description:""},
      { id: 2, type:"internal", slug: "registration-agreement", name: "contact_us", url: "/contact"},
      { id: 3, type:"internal", slug: "report-abuse", name: "Report Abuse", url: "/report-abuse" },
    ]
  },
]
export default menu;