<template>
  <header>
    <InfoNews :news="news" :number="number" :isLoggedIn="isLoggedIn" @openLoading="$emit('openLoading')" @closeLoading="$emit('closeLoading')"/>
    <Nav :updateCart="updateCart"  @UpdateAgainCart="$emit('UpdateAgainCart')" :menu="menu" :isLoggedIn="isLoggedIn" :logo="logo" @openLoading="$emit('openLoading')" @closeLoading="$emit('closeLoading')"/>
    <MobileNav :menu="menu" :isLoggedIn="isLoggedIn" :logo="logo" @openLoading="$emit('openLoading')" @closeLoading="$emit('closeLoading')"/> 
    <!-- <Langs/> -->
  </header>
</template>
<script>
import menu from '@/menu.js'
import Nav from '@/components/Nav'
import MobileNav from '@/components/MobileNav'
import Langs from '@/components/Langs'
import InfoNews from '@/components/InfoNews' 
export default{
  props:['isLoggedIn','logo','updateCart','UpdateAgainCart'],
  components:{
    MobileNav,
    Nav,
    Langs,
    InfoNews
  },
  data(){
    return{
      menu:menu,
      news:'',
      number:'',
    } 
  }
}
</script>