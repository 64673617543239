import axios from 'axios'
const price_list = {
    state: {
        data:[],
        message:'',
    },
    getters: {
        data: state => state.data,
    },
    mutations: {
        set_data(state, data) {
            state.data = data
        },
        set_error(state, data) {
            state.message = data
        },
    },
    actions: {
        price_list({commit},queryString){
            return new Promise((resolve, reject) => {
                axios({url: process.env.VUE_APP_API_ENDPOINT+'/price-list?'+queryString, method:"GET"}).then(resp => {
                    commit('set_data',resp.data)
                    resolve(resp)
                }).catch(err => {
                    // if(err.response.status === 404){
                    //     commit('set_error', 'data_not_found')
                    // }else if(err.response.status === 400){
                    //     console.log(400,err.response)
                    // }else if(err.response.status === 422){
                    //     for (let i in err.response.data) {
                    //         commit('set_error', err.response.data[i][0])
                    //     }
                    // }else if(err.response.status === 500){
                    //     for (let i in err.response.data) {
                    //         commit('set_error', 'technical_problem')
                    //     }
                    // }
                    reject(err)
                })
            })
        },
    },
    modules: {
    }
}

export default price_list;

