<template>
  <div class="row">
    <div class="alert alert-danger" v-if="errorMessage2">{{ errorMessage2 }}</div>
    <div class="alert alert-success" v-if="meesage2">{{ meesage2 }}</div> 
    <div class="col-md-12">
      <div class="container">
        <div class="row text-center">
          <h2 class="section-heading mergecolor text-center w-100">{{ $t('Aditional information') }}</h2>
        </div>
      </div>
    </div>
    <div class="col-md-12  pb-3">
      <div class="bg-seccolorstyle bg-white noshadow mt-50 p-50 br-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="control-label c-black mergecolor">{{ $t('First Name') }} *</label>
              <input type="text" class="form-control" v-model="details.first_name">
            </div>
            <div class="form-group">
              <label class="control-label c-black mergecolor">{{ $t('Last Name') }} *</label>
              <input type="text" class="form-control" v-model="details.last_name">
            </div>
            <div class="form-group">
              <label class="control-label c-black mergecolor">{{ $t('Company Name') }} *</label>
              <input type="text" class="form-control" v-model="details.company_name">
            </div>
            <div class="form-group">
              <label class="control-label c-black mergecolor">{{ $t('Address') }} 1 *</label>
              <input type="text" class="form-control" v-model="details.address">
            </div>
            <div class="form-group">
              <label class="control-label c-black mergecolor">{{ $t('Address') }} 2</label>
              <input type="text" class="form-control" v-model="details.address_second">
            </div>
          </div> 
          <div class="col-sm-6">
            <div class="form-group">
              <label class="control-label c-black mergecolor">{{ $t('City') }} *</label>
              <input type="text" class="form-control" v-model="details.city">
            </div>
            <div class="form-group">
              <label class="control-label c-black mergecolor">{{ $t('State') }}/{{ $t('Region') }} *</label>
              <input type="text" class="form-control" v-model="details.state">
            </div>
            <div class="form-group">
              <label class="control-label c-black mergecolor">{{ $t('Country') }} *</label> 
              <select class="form-control custom-select" required="required" v-model="details.country_id">
                <option :value="k.id" v-for="(k,i) in countries" :key="i">{{k.name}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label c-black mergecolor">{{ $t('Zip Code') }}</label>
              <input type="text" class="form-control" v-model="details.zip_code">
            </div>
          </div>
          <button class="btn btn-primary mr-5 mt-3" type="submit" name="save" @click="UpdateDetails">{{$t('Save Changes')}}</button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>  
</template>
<script>
import { mapGetters } from 'vuex'
export default{
  computed:{
    ...mapGetters(['profile']),
  },
  data(){
    return{
      errorMessage2:"",
      meesage2:"",
      details:{
        address:"",
        address_second:"",
        city:"",
        company_name:"",
        country_id:"",
        first_name :"",
        last_name :"",
        state:"",
        zip_code:"",
      },
      countries:[],
    }
  },
  watch:{
    $route:{
      handler(n,o){
        if(n){
          this.$emit('openLoading')
          this.Countries()
          this.user = typeof this.$store.getters.profile === 'string'  ? JSON.parse(this.$store.getters.profile) : this.$store.getters.profile
          if(this.user.details){
            for(let i in this.user.details){
              this.details[i] = this.user.details[i] 
            }
          }
        }
      },
      immediate:true,
      deep:true
    }
  }, 
  methods:{ 
    Countries(){
      this.$store.dispatch('countries').then(()=>{
        this.countries = this.$store.state.countries.data
        this.details.country_id = this.countries[0].id
        this.$emit('closeLoading')
      })
    },
    UpdateDetails(){
      this.$emit('openLoading')
      this.$store.dispatch('UpdateDetails',this.details).then(()=>{
        this.errorMessage2 = ''
        this.meesage2 = "Information updated successfully"
        this.$emit('closeLoading')
      }).catch(()=>{
        this.meesage2 = ''
        this.errorMessage2 = this.$store.state.profile.error_message 
        this.$emit('closeLoading')
      }) 
    },
  }
}
</script>