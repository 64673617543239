<template>
  <div class="box-container limit-width">
    <div class="bg-colorstyle">
      <div class="top-header item17 overlay">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="wrapper">
                <h1 class="heading">{{$t('My domains')}}</h1>
                <h3 class="subheading">{{$t('Here is your domain list')}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper sec-normal">
        <div class="content bg-colorstyle noshadow nopadding">
          <div class="inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <section id="main-body">
                  <div class="container">
                    <div class="col-md-12">
                      <div class="table-container clearfix">
                        <div id="tableQuotesList_wrapper" class="dataTables_wrapper form-inline dt-bootstrap no-footer">
                          <div class="listtable table-responsive">
                            <div id="tableQuotesList_filter" class="dataTables_filter">
                              <label><input type="search" class="form-control input-sm" placeholder="" aria-controls="tableQuotesList"></label></div>
                            <div class="dataTables_info" id="tableQuotesList_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div>
                            <table class="table table-list" aria-describedby="tableQuotesList_info" role="grid" style="width: 0px;">
                              <thead>
                              <tr role="row">
                                <th class="sorting_desc" tabindex="0" aria-controls="tableQuotesList" rowspan="1" colspan="1" style="width: 0px;" aria-label="Quote #: activate to sort column ascending" aria-sort="descending">Quote #</th>
                                <th class="sorting" tabindex="0" aria-controls="tableQuotesList" rowspan="1" colspan="1" style="width: 0px;" aria-label="Subject: activate to sort column ascending">Subject</th>
                                <th class="sorting" tabindex="0" aria-controls="tableQuotesList" rowspan="1" colspan="1" style="width: 0px;" aria-label="Date Created: activate to sort column ascending">Date Created</th>
                                <th class="sorting" tabindex="0" aria-controls="tableQuotesList" rowspan="1" colspan="1" style="width: 0px;" aria-label="Valid Until: activate to sort column ascending">Valid Until</th>
                                <th class="sorting" tabindex="0" aria-controls="tableQuotesList" rowspan="1" colspan="1" style="width: 0px;" aria-label="Stage: activate to sort column ascending">Stage</th>
                                <th class="sorting_disabled" rowspan="1" colspan="1" style="width: 0px;" aria-label="&amp;nbsp;">&nbsp;</th>
                                <th class="responsive-edit-button sorting" style="width: 0px;" tabindex="0" aria-controls="tableQuotesList" rowspan="1" colspan="1" aria-label=": activate to sort column ascending"></th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr class="odd">
                                <td valign="top" colspan="6" class="dataTables_empty">No Records Found</td></tr></tbody>
                            </table>
                          </div>
                          <div class="dataTables_paginate paging_simple_numbers" id="tableQuotesList_paginate">
                            <ul class="pagination"><li class="paginate_button previous disabled" aria-controls="tableQuotesList" tabindex="0" id="tableQuotesList_previous">
                              <a href="#">Previous</a></li><li class="paginate_button next disabled" aria-controls="tableQuotesList" tabindex="0" id="tableQuotesList_next">
                              <a href="#">Next</a></li></ul></div><div class="dataTables_length" id="tableQuotesList_length">
                          <label>Show <select name="tableQuotesList_length" aria-controls="tableQuotesList" class="form-control input-sm">
                            <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="-1">All</option>
                          </select> entries</label></div></div>
                        <div class="text-center hidden" id="tableLoading">
                          <p><i class="fas fa-spinner fa-spin"></i> Loading...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MyDomainList",
  mounted(){
    this.$emit('closeLoading')
      // this.$emit('openLoading')
  }
}
</script>

<style scoped>

</style>