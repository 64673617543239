<template>
  <div class="sec-bg3 infonews">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 col-md-7 news">
          <span class="badge bg-purple me-2"> {{ $t('news') }}</span>
          <span>{{ news }}</span>
          <span>
            <router-link to="/vps" class="golink" :title="$t('cloud_overview')">
              {{$t('cloud_overview')}}
              <i class="fas fa-arrow-circle-right"></i>
            </router-link>
          </span>
        </div>
        <div class="col-6 col-md-5 link">
          <div class="infonews-nav float-end">
            <router-link to="/blog-details" class="iconews" :title="$t('blog')"><i class="ico-message-content f-18 w-icon"></i></router-link>
            <router-link to="/contact" class="iconews" :title="$t('contact_us')"><i class="ico-bell f-18 w-icon"></i></router-link>
            <router-link to="/cart" class="iconews" :title="$t('login')"><i class="ico-shopping-cart f-18 w-icon"></i></router-link>
            <router-link to="javascript:void(0)" :title="$t('phone_number')" class="iconews tabphone" >{{ number }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  props:['news','number'],
}
</script>