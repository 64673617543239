import Vue from "vue";
import axios from 'axios'
import Vuex from "vuex";

Vue.use(Vuex);

import price_list from './price_list' 
import profile from './profile'
import auth from './auth'
import site from './site'

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    data:[],
    error_message:"",
    success_message:"",
    countries:[],
  },
  getters: {},
  mutations: {
    set_register_data(state,data){
      state.register_data = data
    },
    set_countries(state,data){
      state.countries = data
    },
    set_error_message(state, response) {
      const errorMap = {
        404: "data_not_found",
        400: "bad_request",
        403: "forbidden",
        405: "method_not_allowed",
        429: "too_many_requests",
        422: (response) =>
          response.data.success === false
            ? response.data.data.message
            : Object.values(response.data)[0][0],
        500: "please_try_again",
      };
      state.error_message =
        errorMap[response.status] instanceof Function
          ? errorMap[response.status](response)
          : errorMap[response.status] || "unknown_error";
    },
  },
  actions: {
    register_data({ commit }) {
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_API_ENDPOINT + "register-data", method: "GET" }).then((resp) => {
          commit("set_register_data", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          commit("set_error_message", err.response);
          reject(err);
        });
      });
    },
    countries({ commit }) {
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_API_ENDPOINT + "account/countries", method: "GET" }).then((resp) => {
          commit("set_countries", resp.data);
          resolve(resp);
        }).catch((err) => {
          commit("set_error_message", err.response);
          reject(err);
        });
      });
    },

  },
  modules: {
    price_list:price_list, 
    profile:profile,
    auth:auth,
    site:site
  }
})
