<template>
<div>
  <div class="bg-colorstyle">
    <div class="top-header item17 overlay">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="wrapper">
              <h1 class="heading">Vezra.com</h1>
              <h3 class="subheading">.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper sec-normal">
      <div class="content bg-colorstyle noshadow nopadding">
        <div class="inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <section id="main-body">
                <div class="container">
                  <div class="main-content">
                    <div class="row"> 
                      <div class="col-xs-12">  
                        <div id="order-standard_cart">
                          <div class="domain-checker-container overlay mt-50">
                            <div class="domain-checker-bg clearfix">
                              <div class="row"> 
                                <div class="col-md-8 col-md-offset-2 col-xs-10 col-xs-offset-1" style="left: 14rem; position: relative;">
                                  <div class="col-sm-12 seccolor">
                                    <small>Select type : </small>
                                    <small>Email <input type="radio" name="type" value="email" v-model="type" @click="type='email';$router.push('/reset/'+type)"></small>
                                    <small>Phone <input type="radio" name="type" value="phone" v-model="type" @click="type='phone';$router.push('/reset/'+type)"></small>
                                  </div>
                                  <strong style="text-align: center; display: block; padding-bottom: 2rem" class="section-subheading mergecolor">Lost Password Reset With {{ type }}</strong>
                                  <Email v-if="type === 'email'" @closeLoading="$emit('closeLoading')" @openLoading="$emit('openLoading')"/>
                                  <Mobile v-if="type === 'phone'" @closeLoading="$emit('closeLoading')" @openLoading="$emit('openLoading')"/> 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import Email from '@/components/ResetPassword/Email'
import Mobile from '@/components/ResetPassword/Mobile'
export default {
  components:{ Email, Mobile },
  name: "Reset",
  data(){
    return{
      type:"email", 
    }
  },
  mounted(){
    this.type = this.$route.params.type ==='phone' ? 'phone' : 'email'
  }
}
</script> 