<template>
    <div class="wrapper sec-normal">
      <div class="top-header item17 overlay">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="wrapper">
              <h1 class="heading">vezra.com</h1>
              <h3 class="subheading">.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="wrapper sec-normal">
        <div class="content bg-colorstyle noshadow nopadding">
         <div class="inner-content">
           <div class="main-body">
             <div class="page-wrapper">
                <div class="container">
                 <div class="main-content">
                  <div class="row">
                    <div class="col-md-12 mt-70">
                      <div class="header-lined">
                        <div class="text-center">
                          <h2 class="section-heading mergecolor border-0">Transfer your domain to us</h2>
                          <p class="section-subheading mergecolor mb-15">Transfer now to extend your domain by 1 year!*</p>
                        </div> 
                      </div>
                      <div class="domain-transfer-container overlay">
                        <div class="domain-transfer-bg clearfix"> 
                          <div class="row" style="justify-content: center;">
                            <div class="col-sm-8 col-sm-offset-2">
                              <!-- <div class="alert alert-danger slim-alert text-center">error</div>
                              <div class="alert alert-success slim-alert text-center">success</div> -->
                                <div class="panel panel-default text-center">
                                <span class="renewed-point text-muted f-14">* Excludes certain TLDs and recently renewed domains</span>
                                <div class="panel-body">
                                  <div class="form-group">
                                    <input type="text" class="form-control form-control-manual" placeholder="Domain Name">
                                  </div>
                                  <div class="form-group">
                                    <input type="text" class="form-control form-control-manual">
                                  </div>
                                  <div class="form-group recaptcha-container"></div>
                                </div>
                                <div class="panel-footer text-center">
                                  <button type="submit" class="btn btn-lg btn-default-yellow-fill btn-transfer">
                                    <span class="loader hidden">
                                      <i class="fas fa-fw fa-spinner fa-spin"></i>
                                    </span>
                                    <span>Add to Cart</span>
                                    <i class="ico-chevron-right w-icon"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
</template>
<script>
export default{
  mounted(){
    this.$emit('closeLoading')
      // this.$emit('openLoading')
  }
}
</script>
<style scoped>
.domain-transfer-container {
  background-image: url(/assets/img/sliders/domains.jpg);
}
.sec-normal {
  padding-bottom: 80px;
  background-color: #15212a!important;
}
 
</style>