<template>
  <div class="row">
    <div class="alert alert-danger" v-if="errorMessage"> {{ errorMessage }} </div>
    <div class="alert alert-success" v-if="meesage"> {{ meesage }} </div>
    <div class="col-md-12">
      <div class="container">
        <div class="row text-center">
          <h2 class="section-heading mergecolor text-center w-100">{{ $t('Information') }}</h2>
        </div>
      </div>
    </div>  
    <div class="col-sm-12">
      <div class="table tabs-item active">
        <div class="cd-filter-block mb-0"> 
          <div class="cd-filter-content">
            <div class="comments-form"> 
              <div class="col-md-12  pb-3">
                <div class="bg-seccolorstyle bg-white noshadow mt-50 p-50 br-12">
                  <div class="row">
                    <div class="col-sm-12 seccolor mb-4">
                      <small>{{ $t('Your Account type is') }} : </small>
                       {{  user.type }} 
                    </div>
                    <div class="mb-5 d-flex">
                      <div class="col-md-6 position-relative mr-5">
                        <label><i class="fas fa-user-tie"></i></label>
                        <input class="cursor-disabled" type="text" :placeholder="$t('Username')" :value="user.username" disabled>
                      </div>
                      <div class="col-md-6 position-relative ml-5">
                        <label ><i class="fas fa-envelope"></i></label>
                        <input type="email" :placeholder="$t('Email Address')" required v-model="user.email">
                      </div> 
                    </div>
                    <div class="mb-5 d-flex">
                      <div class="col-md-6 position-relative mr-5">
                        <select class="select-filter" v-model="user.phone_prefix">
                          <option v-for="(k,i) in phone_codes"  :key="i">{{k.phone_code}}</option>
                        </select> 
                      </div>
                      <div class="col-md-6 position-relative ml-5">
                        <label><i class="fas fa-phone"></i></label>
                        <input type="tel" :placeholder="$t('Phone Number')" required v-model="user.phone">
                      </div> 
                    </div>
                    <button type="submit" value="Submit" class="btn btn-default-yellow-fill mb-1 disable-on-click spinner-on-click" @click="editProfile">{{ $t('Update') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default{
  data(){
    return{
      meesage:"",
      errorMessage:"",    
      username:"",
      email:"",
      phone:"",
      phone_prefix:"",
      type:"",
      phone_codes:"",
      user:[],
    }
  },
  computed:{
    ...mapGetters(['profile']),
  },
  watch:{
    $route:{
      handler(n,o){
        if(n){
          this.$emit('openLoading')
          this.registerData()
          this.user = typeof this.$store.getters.profile === 'string'  ? JSON.parse(this.$store.getters.profile) : this.$store.getters.profile
        }
      },
      immediate:true,
      deep:true
    }
  },
  methods:{
    registerData(){
      this.$store.dispatch('register_data').then(()=>{
        this.phone_codes = this.$store.state.register_data.data.phone_codes 
        this.$emit('closeLoading')
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    editProfile(){
      // wait api
      this.meesage  = "Information updated successfully"
    }
  },
 
}
</script>
<style>
.cursor-disabled{
  cursor: not-allowed;
}
</style>