<template>
  <div class="modal fade modal-remove-item show">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click="$emit('close')">
            <span>×</span>
          </button>
          <h4 class="modal-title">
            <i class="ico-trash-2 f-54 c-grey"></i>
            <span class="c-grey">Remove Item</span>
          </h4>
        </div>
        <div class="modal-body"> Are you sure you want to remove this item from your cart? </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-default" @click="$emit('close')">No</button>
          <button type="submit" class="btn btn-sm btn-danger" @click="deleteItem">Yes</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  props:['id'],
  methods:{
    deleteItem(){
      const storedArray = JSON.parse(localStorage.getItem('cart_items')) 
      let indexOfItemToRemove = -1;
      storedArray.forEach((item, index) => {
        if (item.id === this.id) {
          indexOfItemToRemove = index;
          return;
        }
      });
      if (indexOfItemToRemove !== -1) {
        storedArray.splice(indexOfItemToRemove, 1);
        localStorage.setItem('cart_items', JSON.stringify(storedArray));
        this.$emit('updateCart')
      } 
    }
  }
}
</script>