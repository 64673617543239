<template>
  <div class="box-container limit-width">
    <div class="bg-colorstyle">
      <div class="top-header item17 overlay">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="wrapper">
                <h1 class="heading">{{$t('Login history')}}</h1>
                <h3 class="subheading">{{$t('Here is your login history')}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper sec-normal">
        <div class="content bg-colorstyle noshadow nopadding">
          <div class="inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <section id="main-body">
                  <div class="container">
                    <div class="main-content">
                      <div class="tabs-header btn-select-customer">
                        <ul class="btn-group d-block">
                          <li class="btn btn-secondary mb-2" :class="{active:$route.fullPath==='/account/histories'}" @click="$router.push('/account/histories').catch(()=>{})">{{ $t('Login history') }}</li>
                         </ul>
                      </div> 
                      <LoginHistory v-if="LoginHistoryTab" @closeLoading="$emit('closeLoading')" @openLoading="$emit('openLoading')"/>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginHistory from '@/components/AccountHisotries/LoginHistory' 
export default {
  components:{ LoginHistory },
  data(){
    return{ 
      LoginHistoryTab:true, 
    }
  },
  watch:{
    $route:{
      handler(n,o){
        if(n){
          this.changeRoute()
        }
      }
    }
  },
  methods:{
    changeRoute(){
      if(this.$route.path === '/account/histories'){
        this.LoginHistoryTab = true 
      }else if(this.$route.path === '/account/histories/login-history'){
        this.LoginHistoryTab = false 
      }else{
        this.LoginHistoryTab = true 
      }
    }
  },
  mounted(){
    this.changeRoute()
  }
}
</script>
<style>
  .cd-filter-block label i { top:15px;  }
</style> 