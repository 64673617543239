<template>
  <div class="box-container limit-width">
    <div class="bg-colorstyle">
      <div class="top-header item17 overlay">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="wrapper">
                <h1 class="heading">{{$t('Settings')}}</h1>
                <h3 class="subheading">{{$t('Here is your account settings')}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper sec-normal">
        <div class="content bg-colorstyle noshadow nopadding">
          <div class="inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <section id="main-body">
                  <div class="container">
                    <div class="main-content">
                      <div class="tabs-header btn-select-customer">
                        <ul class="btn-group d-block">
                          <li class="btn btn-secondary mb-2" :class="{active:$route.fullPath==='/account/settings'}" @click="$router.push('/account/settings').catch(()=>{})">{{ $t('Information') }}</li>
                          <li class="btn btn-secondary" :class="{active:$route.fullPath==='/account/settings/additional-information'}" @click="$router.push('/account/settings/additional-information').catch(()=>{})">{{$t('Additional information')}}</li>
                          <li v-if="email_verified_at === null" class="btn btn-secondary" :class="{active:$route.fullPath==='/account/settings/email-verification'}" @click="$router.push('/account/settings/email-verification').catch(()=>{})">{{$t('Email verification')}}</li>
                        </ul>
                      </div>  
                      <Information v-if="InformationTab" @closeLoading="$emit('closeLoading')" @openLoading="$emit('openLoading')"/>
                      <AdditionalInformation v-if="AdditionalInformationTab" @closeLoading="$emit('closeLoading')" @openLoading="$emit('openLoading')"/>
                      <EmailVerification v-if="EmailVerificationTab && email_verified_at === null" @closeLoading="$emit('closeLoading')" @openLoading="$emit('openLoading')"/>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Information from '@/components/AccountSettings/Information'
import AdditionalInformation from '@/components/AccountSettings/AdditionalInformation'
import EmailVerification from '@/components/AccountSettings/EmailVerification'
import { mapGetters } from 'vuex'
export default {
  computed:{
    ...mapGetters(['profile','isLoggedIn']),
  },
  components:{
    Information,
    AdditionalInformation,
    EmailVerification
  },
  data(){
    return{ 
      InformationTab:true,
      AdditionalInformationTab:false,
      EmailVerificationTab:false,
      email_verified_at:'',
    }
  },
  watch:{
    $route:{
      handler(n,o){
        if(n){
          this.changeRoute()
        }
      }
    }
  },
   
  methods:{
    changeRoute(){
      this.email_verified_at = typeof this.$store.getters.profile === 'string'  ? JSON.parse(this.$store.getters.profile).email_verified_at : this.$store.getters.profile.email_verified_at
      if(this.$route.path === '/account/settings'){
          this.InformationTab = true
          this.AdditionalInformationTab = false
          this.EmailVerificationTab = false
        }else if(this.$route.path === '/account/settings/additional-information'){
          this.InformationTab = false
          this.AdditionalInformationTab = true
          this.EmailVerificationTab = false
        }else if(this.$route.path === '/account/settings/email-verification' && this.email_verified_at === null){
          this.InformationTab = false
          this.AdditionalInformationTab = false
          this.EmailVerificationTab = true
        }else{
          this.InformationTab = true
          this.AdditionalInformationTab = false
          this.EmailVerificationTab = false
        }
    }
  },
  mounted(){
    this.changeRoute()
  }
}
</script>
<style>
.cd-filter-block label i { top:15px;  }
</style>