<template>
  <div class="row">
    <div class="alert alert-danger" v-if="errorMessage"> {{ errorMessage }} </div>
    <div class="alert alert-success" v-if="meesage"> {{ meesage }} </div>
    <div class="col-sm-12">
      <div class="table tabs-item active">
        <div class="cd-filter-block mb-0"> 
          <div class="cd-filter-content">
            <div class="comments-form"> 
              <div class="col-md-12">
                <div class="container">
                  <div class="row text-center">
                    <h2 class="section-heading mergecolor text-center w-100">{{ $t('Change password') }}</h2>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pb-3">
                <div class="bg-seccolorstyle bg-white noshadow mt-50 p-50 br-12">
                  <div class="row">
                    <div class="mb-4">
                      <div class="col-md-12 position-relative mb-4">
                        <label><i class="fas fa-lock"></i></label>
                        <input type="password" :placeholder="$t('Current password')" required v-model="current_password">
                      </div>
                      <div class="col-md-12 position-relative mb-4">
                        <label><i class="fas fa-lock"></i></label>
                        <input type="password" :placeholder="$t('New password')" required v-model="password">
                      </div>
                      <div class="col-md-12 position-relative">
                        <label ><i class="fas fa-lock"></i></label>
                        <input type="password" :placeholder="$t('Password confirmation')" required v-model="password_confirmation">
                      </div> 
                    </div>
                    <button type="submit" value="Submit" class="btn btn-default-yellow-fill disable-on-click spinner-on-click" @click="editPassword">{{ $t('Update') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</template>
<script>
export default{
  data(){
    return{
      errorMessage:"",
      meesage:"",
      current_password:"",
      password:"",
      password_confirmation:""
    }
  },
  methods:{
    editPassword(){
      
      
      this.$emit('openLoading') 
      let data = {
        current_password:this.current_password,
        password:this.password,
        password_confirmation:this.password_confirmation
      }
      this.$store.dispatch('update_password',data).then(()=>{
        this.errorMessage = ''
        this.message = this.$store.state.profile.message
        this.$emit('closeLoading') 
      }).catch(()=>{
        this.message = ''
        this.errorMessage = this.$store.state.profile.error_message
        this.$emit('closeLoading') 
      })
    }
  },
  mounted(){
    this.$emit('closeLoading') 
  }
}
</script>
 