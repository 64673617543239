<template>
  <div class="menu-wrap mobile" :class="{active:active}">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-6">
          <a href="." aria-label="Home Page">
            <img :src="logo" class="logo">
          </a>
        </div>
        <div class="col-6">
          <nav class="nav-menu float-end d-flex">
            <div class="tech-box"> </div>
            <button id="nav-toggle" class="menu-toggle " aria-label="Dropdown Menu" @click="active=!active" :class="{active:active}">
            <span class="icon"></span>
            <span class="icon"></span>
            <span class="icon"></span>
            </button>
            <div class="main-menu bg-seccolorstyle"> 
              <div class="menu-item" v-for="(k,i) in menu" :key="i" @click="show=true;activeId=k.id">
                <a href="javascript:void(0)" class="mergecolor" :class="{show:show&&activeId==k.id}" data-bs-toggle="dropdown">{{ $t(k.name) }} </a>
                <div class="dropdown-menu" :class="{show:show&&activeId==k.id}" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(70px, 281px, 0px);">
                  <router-link :to="k.url" class="dropdown-item menu-item" >{{k.name}}</router-link>
                </div>
              </div>
              <div class="float-start w-100 mt-3">
                <p class="c-grey-light seccolor"> <small> Phone: + (123) 1300-656-1046</small> </p>
                <p class="c-grey-light seccolor"><small>Email: vezra@mail.com</small> </p>
              </div>
              <div v-if="getMonitorSize() === 'mobile'">
                <ClientArea :isLoggedIn="isLoggedIn" @openLoading="$emit('openLoading')"  @closeLoading="$emit('closeLoading')"/>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClientArea from '@/components/ClientArea'
export default { 
  props:['menu','isLoggedIn','logo'],
  watch:{
    isLoggedIn :{
      handler(n){
        if(n)
        this.logged = n
      }
    }
  },
  components:{ ClientArea },
  data(){
    return{
      logged:false,
      activeId:0,
      active:false,
      show:false
    }
  }
}
</script>

<style>

@media only screen and (max-width: 991px) {
  .logo {
    width: 180px;
  }
}

</style>