<template>
  <div class="box-container limit-width">
    <div class="col-md-12">
      <div class="container">
        <div class="row text-center">
          <h2 class="section-heading mergecolor text-center w-100">{{ $t('Google 2-fa security') }}</h2>
        </div>
      </div>
    </div>
    
    <div class="col-md-12 pb-3">
      <div class="bg-seccolorstyle bg-white noshadow mt-50 p-50 br-12">
        <div class="row"> 
          <div v-if="authenticator">
            <div class="alert alert-danger" v-if="errorMessage">{{ errorMessage }}</div>
            <div class="alert alert-success" v-if="message">{{ message }}</div>
            <input type="text" v-model="google_code_disable" class="form-control" :placeholder="$t('Google code')">
            <button class="btn mt-3 mb-3 btn-default-yellow-fill" @click="disable">{{ $t('Disable now') }}</button>
          </div>
          <div v-else>
            <div class="alert alert-danger" v-if="errorMessage">{{ errorMessage }}</div>
            <div class="alert alert-success" v-if="message">{{ message }}</div>
            <div class="result">
              <img :src="qr" width="300">
              <div class="secret mt-2 mb-2 text-white" v-if="secret">
                <strong>Secret: </strong>
                <span>{{secret}}</span><br>
              </div>
            </div>
            <input v-if="secret" type="text" v-model="google_code" class="form-control" :placeholder="$t('Write Your Google 2fa code here')">
            <button class="btn mt-3 mb-3 btn-default-yellow-fill" @click="enable">{{ $t('Enable now') }}</button>
          </div>
        </div>
      </div>
    </div> 

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default{
  computed:{
    ...mapGetters(['profile','isLoggedIn','user']),
    authenticator_enabled:function(){ 
      return this.authenticator = typeof this.$store.getters.profile === 'string' ? 
        JSON.parse(this.$store.getters.profile).authenticator_enabled : 
        this.$store.getters.profile.authenticator_enabled 
    },
  },
  watch:{
    user:{
      handler(n,o){
        if(n){ 
          this.checkUser()
        }       
      }
    }, 
    $route:{
      handler(n,o){
        if(n){ 
          this.checkUser()
        }
      },
      immediate:true,
      deep:true
    }
  },
  data(){
    return{
      authenticator:'',
      qr:"",
      secret:"",
      google_code:"",
      google_code_disable:"",
      errorMessage:"",
      message:"",
    }
  }, 
  methods:{

    checkUser(){ 
      this.$emit('closeLoading') 
      
      this.authenticator = typeof this.$store.getters.user === 'string' ? 
      JSON.parse(this.$store.getters.user).authenticator_enabled : 
      this.$store.getters.user.authenticator_enabled 
      let interval = setInterval(()=>{
        if(typeof this.authenticator !== 'undefined'){
          this.$emit('closeLoading') 
          clearInterval(interval) 
        }
      },1000)
      return this.authenticator 
    },

    enable(){
      this.errorMessage = ''
      this.message = ''
      this.$emit('openLoading')
      this.$store.dispatch('enable_two_fa_google',{google_code:this.google_code}).then(()=>{ 
        this.qr = this.$store.state.profile.message.qr 
        this.secret = this.$store.state.profile.message.secret
        if(this.google_code){
            this.message = this.$store.state.profile.message 
        }
        this.$store.dispatch('profile').then(()=>{ this.$emit('closeLoading') })
      }).catch(()=>{
        this.errorMessage = this.$store.state.profile.error_message
        this.$emit('closeLoading')
      })
    },

    disable(){ 
      this.errorMessage = ''
      this.message = ''
      this.$emit('openLoading')
      this.$store.dispatch('disable_two_fa_google',{google_code:this.google_code_disable}).then(()=>{ 
        this.message = this.$store.state.profile.message
        this.$store.dispatch('profile').then(()=>{ this.$emit('closeLoading') })
      }).catch(()=>{
        this.errorMessage = this.$store.state.profile.error_message
        this.$emit('closeLoading')
      })
    }

  },
  beforeMount(){
    this.$emit('openLoading')
  } 
}
</script>