<template>
<div>
  <div class="top-header">
    <div class="total-grad-inverse"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="wrapper">
            <div class="heading mb-0">Frequently asked questions</div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="cd-filter-block checkbox-group mb-0">
            <label><a href="javascript:void(0)" @change="find"><i class="fas fa-search"></i></a></label>
            <input type="text" class="input" placeholder="Search.." v-model="word">
          </div>
        </div>
      </div>
    </div>
  </div>
  <section id="gotop" class="blog motpath pb-80 bg-colorstyle">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <div class="col-md-12 col-lg-4">
          <aside id="sidebar" class="sidebar manual-position mt-80 sec-bg1 bg-seccolorstyle noshadow" style="top: 472px; bottom: auto;">
            <div class="menu categories clear">
              <h4 class="mergecolor"><strong>{{$t('faq')}}</strong></h4>
              <hr>
              <div class="heading pt-2">
                <a href="javascript:void(0)" id="showall" class="gocheck seccolor" :class="{active:activeId===''}" @click="activeId=''"> {{$t('all_answers')}} </a>
              </div>
              <div class="heading pt-2" v-for="(k,i) in data" :key="i">
                <a href="javascript:void(0)" class="gocheck showSingle seccolor" :class="{active:activeId===k.id}" @click="getText(k)">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg me-3" src="./assets/fonts/svg/cloudfiber.svg" alt="Shared Hosting">
                    <title>cloudfiber</title>
                    <path id="svg-ico" fill="#ee5586" d="M21.294 1.969c-0.012 0-0.025 0-0.038 0h-0.156c-0.012 0-0.025 0-0.038 0-5.325 0.087-10.656 3.756-10.656 10.688 0 0.238 0.194 0.425 0.425 0.425 0.238 0 0.425-0.194 0.425-0.425 0-6.719 5.125-9.8 9.912-9.838 5.387 0.038 9.762 4.438 9.762 9.838 0 5.425-4.412 9.837-9.838 9.837h-13.325c-3.725 0-6.756-3.031-6.756-6.756s3.031-6.756 6.756-6.756c0.238 0 0.425-0.194 0.425-0.425s-0.194-0.425-0.425-0.425c-4.2-0.006-7.612 3.412-7.612 7.606s3.413 7.613 7.612 7.613h13.331c5.894 0 10.694-4.794 10.694-10.694 0-5.825-4.694-10.581-10.5-10.688z"></path>
                    <path fill="#5e686c" d="M24.413 28.15h-6.781c-0.156-0.6-0.631-1.075-1.231-1.231v-2.194c0-0.238-0.194-0.425-0.425-0.425-0.238 0-0.425 0.194-0.425 0.425v2.194c-0.6 0.156-1.075 0.631-1.231 1.231h-6.781c-0.237 0-0.425 0.194-0.425 0.425s0.194 0.425 0.425 0.425h6.781c0.188 0.738 0.863 1.281 1.656 1.281s1.469-0.544 1.656-1.281h6.781c0.238 0 0.425-0.194 0.425-0.425s-0.188-0.425-0.425-0.425zM15.975 29.431c-0.469 0-0.856-0.381-0.856-0.856 0 0 0 0 0 0s0 0 0 0c0-0.469 0.381-0.856 0.856-0.856s0.856 0.381 0.856 0.856c0 0.475-0.387 0.856-0.856 0.856z"></path>
                    <path fill="#5e686c" d="M21.238 6.931c0.238 0 0.425-0.194 0.425-0.431-0.006-0.237-0.219-0.444-0.431-0.425-3.381 0.044-6.375 2.719-6.675 6.044l0.006 0.075c0.006 0.156 0.012 0.313 0.012 0.463 0 0.238 0.194 0.425 0.425 0.425 0.238 0 0.425-0.194 0.425-0.425 0-0.162-0.006-0.331-0.012-0.525l-0.012-0.019c0.269-2.819 2.888-5.144 5.838-5.181z"></path>
                  </svg>  -->
                {{ k.title }}
                </a>
              </div> 
            </div>
          </aside>
        </div>
        <div class="pt-35 col-md-12 col-lg-8">
          <div id="sidebar_content" class="wrap-blog">
            <div class="row">
              <div class="col-md-12 col-lg-12 knowledge">
                <div id="div3" class="wrapper targetDiv mt-5 bg-seccolorstyle noshadow" v-for="(k,i) in data" v-if="activeId===''" :key="i">
                  <h6> 
                    <a href="javascript:void(0)" class="category h4"><strong>{{k.title}}</strong></a>  
                  </h6>
                  <hr> 
                  <div class="detail-data">
                    {{ k.text }}
                  </div>
                </div>  
                <div id="div3" class="wrapper targetDiv mt-5 bg-seccolorstyle noshadow" v-if="activeId !==''" >
                  <h6> 
                    <a href="javascript:void(0)" class="category h4"><strong>{{activeItem.title}}</strong></a> 
                    <span class="float-end c-grey seccolor">[{{ $t('questions') }} {{activeItem.id}}]</span>
                  </h6>
                  <hr> 
                  <div class="detail-data">
                    {{ activeItem.text }}
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <BottomSvg/>
</div>
</template>
<script> 
export default { 
  data(){
    return{
      word:"",
      activeItem:[],
      activeId:'',
      data:[
        {id:1,title:"Title",text:"" },
        {id:2,title:"Title",text:"Text"},
        {id:3,title:"Title",text:"Text"},
      ],
    }
  },
  methods:{
    find(){
      //filtre data
      console.log(1)
    },
    getData(){
      this.$emit('closeLoading')
      // this.$emit('openLoading')
    },
    getText(item){
      this.activeItem = item
      this.activeId = item.id
    }
  },
  mounted(){
    this.$emit('openLoading')
    this.getData()
  }
}
</script>

<style scoped>
.cd-filter-block label i {
  top: 18px !important;
}
.manual-position{
  position: static !important;
}
</style>
