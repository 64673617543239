<template>
    <div class="box-container limit-width">
      <div class="bg-colorstyle">
        <div class="top-header item17 overlay">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <div class="wrapper">
                  <h1 class="heading">{{$t('Login history')}}</h1>
                  <h3 class="subheading">{{$t('Here is your login history')}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper sec-normal">
          <div class="content bg-colorstyle noshadow nopadding">
            <div class="inner-content">
              <div class="main-body">
                <div class="page-wrapper">
                  <section id="main-body">
                    <div class="container">
                      <div class="main-content ">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="container">
                              <div class="row text-center">
                                <h2 class="section-heading mergecolor text-center w-100">Add Funds</h2>
                                <p class="section-subheading mergecolor">Deposit money in advance</p>
                              </div>
                              <div class="dropnav-header-lined">
                                <button id="dropside-content" type="button" class="drop-down-btn dropside-content" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                                <div class="dropdown-menu bg-seccolorstyle" aria-labelledby="dropside-content">
                                  <div menuitemname="Add Funds" class="item-drop-side panel panel-info">
                                    <div class="panel-heading">
                                      <h3 class="panel-title mergecolor"> Add Funds </h3>
                                    </div>
                                    <div class="panel-body mergecolor"> Add funds to your account with us to avoid lots of small transactions and to automatically take care of any new invoices that are generated. </div>
                                  </div>
                                  <div menuitemname="Billing" class="item-drop-side item-drop-side">
                                    <div class="panel-heading">
                                      <h3 class="panel-title mergecolor"> Billing </h3>
                                    </div>
                                    <div>
                                      <a menuitemname="Invoices" href="/whmcs/clientarea.php?action=invoices" class="list-group-item" id="Secondary_Sidebar-Billing-Invoices"> My Invoices </a>
                                      <a menuitemname="Quotes" href="/whmcs/clientarea.php?action=quotes" class="list-group-item" id="Secondary_Sidebar-Billing-Quotes"> My Quotes </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 ">
                            <div class="alert alert-danger text-center"> We do not allow depositing funds in advance with us at the current time. </div>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
    export default {
      mounted(){
        this.$emit('closeLoading')
          // this.$emit('openLoading')
      }
    }
  </script>
  <style scoped></style>
 