<template>
  <div class="marquee">
    <marquee>
      {{ text }}
    </marquee>
  </div>
</template>
<script>
export default{
  props:['text'],
}
</script>
<style>
.marquee{
  width: 100%;
  height: 40px;
  background: #d34e7c !important;
  z-index: 99999;
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  color: white;
  padding-top: 3px;
}
</style>