<template>
  <div class="spinner-area" v-if="show">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
      <div class="spinner-txt">Loading...</div>
    </div>
  </div>
</template>
<script>
export default{
    props:['show']
}
</script>
<style>
.spinner-area{
  background-color: #000;
  z-index: 9999;
  opacity: .9; 
  display: block;
  position: fixed;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}
</style>

