<template>
  <div>
    <div class="top-header item17 overlay">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="wrapper">
              <h1 class="heading">WHOIS</h1>
              <h3 class="subheading"></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper sec-normal">
      <div class="content bg-seccolorstyle noshadow nopadding">
        <div class="inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <section id="main-body ">
                <div class="container">
                  <div class="main-content ">
                    <div class="row"> 
                      <div class="col-xs-12">  
                        <div id="order-standard_cart">
                          <div class="row">
                            <div class="col-md-12 mt-50">
                              <div class="domain-checker-container overlay custom-media">
                                <div class="domain-checker-bg clearfix custom-dflex">
                                  <div class="row text-center" style="display: block; position: relative">
                                      <div>
                                        <b style="display: block; position: relative; padding-bottom: 2rem" class="section-subheading mergecolor text-end"> Enter the domain name in the search box to find out who owns it.
                                        </b>
                                        <div class="input-group input-group-lg input-group-box" style="display: block; text-align: end">
                                          <input type="text" class="form-control custom-mb" placeholder="Find your new domain name" v-model="domain" @input="changeUrl" @keyup.enter="search">
                                          <span class="input-group-btn">
                                            <button type="submit" @click="search" class="btn btn-default-yellow-fill domain-check-availability">Search</button>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                              <p class="domain-unavailable domain-checker-unavailable" v-if="errorMesasge" style="display: block;">{{errorMesasge}} </p>
                              <div class="domain-checker-container col-md-12 mt-10 p-5 text-white" v-if="data.length" style="background-color: #16212a !important; " > 
                              <h2>Result!</h2>
                              <span v-html="data"></span>
                            </div>
                            </div>
                    
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="sec-normal history-section custom sec-bg3 bg-colorstyle " style="padding-top: 3rem; padding-bottom: 3rem">
                <div class="faq">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 text-center">
                        <h2 class="section-heading mergecolor pt-50">Frequently Asked Questions</h2>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="accordion faq pt-5">
                          <div class="panel-wrap" v-for="(k,i) in asked_questions" :key="i">
                            <div class="panel-title seccolor" @click="activeAcardion=k.id;display='block'" :class="{active:activeAcardion===k.id}">
                              <span>{{ k.question }}</span>
                              <div class="float-end">
                                <i class="fa fa-plus"></i>
                                <i class="fa fa-minus c-pink"></i>
                              </div>
                            </div>
                            <div class="panel-collapse" :style="{display:activeAcardion===k.id?'block':'none'}" >
                              <div class="wrapper-collapse">
                                <div class="info">
                                  <ul class="list seccolor">
                                    <li>
                                      {{ k.answer }}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="accordion faq pt-5">
                          <div class="panel-wrap" v-for="(k,i) in asked_questions2" :key="i">
                            <div class="panel-title seccolor" @click="activeAcordion=k.id;display='block'" :class="{active:activeAcordion===k.id}">
                              <span>{{ k.question }}</span>
                              <div class="float-end">
                                <i class="fa fa-plus"></i>
                                <i class="fa fa-minus c-pink"></i>
                              </div>
                            </div>
                            <div class="panel-collapse" :style="{display:activeAcordion===k.id?'block':'none'}" >
                              <div class="wrapper-collapse">
                                <div class="info">
                                  <ul class="list seccolor">
                                    <li>
                                      {{ k.answer }}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{
      data:[],
      errorMesasge:"",
      domain:"",
      activeAcardion:0,
      activeAcordion:0,
      advancedDomainId:1,
      video : '/assets/videos/planet.mp4',
      asked_questions:[
        {
          id:1,
          question:"What is a domain name?",
          answer:" A domain name is the address you type into the browser's address bar to access a website. It also helps users locate your website on the internet.When selected thoughtfully, they can contribute to branding, influence, or even act as engaging and memorable advertisements."
        },
        {
          id:2,
          question:"How do I register a new domain?",
          answer:"To register a domain name, visit our website and use the domain search feature to check for availability. Once you find a suitable name, follow the registration process, providing the required information.",
        },
        {
          id:3,
          question:"What is the registration period for a domain?",
          answer:"The registration period varies but is typically one year. You can choose to renew your domain registration before it expires to maintain ownership."
        },
        {
          id:4,
          question:"What is the use of domain search?",
          answer:"The domain search functionality serves the purpose of verifying the availability of your chosen website name for registration. Utilising our domain search tool at the top of the page, you can broaden the parameters of your search. Once you've made your decision, initiate the domain purchase process by clicking the REGISTER button."
        }
      ],
      asked_questions2:[
        {
          id:1,
          question:"What is WHOIS information?",
          answer:"WHOIS information is a public record that includes details about the owner of a domain. It typically includes the registrant's name, contact information, and registration and expiration dates."
        },
        {
          id:2,
          question:"How can I update my WHOIS information?",
          answer:"You can update your WHOIS information by logging in to your account and accessing the domain management section. Follow the instructions to edit and save your updated information."
        },
        {
          id:3,
          question:"What happens if my domain registration expires?",
          answer:"If your domain registration expires, you may lose control of the domain. It is crucial to renew your registration before the expiration date to avoid any disruption in services."
        },
        {
          id:4,
          question:"What is ICANN?",
          answer:"ICANN (Internet Corporation of Assigned Names), the most authoritative institution in the field of domain regulation and control, is responsible for the coordination of all domain names in the world. When acquiring a domain, it is crucial to opt for domain registrars authorised by ICANN. ICANN's purpose is to regulate the domain name registration process and take measures for internet security."
        }
      ]
    }
  },
  methods:{
    search(){
      this.errorMesasge = ''
      this.$emit('openLoading') 
      this.$store.dispatch('whois',this.domain).then(()=>{
        this.data = this.$store.state.site.data.data.replace(/\n/g, '<br>');
        this.$emit('closeLoading')
      }).catch(()=>{
        this.errorMesasge = this.$store.state.site.error_message
        this.$emit('closeLoading')
      })
    },
    changeUrl(){ 
      let url = new URL(window.location.href)
      url.searchParams.set("domain", this.domain)
      window.history.replaceState(null, "", url);
    }
  },
  mounted(){
    this.$emit('closeLoading')
    let url = new URL(window.location.href)
    if(url.searchParams.has("domain")){
      this.domain = url.searchParams.get("domain")
      this.search()
    }
  }
}
</script>
<style>
.custom-dflex{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 991px) {
  .custom-media{
  padding: 0px 20px 0px 20px;
}
  .custom-mb{
    margin-bottom: 12px;
  }
}
</style>