<template>
  <div class="row">
    <div class="alert alert-danger" v-if="errorMessage"> {{ errorMessage }} </div>
    <div class="alert alert-success" v-if="meesage"> {{ meesage }} </div>
    <div class="col-md-12">
      <div class="container">
        <div class="row text-center">
          <h2 class="section-heading mergecolor text-center w-100">{{ $t('verification your email') }}</h2>
        </div>
      </div>
    </div> 
    <div class="col-sm-12">
      <div class="table tabs-item active">
        <div class="cd-filter-block mb-0"> 
          <div class="cd-filter-content">
            <div class="comments-form"> 
              <div class="row">        
                <button type="submit" value="Submit" class="btn btn-default-yellow-fill mb-1 disable-on-click spinner-on-click" @click="sendEmail">{{ $t('Send verification link') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{
      meesage:"",
      errorMessage:"",    
      username:"",
      email:"",
      phone:"",
      phone_prefix:"",
      type:"",
      phone_codes:"",
    }
  },
  watch:{
    $route:{
      handler(n,o){
        if(n){
        this.$emit('closeLoading')
        }
      },
      immediate:true,
      deep:true
    }
  },
  methods:{
    sendEmail(){
      this.$emit('openLoading')
      this.$store.dispatch('send_email_verification').then(()=>{
        this.meesage = this.$store.state.profile.message
        this.$emit('closeLoading')
      }).catch(()=>{
        this.errorMessage = this.$store.state.profile.error_message
        this.$emit('closeLoading')
      })
    },
     
    editProfile(){
      this.meesage  = "Information updated successfully"
    }
  }, 
}
</script>