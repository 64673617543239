<template>
<div>
  <div class="top-header item7 overlay scrollme">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="wrapper animateme" data-when="span" data-from="0" data-to="0.75" data-opacity="1" data-translatey="-50" style="opacity: 1; transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);">
              <h1 class="heading">Pricing &amp; Plans</h1>
              <h3 class="subheading">You are free to customize your pricing plans to your liking</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="pricing special sec-normal motpath bg-colorstyle">

      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="section-heading mergecolor">Plans Box</h2>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="wrapper first text-start noshadow">
              <div class="top-content bg-seccolorstyle topradius">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg mb-3" src="./assets/fonts/svg/privacy.svg" alt="">
                  <title>privacy</title>
                  <path fill="#5e686b" d="M31.688 3.688c0-0.188-0.15-0.337-0.337-0.337h-30.756c-0.012 0-0.025 0-0.037 0-0.169 0.019-0.306 0.163-0.306 0.337v24.6c0 0.188 0.15 0.337 0.338 0.337h30.75c0.188 0 0.337-0.15 0.337-0.337v-18.375c0-0.012 0-0.025 0-0.031 0-0.012 0-0.025 0-0.037l0.012-6.156zM31.006 4.031v5.469h-30.069v-5.469h30.069zM0.938 27.95v-17.769h30.069v17.769h-30.069z"></path>
                  <path fill="#5e686b" d="M5.1 8.019c0.75 0 1.356-0.612 1.356-1.362s-0.612-1.362-1.356-1.362c-0.75 0-1.362 0.612-1.362 1.362 0.006 0.756 0.612 1.362 1.362 1.362zM5.1 5.981c0.375 0 0.681 0.306 0.681 0.681s-0.306 0.681-0.681 0.681c-0.375 0-0.681-0.306-0.681-0.681s0.306-0.681 0.681-0.681z"></path>
                  <path fill="#5e686b" d="M9.931 8.019c0.75 0 1.363-0.612 1.363-1.362s-0.613-1.362-1.363-1.362c-0.75 0-1.363 0.612-1.363 1.362 0.006 0.756 0.613 1.362 1.363 1.362zM9.931 5.981c0.375 0 0.681 0.306 0.681 0.681s-0.306 0.681-0.681 0.681c-0.375 0-0.681-0.306-0.681-0.681s0.306-0.681 0.681-0.681z"></path>
                  <path fill="#5e686b" d="M14.762 8.019c0.75 0 1.363-0.612 1.363-1.362s-0.613-1.362-1.363-1.362-1.363 0.612-1.363 1.362c0.006 0.756 0.613 1.362 1.363 1.362zM14.762 5.981c0.375 0 0.681 0.306 0.681 0.681s-0.306 0.681-0.681 0.681-0.681-0.306-0.681-0.681 0.306-0.681 0.681-0.681z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M15.975 16.719c-2.438 0-4.419 1.981-4.419 4.419s1.981 4.419 4.419 4.419c2.438 0 4.419-1.981 4.419-4.419s-1.988-4.419-4.419-4.419zM15.975 24.875c-2.063 0-3.737-1.675-3.737-3.738s1.675-3.738 3.738-3.738c2.063 0 3.738 1.675 3.738 3.738s-1.675 3.738-3.738 3.738z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M15.975 20.081c-0.188 0-0.338 0.15-0.338 0.337v2.038c0 0.188 0.15 0.337 0.338 0.337s0.338-0.15 0.338-0.337v-2.038c0-0.188-0.15-0.337-0.338-0.337z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M13.869 16.363c0.188 0 0.338-0.15 0.338-0.337v-1.35c0-0.556 0.456-1.012 1.019-1.012h1.5c0.563 0 1.019 0.456 1.019 1.012v1.35c0 0.188 0.15 0.337 0.337 0.337s0.337-0.15 0.337-0.337v-1.35c0-0.931-0.762-1.694-1.694-1.694h-1.5c-0.938 0-1.694 0.762-1.694 1.694v1.35c-0.006 0.188 0.15 0.337 0.338 0.337z"></path>
                </svg>
                <div class="title">Positive SSL</div>
                <div class="fromer seccolor">+ VAT at 20%</div>
                <div class="price mergecolor"><sup>$</sup>39.99 <span class="period">/year</span></div>
                <a href="" class="btn btn-default-yellow-fill">Buy now</a>
              </div>
              <ul class="list-info">
                <li><i class="icon-lock"></i> <span class="c-purple">DOMAIN</span><br> <span>Secure HTTPS</span></li>
                <li><i class="icon-browser"></i> <span class="c-purple">BROWSER</span><br> <span>Padlock visible</span></li>
                <li><i class="icon-money"></i> <span class="c-purple">WARRANTY</span><br> <span>No included</span></li>
                <li><i class="icon-key"></i> <span class="c-purple">ENCRYPTION</span><br> <span>128/256 bit SHA-2</span></li>
              </ul>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="wrapper text-start noshadow">
              <div class="plans badge feat bg-pink">most popular</div>
              <div class="top-content bg-seccolorstyle topradius">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg mb-3" src="./assets/fonts/svg/privacy.svg" alt="">
                  <title>privacy</title>
                  <path fill="#5e686b" d="M31.688 3.688c0-0.188-0.15-0.337-0.337-0.337h-30.756c-0.012 0-0.025 0-0.037 0-0.169 0.019-0.306 0.163-0.306 0.337v24.6c0 0.188 0.15 0.337 0.338 0.337h30.75c0.188 0 0.337-0.15 0.337-0.337v-18.375c0-0.012 0-0.025 0-0.031 0-0.012 0-0.025 0-0.037l0.012-6.156zM31.006 4.031v5.469h-30.069v-5.469h30.069zM0.938 27.95v-17.769h30.069v17.769h-30.069z"></path>
                  <path fill="#5e686b" d="M5.1 8.019c0.75 0 1.356-0.612 1.356-1.362s-0.612-1.362-1.356-1.362c-0.75 0-1.362 0.612-1.362 1.362 0.006 0.756 0.612 1.362 1.362 1.362zM5.1 5.981c0.375 0 0.681 0.306 0.681 0.681s-0.306 0.681-0.681 0.681c-0.375 0-0.681-0.306-0.681-0.681s0.306-0.681 0.681-0.681z"></path>
                  <path fill="#5e686b" d="M9.931 8.019c0.75 0 1.363-0.612 1.363-1.362s-0.613-1.362-1.363-1.362c-0.75 0-1.363 0.612-1.363 1.362 0.006 0.756 0.613 1.362 1.363 1.362zM9.931 5.981c0.375 0 0.681 0.306 0.681 0.681s-0.306 0.681-0.681 0.681c-0.375 0-0.681-0.306-0.681-0.681s0.306-0.681 0.681-0.681z"></path>
                  <path fill="#5e686b" d="M14.762 8.019c0.75 0 1.363-0.612 1.363-1.362s-0.613-1.362-1.363-1.362-1.363 0.612-1.363 1.362c0.006 0.756 0.613 1.362 1.363 1.362zM14.762 5.981c0.375 0 0.681 0.306 0.681 0.681s-0.306 0.681-0.681 0.681-0.681-0.306-0.681-0.681 0.306-0.681 0.681-0.681z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M15.975 16.719c-2.438 0-4.419 1.981-4.419 4.419s1.981 4.419 4.419 4.419c2.438 0 4.419-1.981 4.419-4.419s-1.988-4.419-4.419-4.419zM15.975 24.875c-2.063 0-3.737-1.675-3.737-3.738s1.675-3.738 3.738-3.738c2.063 0 3.738 1.675 3.738 3.738s-1.675 3.738-3.738 3.738z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M15.975 20.081c-0.188 0-0.338 0.15-0.338 0.337v2.038c0 0.188 0.15 0.337 0.338 0.337s0.338-0.15 0.338-0.337v-2.038c0-0.188-0.15-0.337-0.338-0.337z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M13.869 16.363c0.188 0 0.338-0.15 0.338-0.337v-1.35c0-0.556 0.456-1.012 1.019-1.012h1.5c0.563 0 1.019 0.456 1.019 1.012v1.35c0 0.188 0.15 0.337 0.337 0.337s0.337-0.15 0.337-0.337v-1.35c0-0.931-0.762-1.694-1.694-1.694h-1.5c-0.938 0-1.694 0.762-1.694 1.694v1.35c-0.006 0.188 0.15 0.337 0.338 0.337z"></path>
                </svg>
                <div class="title">Wildcard SSL</div>
                <div class="fromer seccolor">+ VAT at 20%</div>
                <div class="price mergecolor"><sup>$</sup>88.00 <span class="period">/year</span></div>
                <a href="" class="btn btn-default-yellow-fill">Buy now</a>
              </div>
              <ul class="list-info bg-purple">
                <li><i class="icon-lock"></i> <span class="c-pink">DOMAIN</span><br> <span>Secure HTTPS</span></li>
                <li><i class="icon-browser"></i> <span class="c-pink">BROWSER</span><br> <span>Padlock visible</span></li>
                <li><i class="icon-money"></i> <span class="c-pink">WARRANTY</span><br> <span>$250.000</span></li>
                <li><i class="icon-key"></i> <span class="c-pink">ENCRYPTION</span><br> <span>128/256 bit SHA-2</span></li>
              </ul>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="wrapper third text-start noshadow">
              <div class="top-content bg-seccolorstyle topradius">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg mb-3" src="./assets/fonts/svg/privacy.svg" alt="">
                  <title>privacy</title>
                  <path fill="#5e686b" d="M31.688 3.688c0-0.188-0.15-0.337-0.337-0.337h-30.756c-0.012 0-0.025 0-0.037 0-0.169 0.019-0.306 0.163-0.306 0.337v24.6c0 0.188 0.15 0.337 0.338 0.337h30.75c0.188 0 0.337-0.15 0.337-0.337v-18.375c0-0.012 0-0.025 0-0.031 0-0.012 0-0.025 0-0.037l0.012-6.156zM31.006 4.031v5.469h-30.069v-5.469h30.069zM0.938 27.95v-17.769h30.069v17.769h-30.069z"></path>
                  <path fill="#5e686b" d="M5.1 8.019c0.75 0 1.356-0.612 1.356-1.362s-0.612-1.362-1.356-1.362c-0.75 0-1.362 0.612-1.362 1.362 0.006 0.756 0.612 1.362 1.362 1.362zM5.1 5.981c0.375 0 0.681 0.306 0.681 0.681s-0.306 0.681-0.681 0.681c-0.375 0-0.681-0.306-0.681-0.681s0.306-0.681 0.681-0.681z"></path>
                  <path fill="#5e686b" d="M9.931 8.019c0.75 0 1.363-0.612 1.363-1.362s-0.613-1.362-1.363-1.362c-0.75 0-1.363 0.612-1.363 1.362 0.006 0.756 0.613 1.362 1.363 1.362zM9.931 5.981c0.375 0 0.681 0.306 0.681 0.681s-0.306 0.681-0.681 0.681c-0.375 0-0.681-0.306-0.681-0.681s0.306-0.681 0.681-0.681z"></path>
                  <path fill="#5e686b" d="M14.762 8.019c0.75 0 1.363-0.612 1.363-1.362s-0.613-1.362-1.363-1.362-1.363 0.612-1.363 1.362c0.006 0.756 0.613 1.362 1.363 1.362zM14.762 5.981c0.375 0 0.681 0.306 0.681 0.681s-0.306 0.681-0.681 0.681-0.681-0.306-0.681-0.681 0.306-0.681 0.681-0.681z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M15.975 16.719c-2.438 0-4.419 1.981-4.419 4.419s1.981 4.419 4.419 4.419c2.438 0 4.419-1.981 4.419-4.419s-1.988-4.419-4.419-4.419zM15.975 24.875c-2.063 0-3.737-1.675-3.737-3.738s1.675-3.738 3.738-3.738c2.063 0 3.738 1.675 3.738 3.738s-1.675 3.738-3.738 3.738z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M15.975 20.081c-0.188 0-0.338 0.15-0.338 0.337v2.038c0 0.188 0.15 0.337 0.338 0.337s0.338-0.15 0.338-0.337v-2.038c0-0.188-0.15-0.337-0.338-0.337z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M13.869 16.363c0.188 0 0.338-0.15 0.338-0.337v-1.35c0-0.556 0.456-1.012 1.019-1.012h1.5c0.563 0 1.019 0.456 1.019 1.012v1.35c0 0.188 0.15 0.337 0.337 0.337s0.337-0.15 0.337-0.337v-1.35c0-0.931-0.762-1.694-1.694-1.694h-1.5c-0.938 0-1.694 0.762-1.694 1.694v1.35c-0.006 0.188 0.15 0.337 0.338 0.337z"></path>
                </svg>
                <div class="title">EV SSL</div>
                <div class="fromer seccolor">+ VAT at 20%</div>
                <div class="price mergecolor"><sup>$</sup>110.99 <span class="period">/year</span></div>
                <a href="" class="btn btn-default-yellow-fill">Buy now</a>
              </div>
              <ul class="list-info">
                <li><i class="icon-lock"></i> <span class="c-purple">DOMAIN</span><br> <span>Secure HTTPS</span></li>
                <li><i class="icon-browser"></i> <span class="c-purple">BROWSER</span><br> <span>Green bar visible</span></li>
                <li><i class="icon-money"></i> <span class="c-purple">WARRANTY</span><br> <span>$1.000.000</span></li>
                <li><i class="icon-key"></i> <span class="c-purple">ENCRYPTION</span><br> <span>128/256 bit SHA-2</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec-normal bg-colorstyle">
      <div class="best-plans pricing">
        <div class="container">
          <div class="col-sm-12 mb-5">
            <h2 class="section-heading mergecolor">Plans Comparison</h2>
          </div>
          <div class="randomline">
            <div class="bigline"></div>
            <div class="smallline"></div>
          </div>
          <div class="sec-main sec-bg1">
            <div class="plans badge feat bg-pink hideelement">Compare All GIT Plans</div>
            <div class="row">
              <div class="col-sm-12">
                <div id="table-container" class="table-responsive-lg">
                  <table id="maintable" class="table">
                    <thead>
                    <tr>
                      <td></td>
                      <td>
                        <div class="title">Business GIT</div>
                        <div class="price mergecolor"><sup>$</sup>2.99<span class="period">/mo</span></div>
                        <div class="plans badge bg-grey">Renews $4.00 /mo</div>
                        <div class="info seccolor">Great start for a single site</div>
                        <a href="" class="btn btn-default-yellow-fill">Buy Now</a><br>
                      </td>
                      <td>
                        <div class="title">Professional GIT</div>
                        <div class="price mergecolor"><sup>$</sup>4.00<span class="period">/mo</span></div>
                        <div class="plans badge bg-grey">Renews $4.00 /mo</div>
                        <div class="info seccolor">More sites and intensive resources</div>
                        <a href="" class="btn btn-default-yellow-fill">Buy Now</a>
                      </td>
                      <td>
                        <div class="title">Enterprise GIT</div>
                        <div class="price mergecolor"><sup>$</sup>7.99<span class="period">/mo</span></div>
                        <div class="plans badge bg-grey">Renews $15.00 /mo</div>
                        <div class="info seccolor">High performance &amp; unlimited sites</div>
                        <a href="" class="btn btn-default-yellow-fill">Buy Now</a>
                      </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <div class="title-table" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="I must explain to you">Websites
                        </div>
                      </td>
                      <td>2 Websites</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>
                        <div id="element" class="title-table" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="I must explain to you" aria-describedby="tooltip347465">SSD Storage
                        </div>
                      </td>
                      <td>20GB SSD</td>
                      <td>60GB SSD</td>
                      <td>120GB SSD</td>
                    </tr>
                    <tr>
                      <td class="title-table">Database</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td class="title-table"><span class="badge bg-purple">hot</span> Git Support</td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                    </tr>
                    <tr>
                      <td class="title-table"><span class="badge bg-pink">free</span> SSL Certificate</td>
                      <td>Positive SSL</td>
                      <td>Positive SSL</td>
                      <td>Wildcard SSL</td>
                    </tr>
                    <tr>
                      <td class="title-table">Auto Daily Backups</td>
                      <td><i class="fas fa-times seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                    </tr>
                    <tr>
                      <td class="title-table">Technology</td>
                      <td>GIT, PHP, MySQL</td>
                      <td>GIT, PHP, MySQL</td>
                      <td>GIT, PHP, MySQL</td>
                    </tr>
                    <tr>
                      <td class="title-table">Traffic</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td class="title-table"><span class="badge bg-pink">free</span> Control Panel</td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                    </tr>
                    <tr>
                      <td class="title-table">Deployment</td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                    </tr>
                    <tr>
                      <td class="title-table">SSH Access</td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                    </tr>
                    <tr>
                      <td class="title-table"><span class="badge bg-purple">hot</span> Push and Pull</td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                    </tr>
                    <tr>
                      <td class="title-table"><span class="badge bg-purple">plus</span> Multiple Branches</td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                    </tr>
                    <tr>
                      <td class="title-table"><span class="badge bg-grey">premium</span>  24/7 Ticket Support</td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                    </tr>
                    <tr>
                      <td class="title-table">Live Chat Support</td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                    </tr>
                    <tr>
                      <td class="title-table">Money-back Guarantee</td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                      <td><i class="fas fa-check seccolor"></i></td>
                    </tr>
                    <tr>
                      <td class="border-0 sticky-stopper"></td>
                      <td><a href="" class="btn btn-default-yellow-fill">Buy Now</a></td>
                      <td><a href="" class="btn btn-default-yellow-fill">Buy Now</a></td>
                      <td><a href="" class="btn btn-default-yellow-fill">Buy Now</a></td>
                    </tr>
                    </tbody>
                  </table>
                  <div id="bottom_anchor"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <hr class="fullline d-none">


</div>
</template>
<script>
export default{
  mounted(){
    this.$emit('closeLoading')
      // this.$emit('openLoading')
  }
}
</script>