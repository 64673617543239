import Vue from "vue";
import VueRouter from "vue-router";
import store from  "@/store"  

import Home from '../views/Pages/Home'
import About from '../views/Pages/About'
import Contact from '../views/Pages/Contact'
import DomainMarket from '../views/Pages/DomainMarket' 
import Forgot from '../views/Pages/Forgot'
import PriceList from '../views/Pages/PriceList'
import Reset from '../views/Pages/Reset'
import ResetPasswod from '../views/Pages/ResetPasswod' 
import LoginSignup from '../views/Pages/LoginSignup' 
import Hosting from '../views/Pages/Hosting'
import ContactUsNow from '../views/Pages/ContactUsNow'
import guideCenter from '../views/Pages/guideCenter'
import OpenTicket from '../views/Pages/OpenTicket'
import Cart from '../views/Pages/Cart'
import ReportAbuse from '../views/Pages/ReportAbuse'

import Faq from '../views/Pages/Faq'

//Authorized
import VerifyEmail from '../views/Account/VerifyEmail'
import TransferDomain from '../views/Account/TransferDomain'
import Histories from '../views/Account/Histories'
import MyAccount from '../views/Account/MyAccount'
import MyDomainList from '../views/Account/MyDomainList'
import Notifications from '../views/Account/Notifications'
import Settings from '../views/Account/Settings'
import Security from '../views/Account/Security'

import MyDomains from '../views/Account/MyDomains'
import SupportTickets from '../views/Account/SupportTickets'
import Invoices from '../views/Account/Invoices'
import Announcements from '../views/Account/Announcements'
import SubmitTicket from '../views/Account/SubmitTicket'

import TransactionHistory from '../views/Account/TransactionHistory'
import Addfunds from '../views/Account/Addfunds'

import RegisterDomain from '../views/Pages/RegisterDomain'
import WhoisInquiry from '../views/Pages/WhoisInquiry'
import DomainPrices from '../views/Pages/DomainPrices'
import DomainTansfer from '../views/Pages/DomainTansfer'

// Not found
import NotFound from '../Layouts/404'

Vue.use(VueRouter);
const routes = [
  // For Unauthorized
  { path: '',                     name: '',                     meta:{ requiresAuth:false, class:'' }, component: Home },
  { path: '/home',                name: 'home',                 meta:{ requiresAuth:false, class:'' }, component: Home },
  { path: '/about-us',            name: 'about',                meta:{ requiresAuth:false, class:'' }, component: About },
  { path: '/contact',             name: 'contact',              meta:{ requiresAuth:false, class:'' }, component: Contact },
  { path: '/report-abuse',             name: 'report',              meta:{ requiresAuth:false, class:'' }, component: ReportAbuse },


  // { path: '/domain-market',       name: 'domain-market',     meta:{ requiresAuth:false, class:'' }, component: DomainMarket },
  // { path: '/faq',                 name: 'faq',               meta:{ requiresAuth:false, class:'' }, component: Faq },
  // { path: '/forgot',              name: 'forgot',            meta:{ requiresAuth:false, class:'' }, component: Forgot },
  { path: '/login',                 name: 'login',                meta:{ requiresAuth:false, class:'' }, component: LoginSignup },
  { path: '/signup',                name: 'signup',               meta:{ requiresAuth:false, class:'' }, component: LoginSignup },
  { path: '/price-list',            name: 'price-list',           meta:{ requiresAuth:false, class:'' }, component: PriceList },
  { path: '/reset',                 name: 'reset',                meta:{ requiresAuth:false, class:'' }, component: Reset },
  { path: '/reset/:type',           name: 'reset',                meta:{ requiresAuth:false, class:'' }, component: Reset },
  { path: '/reset-password/:token', name: 'reset-passwod',        meta:{ requiresAuth:false, class:'' }, component: ResetPasswod },
  
  { path: '/hosting',             name: 'hosting',              meta:{ requiresAuth:false, class:'' }, component: Hosting },
  { path: '/cart',                name: 'cart',                 meta:{ requiresAuth:false, class:'' }, component: Cart },
  // blog-details   
  // For Authorized
   
  { path: '/verify/:id/:token',             name: 'verify-email',         meta:{ requiresAuth:true, class:'' }, component: VerifyEmail },
  { path: '/account/my-account',            name: 'my-account',           meta:{ requiresAuth:true, class:'' }, component: MyAccount },
  { path: '/account/settings',              name: 'settings',             meta:{ requiresAuth:true, class:'' }, component: Settings },
  { path: '/account/settings/additional-information', name: 'additional-information',   meta:{ requiresAuth:true, class:'' }, component: Settings },  
  { path: '/account/settings/email-verification', name: 'email-verification',   meta:{ requiresAuth:true, class:'' }, component: Settings },  

  { path: '/account/security',              name: 'security',             meta:{ requiresAuth:true, class:'' }, component: Security },
  { path: '/account/security/two-fa',       name: 'security',             meta:{ requiresAuth:true, class:'' }, component: Security },

  // 
  { path: '/account/histories',               name: 'histories',        meta:{ requiresAuth:true, class:'' }, component: Histories },
  { path: '/account/histories/login-history', name: 'histories',        meta:{ requiresAuth:true, class:'' }, component: Histories },

  { path: '/account/transfer-domain',     name: 'transfer-domain',      meta:{ requiresAuth:true, class:'' }, component: TransferDomain },

  { path: '/account/my-domains',          name: 'my-domains',           meta:{ requiresAuth:true, class:'' }, component: MyDomainList },
  { path: '/account/notifications',       name: 'notifications',        meta:{ requiresAuth:true, class:'' }, component: Notifications },
  { path: '/account/transaction-history', name: 'transaction-history',  meta:{ requiresAuth:true, class:'' }, component: TransactionHistory },
  { path: '/account/addfunds',            name: 'addfunds',             meta:{ requiresAuth:true, class:'' }, component: Addfunds },

  { path: '/account/my-domains',          name: 'my-domains',           meta:{ requiresAuth:true, class:'' }, component: MyDomains },
  { path: '/account/support-tickets',     name: 'support-tickets',      meta:{ requiresAuth:true, class:'' }, component: SupportTickets },
  { path: '/account/invoices',            name: 'invoices',             meta:{ requiresAuth:true, class:'' }, component: Invoices },
  { path: '/account/announcements',       name: 'announcements',        meta:{ requiresAuth:true, class:'' }, component: Announcements },
  { path: '/account/submitticket',        name: 'submitticket',         meta:{ requiresAuth:true, class:'' }, component: SubmitTicket },
  
  // domain
  { path: '/domains/register-domain', name: 'register-domain',  meta:{ requiresAuth:false, class:'' }, component: RegisterDomain },
  { path: '/domains/whois-inquiry',   name: 'whois-inquiry',    meta:{ requiresAuth:false, class:'' }, component: WhoisInquiry },
  { path: '/domains/domain-prices',   name: 'domain-prices',    meta:{ requiresAuth:false, class:'' }, component: DomainPrices },
  { path: '/domains/domain-transfer', name: 'domain-transfer',  meta:{ requiresAuth:false, class:'' }, component: DomainTansfer },
  // Support
  // { path: '/support/contact-us-now',   name: 'contact-us-now',  meta:{ requiresAuth:false, class:'' }, component: ContactUsNow },
  { path: '/guide-center/:name', name: 'guide-center',       meta:{ requiresAuth:false, class:'' }, component: guideCenter },
  // { path: '/support/open-ticket',   name: 'open-ticket',     meta:{ requiresAuth:true, class:'' }, component: OpenTicket }, 
  // Not found
  { path: "/:pathMatch(.*)*",     name: '*',                    meta:{ requiresAuth:false, class:''},  component: NotFound },
]
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
 
router.afterEach((to) => {
  Vue.nextTick(() => {
    if(to.name !=='guide-center'){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      document.body.className = to.meta.class;
    } 
  });
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});
export default router;