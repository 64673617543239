<template>
  <div>
    <p class="domain-unavailable domain-checker-unavailable domain-checker-available" v-if="message" style=" display: block; padding: 20px; color: white;  background-color: green!important;">{{message}} </p>
    <p class="domain-unavailable domain-checker-unavailable" v-if="errorMessage" style="display: block; padding: 20px; color: white; ">{{errorMessage}} </p> 
    <div class="input-group input-group-lg input-group-box" style="display: block; text-align: end">
      <input type="email" class="form-control" placeholder="Enter email" v-model="email">
      <span class="input-group-btn"> 
        <input type="submit" :value="$t('Send code')" @click="resetPasswordEmail" class="btn btn-default-yellow-fill disable-on-click spinner-on-click">
      </span>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{
      errorMessage:"",
      message:"",
      email:"",
    }
  },
  methods:{
    resetPasswordEmail(){
      this.$emit('openLoading')
      this.errorMessage = ''
      this.message = ''
      let data = {
        type:"email",
        email:this.email
      }
      this.$store.dispatch('send_link',data).then(()=>{  
        this.message = this.$store.state.auth.message
        this.$emit('closeLoading')
      }).catch(()=>{
        this.errorMessage = this.$store.state.auth.error_message
        this.$emit('closeLoading')
      })
    },
  }
}
</script>