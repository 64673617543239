<template>
  <div class="box-container limit-width">
    <div class="top-header motpath">
      <div class="total-grad-inverse"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="wrapper">
              <div class="heading">Contact us</div>
              <!-- <div class="subheading">Don't worry! We have support premium 24/7/365. We are looking forward waiting for your contact. </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <section id="ticket" class="exapath pb-80 noimage bg-seccolorstyle toppadding bottomhalfpadding">
      <div class="container" style="height: 700px;">
        <div class="sec-main sec-up mb-0 sec-bg1 bg-seccolorstyle nomargin nopadding noshadow" :style="{'top' : getMonitorSize() === 'web'?'67px':''}">
          <div class="row">
            <div class="col-md-12 col-lg-12 cd-filter-block mb-0">
              <div class="form-contact cd-filter-content p-0 sec-bx">
                <div class="text-center">
                  <h2 class="section-heading mergecolor">Full out the Contact form to contact us</h2>
                  <p class="mergecolor">We Will Help You To Choose The Best Plan!</p>
                </div>
                <div>
                  <div class="row">
                    <div class="col-md-12 mt-4" v-if="responseMessage">
                        <h3 class="c-pink">{{ responseMessage }}</h3>
                      </div>
                    <div class="col-md-6">
                    <div class="col-md-12 position-relative mt-4">
                      <label><i class="fas fa-user-tie"></i></label>
                      <input v-model="full_name" type="text" placeholder="Full Name" required>
                    </div>
                    <div class="col-md-12 position-relative mt-4">
                      <label><i class="fas fa-envelope"></i></label>
                      <input v-model="email" type="email" name="email" placeholder="Email Address" required>
                    </div>
                      <div class="col-md-12 position-relative mt-4">
                        <label><i class="fas fa-phone"></i></label>
                        <input v-model="phone" type="tel" name="phone" placeholder="Phone" value="phone" required @input="validatePhoneNumber">
                      </div>
                      <div class="col-md-12 position-relative">
                        <div class="cd-select mt-4">
                          <label class="db"></label>
                          <select id="department" name="department" class="select-filter" v-model="department">
                            <option value="">Select Department</option>
                            <option value="sale">Sale</option>
                            <option value="technical">Technical Support</option>
                            <option value="accounting">Accounting</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-12 position-relative mt-4">
                        <label><i class="fas fa-file-alt"></i></label>
                        <input v-model="subject" type="text" name="subject" placeholder="Subject" required>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="col-md-12 position-relative mt-4">
                        <label><i class="fas fa-file-alt" style="top: 1.5rem"></i></label>
                        <textarea v-model="message" type="text" :rows="getMonitorSize() === 'web' ? '12':''" placeholder="Message..." required style="resize: none;"></textarea>
                      </div>
                      <div class="g-recaptcha" :data-sitekey="captchaKey"></div>
                      <div class="col-md-12 position-relative" style="margin-top: 18px;">
                        <input name="newsletter" type="checkbox" id="checkbox" class="filter">
                        <button @click="sendEmaile" type="submit" value="Submit" class="btn btn-default-yellow-fill float-end" style="width: 100%;">Send</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="services maping sec-normal sec-grad-grey-to-grey bottompadding">
      <div class="container">
        <div class="service-wrap">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h2 class="section-heading text-white mergecolor" style="padding-top: 5rem">Our Datacenter Is Located In Georgia</h2>
            </div>
            <div class="col-md-12 pt-5 scrollme position-relative">
              <img src="/assets/patterns/map.svg" class="w-10 animateme ls-is-cached lazyloaded" alt="Load Balancing" data-when="view" data-from="1" data-to="0" data-opacity="0" style="opacity: 0.01; transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);">
              <span class="datacenters georgia" data-bs-toggle="popover" data-bs-container="body" data-bs-trigger="hover" data-bs-placement="top" data-bs-content="Company Address: 32 Zhiuli Shartava Avenue – Georgia,Batumi" role="button" aria-label="Georgia" data-bs-original-title="Georgia"></span>
              <!-- <span class="datacenters newyork" data-bs-toggle="popover" data-bs-container="body" data-bs-trigger="hover" data-bs-placement="top" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." aria-label="New York" data-bs-original-title="New York"></span>
              <span class="datacenters portugal" data-bs-toggle="popover" data-bs-container="body" data-bs-trigger="hover" data-bs-placement="top" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." aria-label="Portugal" data-bs-original-title="Portugal"></span>
              <span class="datacenters london" data-bs-toggle="popover" data-bs-container="body" data-bs-trigger="hover" data-bs-placement="top" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." aria-label="London" data-bs-original-title="London"></span>
              <span class="datacenters moskow" data-bs-toggle="popover" data-bs-container="body" data-bs-trigger="hover" data-bs-placement="top" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." aria-label="Moskow" data-bs-original-title="Moskow"></span>
              <span class="datacenters hongkong" data-bs-toggle="popover" data-bs-container="body" data-bs-trigger="hover" data-bs-placement="top" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." aria-label="Hong Kong" data-bs-original-title="Hong Kong"></span>
              <span class="datacenters singapure" data-bs-toggle="popover" data-bs-container="body" data-bs-trigger="hover" data-bs-placement="top" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." aria-label="Singapure" data-bs-original-title="Singapure"></span> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data(){
    return{
      topStyle:"",
      responseMessage:"",
      subject:"",
      full_name:"",
      email:"",
      phone:"",
      message:"",
      department:"",
      captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
      captchaResponse:"",
    }
  },
  watch:{
    '$route':{
      handler(newRoute, oldRoute){  
        if(newRoute.name === 'contact'){
          const script = document.getElementById('captcha-script');
          if(script){
            script.parentNode.removeChild(script);
          }
          const newScript = document.createElement('script');
          newScript.id = 'captcha-script';
          newScript.src = 'https://www.google.com/recaptcha/api.js';
          document.body.appendChild(newScript); 
          newScript.onload = () => {};
        }
      },
      immediate: true
    }
  },
  methods:{
    sendEmaile(){
      this.captchaResponse = grecaptcha.getResponse();
      if(this.captchaResponse.length == 0) {
        this.responseMessage  = this.$t("Please complete the reCAPTCHA")
        return
      }
      this.$emit('openLoading')
      let data = {
        subject: this.subject,
        full_name: this.full_name,
        email: this.email,
        phone: this.phone,
        message: this.message,
        department: this.department,
        "g-recaptcha-response":this.captchaResponse
      }
      this.$store.dispatch('contact_email',data).then(()=>{
        this.responseMessage = this.$store.state.site.message
        this.$emit('closeLoading')
      }).catch(()=>{
        this.responseMessage = this.$store.state.site.error_message
        this.$emit('closeLoading')
      })
    },
    validatePhoneNumber(){
      this.phone = this.phone.replace(/[^\d]/g, '');
    }
  },
  mounted(){
    this.$emit('closeLoading')
  }
}
</script>
<style scoped>
  .maping.datacenters.georgia {top: 44%;left: 59%;}
  .kanga1{fill:none;stroke:gray;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}
  .lion1,.lion2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}
  .lion1{stroke-width:2;stroke:#ee5586}
  .lion2{stroke:gray}
  .cock0,.cock1{fill:none;stroke:gray;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}
  .cock1{stroke:#ee5586;stroke-width:2}
  textarea {
    background-color: #101920 !important;
    border: 1px solid #414142;
    color: #fff !important;
    border-radius: 20px !important;
    font-family: 'Open Sans', sans-serif !important;
    width: 100%;
    padding: 15px 60px 15px 30px;
    font-size: 14px;
    cursor: pointer;
    appearance: none;
    box-shadow: none;
    line-height: inherit;
  }
  .cd-select .select-filter {
    color: grey !important;
  }
  .cd-filter-block .cd-select::after {
    margin: 0;
    padding: 0;
    right: 2.5rem;
  }
</style>