<template>
  <div class="box-container limit-width">
    <div class="bg-colorstyle">
      <div class="top-header item17 overlay">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="wrapper">
                <h1 class="heading">{{$t('Transfer Domain')}}</h1>
                <h3 class="subheading">{{$t('Here is your can make transfer domain')}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  Content here  -->
    </div>
  </div>
</template>
<script>
export default {
  name: "TransferDomain",
  mounted(){
    this.$emit('closeLoading')
    // this.$emit('openLoading')
  }
}
</script>