import axios from 'axios'
const site = {
  
  state: {
    message:'',
    data:[],
    report_types:[],
    report_abouse:[],
  },
 
  mutations: {
    set_success_message(state,data){
      state.message = data
    }, 
    set_data(state, data){
      state.data = data
    },
    set_report_types(state, data){
      state.report_types = data
    },
    set_error_message(state, response) {
      const errorMap = {
        404: "data_not_found",
        400: response.data.message,
        401: () => { this.commit('del_user') },
        403: response.data.message,
        405: "method_not_allowed",
        429: "too_many_requests",
        422: (response) =>
        response.data.success === false
          ? response.data.data.message
          : Object.values(response.data)[0][0],
        500: "please_try_again",
      };
      state.error_message = errorMap[response.status] instanceof Function
        ? errorMap[response.status](response) : errorMap[response.status] || "unknown_error";
    },
  },
  actions: {

    whois({commit},domain){
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_API_ENDPOINT + "whois?domain="+domain, method: "GET" }).then((resp) => { 
          commit('set_data',resp.data)
          resolve(resp);
        }).catch((err) => {
          commit("set_error_message", err.response);
          reject(err);
        });
      });
    },

    search_domain({commit},domain){
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_API_ENDPOINT + "whois?domain="+domain, method: "GET" }).then((resp) => { 
          commit('set_data',resp.data)
          resolve(resp);
        }).catch((err) => {
          commit("set_error_message", err.response);
          reject(err);
        });
      });
    },

    report_types({commit},){
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_API_ENDPOINT + "report-types", method: "GET" }).then((resp) => {  
          commit('set_report_types',resp.data.data)
          resolve(resp);
        }).catch((err) => {
          commit("set_error_message", err.response);
          reject(err);
        });
      });
    },

    report_abouse({commit},data){
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_API_ENDPOINT + "report", data:data, method: "POST" }).then((resp) => { 
          commit('set_success_message',resp.data.data)
          resolve(resp);
        }).catch((err) => {
          commit("set_error_message", err.response);
          reject(err);
        });
      });
    },
 
    contact_email({commit},data){
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_API_ENDPOINT + "contact", data:data, method: "POST" }).then((resp) => {  
          commit('set_success_message',resp.data.data)
          resolve(resp);
        }).catch((err) => {
          commit("set_error_message", err.response);
          reject(err);
        });
      });
    }
  },
  modules: {}
}

export default site;

