<template>
    <div class="box-container limit-width">
      <div class="bg-colorstyle">
        <div class="top-header item17 overlay">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <div class="wrapper">
                  <h1 class="heading">{{$t('Security')}}</h1>
                  <h3 class="subheading">{{$t('Here is your account settings')}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper sec-normal">
          <div class="content bg-colorstyle noshadow nopadding">
            <div class="inner-content">
              <div class="main-body">
                <div class="page-wrapper">
                  <section id="main-body">
                    <div class="container">
                      <div class="main-content">
                        <div class="tabs-header btn-select-customer">
                        <ul class="btn-group d-block">
                          <li class="btn btn-secondary mb-2" :class="{active:$route.fullPath==='/account/security'}" @click="$router.push('/account/security').catch(()=>{})">{{ $t('Edit password') }}</li>
                          <li class="btn btn-secondary" :class="{active:$route.fullPath==='/account/security/two-fa'}" @click="$router.push('/account/security/two-fa').catch(()=>{})">{{$t('Two fa security')}}</li>
                        </ul>
                      </div> 
                      <ChangePassword v-if="ChangePasswordTab" @closeLoading="$emit('closeLoading')" @openLoading="$emit('openLoading')"/>
                      <TwoFaGoogle v-if="TwoFaGoogleTab" @closeLoading="$emit('closeLoading')" @openLoading="$emit('openLoading')"/> 
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
import ChangePassword from '@/components/AccountSecurity/ChangePassword'
import TwoFaGoogle from '@/components/AccountSecurity/TwoFaGoogle' 
export default {
  components:{ ChangePassword, TwoFaGoogle },
  data(){
    return{ 
      ChangePasswordTab:true,
      TwoFaGoogleTab:false
    }
  },
  watch:{
    $route:{
      handler(n,o){
        if(n){
          this.changeRoute()
        }
      }
    }
  },
  methods:{ 
    changeRoute(){ 
      if(this.$route.path === '/account/security'){
        this.ChangePasswordTab = true
        this.TwoFaGoogleTab = false
      }else if(this.$route.path === '/account/security/two-fa'){
        this.ChangePasswordTab = false
        this.TwoFaGoogleTab = true
      }else{
        this.ChangePasswordTab = true
        this.TwoFaGoogleTab = false
      }
    }
  },
  mounted(){
    this.changeRoute()
  }
}
</script>
<style>
  .cd-filter-block label i { top:15px;  }
</style> 