<template>
<div>
  <div class="top-header">
    <div class="total-grad-inverse"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="wrapper">
            <div class="heading mb-0">{{ $t(activeTitle) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section id="gotop" class="blog motpath pb-80 bg-colorstyle">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-4">
          <aside id="sidebar" class="sidebar manual-position mt-80 sec-bg1 bg-seccolorstyle noshadow" style="top: -35px; bottom: auto">
            <div class="menu categories clear">
              <div class="heading pt-2" v-for="(k, i) in menu" :key="i">
                <router-link :to="k.url" class="gocheck showSingle seccolor" :class="{ active: $route.params.name === k.slug }" @click="(activeID = k.id), (activeTitle = k.name)">
                  {{ k.name }}
                </router-link>
              </div>  
              <div class="heading pt-2">
                <a href="https://www.icann.org/registrants" class="gocheck showSingle seccolor" target="_blank">Registrant Educational Information</a>
                <img src="/assets/img/external-link.svg">
              </div>
              <div class="heading pt-2">
                <a href="https://www.icann.org/resources/pages/wdrp-2012-02-25-en" class="gocheck showSingle seccolor" target="_blank">WHOIS Data Reminder Policy</a>
                <img src="/assets/img/external-link.svg">
              </div>
              <div class="heading pt-2">
                <a href="https://www.icann.org/resources/pages/errp-2013-02-28-en" class="gocheck showSingle seccolor" target="_blank">Expired Registration Recovery Policy</a>
                <img src="/assets/img/external-link.svg">
              </div> 
              <div class="heading pt-2">
                <a href="https://www.icann.org/resources/pages/help/dndr/udrp-en?search_text=abuse&previous_text=&commit=%EF%80%82" class="gocheck showSingle seccolor" target="_blank">Dispute Resolution Policy</a>
                <img src="/assets/img/external-link.svg"> 
              </div>
            </div>
          </aside>
        </div>
        <div class="pt-35 col-md-12 col-lg-8">
          <div id="sidebar_content" class="wrap-blog">
            <div class="row">
              <div class="col-md-12 col-lg-12 knowledge">
                <div id="div1" class="wrapper targetDiv mt-5 bg-seccolorstyle noshadow" style="height: 70vh; overflow-x: scroll">
                  <h5><a href="javascript:void(0)" class="category"><b>{{ activeTitle }}</b></a></h5>
                  <hr />
                  <div class="blog-info" v-if="activeID === 1">
                    <p class="seccolor">
                      Domain transactions are conducted in accordance with the guidelines set by the globally recognized Internet Corporation for Assigned Names and Numbers - ICANN.
                      These procedures, established by the relevant authority, are globally applicable, unalterable, and mandatory for all domain extensions.
                      <br><br>
                      After the registration period expires, domains forfeit all management rights. No crucial modifications, such as contact information edits or DNS changes, 
                      can be made to domains with an expired registration period. To prevent this scenario, it is advisable to renew the domain before the expiration date.
                      <br>
                      Complete ownership loss of domains occurs at the conclusion of an 80-day period. <br><br>
                      If the ownership of a domain is lost due to the expiration of the registration period, and the retrieval process is not initiated following the prescribed procedures, the domain becomes available for re-registration by any user, in accordance with the extension policy.
                      <br><br>
                      <strong>Domain Procedures;</strong><br>
                      Active Status (Active): The domain is registered and has not entered any deletion phase. At any point during this process, the domain owner can extend the duration of the domain at regular pricing and perform all domain-related management operations.
                      <br><br>
                      On-Hold Status: The term "registrar-hold" is displayed on the status screen resulting from a whois query for domains in this process. Domains first enter on-hold status when their registration period expires. VEZRA provides its customers with an additional renewal period for the first 5 days that have entered on-hold status. During this 5-day period, customers can extend their domains at regular pricing.The on-hold status lasts for a total of 45 days. (On-hold status does not cover all extensions.)
                      <br><br>
                      Redemption Period (Last chance to save): This period is created by ICANN to give the domain owner the last opportunity to extend the domain after on-hold status ends. This period lasts for a total of 30 days.
                      <br><br>
                      Pending Delete (Pending deletion): This is the 5-day period following the Redemption period. During this period, the domain owner does not have the right to reclaim the domain. At the end of this process, the domain is entirely deleted and becomes available for new registration.
                      <br><br>
                      As outlined in the above processes, a domain can be re-registered within 80 days if its registration is not renewed.
                      <br><br>
                      <strong>Warnings and Reminders;</strong><br>
                      VEZRA not only sends warning messages to your email address in your registered membership in the system if your domain expires and you exceed your extension order but also enables you to track your domains that are about to expire and extend them from your panel with the username and password provided to you.
                      <br><br>
                      As our company has established the necessary infrastructure concerning expired domains under any circumstances, it does not accept responsibility for issues such as not extending or missing the domain period.
                      <br><br>
                      <strong>Our recommendations to our customers;</strong><br>
                      As VEZRA, we consistently advise domain users to opt for longer registration periods when registering long-term domains, especially those with a corporate identity or important domains belonging to their companies. It is crucial to complete the extension process on time, without delay, to avoid any problems.
                    </p>
                  </div>
                  <div class="blog-info" v-if="activeID === 2">
                    <p class="seccolor">
                      The return and exchange conditions for products purchased through vezra.com are governed by the following terms and procedures in accordance with the electronically executed sales contract between the customer and the final owner of the product.<br>
                      <br><strong>Return Condition.</strong><br>
                      1. The return request is applicable only if the service performance has not commenced.<br>
                      2. The product intended for return must be in a condition suitable for resale with the same quality and quantity.<br>
                      3. The final owner of the product must notify vezra.com in writing within 7 calendar days following the purchase to initiate the return process.<br>
                      4. The final owner of the product should not have had a positive refund request for other products within the same category or product group within the last 1 (one) year. .<br>
                      5. To facilitate return transactions, the return request form at the bottom of the invoice must be completed and submitted to 
                      <a href="mailto:accounting.manager@vezra.com">accounting.manager@vezra.com</a> Invoices and orders lacking a return request form cannot be cancelled or refunded.<br>
                      6. Vezra.com will notify the customer about cancellation and return procedures for undeliverable products (e.g., registration, renewal, installation). If the customer fails to respond to cancellation or return information due to incomplete or incorrect contact details, Vezra will credit the refund to the customer's existing account in the system.<br>
                      7. In the event of a double payment from the customer's credit card due to technical, bank-related, or customer-caused reasons, the customer must inform Vezra's Accounting section in writing about the duplicate withdrawal. We will conduct the necessary checks and cancel the duplicate withdrawal upon notification.<br>
                      <br><strong>Products are not eligible for return.</strong><br>
                      The following products cannot be returned because, upon service performance and/or return, they cannot be resold to another customer in the same quality and quantity.
                      <br>
                      1. Domain Names: Once a domain has been acquired, it is non-returnable.<br>
                      2. Trademark Registration Service and Fee: Fees associated with trademark registration services are non-refundable.<br>
                      3. Consultancy and Brokerage Services: All types of consultancy and brokerage services are non-returnable.<br>
                      4. Technical Support Services: All types of technical support services are non-refundable.<br>
                      5. Third-Party Procured Products: Products acquired through third-party procurement, of any kind, are non-returnable.<br>
                      6. Free Services and/or Products: Any services or products provided without charge are non-returnable.<br>
                      7. Installed or Activated SSL Certificates: Once SSL certificates have been installed or activated, they cannot be returned.<br>
                      <br><strong>Return procedure.</strong><br>

                      If the return conditions outlined above are met for products eligible for return, vezra.com will initiate the following return procedures within 15 (fifteen) business days following the final consumer request.
                      <br><br><strong>Transmission of a refund request.</strong><br>
                      For return requests made within 7 (seven) calendar days following the purchase, the end consumer must submit the following information via email to 
                      <a href="mailto:accounting.manager@vezra.com">accounting.manager@vezra.com</a> using the verified email address associated with the verza.com member account: <br>
                      Upon receiving the refund request, verza.com will initiate the necessary verification and processing steps in accordance with the established return procedures.<br>
                    
                      1. Product/Service Subject to Return:Clearly specify the product or service for which the return is requested.<br>
                      2. Reason for Return (if applicable): Provide us with a brief explanation of the reason for the return, if applicable.<br>
                      3. Type of Refund Requested: Indicate cash, credit card, or other. <br><br>
                      <strong>Refusal of Refund Request.</strong><br> Vezra.com reserves the right to reject refund requests that do not align with the return policies previously announced. Refusal may occur if the request fails to meet the specified conditions or if it is inconsistent with the established procedures.<br>
                      <br><strong>Withdrawal from the Refund Request.</strong><br>The final consumer retains the right to withdraw from the refund request at any point until the refund is successfully processed. This withdrawal option allows consumers flexibility in their decision-making process regarding the return of products or services.
                      <br><br>These return policies form a binding agreement
                      between vezra.com and the individuals or organisations acting as the final consumers of its products and services. Every person or entity making a purchase through vezra.com is obligated to adhere to the terms outlined in this declaration and its associated principles.
                    </p>
                  </div>
                  <div class="blog-info" v-if="activeID === 3">
                    <p class="seccolor">
                      Before engaging in any activities on Vezra.com, it is essential to familiarise
                      yourself with our Privacy Policy. If you do not agree with the terms
                       outlined in the Privacy Policy, kindly refrain from taking any
                      actions on Vezra.com. Please note that this Privacy Policy is applicable exclusively to
                      Vezra.com and is not applicable to other sites mentioned on Vezra.com. By using Vezra.com,
                      you are confirming your acceptance of the terms set forth in this Privacy Policy. <br><br>
                      To acquaint you with our offerings, share details about new products, services, or promotions,
                      facilitate product sales, or offer post-sales assistance, we may require specific personal details from
                      you, including your name, address, email address, and telephone number. Should the provided information
                      be insufficient, we might request additional details through communication channels such as email or telephone.
                      All information gathered within this context is treated as personal information on Vezra.com. <br>
                      Vezra.com may employ technologies enabling the automatic collection of non-personal information
                      during your site visit. This technical information may include your Internet Protocol (IP) address,
                      your computer's operating system, browser type, traffic patterns, or the addresses of pertinent websites.
                      To gather more detailed insights into our visitors, our site may transmit information files known as cookies to
                      your computer. These files contain technical details about the system you are using and are commonly utilised globally.
                      <br><br> Vezra.com reserves the right to share non-personal data, such as information about the operating system used, with
                      third parties for informational purposes. However, any private information classified as personal will not be disclosed to third parties
                      unless legally mandated. <br> For specific services received, such as SSL, domain, or licence, Vezra.com may share user information with third parties
                      as part of the registration or service benefit process. This sharing is conducted to provide the necessary information required for the
                      transaction with the relevant party (institution/company). Additionally, in accordance with ICANN rules governing domain name registration,
                      Vezra.com may transmit WHOIS information to institutions or companies as mandated and specified by ICANN.
                      <br><br>In the process of domain registration, the domain operator may record and process account and WHOIS information
                      that is transferred to the domain operator for its own operational purposes. <br><br>
                      Security is of utmost importance to us, and we implement essential technological measures to ensure the absolute security of data.
                      It is essential to note, however, that no technological system can guarantee 100% security. Vezra.com is committed to taking the
                      necessary precautions to prevent or minimise risks such as misuse or unauthorised access to your personal information. We strive to keep you
                      informed through informative publications regarding our ongoing efforts to enhance security measures and safeguard your data.
                      <br>Vezra.com is dedicated to maintaining the accuracy of the collected data. In cases where information entered by a visitor is incomplete
                      or incorrect, Vezra.com reserves the right to replace or delete such data. This may involve updating the information with new details provided by
                      the visitor or correcting it through cross-examination methods to ensure the accuracy and integrity of the data.
                      <br><br>We reserve the right to change this privacy policy at any time. Any changes we make will be posted on this page.Should you have any
                      questions or concerns about this privacy policy and our privacy practices, you may contact us at
                      <a href="mailto:compliancepro@vezra.com">compliancepro@vezra.com</a>
                    </p>
                  </div>
                  <div class="blog-info" v-if="activeID === 4">
                    <p class="seccolor">
                      If you use the website with the extension www.vezra.com, your personal data may be processed in accordance with the purposes specified in the
                      information. Text on the processing of personal data for website visitors, depending on the use of the website. It can also be collected through
                      cookies.
                    </p>
                      <strong class="seccolor">What is a Cookie?</strong>
                    <p class="seccolor">
                      We use cookies and other technologies to enable features on the website. A cookie is a small text file that stores Internet settings. The next time
                      you visit this website with the same terminal device, the cookie and the information in it are either sent back to the originating website (first-party cookie)
                      or to another website to which it belongs (third-party cookie). By that, the website can detect that it has already been opened using this browser, and in some
                      cases, it will then vary the content it shows. <br> Almost every website uses cookie technology. Some cookies are useful as they are able to improve the user experience.
                      Cookies, among other things, speed navigation through our website, keep track of information so that you do not have to re-enter it each time you visit our website, help
                      us provide you with customised content, and allow us to learn about your visit. <br> Some cookies we use require your consent, while others are exempt.
                    </p>
                    <strong class="seccolor">Types of Cookies Vezra uses.</strong>
                    <p class="seccolor">
                      Cookies are divided into groups according to their managers, lifetimes, and purposes of use. Accordingly, the cookies used on our Website;
                      <ul class="custom-list">
                        <li>Session cookies are temporary cookies that remain active only during your browser session and are automatically deleted after you close your browser.
                          They do not recognise you on subsequent visits to the website.
                        </li>
                        <li>Stored for an extended period until manually deleted by you or automatically by your browser, persistent cookies typically have a retention period ranging from
                          30 days to a maximum of 2 years.
                        </li>
                        <li>First-party cookies are created by the website you are visiting.
                        </li>
                        <li>Cookies are generated by third parties, such as business partners and service providers associated with the website.
                        </li>
                        <li>Necessary cookies are essential for providing services through the website, ensuring efficient website operation based on your settings, and maintaining website security.
                          These cookies are instrumental in detecting and addressing potential issues, such as identity theft, bot attacks, and fraud.
                        </li>
                        <li>Primarily persistent cookies from third parties, advertising cookies enable the delivery of personalised ads based on user interests. They also assist in monitoring marketing campaigns.
                          If you're logged into social media accounts while browsing, these cookies may create a profile based on your interests.
                        </li>
                        <li>Tracking cookies, such as pixels, scripts, web beacons, gifs, or page tags, record interactions to analyse and enhance services and campaigns.
                          Blocking tracking cookies in your browser does not affect the website's services. The processing of tracking cookies is contingent upon your explicit consent.
                        </li>
                      </ul>
                    </p>
                    <strong class="seccolor">How To Control And Disable Cookies.</strong>
                    <p class="seccolor">
                      You can control, delete, or disable cookies at any time by adjusting your browser settings. Browsers are different, so refer to instructions related to your browser to learn about cookie-related and other privacy and security settings that may be available.
                      <br><br> Please be aware, however, that if you turn off cookies, you may not be able to take full advantage of all of the features of our website.
                    </p>
                  </div>
                  <div class="blog-info" v-if="activeID === 5">
                    <p class="seccolor">
                      <strong>Domain Name Registrants' Rights:</strong>
                      <ul>
                        <li>1. Your domain name registration and any privacy/proxy services you may use in conjunction with it must be subject to a Registration Agreement with an ICANN Accredited Registrar.
                        </li>
                        <li class="custom-list">You are entitled to review this Registration Agreement at any time, and download a copy for your records.
                        </li>
                        <li>2. You are entitled to accurate and accessible information about:
                        </li>
                        <li class="custom-list">The identity of your ICANN Accredited Registrar;
                        </li>
                        <li class="custom-list">The identity of any proxy or privacy service provider affiliated with your Registrar;
                        </li>
                        <li class="custom-list">Your Registrar's terms and conditions, including pricing information, applicable to domain name registrations;
                        </li>
                        <li class="custom-list">The terms and conditions, including pricing information, applicable to any privacy services offered by your Registrar;
                        </li>
                        <li class="custom-list">The customer support services offered by your Registrar and the privacy services provider, and how to access them;
                        </li>
                        <li class="custom-list">How to raise concerns and resolve disputes with your Registrar and any privacy services offered by them; and
                        </li>
                        <li class="custom-list">Instructions that explain your Registrar's processes for registering, managing, transferring, renewing, and restoring your domain name registrations, including through any proxy or privacy services made available by your Registrar.
                        </li>
                        <li>3. You shall not be subject to false advertising or deceptive practices by your Registrar or though any proxy or privacy services made available by your Registrar. This includes deceptive notices, hidden fees, and any practices that are illegal under the consumer protection law of your residence.
                        </li>
                      </ul>
                      <strong>Domain Name Registrants' Responsibilities:</strong>
                      <ul class="seccolor">
                      <li>1. You must comply with the terms and conditions posted by your Registrar, including applicable policies from your Registrar, the Registry and ICANN.
                      </li>
                      <li>2. You must review your Registrar's current Registration Agreement, along with any updates.
                      </li>
                      <li>3. You will assume sole responsibility for the registration and use of your domain name.
                      </li>
                      <li>4. You must provide accurate information for publication in directories such as WHOIS and promptly update this to reflect any changes.
                      </li>
                      <li>5. You must respond to inquiries from your Registrar within fifteen (15) days and keep your Registrar account data current. If you choose to have your domain name registration renewed automatically, you must also keep your payment information current.
                      </li>
                      </ul>
                    </p>
                  </div>
                  <div class="blog-info" v-if="activeID === 6">
                    <p class="seccolor">
                      <strong>1 - Parties</strong><br>
                      1.1: Portosoftware LLC, referred to as VEZRA, provides the services specified in the services section of this contract (referred to as "services"). The individual or institution (referred to as " Registrant ") with the details specified in the new Registrant registration section, using the website operating at https://vezra.com/ and purchasing services through this site, will be deemed to have agreed to the terms and conditions specified below.<br>
                      1.2: The Registrar agrees to process the domain name registration on behalf of the Registrant based on the accurate and complete registration data provided by the Registrant.<br>
                      1.3: The registrant acknowledges that the registration of the domain name is subject to the policies and guidelines of ICANN and any relevant domain registry.<br>
                      1.4: The registrant understands that the registration of the domain name does not confer any legal rights on the name itself and is subject to the rights of third parties.<br>
                      1.5: The registrant acknowledges that the domain name registration is on a first-come, first-served basis and does not guarantee the availability of the requested domain name.<br>
                      1.6: The parties declare, accept, and undertake the accuracy of the information written in this agreement.<br>
                      1.7: The registrant represents that the registration and use of the domain name do not infringe the legal rights of any third party.<br><br>
                      <strong>2- Subject</strong><br>
                      2.1: This agreement elucidates the responsibilities of the Registrant to the company and vice versa concerning the registered domain name and other services utilized. It also outlines the rules and conditions for the registration, renewal, restoration, and transfer of domain names. To complete the registration process, the Registrant must read and accept all the rules and conditions specified in this agreement.<br>
                      2.2: During registration, purchase, or similar transactions, VEZRA may require the sharing of contact information such as email, address, mobile phone, etc. This information may be utilized for notifications, newsletters, or the enforcement of security protocols, including account confirmation.<br>
                      2.3: The registrant agrees to provide accurate, current, and complete registration data during the domain name registration process and to promptly update such information whenever it changes.<br>
                      2.4: The Registrar shall maintain the confidentiality of the registration data but may disclose such information to ICANN, relevant domain registries, or as required by law.<br>
                      2.5: The registrant acknowledges that providing false or misleading registration data may lead to the termination of the domain name registration.<br>
                      2.6: VEZRA reserves the right to make changes and updates to this agreement when deemed necessary.<br><br>
                      <strong>3 - Responsibilities</strong><br>
                      3.1: If the domain name chosen by the Registrant infringes upon any trademark or intellectual property, creates unfair competition, or violates other situations specified by law, all responsibility lies with the Registrant. VEZRA reserves the right to suspend or cancel the domain name if it is determined that the domain name is used for an unlawful purpose, in an unlawful manner, or does not comply with any other terms of use.<br>
                      3.2: VEZRA does not warrant that any domain name will be available for registration, renewal, or transfer at any time or that VEZRA may have a superior right of entry to the principal registration authority's records than other registrars. After selecting the domain name, the Registrant must check whether this domain name is available for registration.<br>
                      3.3: The Registrant states and accepts that the registration and direct or indirect use of the domain name, with his or her knowledge, will not harm the legal rights of any third party and that the domain name will not be registered or used for any unlawful purpose. VEZRA reserves the right to suspend or cancel the domain name if it is determined that the domain name is used for an unlawful purpose, in an unlawful manner, or does not comply with any other terms of use.<br>
                      3.4: The Registrant agrees to adhere to the statements and warnings received from VEZRA within the service provided. The Registrant declares, accepts, and undertakes to comply with any warning or notification issued by VEZRA while using the hosting account. The Registrant is prohibited from distributing or selling, either for a fee or free of charge, the services provided to them free of charge or unlimited in the possession service they have received, whether limited or unlimited.<br>
                      3.5: All live support calls, support requests, e-mails, telephone calls, and written and verbal communications between the Registrant and VEZRA are private and confidential. It cannot be shared without the permission and approval of VEZRA.<br>
                      3.6: The Registrant undertakes not to access files or programmes for which they do not have access rights, using the software and programmes they own within the service. The Registrant also agrees not to create any issues due to such unauthorised access and to be liable for any damages that may arise from such problems or issues.<br>
                      3.7: The Registrant acknowledges and agrees that they will be responsible for any taxes, duties, and similar obligations that are in force during the use of the domain name, hosting, or services received that come into force during the contract.<br>
                      3.8: The Registrant accepts and undertakes to bear all legal and criminal liability arising from the illegality of such data, information, and statements. They are responsible for all files, documents, and programmes contained within the Registrant service, as well as all transactions used and benefited from the website and e-mail services. VEZRA may terminate user accounts for violating these guidelines, for any other reason, or if VEZRA believes that it is harmful to its own business or the business of any of its users. VEZRA has the right to delete illegal acts and actions without informing the Registrant as soon as it becomes aware of them.<br>
                      3.9: VEZRA reserves the right to change the products and services it provides over time.<br>
                      3.10: The Registrant is obligated to use the services received in a manner that does not harm other users. If such uses are detected, VEZRA may warn the Registrant and request correction, or it may temporarily suspend the service without prior notification.<br>
                      3.11: VEZRA reserves the right to discontinue the expired domain name, hosting, or other services it offers after the expiration of the period and to cancel the service entirely.<br>
                      3.12: In VEZRA's Domain Name Registration Services, the responsibility for changing and correcting WHOIS information, domain name registration passwords, and transfer locks lies with the Registrant. VEZRA or domain operators may suspend the domain name due to unverified WHOIS information in accordance with ICANN rules and may request documents to verify WHOIS information.<br>
                      3.13: In domain transactions such as registration, transfer, renewal, WHOIS, NS update, status change, etc., VEZRA applies the relevant ICANN rules in conjunction with the rules and agreements depending on the registrar from which it receives service. These rules are subject to change over time.<br>
                      3.14: For domain name dispute resolution, VEZRA may guide the user to legal authorities or the Domain Name Dispute Resolution Mechanism (UDRP). Additionally, VEZRA reserves the right to limit or close access to the domain name via the panel when deemed necessary. Users can enable or disable domain name WHOIS protection, activate or deactivate domain transfer lock, extend the domain name, or update domain NS information.<br>
                      3.15: Domain name services are forms of service for which the right to use is granted for the duration of the fee. Therefore, if the fee for the domain name is not paid within the expiration period, this right may be transferred to another person.<br>
                      3.16: The essence of this contract lies in the registrant's commitment to register one or more domain names through the registrar, adhering to the terms and conditions stipulated in this agreement and any additional requirements set by ICANN and the registrar.<br>
                      3.17: The Registrant agrees to register one or more domain names through the Registrar, subject to the terms and conditions of this Agreement and any additional terms, specifications, and policies set forth by ICANN and the Registrar.<br>
                      3.18: The Registrant must provide to the registrar with accurate and reliable contact details and correct and update them within seven (7) days of any change during the term of the Registrant including the full name, address, e-mail address, telephone number, etc.<br>
                      3.19: If a Registrant willfully provides inaccurate or unreliable information, fails to promptly update information with the Registrar, or does not respond within fifteen (15) calendar days to inquiries regarding the accuracy of contact details associated with the registration, it constitutes a material breach of the Registrant-registrar contract. Such actions can be grounds for the cancellation of the registration.<br>
                      3.20 If a Registrant intends to license the use of a domain name to a third party, they remain the Registrant of record and must ensure the provision of their own complete contact details. It is the Registrant's responsibility to furnish and update accurate technical and administrative contact information to facilitate the timely resolution of any issues related to the domain. In cases where a Registrant licenses the use of a domain, they assume liability for any harm resulting from improper use, unless they promptly disclose the current contact information and the identity of the licensee to a party providing reasonable evidence of actionable harm.<br><br>
                      <strong>4 - Duration </strong><br>
                      4.1: The stated rights and obligations of the parties in this contract commence when the order and payment transactions are transmitted to VEZRA via the internet.<br>
                      4.2: The contract period is the payment period selected by the Registrant during the order for the relevant service.4.3: The Registrar shall notify the Registrant regarding the expiration of the domain name registration and the renewal process.<br>
                      4.4: The registrant may choose to transfer the domain name to another accredited registrar in compliance with ICANN's Transfer Policy.
                      <br><br>
                      <strong>5 - Fee</strong><br>5.1: The registrant agrees to pay the applicable fees for the registration and renewal of the domain name as specified by the registrar.<br>
                      5.2: The registrant understands that failure to pay the fees within the designated time may result in the suspension or termination of the domain name registration.5.3: The fee to be paid for the services specified in this contract is the amount specified during the order process. It is calculated by including VAT in the specified fees and is presented to the Registrant. In the case of a delay in payment, VEZRA reserves the right to issue a late payment invoice.<br>
                      5.3: VEZRA reserves the right to turn off or on the relevant service until the Registrant completes the payment process.<br>
                      5.4: The Registrant is obligated to notify VEZRA about the payments for the services they have received, regardless of the payment method. The notification must clearly state the order number, the name of the product or service being paid for, and the payment method.
                      <br>5.5: The Registrant shall indemnify and hold harmless the Registry Operator and its directors, officers, employees, and agents from and against all claims, damages, liabilities, costs, and expenses (including reasonable legal fees and expenses) arising out of or related to the Registrant's domain name registration.
                      <br><br><strong>6 - Suspension</strong> <br>6.1: The Registrant agrees that the registered domain name may be suspended, cancelled, or transferred by the Registrar or the Registry Operator (as per ICANN Specification or Policy) in case of mistakes in registration or for the resolution of disputes concerning the domain name.<br>
                      6.2: VEZRA reserves the right to suspend all services provided to the Registrant, including e-mail, web, and FTP accounts, in the event of payment issues, provisioning problems for Registrant with credit card payment orders, or breaches of provisions and obligations. During this situation, access to e-mail, web, and FTP services on behalf of the Registrant will be restricted, and e-mail accounts will be blocked, with incoming e-mails being rejected.<br><br>
                      <strong>7 - Termination </strong><br>
                      7.1: If the Registrant violates any article of this contract, fails to fulfil its responsibilities and commitments, or if it is determined that the information declared on the front of this contract is not correct, and if the suspension of the contract continues for more than 7 days, VEZRA has the right to unilaterally terminate the contract without any need.<br>
                      7.2: After such termination, the Registrant declares, accepts, and undertakes that they cannot claim back the last contract fee they paid, regardless of the remaining period.7.3: The Registrar shall not be liable for any loss, damages, or costs arising out of the use or registration of the domain name.<br>
                      7.4: The Registrant agrees to indemnify and hold the Registrar harmless from any claim or dispute arising from the use of the domain name.
                      <br><br><strong>8 - Contact and Information Addresses</strong><br>
                      8.1: The parties accept, declare, and undertake that the addresses specified in the order address are their legal notification addresses for all kinds of notifications arising from the contract.<br>
                      8.2: Any notification made to these addresses will be deemed to have been notified, even if the parties do not receive it. Unless changes to these addresses are notified to the other party in writing, the old addresses will be valid.<br>
                      8.3: VEZRA may send messages, information, letters, warnings, payment notifications, account activity schedules, and account statements to the e-mail address allocated to the Registrant during the contract period. The Registrant cannot claim that the electronic messages in question were not received or did not reach him. They declare, accept, and undertake that they will be deemed to have been notified in a legal sense.8.4: The registrant shall provide accurate and reliable contact details during the registration process, including full name, postal address, email address, voice telephone number, fax number (if available), and any other data elements as required by ICANN, and shall promptly update this information within seven (7) days of any changes.<br>
                      8.5: The registrant acknowledges that providing inaccurate or unreliable information or failing to update the contact details within the specified time frame may result in the suspension and/or cancellation of the registered domain name.
                      <br><br><strong>9- Licencing of Domain Names</strong><br>
                      9.1: If the Registrant licences the use of the registered domain name to a third party, the Registrant remains the Registered Name Holder of record and agrees to provide and maintain accurate technical and administrative contact information to resolve any issues related to the domain name promptly.<br>
                      9.2: The Registrant accepts liability for any harm caused by the wrongful use of the domain name, unless the Registrant discloses the current contact information of the licensee and the licensee's identity within seven (7) days to any party providing evidence of actionable harm.<br>
                      9.3: The Registrant acknowledges and agrees that its willful provision of inaccurate or unreliable information, its willful failure to update information provided to the Registrar within seven (7) days of any change, or its failure to respond for over fifteen (15) days to inquiries by the Registrar concerning the accuracy of contact details associated with the Registered Name Holder's registration shall constitute a material breach of the Registered Name Holder-Registrar contract and be a basis for suspension and/or cancellation of the Registered Name registration.
                      <br><br><strong>10-Personal Data Processing</strong><br>
                      10.1 The registrar shall provide notice to each new or renewed registered name holder, explaining the purposes for collecting personal data, the intended recipients or categories of recipients of the data, and the rights to access and rectify the data.<br>
                      10.2 The registrant shall consent to the processing of personal data as described in the notice provided by the registrar.<br>
                      10.3 The Registrant shall ensure that any third-party individuals whose personal data is supplied to the Registrar have been provided with equivalent notice and have given their consent to the processing of their data.<br>
                      10.4 The registrar agrees not to process personal data in a manner inconsistent with the provided notice and will take reasonable precautions to protect personal data from loss, misuse, unauthorised access or disclosure, alteration, or destruction.
                      <br><br><strong>11-Rights of Third Parties and Jurisdiction</strong><br>
                      11.1: The registrant represents that the registration and use of the domain name do not infringe the legal rights of any third party.11.2: For the adjudication of disputes concerning or arising from the use of the registered domain name, the registrant shall submit to the jurisdiction of the courts (1) of the registrant's domicile and (2) where the registrar is located, without prejudice to other potentially applicable jurisdictions.<br>
                      11.3: The registrant agrees not to use the data obtained through our services for any marketing activities, regardless of the medium used, including but not limited to email, telephone, facsimile, postal mail, SMS, and wireless alerts.<br>
                      11.4:The registrant agrees not to sell or redistribute the data, except when incorporated into a value-added product or service. Such incorporation should not permit the extraction of a substantial portion of the bulk data from the value-added product or service for use by other parties.<br><br>
                      <strong>12-Expired Domain Name Recovery</strong><br>12.1. A Registrant at Expiration ("RAE") is the registered name holder eligible to renew a domain name registration immediately before its expiration.<br>
                      12.2. In the event of a domain name registration modification related to expiration, the RAE is the entity or individual identified as the registered name holder immediately prior to that modification.<br>
                      12.3. Registrars will notify the registered name holder at least two times before the expiration, approximately one month and one week prior.If a registration is not renewed or deleted within five days after expiration, the registrar must transmit at least one additional expiration notice to the RAE.<br>
                      12.4. Registrars may delete registrations after expiration, subject to applicable policies.<br><br>

                      <strong>13-Miscellaneous</strong><br>
                      13.1: This agreement constitutes the entire understanding between the parties concerning the subject matter hereof and supersedes all prior and contemporaneous agreements, whether oral or written.<br>
                      13.2: This agreement shall be binding upon and inure to the benefit of the parties hereto and their respective successors and assigns.<br>
                      13.3: This agreement may be amended only in writing and signed by both parties.<br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <BottomSvg/>
</div>
</template>
<script>
export default {
  data() {
    return {
      activeID: 1,
      activeTitle: "domain deletion : Renewal Procedures",
      menu: [
        { id:1, name:"Domain Deletion : Renewal Procedures", slug:"deletion-renewal-procedures", url:"/guide-center/deletion-renewal-procedures"},
        { id:2, name:"Return Policy", slug:"return-policy", url:"/guide-center/return-policy"},
        { id:3, name:"Privacy Policy", slug:"privacy-polocy", url:"/guide-center/privacy-polocy"},
        { id:4, name:"Cookie Policy", slug:"cookie-policy", url:"/guide-center/cookie-policy"},
        { id:5, name:"Registrants' Responsibilities", slug:"registrants-responsibilities", url:"/guide-center/registrants-responsibilities"},
        { id:6, name:"User Agreement", slug:"user-agreement", url:"/guide-center/user-agreement"},
      ]
    };
  },
  watch:{
    $route:{
      handler(){
        this.getContent()
      },
      immediate:true,
      deep:true,
    }
  },  
  methods:{
    getContent(){
      this.menu.find((item) => {
        if (this.$route.params.name === item.slug) {
          this.activeID = item.id;
          this.activeTitle = item.name;
        }
      });
      this.$emit('closeLoading')
      // this.$emit('openLoading')
    }
  },
  mounted() {
    this.getContent()
  },
};
</script>
<style scoped>
.cd-filter-block label i {
  top: 18px !important;
}
.manual-position {
  position: static !important;
}
.blog-info h6 {
  color: #fff !important;
}
ul.custom-list {
  list-style-type: circle;
}
ul .custom-list {
  list-style-type: circle;
}
</style>
