<template>
  <div class="menu-wrap">
    <div class="nav-menu">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-2 col-md-2">
            <router-link to="/"> 
              <img :src="logo" width="200"/>  
            </router-link> 
          </div>
          <nav id="menu" class="col-10 col-md-10">
            <div class="navigation float-end">
              <button class="menu-toggle">
                <span class="icon"></span>
                <span class="icon"></span>
                <span class="icon"></span>
              </button> 
              <div class="main-menu nav navbar-nav navbar-right">
                <div class="menu-item menu-item-has-children menu-last" v-for="(k,i) in menu" :key="i">
                  <router-link :to="k.url">{{$t(k.name)}}</router-link> 
                  <div class="sub-menu megamenu" v-if="k.childrens">
                    <div class="container">
                      <div class="row">
                        <div class="service-list  bg-colorstyle" :class="[k.aditional ? 'col-md-9' : 'col-md-12']">
                          <div class="row">
                            <div class="service" v-for="(j,a) in k.childrens" :key="a" :class="[k.aditional ? 'col-6' : 'col-4']">
                              <div class="media-body" v-if="j.type==='internal'">
                                <router-link :to="j.url" class="menu-item mergecolor">{{$t(j.name)}}</router-link>
                                <p class="text-muted">{{j.description}}</p>
                              </div>
                              <div class="media-body" v-else-if="j.type==='external'">
                                <a class="menu-item mergecolor" target="_blank" :href="j.url">{{ j.name }}</a>
                                <img src="/assets/img/external-link.svg">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="start-offer col-md-3" v-if="k.aditional"> 
                          <div class="inner">
                            <h4 class="title my-3">{{k.aditional.title}}</h4>
                            <div class="inner-content" v-if="k.aditional.text" v-html="k.aditional.text"></div>
                            <router-link v-if="k.aditional.url" :to="k.aditional.url" class="btn btn-default-yellow-fill mt-4">{{ $t(k.aditional.url_title) }}</router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ClientArea :isLoggedIn="isLoggedIn" @openLoading="$emit('openLoading')"  @closeLoading="$emit('closeLoading')"/>
                <div class="cart p-3">
                  <router-link to="/cart" class="iconews" :title="$t('cart')">
                    <i class="ico-shopping-cart f-18 w-icon"></i>
                    <span class="item-count" v-if="itemsCount">{{itemsCount}}</span>
                  </router-link>
                  <router-link to="/notifications" class="iconews" v-if="isLoggedIn">
                    <i class="ico-bell f-18 w-icon"></i>
                    <span class="item-count" v-if="notificationCount">{{notificationCount}}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClientArea from '@/components/ClientArea'
export default {   
  props:['menu','isLoggedIn','logo','updateCart'],
  computed: {
    localStorageWatch() { 
      return this.itemsCount;
    }
  },
  watch:{
    updateCart(newValue, oldValue){
      this.itemsCount = localStorage.getItem('cart_items') ? JSON.parse(localStorage.getItem('cart_items')).length : "" 
      this.$emit('UpdateAgainCart')
    },
    localStorageWatch(newValue, oldValue) {  
      this.itemsCount = newValue
    },
    isLoggedIn :{
      handler(n){
        if(n)
        this.logged = n
      }
    }
  },
  components:{ ClientArea },
  data(){
    return{ 
      itemsCount:localStorage.getItem('cart_items'),
      notificationCount:1,
      logged:false
    }
  },
  methods: {
    handleLocalStorageChange(event) {
      if (event.key === 'myData') {
        this.itemsCount = event.newValue;
      }
    }
  },
  mounted(){
    this.itemsCount = localStorage.getItem('cart_items') ? JSON.parse(localStorage.getItem('cart_items')).length : ""
  },
  created() {
    window.addEventListener('storage', this.handleLocalStorageChange);
  },
}
</script>
<style>
.item-count{
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  text-align: center;
  background-color: #ff0000;
  color: #ffffff;
  position: absolute;
  bottom: 14px;
  left: 10px;
}
</style>