<template>
<div>
  <div class="top-header item7 overlay">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="wrapper">
              <h1 class="heading">Cloud Reseller</h1>
              <h3 class="subheading">Cloud Reseller plans are designed for you to earn money!</h3>
              <div id="run-switch" class="run-switch">
                <div class="mo">Monthly Price</div>
                <div class="switch"></div>
                <div class="an active">Anually Price</div>
              </div>
              <div class="included">
                <h4 class="mb-3">All plans include</h4>
                <ul><li><i class="fas fa-check-circle"></i> c-Panel, WHM</li></ul>
                <ul><li><i class="fas fa-check-circle"></i> Unlimited traffic</li></ul>
                <ul><li><i class="fas fa-check-circle"></i> Unlimited Database</li></ul>
                <ul><li><i class="fas fa-check-circle"></i> Latest PHP Technology</li></ul>
                <ul><li><i class="fas fa-check-circle"></i> 400+ Softaculous Apps</li></ul>
                <ul><li><i class="fas fa-check-circle"></i> Daily Backups</li></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="gocontent godown gocheck" href="#scroll">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 11.5 32" style="enable-background:new 0 0 11.5 32;" xml:space="preserve" class="svg" src="./assets/fonts/svg/arrowdown.svg" height="80" alt="features">
      <title>arrowdown</title>
        <path fill="#5e686c" d="M5.8,31.8c-0.1,0-0.2-0.1-0.2-0.2V0.5c0-0.1,0.1-0.2,0.2-0.2S6,0.4,6,0.5v31.1C6,31.7,5.9,31.8,5.8,31.8z"></path>
        <path id="svg-ico" d="M11.1,25.9c-0.1-0.1-0.3-0.1-0.4,0l-4.9,5.3l-5-5.3c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4l5.2,5.5  c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0l5.1-5.5C11.2,26.2,11.2,26,11.1,25.9z"></path>
      </svg>
      <span>Features</span>
    </a>
    <section class="pricing special sec-uping pb-5 bg-colorstyle specialposition">
      <div class="container">
        <div class="randomline">
          <div class="bigline"></div>
          <div class="smallline"></div>
        </div>
        <div class="row justify-content-end scrollme">
          <div class="col-sm-12 col-md-6 col-lg-4 animateme" data-when="enter" data-from="0" data-to="1" data-opacity="1" data-translatey="-20" style="opacity: 1; transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);">
            <div class="wrapper price-container text-start wow  fadeInUp noshadow animated" style="visibility: visible; animation-name: fadeInUp;">
              <div class="top-content bg-seccolorstyle topradius">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="65" viewBox="0 0 32 32" class="svg mb-3" src="./assets/fonts/svg/reseller.svg" alt="">
                  <title>reseller</title>
                  <path fill="#5e686b" d="M12.7 8.469h1.669c0.231 0 0.419-0.188 0.419-0.419s-0.188-0.419-0.419-0.419h-1.669c-0.231 0-0.419 0.188-0.419 0.419s0.188 0.419 0.419 0.419z"></path>
                  <path fill="#5e686b" d="M17.694 8.469h1.669c0.231 0 0.419-0.188 0.419-0.419s-0.188-0.419-0.419-0.419h-1.669c-0.231 0-0.419 0.188-0.419 0.419s0.188 0.419 0.419 0.419z"></path>
                  <path fill="#5e686b" d="M16.031 14.363c3.906 0 7.087-3.181 7.087-7.088 0-2.125-0.944-4.037-2.431-5.338-0.044-0.069-0.113-0.125-0.188-0.156-1.219-0.994-2.775-1.594-4.469-1.594-3.906 0-7.088 3.181-7.088 7.088s3.181 7.088 7.088 7.088zM16.031 1.019c1.219 0 2.356 0.35 3.313 0.956l-8.675 2.081c1.094-1.819 3.088-3.038 5.363-3.038zM10.194 5.031l10.012-2.406c1.275 1.144 2.075 2.806 2.075 4.65 0 3.45-2.806 6.25-6.25 6.25-3.45 0-6.25-2.806-6.25-6.25 0-0.794 0.144-1.55 0.412-2.244z"></path>
                  <path fill="#5e686b" d="M7.906 22.131c0.013 0 0.031 0 0.044 0h16.212c0.231 0 0.419-0.188 0.419-0.419v-2.956c0-1.863-1.512-3.375-3.375-3.375h-10.344c-1.863 0-3.375 1.512-3.375 3.375v2.962c0 0.231 0.188 0.413 0.419 0.413zM8.319 18.756c0-1.4 1.137-2.544 2.544-2.544h4.75v2.919c0 0.231 0.188 0.419 0.419 0.419s0.419-0.188 0.419-0.419v-2.919h4.75c1.4 0 2.544 1.137 2.544 2.544v2.544h-15.425v-2.544z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M7.981 23.825l-4.944 4.944c-0.25-0.144-0.538-0.231-0.844-0.231-0.919 0-1.669 0.75-1.669 1.669s0.75 1.669 1.669 1.669c0.919 0 1.669-0.75 1.669-1.669 0-0.306-0.087-0.6-0.231-0.844l4.944-4.944c0.162-0.163 0.162-0.425 0-0.587-0.162-0.169-0.431-0.169-0.594-0.006zM2.194 31.044c-0.462 0-0.831-0.375-0.831-0.837 0-0.456 0.375-0.831 0.831-0.831 0.462 0 0.831 0.375 0.831 0.831 0 0.462-0.375 0.837-0.831 0.837z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M29.794 28.538c-0.319 0-0.613 0.087-0.863 0.238l-5.056-4.95c-0.163-0.163-0.425-0.156-0.587 0.006s-0.156 0.431 0.006 0.587l5.044 4.944c-0.15 0.25-0.231 0.538-0.231 0.844 0 0.919 0.756 1.669 1.694 1.669 0.931 0 1.694-0.75 1.694-1.669-0.006-0.919-0.769-1.669-1.7-1.669zM29.794 31.044c-0.475 0-0.856-0.375-0.856-0.837 0-0.456 0.387-0.831 0.856-0.831 0.475 0 0.863 0.375 0.863 0.831-0.006 0.462-0.394 0.837-0.863 0.837z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M16.45 28.6v-4.656c0-0.231-0.188-0.419-0.419-0.419s-0.419 0.188-0.419 0.419v4.65c-0.725 0.181-1.269 0.837-1.269 1.619 0 0.919 0.75 1.669 1.669 1.669s1.669-0.75 1.669-1.669c0-0.775-0.525-1.425-1.231-1.613zM16.012 31.044c-0.463 0-0.831-0.375-0.831-0.837 0-0.456 0.375-0.831 0.831-0.831 0.462 0 0.831 0.375 0.831 0.831 0.006 0.462-0.369 0.837-0.831 0.837z"></path>
                </svg>
                <div class="title">Linux 30 Accounts</div>
                <div class="fromer seccolor">annually get (20% discount)</div>
                <div class="price-content">
                  <div class="price">
                    <sup class="coin mergecolor">$</sup>
                    <span class="value mergecolor">239.40</span>
                    <small class="mergecolor">/</small><span class="period annually mergecolor">year</span>
                  </div>
                </div>
                <a href="" class="btn btn-default-yellow-fill">Order now</a>
              </div>
              <ul class="list-info">
                <li><i class="icon-drives"></i> <span class="c-purple">DISK</span><br> <span>250GB SSD</span></li>
                <li><i class="icon-speed"></i> <span class="c-purple">DATA</span><br> <span>1TB Bandwidth</span></li>
                <li><i class="icon-emailopen"></i> <span class="c-purple">EMAIL</span><br> <span>120 Emails</span></li>
                <li><i class="icon-domains"></i> <span class="c-purple">TLD</span><br> <span>30 Domains</span></li>
              </ul>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 animateme" data-when="span" data-from="0" data-to="1" data-opacity="1" data-translatey="50" style="opacity: 1; transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);">
            <div class="wrapper price-container text-start wow  fadeInUp fast noshadow animated" style="visibility: visible; animation-name: fadeInUp;">
              <div class="plans badge feat bg-pink">best value</div>
              <div class="top-content bg-seccolorstyle topradius">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="svg mb-3" src="./assets/fonts/svg/cloudlinux.svg" height="65" alt="">
                  <title>cloudlinux</title>
                  <path fill="#5e686c" d="M15.219 20.644c-0.231 0-0.412-0.188-0.412-0.413 0-3.95 3.281-6.35 6.456-6.387 0.219-0.012 0.419 0.181 0.419 0.412 0.006 0.231-0.181 0.419-0.413 0.419-2.769 0.037-5.637 2.125-5.637 5.556 0 0.225-0.181 0.413-0.412 0.413z"></path>
                  <path fill="#5e686c" d="M8.488 7.294c-0.912 0-1.656-0.744-1.656-1.656s0.744-1.656 1.656-1.656 1.656 0.744 1.656 1.656-0.738 1.656-1.656 1.656zM8.488 4.806c-0.456 0-0.831 0.369-0.831 0.831s0.375 0.831 0.831 0.831 0.831-0.369 0.831-0.831-0.375-0.831-0.831-0.831z"></path>
                  <path fill="#5e686c" d="M7.356 16.663c-0.156 0-0.306-0.087-0.375-0.244-0.306-0.656-0.463-1.406-0.463-2.169 0-2.45 1.644-4.45 3.669-4.45 1.869 0 3.431 1.694 3.644 3.944 0.019 0.225-0.144 0.431-0.375 0.45-0.238 0.012-0.431-0.144-0.45-0.375-0.169-1.819-1.381-3.188-2.819-3.188-1.563 0-2.838 1.625-2.838 3.619 0 0.644 0.131 1.275 0.388 1.825 0.094 0.206 0.006 0.456-0.2 0.55-0.063 0.025-0.125 0.038-0.181 0.038z"></path>
                  <path fill="#5e686c" d="M11.875 7.294c-0.912 0-1.656-0.744-1.656-1.656s0.744-1.656 1.656-1.656 1.656 0.744 1.656 1.656-0.744 1.656-1.656 1.656zM11.875 4.806c-0.456 0-0.831 0.369-0.831 0.831s0.375 0.831 0.831 0.831 0.831-0.369 0.831-0.831-0.375-0.831-0.831-0.831z"></path>
                  <path fill="#5e686c" d="M10.181 9.119c-0.063 0-0.119-0.012-0.175-0.037l-1.694-0.806c-0.206-0.1-0.294-0.344-0.194-0.55s0.344-0.294 0.55-0.2l1.512 0.719 1.519-0.719c0.206-0.1 0.456-0.013 0.55 0.2 0.1 0.206 0.012 0.456-0.2 0.55l-1.694 0.8c-0.050 0.025-0.113 0.044-0.175 0.044z"></path>
                  <path fill="#5e686c" d="M0.725 16.738c-0.15 0-0.294-0.081-0.369-0.225-0.456-0.869 0.537-2.375 2.325-4.969 1.431-2.075 3.050-4.419 3.050-5.75v-0.169c0-2.45 1.994-4.45 4.45-4.45 2.45 0 4.45 1.994 4.45 4.45v0.194c0 1.2 1.206 3.119 2.506 5.013 0.131 0.188 0.081 0.45-0.106 0.575-0.188 0.131-0.444 0.081-0.575-0.106-1.281-1.869-2.65-3.981-2.65-5.481v-0.194c0-1.994-1.625-3.619-3.619-3.619s-3.619 1.625-3.619 3.619v0.169c0 1.588-1.625 3.944-3.2 6.219-1 1.45-2.519 3.644-2.275 4.113 0.106 0.2 0.025 0.456-0.175 0.563-0.063 0.038-0.125 0.050-0.194 0.050z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M21.194 30.6h-12.938c-4.069 0-7.381-3.313-7.381-7.387 0-4.069 3.313-7.381 7.381-7.381 0.231 0 0.412 0.188 0.412 0.412s-0.188 0.413-0.412 0.413c-3.612 0-6.556 2.938-6.556 6.556 0 3.613 2.938 6.556 6.556 6.556h12.931c5.262 0 9.538-4.281 9.538-9.538 0-5.238-4.238-9.5-9.469-9.537-4.644 0.031-9.619 3.019-9.619 9.537 0 0.231-0.188 0.413-0.412 0.413s-0.412-0.188-0.412-0.413c0-6.725 5.169-10.287 10.338-10.369 0.012 0 0.025 0 0.038 0h0.15c0.012 0 0.025 0 0.038 0 5.631 0.1 10.181 4.713 10.181 10.369 0.006 5.712-4.65 10.369-10.363 10.369z"></path>
                </svg>
                <div class="title">Linux 80 accounts</div>
                <div class="fromer seccolor">annually get (20% discount)</div>
                <div class="price-content">
                  <div class="price">
                    <sup class="coin mergecolor">$</sup>
                    <span class="value mergecolor">299.40</span>
                    <small class="mergecolor">/</small><span class="period annually mergecolor">year</span>
                  </div>
                </div>
                <a href="" class="btn btn-default-yellow-fill">Order now</a>
              </div>
              <ul class="list-info bg-purple">
                <li><i class="icon-drives"></i> <span class="c-pink">DISK</span><br> <span>2TB SSD</span></li>
                <li><i class="icon-speed"></i> <span class="c-pink">DATA</span><br> <span>Unlimited Bandwidth</span></li>
                <li><i class="icon-emailopen"></i> <span class="c-pink">EMAIL</span><br> <span>Unlimited Emails</span></li>
                <li><i class="icon-domains"></i> <span class="c-pink">TLD</span><br> <span>Unlimited Domains</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 103 284" style="enable-background:new 0 0 103 284;" xml:space="preserve" class="blobleft svg d-none" src="./assets/img/background/blob.svg" alt="Blob Background">

      <circle id="svg-ico" class="blob0" cx="13.5" cy="26.5" r="5.5"></circle>
      <circle class="blob1" cx="13.5" cy="5.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="13.5" cy="47.5" r="5.5"></circle>
      <circle class="blob1" cx="13.5" cy="68.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="13.5" cy="89.5" r="5.5"></circle>
      <circle class="blob1" cx="13.5" cy="110.5" r="5.5"></circle>
      <circle class="blob2" cx="13.5" cy="131.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="13.5" cy="152.5" r="5.5"></circle>
      <circle class="blob1" cx="13.5" cy="173.5" r="5.5"></circle>
      <circle class="blob1" cx="13.5" cy="194.5" r="5.5"></circle>
      <circle class="blob2" cx="13.5" cy="215.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="13.5" cy="236.5" r="5.5"></circle>
      <circle class="blob1" cx="13.5" cy="257.5" r="5.5"></circle>
      <circle class="blob2" cx="13.5" cy="278.5" r="5.5"></circle>
      <circle class="blob1" cx="34.5" cy="26.5" r="5.5"></circle>
      <circle class="blob1" cx="34.5" cy="47.5" r="5.5"></circle>
      <circle class="blob1" cx="34.5" cy="68.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="34.5" cy="89.5" r="5.5"></circle>
      <circle class="blob1" cx="34.5" cy="110.5" r="5.5"></circle>
      <circle class="blob1" cx="34.5" cy="131.5" r="5.5"></circle>
      <circle class="blob1" cx="34.5" cy="152.5" r="5.5"></circle>
      <circle class="blob2" cx="34.5" cy="173.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="34.5" cy="194.5" r="5.5"></circle>
      <circle class="blob1" cx="34.5" cy="215.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="34.5" cy="236.5" r="5.5"></circle>
      <circle class="blob1" cx="34.5" cy="257.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="55.5" cy="47.5" r="5.5"></circle>
      <circle class="blob2" cx="55.5" cy="68.5" r="5.5"></circle>
      <circle class="blob1" cx="55.5" cy="89.5" r="5.5"></circle>
      <circle class="blob2" cx="55.5" cy="110.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="55.5" cy="131.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="55.5" cy="152.5" r="5.5"></circle>
      <circle class="blob1" cx="55.5" cy="173.5" r="5.5"></circle>
      <circle class="blob1" cx="55.5" cy="194.5" r="5.5"></circle>
      <circle class="blob1" cx="76.6" cy="194.5" r="5.5"></circle>
      <circle class="blob1" cx="55.5" cy="215.5" r="5.5"></circle>
      <circle class="blob2" cx="55.5" cy="236.5" r="5.5"></circle>
      <circle class="blob1" cx="76.5" cy="89.5" r="5.5"></circle>
      <circle class="blob1" cx="76.5" cy="110.5" r="5.5"></circle>
      <circle class="blob1" cx="76.5" cy="131.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="97.5" cy="131.5" r="5.5"></circle>
      <circle class="blob2" cx="76.5" cy="152.5" r="5.5"></circle>
      <circle class="blob1" cx="97.5" cy="152.5" r="5.5"></circle>
      <circle id="svg-ico" class="blob0" cx="76.5" cy="173.5" r="5.5"></circle>
</svg>
    <section id="scroll" class="history-section feat01 sec-normal bg-colorstyle">
      <div class="container">
        <div class="randomline">
          <div class="bigline"></div>
          <div class="smallline"></div>
        </div>
        <div class="sec-main sec-bg1 bg-colorstyle noshadow nopadding">
          <div class="row">
            <div class="col-md-12 col-lg-5 wow  fadeInUp fast animated" style="visibility: visible; animation-name: fadeInUp;">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 643.4 642" style="enable-background:new 0 0 643.4 642;" xml:space="preserve" class="svg first" src="assets/patterns/api.svg" alt="api automation">
                <g>
	                <path class="api0" d="M320.8,111.6v35.1"></path>
                  <path class="api0" d="M329.8,82.6v5.5h-18v-5.5H329.8z"></path>
                  <path class="api0" d="M322.6,102.9v8.5h-3.5v-8.5H322.6z"></path>
                  <path id="svg-concept" class="api1" d="M309.3,101.7V89.4c0-0.7,0.6-1.3,1.3-1.3H331c0.7,0,1.3,0.6,1.3,1.3v12.3   c0,0.7-0.6,1.3-1.3,1.3h-20.5C309.8,102.9,309.3,102.4,309.3,101.7z"></path>
                  <animateMotion accumulate="none" additive="replace" begin="0s" calcMode="paced" dur="1s" fill="freeze" path="M0, 0 0 0 0 -35" restart="always"></animateMotion>
                </g>
                <g>
	                <path class="api0" d="M469.2,173.1l-24.8,24.8"></path>
                  <path class="api0" d="M496.7,158.3l-4.5,4.5l-12.7-12.7l4.5-4.5L496.7,158.3z"></path>
                  <path class="api0" d="M476.7,168.2l-6,6l-2.5-2.5l6-6L476.7,168.2z"></path>
                  <path id="svg-concept" class="api1" d="M468.1,157.9l8.7-8.7c0.5-0.5,1.3-0.5,1.8,0l14.5,14.5c0.5,0.5,0.5,1.3,0,1.8l-8.7,8.7   c-0.5,0.5-1.3,0.5-1.8,0l-14.5-14.5C467.6,159.2,467.6,158.4,468.1,157.9z"></path>
                  <animateMotion accumulate="none" additive="replace" begin="0.5s" calcMode="paced" dur="1s" fill="freeze" path="M0 0 25 -25" restart="always"></animateMotion>
                </g>
                <g>
	                <path class="api0" d="M530.8,321.6h-35.1"></path>
                  <path class="api0" d="M559.8,330.5h-5.5v-18h5.5V330.5z"></path>
                  <path class="api0" d="M539.5,323.4H531v-3.5h8.5V323.4z"></path>
                  <path id="svg-concept" class="api1" d="M540.7,310.1H553c0.7,0,1.3,0.6,1.3,1.3v20.5c0,0.7-0.6,1.3-1.3,1.3h-12.3   c-0.7,0-1.3-0.6-1.3-1.3v-20.5C539.5,310.6,540,310.1,540.7,310.1z"></path>

                  <animateMotion accumulate="none" additive="replace" begin="1s" calcMode="paced" dur="1s" fill="freeze" path="M0, 0 35 0 0" restart="always">
                    </animateMotion>
                </g>
                <g>
	                <path class="api0" d="M469.1,469.9l-24.8-24.7"></path>
                  <path class="api0" d="M483.8,497.4l-4.5-4.5l12.7-12.7l4.5,4.5L483.8,497.4z"></path>
                  <path class="api0" d="M473.9,477.4l-6-6l2.5-2.5l6,6L473.9,477.4z"></path>
                  <path id="svg-concept" class="api1" d="M484.2,468.8l8.7,8.7c0.5,0.5,0.5,1.3,0,1.8l-14.5,14.5c-0.5,0.5-1.3,0.5-1.8,0l-8.7-8.7   c-0.5-0.5-0.5-1.3,0-1.8l14.5-14.5C483,468.4,483.8,468.4,484.2,468.8z"></path>

                  <animateMotion accumulate="none" additive="replace" begin="1.5s" calcMode="paced" dur="1s" fill="freeze" path="M0 0 25 25" restart="always">
                    </animateMotion>
                </g>
                <g>
	              <path class="api0" d="M320.8,531.6v-35.1"></path>
                  <path class="api0" d="M311.9,560.6v-5.5h18v5.5H311.9z"></path>
                  <path class="api0" d="M319,540.3v-8.5h3.5v8.5H319z"></path>
                  <path id="svg-concept" class="api1" d="M332.3,541.5v12.3c0,0.7-0.6,1.3-1.3,1.3h-20.5c-0.7,0-1.3-0.6-1.3-1.3v-12.3   c0-0.7,0.6-1.3,1.3-1.3H331C331.8,540.3,332.3,540.8,332.3,541.5z"></path>

                  <animateMotion accumulate="none" additive="replace" begin="2s" calcMode="paced" dur="1s" fill="freeze" path="M0, 0 0 0 0 35" restart="always">
                    </animateMotion>
                </g>
                <g>
	                <path class="api0" d="M172.4,470.1l24.8-24.7"></path>
                  <path class="api0" d="M144.8,484.9l4.5-4.5l12.7,12.7l-4.5,4.5L144.8,484.9z"></path>
                  <path class="api0" d="M164.9,475l6-6l2.5,2.5l-6,6L164.9,475z"></path>
                  <path id="svg-concept" class="api1" d="M173.5,485.3l-8.7,8.7c-0.5,0.5-1.3,0.5-1.8,0l-14.5-14.5c-0.5-0.5-0.5-1.3,0-1.8l8.7-8.7   c0.5-0.5,1.3-0.5,1.8,0l14.5,14.5C174,484,174,484.8,173.5,485.3z"></path>

                  <animateMotion accumulate="none" additive="replace" begin="2.5s" calcMode="paced" dur="1s" fill="freeze" path="M0 0 -25 25" restart="always">
		                </animateMotion>
                </g>
                <g>
	                <path class="api0" d="M110.9,321.5h35 M81.8,312.5h5.5v18h-5.5V312.5z M102.1,319.7h8.5v3.5h-8.5V319.7z"></path>
                  <path id="svg-concept" class="api1" d="M100.9,333H88.6c-0.7,0-1.3-0.6-1.3-1.3v-20.5c0-0.7,0.6-1.3,1.3-1.3h12.3   c0.7,0,1.3,0.6,1.3,1.3v20.5C102.1,332.4,101.6,333,100.9,333z"></path>

                  <animateMotion accumulate="none" additive="replace" begin="3s" calcMode="paced" dur="1s" fill="freeze" path="M0, 0 -35 0 0" restart="always">
		                </animateMotion>
                </g>
                <g>
	                <path class="api0" d="M172.5,173.3l24.8,24.8"></path>
                  <path class="api0" d="M157.8,145.8l4.5,4.5L149.5,163l-4.5-4.5L157.8,145.8z"></path>
                  <path class="api0" d="M167.7,165.8l6,6l-2.5,2.5l-6-6L167.7,165.8z"></path>
                  <path id="svg-concept" class="api1" d="M157.4,174.4l-8.7-8.7c-0.5-0.5-0.5-1.3,0-1.8l14.5-14.5c0.5-0.5,1.3-0.5,1.8,0l8.7,8.7   c0.5,0.5,0.5,1.3,0,1.8l-14.5,14.5C158.7,174.9,157.9,174.9,157.4,174.4z"></path>

                  <animateMotion accumulate="none" additive="replace" begin="3.5s" calcMode="paced" dur="1s" fill="freeze" path="M0 0 -25 -25" restart="always">
		                </animateMotion>
                </g>
                <path id="svg-concept" class="api2" d="M466.4,473.3c-37.8,36.3-89.1,58.5-145.6,58.5c-56.5,0-107.8-22.3-145.6-58.5"></path>
                <path id="svg-concept" class="api2" d="M135.2,420.5c-15.7-29.5-24.6-63.1-24.6-98.9c0-116.1,94.1-210.2,210.2-210.2  S531,205.5,531,321.6c0,35.6-8.9,69.2-24.5,98.6"></path>
                <g>
	                <path class="api3" d="M285.6,607.9c-52.4-8-100.1-30-139.3-62.1"></path>
                  <path class="api3" d="M502.8,554c-41.7,31-92.1,51.1-146.8,56"></path>
                  <path class="api3" d="M617.7,356.6c-6.8,56-29.6,107.1-63.7,148.6"></path>
                  <path class="api3" d="M554.3,137.5c34,41.6,56.8,92.7,63.5,148.9"></path>
                  <path class="api3" d="M355.8,32.3c55.1,4.9,105.7,25.2,147.6,56.5"></path>
                  <path class="api3" d="M145.7,97.1c39.2-32.2,87-54.4,139.5-62.5"></path>
                  <path class="api3" d="M41.9,286.5c6.2-51.6,25.9-99.1,55.5-138.7"></path>
                  <path class="api3" d="M97.8,495.3c-29.9-39.8-49.8-87.4-56-139.3"></path>
                </g>
                <path id="svg-concept" class="api1" d="M232.9,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C234.2,277.9,233.6,278.4,232.9,278.4z M226.8,296.8h-3.4c-0.9,0-1.7-0.8-1.7-1.7v-3.4  c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C228.5,296,227.7,296.8,226.8,296.8z M316.8,278.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C318,277.9,317.5,278.4,316.8,278.4z   M310.7,296.8h-3.4c-0.9,0-1.7-0.8-1.7-1.7v-3.4c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4  C312.4,296,311.6,296.8,310.7,296.8z M400.7,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C401.9,277.9,401.3,278.4,400.7,278.4z M394.6,296.8h-3.4c-0.9,0-1.7-0.8-1.7-1.7v-3.4  c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C396.2,296,395.5,296.8,394.6,296.8z M459.7,282.8c0,7.1-5.8,12.9-12.9,12.9  s-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9S459.7,275.7,459.7,282.8z"></path>
                <path id="svg-concept" class="api1" d="M451.6,282.8c0,2.6-2.1,4.8-4.8,4.8c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8  C449.4,278,451.6,280.2,451.6,282.8z M459.7,282.8h-8.1 M442,282.8h-8.1 M232.9,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C234.2,357.9,233.6,358.4,232.9,358.4z M226.8,376.8h-3.4  c-0.9,0-1.7-0.8-1.7-1.7v-3.4c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C228.5,376,227.7,376.8,226.8,376.8z   M316.8,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C318,357.9,317.5,358.4,316.8,358.4z M310.7,376.8h-3.4c-0.9,0-1.7-0.8-1.7-1.7v-3.4c0-0.9,0.8-1.7,1.7-1.7h3.4  c0.9,0,1.7,0.8,1.7,1.7v3.4C312.4,376,311.6,376.8,310.7,376.8z M400.7,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C401.9,357.9,401.3,358.4,400.7,358.4z M394.6,376.8h-3.4  c-0.9,0-1.7-0.8-1.7-1.7v-3.4c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C396.2,376,395.5,376.8,394.6,376.8z   M459.7,362.8c0,7.1-5.8,12.9-12.9,12.9s-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9S459.7,355.7,459.7,362.8z"></path>
                <path id="svg-concept" class="api1" d="M451.6,362.8c0,2.6-2.1,4.8-4.8,4.8c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8  C449.4,358,451.6,360.2,451.6,362.8z M459.7,362.8h-8.1 M442,362.8h-8.1"></path>
                <path class="api4" d="M506.4,302.1c0,6-4.4,10.9-9.9,10.9H145.1c-5.5,0-9.9-4.9-9.9-10.9v-38.5c0-6,4.4-10.9,9.9-10.9h351.5  c5.5,0,9.9,4.9,9.9,10.9v38.5H506.4z"></path>
                <path class="api0" d="M155.6,252.7v60 M486,252.7v60.2 M230.2,301.5h-63.7c-2.2,0-4-1.8-4-4v-8.1c0-2.2,1.8-4,4-4h63.7  c2.2,0,4,1.8,4,4v8.1C234.2,299.7,232.4,301.5,230.2,301.5z M172,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C173.2,277.9,172.6,278.4,172,278.4z M181.8,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C183,277.9,182.5,278.4,181.8,278.4z M195.6,278.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C196.8,277.9,196.3,278.4,195.6,278.4z   M172,267.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C173.2,267.2,172.6,267.8,172,267.8z M181.8,267.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C183,267.2,182.5,267.8,181.8,267.8z M232.8,267.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C234,267.2,233.5,267.8,232.8,267.8z M205.4,278.4H203c-0.7,0-1.2-0.5-1.2-1.2  v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C206.7,277.9,206.1,278.4,205.4,278.4z M215.1,278.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C216.3,277.9,215.8,278.4,215.1,278.4z"></path>
                <path class="api0" d="M215.3,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C216.5,277.9,215.9,278.4,215.3,278.4z M215.3,267.8h-21.2c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h21.2  c0.7,0,1.2,0.5,1.2,1.2v2.5C216.5,267.2,215.9,267.8,215.3,267.8z M187.7,285.4v16.1 M214.4,296.8H211c-0.9,0-1.7-0.8-1.7-1.7v-3.4  c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C216.1,296,215.3,296.8,214.4,296.8z M314,301.5h-63.7c-2.2,0-4-1.8-4-4v-8.1  c0-2.2,1.8-4,4-4H314c2.2,0,4,1.8,4,4v8.1C318,299.7,316.2,301.5,314,301.5z M255.8,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C257.1,277.9,256.5,278.4,255.8,278.4z M265.7,278.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C266.9,277.9,266.3,278.4,265.7,278.4z   M279.5,278.4H277c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C280.7,277.9,280.2,278.4,279.5,278.4z M255.8,267.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C257.1,267.2,256.5,267.8,255.8,267.8z M265.7,267.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C266.9,267.2,266.3,267.8,265.7,267.8z M316.7,267.8h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C317.9,267.2,317.3,267.8,316.7,267.8z   M289.3,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C290.5,277.9,290,278.4,289.3,278.4z M299,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2  v2.5C300.2,277.9,299.6,278.4,299,278.4z"></path>
                <path class="api0" d="M299.1,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C300.3,277.9,299.8,278.4,299.1,278.4z M299.1,267.8h-21.2c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h21.2  c0.7,0,1.2,0.5,1.2,1.2v2.5C300.3,267.2,299.8,267.8,299.1,267.8z M271.6,285.4v16.1 M298.2,296.8h-3.4c-0.9,0-1.7-0.8-1.7-1.7v-3.4  c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C299.9,296,299.2,296.8,298.2,296.8z M397.9,301.5h-63.7c-2.2,0-4-1.8-4-4  v-8.1c0-2.2,1.8-4,4-4h63.7c2.2,0,4,1.8,4,4v8.1C401.9,299.7,400.1,301.5,397.9,301.5z M339.7,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2  v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C340.9,277.9,340.4,278.4,339.7,278.4z M349.5,278.4H347  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C350.7,277.9,350.2,278.4,349.5,278.4z   M363.4,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C364.6,277.9,364,278.4,363.4,278.4z M339.7,267.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C340.9,267.2,340.4,267.8,339.7,267.8z M349.5,267.8H347c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C350.7,267.2,350.2,267.8,349.5,267.8z M400.5,267.8H398  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C401.8,267.2,401.2,267.8,400.5,267.8z   M373.2,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C374.4,277.9,373.8,278.4,373.2,278.4z M382.8,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C384,277.9,383.5,278.4,382.8,278.4z"></path>
                <path class="api0" d="M383,278.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C384.2,277.9,383.7,278.4,383,278.4z"></path>
                <path id="svg-concept" class="api1" d="M477.7,294.2c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8  C476,290.4,477.7,292.1,477.7,294.2z"></path>
                <path class="api0" d="M475.1,283.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C476.3,282.9,475.8,283.4,475.1,283.4z M475.1,272.6h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C476.3,272.1,475.8,272.6,475.1,272.6z M383,267.8h-21.2c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2H383c0.7,0,1.2,0.5,1.2,1.2v2.5C384.2,267.2,383.7,267.8,383,267.8z M355.4,285.4v16.1 M382.1,296.8h-3.4  c-0.9,0-1.7-0.8-1.7-1.7v-3.4c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C383.8,296,383,296.8,382.1,296.8z M241,252.7  v60.2 M226,183h-12.1 M222.9,187.7h-11.6 M219.9,192.6h-11.7 M216.8,197.5h-11.7 M213.7,202.4H202 M146.7,272.9v17.8 M495,272.9  v17.8 M324.3,252.7v60.2 M407.6,252.7v60.2"></path>
                <path class="api4" d="M506.4,382.1c0,6-4.4,10.9-9.9,10.9H145.1c-5.5,0-9.9-4.9-9.9-10.9v-38.5c0-6,4.4-10.9,9.9-10.9h351.5  c5.5,0,9.9,4.9,9.9,10.9v38.5H506.4z"></path>
                <path class="api0" d="M155.6,332.7v60 M486,332.7v60.2 M230.2,381.5h-63.7c-2.2,0-4-1.8-4-4v-8.1c0-2.2,1.8-4,4-4h63.7  c2.2,0,4,1.8,4,4v8.1C234.2,379.7,232.4,381.5,230.2,381.5z M172,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C173.2,357.9,172.6,358.4,172,358.4z M181.8,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C183,357.9,182.5,358.4,181.8,358.4z M195.6,358.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C196.8,357.9,196.3,358.4,195.6,358.4z   M172,347.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C173.2,347.2,172.6,347.8,172,347.8z M181.8,347.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C183,347.2,182.5,347.8,181.8,347.8z M232.8,347.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C234,347.2,233.5,347.8,232.8,347.8z M205.4,358.4H203c-0.7,0-1.2-0.5-1.2-1.2  v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C206.7,357.9,206.1,358.4,205.4,358.4z M215.1,358.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C216.3,357.9,215.8,358.4,215.1,358.4z"></path>
                <path class="api0" d="M215.3,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C216.5,357.9,215.9,358.4,215.3,358.4z M215.3,347.8h-21.2c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h21.2  c0.7,0,1.2,0.5,1.2,1.2v2.5C216.5,347.2,215.9,347.8,215.3,347.8z M187.7,365.4v16.1 M214.4,376.8H211c-0.9,0-1.7-0.8-1.7-1.7v-3.4  c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C216.1,376,215.3,376.8,214.4,376.8z M314,381.5h-63.7c-2.2,0-4-1.8-4-4v-8.1  c0-2.2,1.8-4,4-4H314c2.2,0,4,1.8,4,4v8.1C318,379.7,316.2,381.5,314,381.5z M255.8,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C257.1,357.9,256.5,358.4,255.8,358.4z M265.7,358.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C266.9,357.9,266.3,358.4,265.7,358.4z   M279.5,358.4H277c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C280.7,357.9,280.2,358.4,279.5,358.4z M255.8,347.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C257.1,347.2,256.5,347.8,255.8,347.8z M265.7,347.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C266.9,347.2,266.3,347.8,265.7,347.8z M316.7,347.8h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C317.9,347.2,317.3,347.8,316.7,347.8z   M289.3,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C290.5,357.9,290,358.4,289.3,358.4z M299,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2  v2.5C300.2,357.9,299.6,358.4,299,358.4z"></path>
                <path class="api0" d="M299.1,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C300.3,357.9,299.8,358.4,299.1,358.4z M299.1,347.8h-21.2c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h21.2  c0.7,0,1.2,0.5,1.2,1.2v2.5C300.3,347.2,299.8,347.8,299.1,347.8z M271.6,365.4v16.1 M298.2,376.8h-3.4c-0.9,0-1.7-0.8-1.7-1.7v-3.4  c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C299.9,376,299.2,376.8,298.2,376.8z M397.9,381.5h-63.7c-2.2,0-4-1.8-4-4  v-8.1c0-2.2,1.8-4,4-4h63.7c2.2,0,4,1.8,4,4v8.1C401.9,379.7,400.1,381.5,397.9,381.5z M339.7,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2  v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C340.9,357.9,340.4,358.4,339.7,358.4z M349.5,358.4H347  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C350.7,357.9,350.2,358.4,349.5,358.4z   M363.4,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C364.6,357.9,364,358.4,363.4,358.4z M339.7,347.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C340.9,347.2,340.4,347.8,339.7,347.8z M349.5,347.8H347c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C350.7,347.2,350.2,347.8,349.5,347.8z M400.5,347.8H398  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C401.8,347.2,401.2,347.8,400.5,347.8z   M373.2,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C374.4,357.9,373.8,358.4,373.2,358.4z M382.8,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C384,357.9,383.5,358.4,382.8,358.4z"></path>
                <path class="api0" d="M383,358.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C384.2,357.9,383.7,358.4,383,358.4z"></path>
                <path id="svg-concept" class="api1" d="M477.7,374.2c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8  C476,370.4,477.7,372.1,477.7,374.2z"></path>
                <path class="api0" d="M475.1,363.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C476.3,362.9,475.8,363.4,475.1,363.4z M475.1,352.6h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C476.3,352.1,475.8,352.6,475.1,352.6z M383,347.8h-21.2c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2H383c0.7,0,1.2,0.5,1.2,1.2v2.5C384.2,347.2,383.7,347.8,383,347.8z M355.4,365.4v16.1 M382.1,376.8h-3.4  c-0.9,0-1.7-0.8-1.7-1.7v-3.4c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C383.8,376,383,376.8,382.1,376.8z M241,332.7  v60.2 M146.7,352.9v17.8 M495,352.9v17.8 M324.3,332.7v60.2 M407.6,332.7v60.2"></path>
                <path class="api4" d="M506.4,462.1c0,6-4.4,10.9-9.9,10.9H145.1c-5.5,0-9.9-4.9-9.9-10.9v-38.5c0-6,4.4-10.9,9.9-10.9h351.5  c5.5,0,9.9,4.9,9.9,10.9v38.5H506.4z"></path>
                <path class="api0" d="M145.1,252.7l53.3-74.9c2.6-4.2,6.8-7.5,15-7.5h221c8.2,0,11.8,2.9,15,7.5l47.2,74.9 M484,392.9l12.5,19.8   M145.1,412.7l14.2-20 M484,312.9l12.5,19.8 M145.1,332.7l14-19.8"></path>
                <path class="api0" d="M382.8,197.6H429c2.8,0,5.8-2.4,5-5l-1.8-4.9c-0.9-3.3-3.5-4.7-6.2-4.7h-43.2c-2.8,0-5,2.2-5,5v4.6  C377.8,195.3,380,197.6,382.8,197.6L382.8,197.6z"></path>
                <path id="svg-concept" class="api1" d="M232.9,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C234.2,437.9,233.6,438.4,232.9,438.4z M226.8,456.8h-3.4c-0.9,0-1.7-0.8-1.7-1.7v-3.4  c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C228.5,456,227.7,456.8,226.8,456.8z M316.8,438.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C318,437.9,317.5,438.4,316.8,438.4z   M310.7,456.8h-3.4c-0.9,0-1.7-0.8-1.7-1.7v-3.4c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4  C312.4,456,311.6,456.8,310.7,456.8z M400.7,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C401.9,437.9,401.3,438.4,400.7,438.4z M394.6,456.8h-3.4c-0.9,0-1.7-0.8-1.7-1.7v-3.4  c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C396.2,456,395.5,456.8,394.6,456.8z M459.7,442.8c0,7.1-5.8,12.9-12.9,12.9  s-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9S459.7,435.7,459.7,442.8z"></path>
                <path id="svg-concept" class="api1" d="M451.6,442.8c0,2.6-2.1,4.8-4.8,4.8c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8  C449.4,438,451.6,440.2,451.6,442.8z M459.7,442.8h-8.1 M442,442.8h-8.1"></path>
                <path class="api0" d="M155.6,412.7v60 M486,412.7v60.2 M230.2,461.5h-63.7c-2.2,0-4-1.8-4-4v-8.1c0-2.2,1.8-4,4-4h63.7  c2.2,0,4,1.8,4,4v8.1C234.2,459.7,232.4,461.5,230.2,461.5z M172,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C173.2,437.9,172.6,438.4,172,438.4z M181.8,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C183,437.9,182.5,438.4,181.8,438.4z M195.6,438.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C196.8,437.9,196.3,438.4,195.6,438.4z   M172,427.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C173.2,427.2,172.6,427.8,172,427.8z M181.8,427.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C183,427.2,182.5,427.8,181.8,427.8z M232.8,427.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C234,427.2,233.5,427.8,232.8,427.8z M205.4,438.4H203c-0.7,0-1.2-0.5-1.2-1.2  v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C206.7,437.9,206.1,438.4,205.4,438.4z M215.1,438.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C216.3,437.9,215.8,438.4,215.1,438.4z"></path>
                <path class="api0" d="M215.3,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C216.5,437.9,215.9,438.4,215.3,438.4z M215.3,427.8h-21.2c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h21.2  c0.7,0,1.2,0.5,1.2,1.2v2.5C216.5,427.2,215.9,427.8,215.3,427.8z M187.7,445.4v16.1 M214.4,456.8H211c-0.9,0-1.7-0.8-1.7-1.7v-3.4  c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C216.1,456,215.3,456.8,214.4,456.8z M314,461.5h-63.7c-2.2,0-4-1.8-4-4v-8.1  c0-2.2,1.8-4,4-4H314c2.2,0,4,1.8,4,4v8.1C318,459.7,316.2,461.5,314,461.5z M255.8,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C257.1,437.9,256.5,438.4,255.8,438.4z M265.7,438.4h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C266.9,437.9,266.3,438.4,265.7,438.4z   M279.5,438.4H277c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C280.7,437.9,280.2,438.4,279.5,438.4z M255.8,427.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C257.1,427.2,256.5,427.8,255.8,427.8z M265.7,427.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C266.9,427.2,266.3,427.8,265.7,427.8z M316.7,427.8h-2.5  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C317.9,427.2,317.3,427.8,316.7,427.8z   M289.3,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C290.5,437.9,290,438.4,289.3,438.4z M299,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2  v2.5C300.2,437.9,299.6,438.4,299,438.4z"></path>
                <path class="api0" d="M299.1,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C300.3,437.9,299.8,438.4,299.1,438.4z M299.1,427.8h-21.2c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h21.2  c0.7,0,1.2,0.5,1.2,1.2v2.5C300.3,427.2,299.8,427.8,299.1,427.8z M271.6,445.4v16.1 M298.2,456.8h-3.4c-0.9,0-1.7-0.8-1.7-1.7v-3.4  c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C299.9,456,299.2,456.8,298.2,456.8z M397.9,461.5h-63.7c-2.2,0-4-1.8-4-4  v-8.1c0-2.2,1.8-4,4-4h63.7c2.2,0,4,1.8,4,4v8.1C401.9,459.7,400.1,461.5,397.9,461.5z M339.7,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2  v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C340.9,437.9,340.4,438.4,339.7,438.4z M349.5,438.4H347  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C350.7,437.9,350.2,438.4,349.5,438.4z   M363.4,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C364.6,437.9,364,438.4,363.4,438.4z M339.7,427.8h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C340.9,427.2,340.4,427.8,339.7,427.8z M349.5,427.8H347c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C350.7,427.2,350.2,427.8,349.5,427.8z M400.5,427.8H398  c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5C401.8,427.2,401.2,427.8,400.5,427.8z   M373.2,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C374.4,437.9,373.8,438.4,373.2,438.4z M383,438.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C384.2,437.9,383.7,438.4,383,438.4z"></path>
                <path id="svg-concept" class="api1" d="M477.7,454.2c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8  C476,450.4,477.7,452.1,477.7,454.2z"></path>
                <path class="api0" d="M475.1,443.4h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5c0.7,0,1.2,0.5,1.2,1.2v2.5  C476.3,442.9,475.8,443.4,475.1,443.4z M475.1,432.6h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h2.5  c0.7,0,1.2,0.5,1.2,1.2v2.5C476.3,432.1,475.8,432.6,475.1,432.6z M383,427.8h-21.2c-0.7,0-1.2-0.5-1.2-1.2v-2.5  c0-0.7,0.5-1.2,1.2-1.2H383c0.7,0,1.2,0.5,1.2,1.2v2.5C384.2,427.2,383.7,427.8,383,427.8z M355.4,445.4v16.1 M382.1,456.8h-3.4  c-0.9,0-1.7-0.8-1.7-1.7v-3.4c0-0.9,0.8-1.7,1.7-1.7h3.4c0.9,0,1.7,0.8,1.7,1.7v3.4C383.8,456,383,456.8,382.1,456.8z M241,412.7  v60.2 M146.7,432.9v17.8 M495,432.9v17.8 M324.3,412.7v60.2 M407.6,412.7v60.2"></path>
                <g>
	                <path class="api3" d="M285.6,57.8V5.3c0-0.7,0.6-1.3,1.3-1.3h67.6c0.7,0,1.3,0.6,1.3,1.3v52.6   c0,0.7-0.6,1.3-1.3,1.3h-19.2V54h-29v5.2h-19.4C286.2,59.1,285.6,58.5,285.6,57.8z"></path>
                  <path class="api0" d="M302.7,45V17.5c0-0.4,0.3-0.7,0.7-0.7h35.4c0.4,0,0.7,0.3,0.7,0.7V45   c0,0.4-0.3,0.7-0.7,0.7h-35.4C303,45.7,302.7,45.4,302.7,45z"></path>
                  <path class="api3" d="M321.1,34.6c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3C324.1,33.2,322.7,34.6,321.1,34.6z"></path>
                </g>
                <g>
	                <path class="api3" d="M482.4,110.2L519.6,73c0.5-0.5,1.3-0.5,1.8,0l47.8,47.8c0.5,0.5,0.5,1.3,0,1.8L532,159.8   c-0.5,0.5-1.3,0.5-1.8,0l-13.5-13.5l3.7-3.7L499.8,122l-3.7,3.7L482.4,112C481.9,111.5,481.9,110.7,482.4,110.2z"></path>
                  <path class="api0" d="M503.5,113.2L523,93.8c0.3-0.3,0.7-0.3,0.9,0l25,25c0.3,0.3,0.3,0.7,0,0.9l-19.5,19.5   c-0.3,0.3-0.7,0.3-0.9,0l-25-25C503.3,113.9,503.3,113.5,503.5,113.2L503.5,113.2z"></path>
                  <path class="api3" d="M523.9,118.8c-1.2-1.2-1.2-3.1,0-4.2c1.2-1.2,3.1-1.2,4.2,0c1.2,1.2,1.2,3.1,0,4.2   C527,120,525.1,120,523.9,118.8z"></path>
                </g>
                <g>
	                <path class="api3" d="M584.5,286.4h52.6c0.7,0,1.3,0.6,1.3,1.3v67.6c0,0.7-0.6,1.3-1.3,1.3h-52.6   c-0.7,0-1.3-0.6-1.3-1.3v-19.2h5.2v-29h-5.2v-19.4C583.3,287,583.9,286.4,584.5,286.4z"></path>
                  <path class="api0" d="M597.3,303.5h27.5c0.4,0,0.7,0.3,0.7,0.7v35.4c0,0.4-0.3,0.7-0.7,0.7h-27.5   c-0.4,0-0.7-0.3-0.7-0.7v-35.4C596.7,303.8,597,303.5,597.3,303.5z"></path>
                  <path class="api3" d="M607.8,321.9c0-1.7,1.3-3,3-3s3,1.3,3,3s-1.3,3-3,3C609.2,324.9,607.8,323.5,607.8,321.9z"></path>
                </g>
                <g>
	                <path class="api3" d="M531.9,483.1l37.2,37.2c0.5,0.5,0.5,1.3,0,1.8l-47.8,47.8c-0.5,0.5-1.3,0.5-1.8,0   l-37.2-37.2c-0.5-0.5-0.5-1.3,0-1.8l13.5-13.5l3.7,3.7l20.5-20.5l-3.7-3.7l13.7-13.7C530.7,482.6,531.5,482.6,531.9,483.1   L531.9,483.1z"></path>
                  <path class="api0" d="M528.9,504.2l19.5,19.5c0.3,0.3,0.3,0.7,0,0.9l-25,25c-0.3,0.3-0.7,0.3-0.9,0L503,530.2   c-0.3-0.3-0.3-0.7,0-0.9l25-25C528.2,504,528.6,504,528.9,504.2L528.9,504.2z"></path>
                  <path class="api3" d="M523.3,524.6c1.2-1.2,3.1-1.2,4.2,0c1.2,1.2,1.2,3.1,0,4.2s-3.1,1.2-4.2,0   C522.2,527.7,522.2,525.8,523.3,524.6z"></path>
                </g>
                <g>
	                <path class="api3" d="M356,585.4V638c0,0.7-0.6,1.3-1.3,1.3h-67.6c-0.7,0-1.3-0.6-1.3-1.3v-52.6   c0-0.7,0.6-1.3,1.3-1.3h19.2v5.2h29v-5.2h19.4C355.4,584.1,356,584.7,356,585.4z"></path>
                  <path class="api0" d="M338.9,598.2v27.5c0,0.4-0.3,0.7-0.7,0.7h-35.4c-0.4,0-0.7-0.3-0.7-0.7v-27.5   c0-0.4,0.3-0.7,0.7-0.7h35.4C338.6,597.5,338.9,597.8,338.9,598.2z"></path>
                  <path class="api3" d="M320.5,608.7c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S318.9,608.7,320.5,608.7z"></path>
                </g>
                <g>
	                <path class="api3" d="M159.2,533L122,570.2c-0.5,0.5-1.3,0.5-1.8,0l-47.8-47.8c-0.5-0.5-0.5-1.3,0-1.8   l37.2-37.2c0.5-0.5,1.3-0.5,1.8,0L125,497l-3.7,3.7l20.5,20.5l3.7-3.7l13.7,13.7C159.7,531.8,159.7,532.6,159.2,533z"></path>
                  <path class="api0" d="M138.1,530l-19.5,19.5c-0.3,0.3-0.7,0.3-0.9,0l-25-25c-0.3-0.3-0.3-0.7,0-0.9l19.5-19.5   c0.3-0.3,0.7-0.3,0.9,0l25,25C138.3,529.3,138.3,529.7,138.1,530z"></path>
                  <path class="api3" d="M117.7,524.4c1.2,1.2,1.2,3.1,0,4.2c-1.2,1.2-3.1,1.2-4.2,0c-1.2-1.2-1.2-3.1,0-4.2   C114.6,523.3,116.5,523.2,117.7,524.4z"></path>
                </g>
                <g>
	                <path class="api3" d="M57.1,356.7H4.5c-0.7,0-1.3-0.6-1.3-1.3v-67.6c0-0.7,0.6-1.3,1.3-1.3h52.6   c0.7,0,1.3,0.6,1.3,1.3V307h-5.2v29h5.2v19.4C58.3,356.1,57.8,356.7,57.1,356.7L57.1,356.7z"></path>
                  <path class="api0" d="M44.3,339.6H16.8c-0.4,0-0.7-0.3-0.7-0.7v-35.4c0-0.4,0.3-0.7,0.7-0.7h27.5   c0.4,0,0.7,0.3,0.7,0.7v35.4C44.9,339.3,44.6,339.6,44.3,339.6z"></path>
                  <path class="api3" d="M33.8,321.2c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3S33.8,319.5,33.8,321.2z"></path>
                </g>
                <g>
	                <path class="api3" d="M109.7,160.1L72.5,123c-0.5-0.5-0.5-1.3,0-1.8l47.8-47.8c0.5-0.5,1.3-0.5,1.8,0   l37.2,37.2c0.5,0.5,0.5,1.3,0,1.8l-13.5,13.5l-3.7-3.7l-20.5,20.5l3.7,3.7l-13.7,13.7C110.9,160.6,110.1,160.6,109.7,160.1   L109.7,160.1z"></path>
                  <path class="api0" d="M112.7,139l-19.5-19.5c-0.3-0.3-0.3-0.7,0-0.9l25-25c0.3-0.3,0.7-0.3,0.9,0l19.5,19.5   c0.3,0.3,0.3,0.7,0,0.9l-25,25C113.4,139.2,113,139.2,112.7,139z"></path>
                  <path class="api3" d="M118.3,118.6c-1.2,1.2-3.1,1.2-4.2,0c-1.2-1.2-1.2-3.1,0-4.2c1.2-1.2,3.1-1.2,4.2,0   C119.4,115.5,119.4,117.4,118.3,118.6z"></path>
                </g>
                </svg>
            </div>
            <div class="col-md-12 col-lg-6 offset-lg-1">
              <div class="info-content">
                <h1 class="fw-bold mb-3 mergecolor">Reseller API Automation</h1>
                <p class="seccolor">But I must explain to you how all this mistaken idea of <a href="#" class="golink">denouncing pleasure</a> and praising pain was born and I will.</p>
              </div>
              <a href="" class="btn btn-default-yellow-fill mt-3">Go API</a>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="info-content">
                <h1 class="fw-bold mb-3 mergecolor">High Websites Performance</h1>
                <p class="seccolor">But I must explain to you how all this mistaken idea of <a href="#" class="golink">denouncing pleasure</a> and praising pain was born and I will.</p>
              </div>
              <a href="" class="btn btn-default-yellow-fill mt-3">Learn more</a>
            </div>
            <div class="col-md-12 col-lg-5 offset-lg-1 wow  fadeInUp fast animated" style="visibility: visible; animation-name: fadeInUp;">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 693.4 556" style="enable-background:new 0 0 693.4 556;" xml:space="preserve" class="svg second" src="assets/patterns/performance.svg" alt="performance">

                <g>
                <path class="performance0" d="M393.3,206.9c0,6.8-5.7,12.5-12.5,12.5c-6.8,0-12.5-5.7-12.5-12.5c0-6.8,5.7-12.5,12.5-12.5   C387.8,194.2,393.3,199.9,393.3,206.9z"></path>
                <path class="performance0" d="M385.5,206.9c0,2.5-2.1,4.7-4.7,4.7c-2.5,0-4.7-2.1-4.7-4.7c0-2.5,2.1-4.7,4.7-4.7   C383.3,202.2,385.5,204.2,385.5,206.9z"></path>
                <path class="performance0" d="M393.3,206.9h-7.8 M376.1,206.9h-7.8"></path>
                </g>
                <g>
                <path class="performance0" d="M463.7,450.5c0,6.8-5.6,12.5-12.4,12.5s-12.4-5.7-12.4-12.5s5.6-12.5,12.4-12.5S463.7,443.7,463.7,450.5z"></path>
                <path class="performance0" d="M455.7,450.5c0,2.5-2.1,4.7-4.7,4.7s-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7C453.8,445.8,455.7,448,455.7,450.5z"></path>
                <path class="performance0" d="M463.7,450.5h-7.9 M446.4,450.5h-7.8"></path>
                </g>
                <g>
                <path class="performance0" d="M217,382.7c0,6.8-5.7,12.5-12.5,12.5s-12.5-5.7-12.5-12.5s5.7-12.5,12.5-12.5C211.5,370.2,217,375.9,217,382.7   z"></path>
                <path class="performance0" d="M209.2,382.7c0,2.5-2.1,4.7-4.7,4.7c-2.5,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7C207,378,209.2,380.2,209.2,382.7z   "></path>
                <path class="performance0" d="M217,382.7h-7.8 M199.8,382.7H192"></path>
                </g>
                <path class="performance1" d="M57.2,378.6c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9S57.2,374.2,57.2,378.6z"></path>
                <g>
                <path class="performance1" d="M57.5,315.9c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9C54,308.1,57.5,311.6,57.5,315.9z"></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="1s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M71,254.7c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9C67.5,246.9,71,250.4,71,254.7   L71,254.7z"></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="1s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M95.8,198.2c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9C92.2,190.3,95.8,193.9,95.8,198.2z   "></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="1s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M132.7,148.3c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9   C129.1,140.4,132.7,143.9,132.7,148.3z"></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="2s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M179.1,107.3c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9C175.5,99.5,179.1,103,179.1,107.3   z"></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="2s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M232.3,76c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9S232.3,71.6,232.3,76z"></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="2s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M291.7,57.5c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9C288.2,49.7,291.7,53.2,291.7,57.5z   "></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="2s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M353.1,51c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9C349.6,43.2,353.1,46.7,353.1,51z"></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="3s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M414.3,57.5c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9S414.3,53.1,414.3,57.5z"></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="3s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M473.2,76.4c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9C469.6,68.6,473.2,72.1,473.2,76.4z   "></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="3s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M526.8,107.7c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9   C523.3,99.9,526.8,103.4,526.8,107.7z"></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="3s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <g>
                <path class="performance1" d="M573,148.7c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9C569.5,140.9,573,144.4,573,148.7z"></path>

                <animate accumulate="none" additive="replace" attributeName="stroke" calcMode="linear" dur="3s" fill="freeze" restart="always" stroke="gray" values="#808080;#ee5486;#ee5486;#808080">
                </animate>
                </g>
                <path id="svg-ico" class="performance2" d="M609.4,199c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9  C605.8,191.1,609.4,194.6,609.4,199z M634.5,255.1c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9  S634.5,250.7,634.5,255.1z M647.4,316c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9S647.4,311.6,647.4,316z   M646.9,378.1c0,4.4-3.5,7.9-7.9,7.9c-4.3,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9S646.9,373.7,646.9,378.1z"></path>
                <g>
                <path id="svg-concept" class="performance3" d="M300.1,179.5c0,0,35.6,50.9,37.5,139.6 M238.6,209.6c0,0,52.5,33.2,88.5,114.4 M193.6,261   c0,0,61.2,10.6,125.6,71.7 M172,324.6c0,0,60.6-13.4,143.4,18.8 M176.4,392.6c0,0,50.9-35.5,139.6-37.4 M206.9,454   c0,0,33.3-52.3,114.6-88.1 M494,435.5c0,0-60.9-10.7-124.8-72.3 M516.4,370.8c0,0-60.3,13.6-143.1-18.6 M512.1,302.7   c0,0-50.6,35.7-139.3,37.7 M482.5,242.3c0,0-33.3,52.2-114.7,87.7 M431.5,197.2c0,0-11,61-72.7,124.9 M366.6,175.2   c0,0,13.2,60.7-19.2,143.3 M258.3,498.8c0,0,10.6-61.2,71.8-125.5 M322.8,520.3c0,0-13.7-60.6,18.2-143.4 M389.5,515.6   c0,0-35.7-55.1-37.5-139.6 M449.9,485.9c0,0-52.3-33.4-88-114.7"></path>

                <animateTransform accumulate="none" additive="replace" attributeName="transform" begin="0s" calcMode="linear" dur="2s" fill="remove" from="0 343 350" repeatCount="indefinite" restart="always" to="360 347 345" type="rotate">
                </animateTransform>
                </g>
                <path class="performance0" d="M345.2,8.1v19.4 M6.6,311.5l19.4,2 M6.6,382.7l19.3-2 M21.7,241.3l18.6,6.1 M51,176.9l16.7,9.7 M93,119.6  l14.3,12.9 M145.8,72.4L157.1,88 M207,37.1l7.9,17.7 M274.2,15.2l4,19.1 M6.6,382.7c-1.2-11.5-1.8-35-1.8-35  C4.8,160.1,156.9,8.1,344.4,8.1S684,160.2,684,347.7c0,11.7-0.6,23.3-1.8,34.7 M5.2,365.2l9-0.4 M4.8,347.1h8.9 M5.3,328.7l8.9,0.5   M9,293.8l8.9,1.4 M12.3,276.6l8.4,1.8 M16.4,259.3l8.6,2.4 M28.1,223.7l8.3,3.3 M34.7,208.2l8.1,3.6 M42.3,192.4l7.9,4 M60.9,160.6  l7.5,4.9 M70.7,146.6l7.2,5.3 M81.7,132.4l6.9,5.7 M106.6,105.3l6.3,6.3 M118.9,94.1l5.8,6.4 M132,82.7l5.5,7 M160.5,62.5l4.6,7.2   M175.5,53l4.4,7.7 M190.9,44.7l4.1,7.9 M223.1,30.4l3.2,8.3 M239.3,24.7l2.8,8.4 M256.4,19.6l2.3,8.6 M292,12.4l1.3,8.5 M310.6,9.7  l0.9,8.9 M328.3,8.5l0.4,8.5 M682.2,311.6l-19.5,2.1 M682.3,382.5l-19.4-2.1 M667.2,241.8l-18.5,6.1 M638.4,177.5l-16.9,9.8   M596.7,120.3l-14.5,13 M544.2,73l-11.5,15.8 M483.2,37.6l-7.9,17.8 M416.2,15.7l-4.1,19 M683.7,365.2l-9.1-0.5 M684.1,347.1H675   M683.6,328.8l-9.1,0.5 M679.9,294.1l-9,1.4 M676.7,276.9l-8.8,1.9 M672.5,259.8l-8.6,2.3 M660.9,224.2l-8.4,3.3 M654.5,208.8  l-8.3,3.7 M646.9,193l-8,4.1 M628.4,161.3l-7.5,5 M618.7,147.3l-7.2,5.4 M607.8,133.2l-7,5.7 M583.1,106l-6.3,6.4 M571,94.7l-6,6.7   M557.9,83.5l-5.7,7 M529.7,63l-4.9,7.5 M514.6,53.7l-4.5,7.8 M499.2,45.3l-4.1,8 M467.2,30.9l-3.3,8.4 M451,25.1l-2.8,8.5   M433.9,20l-2.3,8.6 M398.4,12.4l-1.3,8.8 M379.8,9.9l-0.9,8.9 M362.1,8.5l-0.4,8.9"></path>
                <circle id="svg-ico" class="performance2" cx="6.8" cy="382.7" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="6.7" cy="311.5" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="21.8" cy="241.3" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="51.2" cy="177" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="93.2" cy="119.7" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="146" cy="72.6" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="207.2" cy="37.4" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="274.2" cy="15.4" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="345.2" cy="8.1" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="416.1" cy="16.1" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="483" cy="37.9" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="544" cy="73.2" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="596.3" cy="120.6" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="638.2" cy="177.6" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="666.9" cy="241.9" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="681.8" cy="311.7" r="3"></circle>
                <circle id="svg-ico" class="performance2" cx="682" cy="382.4" r="3"></circle>
                <path class="performance0" d="M365.1,368.4c-11.5,11.5-30,11.5-41.5,0c-11.4-11.4-11.4-30,0-41.5c11.5-11.5,30-11.4,41.4,0  C376.5,338.4,376.6,356.9,365.1,368.4z"></path>
                <path class="performance0" d="M352.1,355.4c-4.2,4.2-11.1,4.3-15.4,0c-4.2-4.2-4.2-11.1,0-15.4c4.2-4.2,11.1-4.2,15.4,0  C356.3,344.2,356.3,351.1,352.1,355.4z M365.1,368.4l-13.1-13 M334.7,342l-13-13"></path>
                <g>
                  <path id="svg-concept" class="performance4" d="M155.3,378l-35-4.5c-3.8-0.5-4.4-5.8-0.8-7.1l33.4-12.4"></path>
                  <animateTransform accumulate="none" additive="replace" attributeName="transform" calcMode="linear" dur="2s" fill="freeze" from="0 346 348" restart="always" to="192 344 349" type="rotate">
                  </animateTransform>
                </g>
              <path class="performance5" d="M222.1,439.8c25.4-25.4,25.4-66.5,0-91.9c-13.9-13.9-32.6-20.2-50.8-18.8c4.2-38.1,21-75.1,50.2-104.3  s66.2-45.9,104.3-50.2c-1.4,18.2,4.9,36.9,18.8,50.8c25.4,25.4,66.5,25.4,91.9,0c5.3-5.3,9.5-11.3,12.6-17.8  c6.9,5.1,13.6,10.8,19.9,17.1c52.4,52.4,64.6,129.8,36.7,193.8c-25.3-17.3-60.1-14.8-82.6,7.7s-25,57.3-7.7,82.6  c-64,28-141.4,15.7-193.8-36.7c-6.3-6.3-12-12.9-17.1-19.9C210.7,449.3,216.7,445.1,222.1,439.8L222.1,439.8z"></path>
              <path id="svg-concept" class="performance6" d="M151.9,355.3c-0.1-2.5-0.1-5.1-0.1-7.6c0-106.3,86.2-192.5,192.5-192.5  s192.5,86.2,192.5,192.5s-86.2,192.5-192.5,192.5C240.5,540.1,155.9,458,151.9,355.3"></path>
            </svg>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-12 col-lg-6 wow animated fadeInUp fast " style="visibility: hidden; animation-name: none;">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 608.9 468" style="enable-background:new 0 0 608.9 468;" xml:space="preserve" class="svg third" src="assets/patterns/install.svg" alt="1-click-installer">

                <path class="install0" d="M21.2,461.7c-7.4,0-13.3-5.5-13.3-12.2V18.2c0-6.7,6-12.2,13.3-12.2h47.2c7.4,0,13.3,5.5,13.3,12.2v431.4  c0,6.7-6,12.2-13.3,12.2H21.2V461.7z M81.7,18.2l91.9,65.4c5.1,3.2,9.2,8.3,9.2,18.4v271.2c0,10.1-3.6,14.5-9.2,18.4l-91.9,58"></path>
                <path id="svg-concept" class="install1" d="M50.2,126.3v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C50.7,127.5,50.2,127,50.2,126.3z M27.6,118.9V114c0-0.9,0.8-1.7,1.7-1.7h4.9c0.9,0,1.7,0.8,1.7,1.7v4.9c0,0.9-0.8,1.7-1.7,1.7  h-4.9C28.4,120.6,27.6,119.8,27.6,118.9z M50.2,229.2v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6  c0,0.7-0.5,1.2-1.2,1.2h-3.6C50.7,230.5,50.2,229.9,50.2,229.2z M27.6,221.8v-4.9c0-0.9,0.8-1.7,1.7-1.7h4.9c0.9,0,1.7,0.8,1.7,1.7  v4.9c0,0.9-0.8,1.7-1.7,1.7h-4.9C28.4,223.5,27.6,222.8,27.6,221.8z M50.2,332.2v-3.6c0-0.7,0.5-1.2,1.2-1.2H55  c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C50.7,333.4,50.2,332.8,50.2,332.2z M27.6,324.8v-4.9c0-0.9,0.8-1.7,1.7-1.7  h4.9c0.9,0,1.7,0.8,1.7,1.7v4.9c0,0.9-0.8,1.7-1.7,1.7h-4.9C28.4,326.5,27.6,325.7,27.6,324.8z M44.8,404.3  c-8.7,0-15.8-7.1-15.8-15.8c0-8.7,7.1-15.8,15.8-15.8s15.8,7.1,15.8,15.8C60.6,397.2,53.5,404.3,44.8,404.3z"></path>
                <path id="svg-concept" class="install1" d="M44.8,394.4c-3.2,0-5.9-2.6-5.9-5.9c0-3.2,2.6-5.9,5.9-5.9c3.2,0,5.9,2.6,5.9,5.9  C50.6,391.8,48,394.4,44.8,394.4z M44.8,404.3v-9.9 M44.8,382.7v-10"></path>
                <path class="install0" d="M81.7,31.1H8.1 M81.7,436.6H7.8 M21.9,123.5v-80c0-2.2,1.8-4,4-4h11.7c2.2,0,4,1.8,4,4v80c0,2.2-1.8,4-4,4H25.9  C23.7,127.5,21.9,125.7,21.9,123.5z M50.2,51.5v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C50.7,52.7,50.2,52.1,50.2,51.5z M50.2,63.5v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C50.7,64.7,50.2,64.2,50.2,63.5z M50.2,80.5v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C50.7,81.7,50.2,81.2,50.2,80.5z M63.2,51.5v-3.6c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C63.8,52.7,63.2,52.1,63.2,51.5z M63.2,63.5v-3.6c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C63.8,64.7,63.2,64.2,63.2,63.5z M63.2,126.1v-3.6c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C63.8,127.4,63.2,126.8,63.2,126.1z M50.2,92.5V89c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C50.7,93.8,50.2,93.2,50.2,92.5z M50.2,104.4v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C50.7,105.6,50.2,105.1,50.2,104.4z"></path>
                <path class="install0" d="M50.2,104.6V101c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6  C50.7,105.8,50.2,105.3,50.2,104.6z M63.2,104.6V78c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2v26.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C63.8,105.8,63.2,105.3,63.2,104.6z M41.6,70.5H21.9 M27.6,103.6v-4.9c0-0.9,0.8-1.7,1.7-1.7h4.9c0.9,0,1.7,0.8,1.7,1.7v4.9  c0,0.9-0.8,1.7-1.7,1.7h-4.9C28.4,105.3,27.6,104.5,27.6,103.6z M21.9,226.5v-80c0-2.2,1.8-4,4-4h11.7c2.2,0,4,1.8,4,4v80  c0,2.2-1.8,4-4,4H25.9C23.7,230.5,21.9,228.7,21.9,226.5z M50.2,154.4v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6  c0,0.7-0.5,1.2-1.2,1.2h-3.6C50.7,155.6,50.2,155.1,50.2,154.4z M50.2,166.5v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2  v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C50.7,167.7,50.2,167.1,50.2,166.5z M50.2,183.4v-3.6c0-0.7,0.5-1.2,1.2-1.2H55  c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C50.7,184.7,50.2,184.1,50.2,183.4z M63.2,154.4v-3.6c0-0.7,0.5-1.2,1.2-1.2  H68c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C63.8,155.6,63.2,155.1,63.2,154.4z M63.2,166.5v-3.6  c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C63.8,167.7,63.2,167.1,63.2,166.5z M63.2,229.1  v-3.6c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C63.8,230.3,63.2,229.7,63.2,229.1z   M50.2,195.5v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6  C50.7,196.7,50.2,196.2,50.2,195.5z M50.2,207.3v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C50.7,208.6,50.2,208,50.2,207.3z"></path>
                <path class="install0" d="M50.2,207.5V204c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6  C50.7,208.8,50.2,208.2,50.2,207.5z M63.2,207.5v-26.6c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2v26.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C63.8,208.7,63.2,208.2,63.2,207.5z M41.6,173.5H21.9 M27.6,206.6v-4.9c0-0.9,0.8-1.7,1.7-1.7h4.9c0.9,0,1.7,0.8,1.7,1.7v4.9  c0,0.9-0.8,1.7-1.7,1.7h-4.9C28.4,208.2,27.6,207.5,27.6,206.6z M21.9,329.4v-80c0-2.2,1.8-4,4-4h11.7c2.2,0,4,1.8,4,4v80  c0,2.2-1.8,4-4,4H25.9C23.7,333.4,21.9,331.6,21.9,329.4z M50.2,257.3v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6  c0,0.7-0.5,1.2-1.2,1.2h-3.6C50.7,258.6,50.2,258,50.2,257.3z M50.2,269.4v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6  c0,0.7-0.5,1.2-1.2,1.2h-3.6C50.7,270.6,50.2,270.1,50.2,269.4z M50.2,286.4v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2  v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C50.7,287.6,50.2,287,50.2,286.4z M63.2,257.3v-3.6c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2  v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C63.8,258.6,63.2,258,63.2,257.3z M63.2,269.4v-3.6c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2  v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C63.8,270.6,63.2,270.1,63.2,269.4z M63.2,332v-3.6c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2  v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C63.8,333.2,63.2,332.7,63.2,332z M50.2,298.4v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2  v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C50.7,299.6,50.2,299.1,50.2,298.4z M50.2,310.3v-3.6c0-0.7,0.5-1.2,1.2-1.2H55  c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6C50.7,311.5,50.2,310.9,50.2,310.3z"></path>
                <path class="install0" d="M50.2,310.5v-3.6c0-0.7,0.5-1.2,1.2-1.2H55c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6  C50.7,311.7,50.2,311.1,50.2,310.5z"></path>
                <path id="svg-concept" class="install1" d="M30.8,426.4c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7s4.6,2.1,4.6,4.7  C35.5,424.3,33.4,426.4,30.8,426.4z"></path>
                <path class="install0" d="M44,423.6V420c0-0.7,0.5-1.2,1.2-1.2h3.6c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6  C44.6,424.8,44,424.2,44,423.6z M57.3,423.6V420c0-0.7,0.5-1.2,1.2-1.2h3.6c0.7,0,1.2,0.5,1.2,1.2v3.6c0,0.7-0.5,1.2-1.2,1.2h-3.6  C57.9,424.8,57.3,424.2,57.3,423.6z M63.2,310.5v-26.6c0-0.7,0.5-1.2,1.2-1.2H68c0.7,0,1.2,0.5,1.2,1.2v26.6c0,0.7-0.5,1.2-1.2,1.2  h-3.6C63.8,311.7,63.2,311.1,63.2,310.5z M41.6,276.4H21.9 M27.6,309.5v-4.9c0-0.9,0.8-1.7,1.7-1.7h4.9c0.9,0,1.7,0.8,1.7,1.7v4.9  c0,0.9-0.8,1.7-1.7,1.7h-4.9C28.4,311.2,27.6,310.4,27.6,309.5z M81.7,135.9H7.8 M167.3,117.5v-14.9 M161.6,113.7V99.4 M155.5,110  V95.6 M149.5,106.3V91.9 M143.5,102.4V88.1 M57,20.2H35 M57,447.7H35 M81.7,238.2H7.8 M81.7,340.4H7.8 M149.4,309.9v56.7  c0,3.4,2.9,7.2,6.1,6.1l6-2.2c4-1.1,5.8-4.2,5.8-7.6v-53c0-3.4-2.8-6.1-6.1-6.1h-5.6C152.2,303.8,149.4,306.5,149.4,309.9z"></path>
                <path class="install2" d="M143.5,238.2H274"></path>
                <path class="install0" d="M600.5,360.7H282c-4.4,0-8-3.6-8-8v-229c0-4.4,3.6-8,8-8h318.5c4.4,0,8,3.6,8,8v229  C608.5,357.1,604.9,360.7,600.5,360.7z"></path>
                <g>
                <path id="svg-concept" class="install3" d="M343.1,238.2h196.3"></path>
                                  <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="1s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
                </animate>
                </g>
                <circle id="svg-ico" class="install4" cx="274" cy="238.2" r="3.7"></circle>
                <circle id="svg-ico" class="install4" cx="143.5" cy="238.2" r="3.7"></circle>
                <g>
                <path id="svg-ico" class="install4" d="M214.8,238.2c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6S214.8,234.8,214.8,238.2z"></path>
                                <animateMotion accumulate="none" additive="replace" begin="0s" calcMode="paced" dur="2s" fill="remove" path="M53, 0 53 0 -53 0 53 0" repeatCount="indefinite" restart="always">
                  </animateMotion>
              </g>
                <path class="install0" d="M595.7,122.8H286.8c-3.7,0-6.7,3-6.7,6.7v217.3c0,3.7,3,6.7,6.7,6.7h308.8c3.7,0,6.7-3,6.7-6.7V129.5  C602.4,125.8,599.4,122.8,595.7,122.8z M551.7,248c0,1.4-1.1,2.5-2.5,2.5h-216c-1.4,0-2.5-1.1-2.5-2.5v-19.6c0-1.4,1.1-2.5,2.5-2.5  h216c1.3,0,2.5,1.1,2.5,2.5V248z"></path>
                <g>
                <path class="install0" d="M551.7,248c0,1.4-1.1,2.5-2.5,2.5h-216c-1.4,0-2.5-1.1-2.5-2.5v-19.6c0-1.4,1.1-2.5,2.5-2.5h216   c1.3,0,2.5,1.1,2.5,2.5V248z"></path>
                <path class="install0" d="M330.8,196.7h167.4 M330.8,178.6h53.7 M391.5,178.6h33 M431.4,178.6h21.5 M523.6,140.8h33 M563.6,140.8h21.5    M391.5,311.7h-60.7 M458.5,311.7h-60.7 M504.7,329.7H403.1 M395.5,329.7h-33 M352.3,329.7h-21.5"></path>
              </g>
                <path class="install5" d="M608.5,232.6v11.2"></path>
                <g>
                <path id="svg-concept" class="install6" d="M536.1,161.2c-7,0.8-12.4,6.7-12.4,14c0,7.8,6.3,14.1,14.1,14.1s14.1-6.3,14.1-14.1   c0-7.2-5.4-13.2-12.4-14"></path>
                <path id="svg-concept" class="install6" d="M542.7,167.8l-5,14.7l-6.7-3.3"></path>
              </g>
              </svg>
            </div>
            <div class="col-md-12 col-lg-5 offset-lg-1">
              <div class="info-content">
                <h1 class="fw-bold mb-3 mergecolor">Easy 1-click-installer</h1>
                <p class="seccolor">But I must explain to you how all this mistaken idea of <a href="#" class="golink">denouncing pleasure</a> and praising pain was born and I will.</p>
              </div>
              <a href="" class="btn btn-default-yellow-fill mt-3">Go cPanel</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="services sec-normal scrollme motpath toppadding bg-colorstyle">
      <div class="container">
        <div class="service-wrap">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h2 class="section-heading mergecolor">Why choose Cloud Reseller?</h2>
              <p class="section-subheading mergecolor">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
            </div>
            <div class="col-sm-12 col-md-4 animateme" data-when="enter" data-from="1" data-to="0" data-opacity="1" data-translatex="-60" data-easeinout="0">
              <div class="service-section bg-seccolorstyle noshadow">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg" src="./assets/fonts/svg/hierarchy.svg" alt="">
                  <title>hierarchy</title>
                  <path fill="#5e686c" d="M31.075 31.45h-12.050c-0.238 0-0.431-0.194-0.431-0.431v-12.044c0-0.238 0.194-0.431 0.431-0.431h12.050c0.238 0 0.431 0.194 0.431 0.431v12.050c0 0.231-0.194 0.425-0.431 0.425zM19.456 30.587h11.188v-11.188h-11.188v11.188z"></path>
                  <path fill="#5e686c" d="M12.969 31.45h-12.050c-0.238 0-0.431-0.194-0.431-0.431v-12.044c0-0.238 0.194-0.431 0.431-0.431h12.050c0.238 0 0.431 0.194 0.431 0.431v12.050c0 0.231-0.194 0.425-0.431 0.425zM1.35 30.587h11.188v-11.188h-11.188v11.188z"></path>
                  <path fill="#5e686c" d="M25.050 16.194h-18.106c-0.237 0-0.431-0.194-0.431-0.431s0.194-0.431 0.431-0.431h18.106c0.238 0 0.431 0.194 0.431 0.431s-0.194 0.431-0.431 0.431z"></path>
                  <path fill="#5e686c" d="M25.050 19.206c-0.238 0-0.431-0.194-0.431-0.431v-3.012c0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431v3.012c0 0.238-0.194 0.431-0.431 0.431z"></path>
                  <path fill="#5e686c" d="M6.944 19.206c-0.237 0-0.431-0.194-0.431-0.431v-3.012c0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431v3.012c0 0.238-0.194 0.431-0.431 0.431z"></path>
                  <path fill="#5e686c" d="M16 16.194c-0.238 0-0.431-0.194-0.431-0.431v-2.644c0-0.238 0.194-0.431 0.431-0.431s0.431 0.194 0.431 0.431v2.644c-0.006 0.238-0.194 0.431-0.431 0.431z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M22.038 13.55h-12.044c-0.238 0-0.431-0.194-0.431-0.431v-12.444c0-0.237 0.194-0.431 0.431-0.431h12.044c0.238 0 0.431 0.194 0.431 0.431v12.45c0 0.231-0.194 0.425-0.431 0.425zM10.419 12.688h11.188v-11.588h-11.188v11.588z"></path>
                </svg>
                <div class="title mergecolor">Seamless Upgrades</div>
                <p class="subtitle seccolor">
                  Excepteur sint occaecat cupidatat non proident voluptate. Lorem ipsum dolor sit amet, consectetur.
                </p>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 animateme" data-when="enter" data-from="1" data-to="0" data-opacity="1" data-translatey="60" data-easeinout="0">
              <div class="service-section bg-seccolorstyle noshadow">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg" src="./assets/fonts/svg/cloudmanaged.svg" alt="">
                  <title>cloudmanaged</title>
                  <path fill="#5e686c" d="M5.756 17.569c-0.225 0-0.412-0.181-0.419-0.413l-0.006-0.387-0.706-0.7-2.831-0.019c-0.169 0-0.319-0.106-0.387-0.262l-0.838-2.050c-0.063-0.15-0.031-0.331 0.081-0.45l1.969-2.075 0.006-0.988-1.988-2.019c-0.119-0.119-0.156-0.3-0.088-0.456l0.85-2.038c0.063-0.15 0.212-0.256 0.375-0.256l2.856-0.075 0.706-0.7 0.019-2.831c0-0.169 0.106-0.319 0.263-0.387l2.044-0.844c0.15-0.063 0.331-0.031 0.45 0.081l2.075 1.975 0.994 0.006 2.013-1.988c0.119-0.119 0.3-0.15 0.456-0.088l2.037 0.85c0.15 0.063 0.256 0.212 0.256 0.375l0.075 2.862 0.7 0.7 2.831 0.019c0.169 0 0.319 0.106 0.387 0.263l0.844 2.044c0.063 0.15 0.031 0.331-0.087 0.45l-1.975 2.069v0.869c0 0.231-0.188 0.419-0.419 0.419 0 0 0 0 0 0-0.231 0-0.419-0.188-0.419-0.419v-1.037c0-0.106 0.044-0.213 0.119-0.287l1.9-1.994-0.631-1.537-2.725-0.019c-0.113 0-0.219-0.044-0.294-0.125l-0.938-0.938c-0.075-0.075-0.119-0.181-0.125-0.287l-0.069-2.756-1.531-0.637-1.938 1.912c-0.081 0.075-0.225 0.119-0.3 0.119l-1.331-0.006c-0.106 0-0.213-0.044-0.287-0.112l-2-1.9-1.531 0.637-0.019 2.725c0 0.112-0.044 0.219-0.125 0.294l-0.944 0.938c-0.075 0.075-0.175 0.119-0.287 0.125l-2.756 0.069-0.637 1.531 1.912 1.937c0.075 0.081 0.119 0.188 0.119 0.294l-0.006 1.331c0 0.106-0.044 0.213-0.112 0.287l-1.9 2 0.631 1.537 2.725 0.019c0.112 0 0.219 0.044 0.294 0.125l0.944 0.944c0.075 0.075 0.119 0.181 0.125 0.288l0.013 0.556c0.006 0.231-0.175 0.425-0.412 0.431 0 0-0.006 0-0.006 0z"></path>
                  <path fill="#5e686c" d="M10.669 14.512c-0.5 0-0.988-0.1-1.45-0.294-0.931-0.387-1.65-1.119-2.037-2.050-0.381-0.938-0.381-1.963 0.006-2.894 0.588-1.412 1.963-2.325 3.494-2.325 0.5 0 0.988 0.1 1.45 0.294 0.931 0.388 1.656 1.119 2.037 2.050 0.381 0.938 0.381 1.963-0.006 2.894-0.588 1.412-1.963 2.325-3.494 2.325zM10.681 7.794c-1.194 0-2.263 0.712-2.719 1.806-0.3 0.725-0.306 1.525-0.006 2.25s0.863 1.294 1.581 1.594c0.363 0.15 0.738 0.225 1.131 0.225 1.194 0 2.263-0.706 2.719-1.806 0.3-0.725 0.306-1.525 0.006-2.25s-0.863-1.294-1.588-1.594c-0.356-0.15-0.738-0.225-1.125-0.225z"></path>
                  <path fill="#5e686c" d="M15.1 21.231c-0.231 0-0.419-0.188-0.419-0.419 0-0.15-0.006-0.306-0.012-0.456l-0.006-0.075c0.294-3.269 3.238-5.894 6.556-5.938 0.219-0.006 0.425 0.181 0.425 0.412 0.006 0.231-0.181 0.425-0.413 0.425-2.9 0.037-5.475 2.319-5.731 5.094l0.012 0.019c0.006 0.188 0.012 0.35 0.012 0.512-0.006 0.238-0.194 0.425-0.425 0.425z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M21.087 31.313h-13.094c-4.125 0-7.475-3.356-7.475-7.475 0-4.125 3.356-7.475 7.475-7.475 0.231 0 0.419 0.188 0.419 0.419s-0.188 0.419-0.419 0.419c-3.663 0-6.638 2.975-6.638 6.637s2.975 6.637 6.638 6.637h13.094c5.325 0 9.663-4.331 9.663-9.663 0-5.3-4.294-9.619-9.587-9.662-4.706 0.031-9.738 3.063-9.738 9.662 0 0.231-0.188 0.419-0.419 0.419s-0.419-0.188-0.419-0.419c0-6.813 5.238-10.419 10.469-10.5 0.012 0 0.025 0 0.038 0h0.15c0.012 0 0.025 0 0.038 0 5.706 0.1 10.313 4.775 10.313 10.5 0 5.794-4.713 10.5-10.506 10.5z"></path>
                </svg>
                <div class="title mergecolor">Perfomance Optimized</div>
                <p class="subtitle seccolor">
                  Excepteur sint occaecat cupidatat non proident dolor. Lorem ipsum dolor sit amet, consectetur.
                </p>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 animateme" data-when="enter" data-from="1" data-to="0" data-opacity="1" data-translatex="60" data-easeinout="0">
              <div class="service-section bg-seccolorstyle noshadow">
                <div class="plans badge feat bg-pink">control panel</div>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="32" viewBox="0 0 32 32" class="svg" src="./assets/fonts/svg/window.svg" alt="">
                  <title>window</title>
                  <path id="svg-ico" fill="#ee5586" d="M11.412 13.744h-5.938c-0.237 0-0.425 0.188-0.425 0.425v10.175c0 0.238 0.188 0.425 0.425 0.425h5.938c0.238 0 0.425-0.188 0.425-0.425v-10.175c0-0.238-0.188-0.425-0.425-0.425zM10.994 23.919h-5.094v-9.325h5.088v9.325z"></path>
                  <path fill="#5e686b" d="M31.719 10.194c0-0.012 0-0.025 0-0.031v-6.119c0-0.237-0.188-0.425-0.425-0.425h-30.581c-0.237 0-0.425 0.188-0.425 0.425v24.469c0 0.238 0.188 0.425 0.425 0.425h30.587c0.231 0 0.425-0.188 0.425-0.425v-18.281c-0.006-0.012-0.006-0.025-0.006-0.037zM1.131 4.469h29.744v5.269h-29.744v-5.269zM1.131 28.087v-17.5h29.744v17.506h-29.744z"></path>
                  <path fill="#5e686b" d="M5.188 5.3c-0.938 0-1.694 0.763-1.694 1.694s0.762 1.694 1.694 1.694c0.938 0 1.694-0.763 1.694-1.694 0.006-0.931-0.756-1.694-1.694-1.694zM5.188 7.844c-0.469 0-0.85-0.381-0.85-0.85s0.388-0.844 0.85-0.844c0.469 0 0.85 0.381 0.85 0.85s-0.381 0.844-0.85 0.844z"></path>
                  <path fill="#5e686b" d="M9.994 5.3c-0.938 0-1.694 0.763-1.694 1.694s0.762 1.694 1.694 1.694 1.694-0.763 1.694-1.694c0.006-0.931-0.756-1.694-1.694-1.694zM9.994 7.844c-0.469 0-0.85-0.381-0.85-0.85s0.381-0.85 0.85-0.85c0.469 0 0.85 0.381 0.85 0.85s-0.381 0.85-0.85 0.85z"></path>
                  <path fill="#5e686b" d="M14.8 5.3c-0.938 0-1.694 0.763-1.694 1.694s0.762 1.694 1.694 1.694 1.7-0.756 1.7-1.688c0-0.938-0.762-1.7-1.7-1.7zM14.8 7.844c-0.469 0-0.85-0.381-0.85-0.85s0.381-0.85 0.85-0.85c0.469 0 0.85 0.381 0.85 0.85s-0.381 0.85-0.85 0.85z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M26.181 17.2h-10.175c-0.238 0-0.425 0.188-0.425 0.425s0.188 0.425 0.425 0.425h10.175c0.238 0 0.425-0.188 0.425-0.425 0-0.231-0.194-0.425-0.425-0.425z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M26.181 20.488h-10.175c-0.238 0-0.425 0.188-0.425 0.425 0 0.231 0.188 0.425 0.425 0.425h10.175c0.238 0 0.425-0.188 0.425-0.425s-0.194-0.425-0.425-0.425z"></path>
                  <path id="svg-ico" fill="#ee5586" d="M23.712 23.781h-7.637c-0.238 0-0.425 0.188-0.425 0.425s0.188 0.425 0.425 0.425h7.631c0.238 0 0.425-0.188 0.425-0.425 0-0.231-0.188-0.425-0.419-0.425z"></path>
                </svg>
                <div class="title mergecolor">Free cPanel &amp; WHM</div>
                <p class="subtitle seccolor">
                  Excepteur sint occaecat cupidatat non proident voluptate. Lorem ipsum dolor sit amet, consectetur.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sec-normal sec-bg1 bg-colorstyle">
      <div class="best-plans pricing">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h2 class="section-heading mergecolor">Reseller Technical Specifications</h2>
              <p class="section-subheading mergecolor">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
            </div>
            <div class="col-sm-12">
              <div class="table-responsive-lg">
                <table class="table sample mt-5">
                  <thead>
                  <tr>
                    <td>
                      <span class="title">Software </span> <span class="badge bg-pink">PLUS</span>
                    </td>
                    <td>
                      <div class="title">Databases</div>
                    </td>
                    <td>
                      <div class="title">Additional Software</div>
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr><td><div class="title-table" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="top" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."> <span class="fas fa-check-circle me-2"></span> Latest cPanel</div></td><td><span class="fas fa-check-circle me-2"></span> MySQL 5.6</td>
                    <td><span class="fas fa-check-circle me-2"></span> WordPress</td></tr>
                  <tr><td><div class="title-table" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="top" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><span class="fas fa-check-circle me-2"></span> CloudLinux 6.9</div></td><td><span class="fas fa-check-circle me-2"></span> phpMyAdmin 4.6.6</td><td><span class="fas fa-check-circle me-2"></span> Joomla, Prestashop</td></tr>
                  <tr><td class="title-table"><span class="fas fa-check-circle me-2"></span> PHP 5.5/5.6/7.0</td><td><span class="fas fa-check-circle me-2"></span> FTPES Supported</td><td><span class="fas fa-check-circle me-2"></span> Zend Engine</td></tr>
                  <tr><td class="title-table"><span class="fas fa-check-circle me-3"></span>Python 2.6.6</td><td><span class="fas fa-check-circle me-2"></span> MSSQL Stored Procedures</td><td><span class="fas fa-check-circle me-2"></span> ionCube Loader </td></tr>
                  <tr><td class="border-0"><a href="" class="btn btn-default-pink-fill">All Specs</a></td><td class="border-0"><a href="" class="btn btn-default-pink-fill">All Specs</a></td><td class="border-0"><a href="" class="btn btn-default-pink-fill">All Specs</a></td></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sec-normal sec-bg2 bg-seccolorstyle bottomhalfpadding">
      <div class="faq">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 text-center">
              <h2 class="section-heading mergecolor">Frequently Asked Questions Reseller</h2>
              <p class="section-subheading mergecolor">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
            </div>
            <div class="col-sm-12">
              <div class="accordion faq pt-5">
                <div class="panel-wrap">
                  <div class="panel-title seccolor active">
                    <span>What exactly is Cloud Reseller?</span>
                    <div class="float-end">
                      <i class="fa fa-plus"></i>
                      <i class="fa fa-minus c-pink"></i>
                    </div>
                  </div>
                  <div class="panel-collapse" style="display: block;">
                    <div class="wrapper-collapse">
                      <div class="info">
                        <ul class="list seccolor">
                          <li>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel-wrap">
                  <div class="panel-title seccolor">
                    <span>Is there a brandable control panel?</span>
                    <div class="float-end">
                      <i class="fa fa-plus"></i>
                      <i class="fa fa-minus c-pink"></i>
                    </div>
                  </div>
                  <div class="panel-collapse">
                    <div class="wrapper-collapse">
                      <div class="info">
                        <ul class="list seccolor">
                          <li>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel-wrap">
                  <div class="panel-title seccolor">
                    <span>Can my Linux Reseller plan be upgraded when needed?</span>
                    <div class="float-end">
                      <i class="fa fa-plus"></i>
                      <i class="fa fa-minus c-pink"></i>
                    </div>
                  </div>
                  <div class="panel-collapse">
                    <div class="wrapper-collapse">
                      <div class="info">
                        <ul class="list seccolor">
                          <li>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel-wrap">
                  <div class="panel-title seccolor">
                    <span>I am not sure about the plan, can I change it later on?</span>
                    <div class="float-end">
                      <i class="fa fa-plus"></i>
                      <i class="fa fa-minus c-pink"></i>
                    </div>
                  </div>
                  <div class="panel-collapse">
                    <div class="wrapper-collapse">
                      <div class="info">
                        <ul class="list seccolor">
                          <li>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 103 284" style="enable-background:new 0 0 103 284;" xml:space="preserve" class="blobright svg d-none" src="./assets/img/background/blob.svg" alt="Blob Background">
        <circle id="svg-ico" class="blob0" cx="13.5" cy="26.5" r="5.5"></circle>
        <circle class="blob1" cx="13.5" cy="5.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="13.5" cy="47.5" r="5.5"></circle>
        <circle class="blob1" cx="13.5" cy="68.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="13.5" cy="89.5" r="5.5"></circle>
        <circle class="blob1" cx="13.5" cy="110.5" r="5.5"></circle>
        <circle class="blob2" cx="13.5" cy="131.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="13.5" cy="152.5" r="5.5"></circle>
        <circle class="blob1" cx="13.5" cy="173.5" r="5.5"></circle>
        <circle class="blob1" cx="13.5" cy="194.5" r="5.5"></circle>
        <circle class="blob2" cx="13.5" cy="215.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="13.5" cy="236.5" r="5.5"></circle>
        <circle class="blob1" cx="13.5" cy="257.5" r="5.5"></circle>
        <circle class="blob2" cx="13.5" cy="278.5" r="5.5"></circle>
        <circle class="blob1" cx="34.5" cy="26.5" r="5.5"></circle>
        <circle class="blob1" cx="34.5" cy="47.5" r="5.5"></circle>
        <circle class="blob1" cx="34.5" cy="68.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="34.5" cy="89.5" r="5.5"></circle>
        <circle class="blob1" cx="34.5" cy="110.5" r="5.5"></circle>
        <circle class="blob1" cx="34.5" cy="131.5" r="5.5"></circle>
        <circle class="blob1" cx="34.5" cy="152.5" r="5.5"></circle>
        <circle class="blob2" cx="34.5" cy="173.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="34.5" cy="194.5" r="5.5"></circle>
        <circle class="blob1" cx="34.5" cy="215.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="34.5" cy="236.5" r="5.5"></circle>
        <circle class="blob1" cx="34.5" cy="257.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="55.5" cy="47.5" r="5.5"></circle>
        <circle class="blob2" cx="55.5" cy="68.5" r="5.5"></circle>
        <circle class="blob1" cx="55.5" cy="89.5" r="5.5"></circle>
        <circle class="blob2" cx="55.5" cy="110.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="55.5" cy="131.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="55.5" cy="152.5" r="5.5"></circle>
        <circle class="blob1" cx="55.5" cy="173.5" r="5.5"></circle>
        <circle class="blob1" cx="55.5" cy="194.5" r="5.5"></circle>
        <circle class="blob1" cx="76.6" cy="194.5" r="5.5"></circle>
        <circle class="blob1" cx="55.5" cy="215.5" r="5.5"></circle>
        <circle class="blob2" cx="55.5" cy="236.5" r="5.5"></circle>
        <circle class="blob1" cx="76.5" cy="89.5" r="5.5"></circle>
        <circle class="blob1" cx="76.5" cy="110.5" r="5.5"></circle>
        <circle class="blob1" cx="76.5" cy="131.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="97.5" cy="131.5" r="5.5"></circle>
        <circle class="blob2" cx="76.5" cy="152.5" r="5.5"></circle>
        <circle class="blob1" cx="97.5" cy="152.5" r="5.5"></circle>
        <circle id="svg-ico" class="blob0" cx="76.5" cy="173.5" r="5.5"></circle>
  </svg>
</div>
</template>

<script>
export default {
  name: "Hosting",
  mounted(){
    this.$emit('closeLoading')
      // this.$emit('openLoading')
  }
}
</script>
<style scoped>
  .install0{fill:none;stroke:#808080;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .install1{fill:none;stroke:#EE5586;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .install2{fill:none;stroke:#808080;stroke-miterlimit:10;}
  .install3{fill:#FFFFFF;stroke:#EE5586;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .install4{fill:#EE5586;}
  .install5{fill:#FFFFFF;stroke:#808080;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .install6{fill:none;stroke:#EE5586;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .blob0{fill:#EE5586;}
  .blob1{fill:#EFEFEF;}
  .blob2{fill:#F5F5F5;}
  .api0{fill:none;stroke:#808080;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .api1{fill:none;stroke:#EE5586;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .api2{fill:none;stroke:#EE5586;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .api3{fill:none;stroke:#808080;stroke-miterlimit:10;}
  .api4{fill:none;stroke:#808080;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .performance0{fill:none;stroke:#808080;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .performance1{fill:none;stroke:#808080;stroke-miterlimit:10;}
  .performance2{fill:#EE5586;}
  .performance3{fill:none;stroke:#EE5586;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .performance4{fill:none;stroke:#EE5586;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;}
  .performance5{fill:none;stroke:#808080;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .performance6{fill:none;stroke:#EE5586;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>