<template>
  <div class="box-container limit-width">
    <div id="ticket"  class="top-header sec-bg6 bg-colorstyle">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="wrapper">
              <h1 class="heading mb-4 fw-bold mergecolor">Report Abuse</h1>
              <div class="mb-3 h5 text-white mergecolor">We take reports of abuse seriously and are committed to addressing them promptly.
              </div>
              <div class="included text-white mergecolor pb-4 mt-0">
                <div class="mb-3 h5 text-white mergecolor">Please use the given form to notify us right away if you come across any misuse or inappropriate activity pertaining domain
                </div>
                <ul>
                  <li><i class="fas fa-check-circle c-pink"></i>Please provide us with as much detail as possible regarding the abuse you've encountered. This will help us investigate the issue promptly and take appropriate action.
                  </li>
                </ul>
                <ul>
                  <li><i class="fas fa-check-circle c-pink"></i>All reports will be treated with the utmost confidentiality, and your identity will be kept confidential whenever possible.
                  </li>
                </ul>
                <ul>
                  <li><i class="fas fa-check-circle c-pink"></i>Once we have received your report, our team will investigate the matter thoroughly and take appropriate action in accordance with our website's policies and procedures.
                  </li>
                </ul>
                <div class="mb-3 h5 text-white mergecolor">Thank you for helping us maintain a safe and respectful online community.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1 cd-filter-block bg-pink p-5 rounded-3"  data-when="enter" data-from="1" data-to="0" data-opacity="1" data-translatex="-60" data-easeinout="0" style="opacity: 1; transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);">
            <div class="form-contact cd-filter-content p-0 sec-bx">
              <div>
                <div class="row">
                  <div class="col-md-12" v-if="responseMessage">
                    <h3 class="c-yellow"> {{ responseMessage }}</h3>
                  </div>
                  <div class="col-md-12 position-relative">
                    <div class="cd-select mt-4">
                      <label class="db" style="right: 1rem"></label>
                      <select  class="select-filter" required v-model="type_id">
                        <option v-for="(k,i) in report_types" :key="i" :value="k.id">{{k.name}}</option> 
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12 position-relative pt-2">
                    <label><i class="fas fa-user-tie" style="top: 1.5rem"></i></label>
                    <input type="text" v-model="full_name" placeholder="Full Name" required>
                  </div>
                  <div class="col-md-12 position-relative pt-2">
                    <label><i class="fas fa-envelope" style="top: 1.5rem"></i></label>
                    <input type="email" v-model="email" placeholder="Email Address" required>
                  </div>
                  <div class="col-md-12 position-relative pt-2">
                    <label><i class="fas fa-building" style="top: 1.5rem"></i></label>
                    <input type="text" v-model="website" placeholder="Abusive Website" required>
                  </div>
                  <div class="col-md-12 position-relative pt-2">
                    <label><i class="fas fa-file-alt" style="top: 1.5rem"></i></label>
                    <input type="text" v-model="subject" placeholder="Subject" required>
                  </div>
                  <div class="col-md-12 position-relative pt-2">
                    <label><i class="fas fa-file-alt" style="top: 1.5rem"></i></label>
                    <textarea v-model="message" type="text" rows="5" placeholder="Message..." required></textarea>
                  </div>
                  <div class="g-recaptcha" :data-sitekey="captchaKey"></div>
                  <div class="col-md-6 position-relative mt-4 d-flex">
                  <div class="col-3">
                    <button @click="reportAbouse" type="submit" value="Submit" class="btn btn-default-yellow-fill float-start me-3 mb-3">Send</button>
                  </div>
                  </div>
                  <div class="col-md-6 mt-4">
                      <div class="questenHover">
                        <a type="question" value="Question" class="btn btn-default-fill float-end mb-3 ">?</a>
                      </div> 
                      <div id="questionText" class="questenVisible">
                        <div class="col-md-12" style="justify-content: space-between; display: flex">
                          <strong>How to submit a form?</strong>
                        </div>
                        <ul style="padding: 15px!important;">
                          <li style="list-style-type: circle">Specify the type of abuse.</li>
                          <li style="list-style-type: circle">Enter your name and the E-mail address at which you wish to receive our reply.</li>
                          <li style="list-style-type: circle">Enter the domain name and describe the inaccuracy.</li>
                          <li style="list-style-type: circle">Click send.</li>
                          <li style="list-style-type: circle">Wait for our feedback.</li>
                        </ul>
                      </div>
                  </div>

                  <!-- <div class="col-md-6 mt-4">
                    <div v-if="showQuestion" id="questionText">
                      <div class="col-md-12" style="justify-content: space-between; display: flex">
                        <strong>How to submit a form?</strong>
                        <strong class="cursor-p" @click="showQuestion=false">x</strong>
                      </div>
                      <ul style="padding: 15px!important;">
                        <li style="list-style-type: circle">Specify the type of abuse.</li>
                        <li style="list-style-type: circle">Enter your name and the E-mail address at which you wish to receive our reply.</li>
                        <li style="list-style-type: circle">Enter the domain name and describe the inaccuracy.</li>
                        <li style="list-style-type: circle">Click send.</li>
                        <li style="list-style-type: circle">Wait for our feedback.</li>
                      </ul>
                    </div>
                    <div>
                      <a @click="showQuestion = true" type="question" value="Question" class="btn btn-default-fill float-end mb-3">?</a>
                    </div>
                  </div> -->
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script> 
export default { 
  data() {
    return {
      showQuestion: false,
      report_types:[],
      responseMessage:"",
      subject:"",
      full_name:"",
      email:"",
      message:"",
      website:"",
      type_id:"",
      captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
      captchaResponse:"",
    };
  },
  watch: {
    '$route': {
      handler(newRoute, oldRoute) { 
        if(newRoute.name === 'report'){
          const script = document.getElementById('captcha-script');
          if (script) {
            script.parentNode.removeChild(script);
          } 
          const newScript = document.createElement('script');
          newScript.id = 'captcha-script'; // Set id for easier retrieval
          newScript.src = 'https://www.google.com/recaptcha/api.js';
          document.body.appendChild(newScript); 
          newScript.onload = () => {
            // Code to execute after script is loaded, if needed
          };
        }

      },
      immediate: true // Run the handler immediately when the component is created
    }
  },
  methods: {
    reportAbouse(){
      this.captchaResponse = grecaptcha.getResponse();
      if(this.captchaResponse.length == 0) {
        this.responseMessage  = this.$t("Please complete the reCAPTCHA")
        return
      }
      this.$emit('openLoading') 
      let data = {
        subject : this.subject,
        full_name : this.full_name,
        email : this.email,
        message : this.message,
        website : this.website,
        type_id : this.type_id,
        "g-recaptcha-response":this.captchaResponse,
      }
      this.$store.dispatch('report_abouse',data).then(()=>{
        this.responseMessage = this.$store.state.site.message
        this.$emit('closeLoading')
      }).catch(()=>{
        this.$emit('closeLoading') 
        this.responseMessage = this.$store.state.site.error_message
      })
    },
    getReportTypes(){
      this.$store.dispatch('report_types').then(()=>{
        this.report_types = this.$store.state.site.report_types
        this.type_id = this.$store.state.site.report_types[0].id 
        this.$emit('closeLoading') 
      }).catch(()=>{
        this.$emit('closeLoading') 
      })
      
    }, 
    toggleQuestion() {
      this.showQuestion = true
    },
  },
  mounted() {
    this.$emit('openLoading');
    this.getReportTypes();
  }
};

</script>

<style scoped>
.questenVisible {
    visibility: hidden;
    opacity: 0;
}
.questenHover:hover + .questenVisible {
    visibility: visible;
    opacity: 1;
}
textarea {
  background-color: #101920 !important;
  border: 1px solid #414142;
  color: #fff !important;
  border-radius: 20px !important;
  font-family: 'Open Sans', sans-serif !important;
  width: 100%;
  padding: 15px 60px 15px 30px;
  font-size: 14px;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
  line-height: inherit;
}

.question {
  /* background-color: red !important;
  color: #fff !important;
  border-radius: 50px !important;
  font-family: 'Open Sans', sans-serif !important;
  border: none;
  text-transform: capitalize;
  cursor: pointer !important;
  font-size: 16px;
  margin: 0;
  padding: 14px 30px;
  position: relative;
  letter-spacing: .2px;
  font-weight: 700;
  box-shadow: 0 2px 8px 0 rgb(62 62 79 / 23%);
  transition: .3s linear;
  width: 80px;
  height: 50px;
  display: inline-block;
  position: relative;
  text-align: center; */
  /* align-items: center; */
  /* display: flex; */
  font-size: 20px!important;
}

.box-container .cd-filter-block select {
  background-color: #101920 !important;
  border: 1px solid #414142;
  color: grey !important
}

#questionButton {
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
}

#questionButton span {
  display: inline-block;
  vertical-align: middle;
}

#questionText {
  position: absolute;
    left: 0px;
    /* top: 540px; */
    margin-top: 0px;
    width: 380px;
    /* width: auto; */
    z-index: 9999;
    background: beige;
    color: black;
    padding: 10px 20px 0px 20px;
    border: none;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
}

.questionText {
  /* position: relative; */
  display: block;
  border: 1px solid;
  width: 105px;
  word-wrap: break-word;
  margin-left: 20px;
}

.triangle-div::before {
  content: '';
  position: absolute;
  top: 0;
  left: -22px;
  width: 0px;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 19px solid beige;
}
</style>