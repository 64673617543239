<template>
  <section class="total-grad-pink-blue-intense">
    <Logo style="display: flex; justify-content: center; margin-top: 60px"/>
    <!-- <div class="total-grad-pink-blue-intense"></div> -->
      <div class="container">
        <div class="main_cont row justify-content-center">
          <div class="col-9">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 850 595" style="enable-background:new 0 0 850 595;" xml:space="preserve" class="svg" src="assets/patterns/maintenance.svg" alt="maintenance">
          <g>
	        <line class="maintenance0" x1="525" y1="539" x2="639.7" y2="539"></line>
            <line class="maintenance0" x1="207.1" y1="539" x2="311.1" y2="539"></line>
            <line class="maintenance0" x1="529.5" y1="487.4" x2="560.9" y2="487.4"></line>
            <line class="maintenance0" x1="286.2" y1="487.4" x2="349.4" y2="487.4"></line>
            <line class="maintenance0" x1="456.5" y1="442.8" x2="494.2" y2="442.8"></line>
            <line class="maintenance0" x1="352.9" y1="442.8" x2="391.9" y2="442.8"></line>
            <line class="maintenance0" x1="455.4" y1="398.5" x2="495.2" y2="398.5"></line>
            <line class="maintenance0" x1="350.9" y1="398.5" x2="396.4" y2="398.5"></line>
            <line class="maintenance0" x1="0" y1="595" x2="57.1" y2="565.8"></line>
            <line class="maintenance0" x1="353.1" y1="439.5" x2="396.3" y2="411.1"></line>
            <line class="maintenance0" x1="116.9" y1="594.9" x2="159.2" y2="567"></line>
            <line class="maintenance0" x1="278.4" y1="594.9" x2="395.2" y2="432.8"></line>
            <line class="maintenance0" x1="425" y1="594.9" x2="425" y2="572.7"></line>
            <line class="maintenance0" x1="850" y1="595" x2="787.6" y2="565.9"></line>
            <line class="maintenance0" x1="493.4" y1="438.1" x2="455.4" y2="412.7"></line>
            <line class="maintenance0" x1="727.5" y1="594.9" x2="685.6" y2="566.8"></line>
            <line class="maintenance0" x1="469.1" y1="452.8" x2="454.9" y2="433.2"></line>
            <line class="maintenance0" x1="571.6" y1="594.9" x2="528.7" y2="535.4"></line>
          </g>
          <rect x="61.5" y="53.2" class="maintenance1" width="100.5" height="510.4"></rect>
          <polyline class="maintenance1" points="217.5,391.9 217.5,528 162,563.6 162,53.2 217.5,93.8 217.5,330.1 "></polyline>
          <polyline class="maintenance1" points="212.1,392.4 212.1,523.9 167.8,549.7 167.8,64.6 212.1,96 212.1,330.2 "></polyline>
          <polygon class="maintenance1" points="204.7,136.4 173.9,119.3 173.9,96 204.7,113.6 "></polygon>
          <polygon class="maintenance1" points="204.7,163.2 173.9,149.1 173.9,125.8 204.7,143.4 "></polygon>
          <polygon class="maintenance1" points="204.7,190.4 173.9,178.6 173.9,155.3 204.7,170.6 "></polygon>
          <polygon class="maintenance1" points="204.7,218.8 173.9,208.5 173.9,185.9 204.7,197.7 "></polygon>
          <polygon class="maintenance1" points="204.7,245.8 173.9,237.6 173.9,215.1 204.7,225.8 "></polygon>
          <polygon class="maintenance1" points="204.7,272.8 173.9,267.8 173.9,245.3 204.7,252.8 "></polygon>
          <polygon class="maintenance1" points="204.7,300.7 173.9,297.8 173.9,275.2 204.7,280.7 "></polygon>
          <polygon class="maintenance1" points="204.7,328.2 173.9,327.5 173.9,304.9 204.7,308.2 "></polygon>
          <line class="maintenance1" x1="196" y1="357.5" x2="173.9" y2="358"></line>
          <line class="maintenance1" x1="173.9" y1="335.4" x2="196" y2="334.9"></line>
          <line class="maintenance1" x1="204.8" y1="385.1" x2="173.9" y2="387.7"></line>
          <line class="maintenance1" x1="173.9" y1="365.6" x2="204.8" y2="363.9"></line>
          <polygon class="maintenance1" points="204.7,411.2 173.9,417.4 173.9,395.7 204.7,390.2 "></polygon>
          <polygon class="maintenance1" points="204.7,438.5 173.9,446.6 173.9,424.8 204.7,417.4 "></polygon>
          <polygon class="maintenance1" points="204.7,466.3 173.9,477.4 173.9,455.6 204.7,446.6 "></polygon>
          <polygon class="maintenance1" points="204.7,492.7 173.9,506.8 173.9,485 204.7,473.1 "></polygon>
          <polygon class="maintenance1" points="204.7,520.3 173.9,536.6 173.9,514.8 204.7,500.6 "></polygon>
          <circle id="svg-ico" class="maintenance2" cx="185.8" cy="114.5" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="193" cy="119.2" r="2"></circle>
          <circle class="maintenance3" cx="200.2" cy="123.8" r="2"></circle>
          <circle class="maintenance3" cx="178.4" cy="110.5" r="2"></circle>
          <circle class="maintenance3" cx="186.1" cy="143.5" r="2"></circle>
          <circle class="maintenance3" cx="193.3" cy="147.4" r="2"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="200.6" cy="151.6" r="2"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.5s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="178.7" cy="140.2" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="185.4" cy="172.6" r="2"></circle>
          <circle class="maintenance3" cx="192.7" cy="176" r="2"></circle>
          <circle class="maintenance3" cx="199.8" cy="179" r="2"></circle>
          <circle class="maintenance3" cx="178.1" cy="169.7" r="2"></circle>
          <circle class="maintenance3" cx="185.7" cy="202.1" r="2"></circle>
          <circle class="maintenance3" cx="193.1" cy="205.1" r="2"></circle>
          <circle class="maintenance3" cx="200" cy="207.5" r="2"></circle>
          <circle class="maintenance3" cx="178.6" cy="199.7" r="2"></circle>
          <circle class="maintenance3" cx="185.8" cy="230.4" r="2"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="192.9" cy="233.1" r="2"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.8s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <g>
<circle id="svg-ico" class="maintenance2" cx="200.3" cy="235.1" r="2"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.7s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>


          <circle class="maintenance3" cx="178.3" cy="228.4" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="185.6" cy="259.6" r="2"></circle>
          <circle class="maintenance3" cx="193.1" cy="261.2" r="2"></circle>
          <circle class="maintenance3" cx="200.2" cy="262.9" r="2"></circle>
          <circle class="maintenance3" cx="178.4" cy="258.1" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="185.7" cy="289.4" r="2"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="192.7" cy="290.2" r="2"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.5s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="200.2" cy="291.1" r="2"></circle>
          <circle class="maintenance3" cx="178.4" cy="288.7" r="2"></circle>
          <circle class="maintenance3" cx="185.5" cy="317.9" r="2"></circle>
          <circle class="maintenance3" cx="193.1" cy="317.9" r="2"></circle>
          <circle class="maintenance3" cx="200.4" cy="317.6" r="2"></circle>
          <circle class="maintenance3" cx="178.2" cy="318.1" r="2"></circle>
          <circle class="maintenance3" cx="204.3" cy="345.5" r="1.3"></circle>
          <circle class="maintenance3" cx="209.4" cy="345.5" r="1.3"></circle>
          <circle class="maintenance3" cx="214.1" cy="345.3" r="1.3"></circle>
          <circle class="maintenance3" cx="199.6" cy="345.7" r="1.3"></circle>
          <circle class="maintenance3" cx="213.7" cy="374" r="1.3"></circle>
          <circle class="maintenance3" cx="218.7" cy="373.4" r="1.3"></circle>
          <circle class="maintenance3" cx="223.4" cy="372.7" r="1.3"></circle>
          <circle class="maintenance3" cx="209" cy="374.8" r="1.3"></circle>
          <polygon class="maintenance1" points="217.5,355.5 196,357.5 196,334.9 217.5,335.5 "></polygon>
          <polygon class="maintenance1" points="227.2,382 204.8,385.1 204.8,363.9 227.2,361.4 "></polygon>
          <circle class="maintenance3" cx="185.7" cy="405.5" r="2"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="192.6" cy="403.5" r="2"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.5s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <g>
<circle id="svg-ico" class="maintenance2" cx="200.2" cy="402.1" r="2"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.7s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="178.4" cy="407.1" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="185.7" cy="434" r="2"></circle>
          <circle class="maintenance3" cx="192.6" cy="431.5" r="2"></circle>
          <circle class="maintenance3" cx="200.4" cy="429.2" r="2"></circle>
          <circle class="maintenance3" cx="178.2" cy="436.3" r="2"></circle>
          <circle class="maintenance3" cx="185.8" cy="463.1" r="2"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="192.7" cy="460.5" r="2"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.8s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="200.4" cy="458.2" r="2"></circle>
          <circle class="maintenance3" cx="178.3" cy="465.4" r="2"></circle>
          <circle class="maintenance3" cx="185.7" cy="490.7" r="2"></circle>
          <circle class="maintenance3" cx="192.6" cy="488.1" r="2"></circle>
          <circle class="maintenance3" cx="200.4" cy="485.9" r="2"></circle>
          <circle class="maintenance3" cx="178.2" cy="493" r="2"></circle>
          <circle class="maintenance3" cx="185.7" cy="519.6" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="192.6" cy="516.2" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="200.3" cy="512.9" r="2"></circle>
          <circle class="maintenance3" cx="178.3" cy="522.7" r="2"></circle>
          <polyline class="maintenance1" points="217.5,117.5 246,117.5 246,508.9 217.5,508.9 "></polyline>
          <polygon class="maintenance1" points="288.6,481.6 246,508.9 246,117.5 288.6,148.7 "></polygon>
          <polygon class="maintenance1" points="284.4,478.5 250.5,498.3 250.5,126.3 284.4,150.4 "></polygon>
          <polygon class="maintenance1" points="278.8,181.3 255.1,168.2 255.1,150.4 278.8,163.9 "></polygon>
          <polygon class="maintenance1" points="278.8,201.9 255.1,191 255.1,173.2 278.8,186.7 "></polygon>
          <polygon class="maintenance1" points="278.8,222.7 255.1,213.7 255.1,195.9 278.8,207.6 "></polygon>
          <polygon class="maintenance1" points="278.8,307.3 255.1,305.1 255.1,287.8 278.8,292 "></polygon>
          <polygon class="maintenance1" points="278.8,328.4 255.1,327.9 255.1,310.6 278.8,313.1 "></polygon>
          <polygon class="maintenance1" points="278.8,349.3 255.1,351.2 255.1,333.9 278.8,333.9 "></polygon>
          <polygon class="maintenance1" points="278.8,370.8 255.1,374 255.1,357.3 278.8,354.7 "></polygon>
          <polygon class="maintenance1" points="278.8,392.1 255.1,396.8 255.1,380.1 278.8,376 "></polygon>
          <polygon class="maintenance1" points="278.8,413 255.1,419.2 255.1,402.5 278.8,396.8 "></polygon>
          <polygon class="maintenance1" points="278.8,434.3 255.1,442.8 255.1,426.1 278.8,419.2 "></polygon>
          <polygon class="maintenance1" points="278.8,454.6 255.1,465.4 255.1,448.7 278.8,439.5 "></polygon>
          <polygon class="maintenance1" points="278.8,475.7 255.1,488.2 255.1,471.5 278.8,460.6 "></polygon>

          <g>
<circle id="svg-ico" class="maintenance2" cx="264.2" cy="164.5" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.8s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <g>
<circle id="svg-ico" class="maintenance2" cx="269.8" cy="168.1" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.7s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="275.3" cy="171.7" r="1.5"></circle>
          <circle class="maintenance3" cx="258.6" cy="161.5" r="1.5"></circle>
          <circle class="maintenance3" cx="264.5" cy="186.8" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="270" cy="189.7" r="1.5"></circle>
          <circle class="maintenance3" cx="275.6" cy="193" r="1.5"></circle>
          <circle class="maintenance3" cx="258.8" cy="184.2" r="1.5"></circle>
          <circle class="maintenance3" cx="263.9" cy="209.1" r="1.5"></circle>
          <circle class="maintenance3" cx="269.6" cy="211.7" r="1.5"></circle>
          <circle class="maintenance3" cx="275" cy="214" r="1.5"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="258.3" cy="206.9" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.4s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>


          <circle id="svg-ico" class="maintenance2" cx="264.2" cy="298.7" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="269.5" cy="299.3" r="1.5"></circle>
          <circle class="maintenance3" cx="275.3" cy="300" r="1.5"></circle>
          <circle class="maintenance3" cx="258.6" cy="298.1" r="1.5"></circle>
          <circle class="maintenance3" cx="264" cy="320.5" r="1.5"></circle>
          <circle class="maintenance3" cx="269.9" cy="320.5" r="1.5"></circle>
          <circle class="maintenance3" cx="275.4" cy="320.3" r="1.5"></circle>
          <circle class="maintenance3" cx="258.4" cy="320.7" r="1.5"></circle>
          <circle class="maintenance3" cx="263.8" cy="343.1" r="1.5"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="269.6" cy="342.7" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.5s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="275.3" cy="342.2" r="1.5"></circle>
          <circle class="maintenance3" cx="258.4" cy="343.5" r="1.5"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="264.1" cy="365" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.6s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle id="svg-ico" class="maintenance2" cx="269.5" cy="364.2" r="1.5"></circle>
          <circle class="maintenance3" cx="275.3" cy="363.4" r="1.5"></circle>
          <circle class="maintenance3" cx="258.6" cy="365.9" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="264.2" cy="387.7" r="1.5"></circle>
          <circle class="maintenance3" cx="269.5" cy="386.2" r="1.5"></circle>
          <circle class="maintenance3" cx="275.3" cy="385.1" r="1.5"></circle>
          <circle class="maintenance3" cx="258.6" cy="388.9" r="1.5"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="264.2" cy="409.6" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.6s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="269.5" cy="407.6" r="1.5"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="275.4" cy="405.9" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.4s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="258.4" cy="411.3" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="264.2" cy="431.8" r="1.5"></circle>

          <g>
<circle class="maintenance3" cx="269.5" cy="429.9" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.7s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="275.5" cy="428.1" r="1.5"></circle>
          <circle class="maintenance3" cx="258.5" cy="433.6" r="1.5"></circle>
          <circle class="maintenance3" cx="264.2" cy="453.4" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="269.5" cy="451.4" r="1.5"></circle>
          <circle class="maintenance3" cx="275.4" cy="449.7" r="1.5"></circle>
          <circle class="maintenance3" cx="258.4" cy="455.2" r="1.5"></circle>
          <circle class="maintenance3" cx="264.2" cy="475.2" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="269.5" cy="472.6" r="1.5"></circle>
          <circle class="maintenance3" cx="275.4" cy="470.1" r="1.5"></circle>
          <circle class="maintenance3" cx="258.5" cy="477.6" r="1.5"></circle>
          <polyline class="maintenance4" points="288.6,173.2 317.9,173.2 317.9,462.2 288.6,462.2 "></polyline>
          <polygon class="maintenance4" points="349.4,442 317.9,462.2 317.9,173.2 349.4,196.2 "></polygon>
          <polygon class="maintenance4" points="346.3,439.7 321.2,454.3 321.2,179.7 346.3,197.4 "></polygon>
          <polygon class="maintenance4" points="342.1,220.3 324.6,210.6 324.6,197.4 342.1,207.4 "></polygon>
          <polygon class="maintenance4" points="342.1,235.5 324.6,227.5 324.6,214.3 342.1,224.3 "></polygon>
          <polygon class="maintenance4" points="342.1,250.9 324.6,244.2 324.6,231 342.1,239.7 "></polygon>
          <polygon class="maintenance4" points="342.1,267 324.6,261.1 324.6,248.3 342.1,255 "></polygon>
          <polygon class="maintenance4" points="342.1,282.2 324.6,277.6 324.6,264.8 342.1,270.9 "></polygon>
          <polygon class="maintenance4" points="342.1,297.5 324.6,294.7 324.6,281.9 342.1,286.2 "></polygon>
          <polygon class="maintenance4" points="342.1,313.3 324.6,311.7 324.6,298.9 342.1,302 "></polygon>
          <polygon class="maintenance4" points="342.1,328.9 324.6,328.5 324.6,315.7 342.1,317.6 "></polygon>
          <polygon class="maintenance4" points="342.1,344.3 324.6,345.7 324.6,332.9 342.1,332.9 "></polygon>
          <polygon class="maintenance4" points="342.1,360.2 324.6,362.6 324.6,350.2 342.1,348.3 "></polygon>
          <polygon class="maintenance4" points="342.1,375.9 324.6,379.4 324.6,367.1 342.1,364 "></polygon>
          <polygon class="maintenance4" points="342.1,391.3 324.6,395.9 324.6,383.6 342.1,379.4 "></polygon>
          <polygon class="maintenance4" points="342.1,407 324.6,413.3 324.6,401 342.1,395.9 "></polygon>
          <polygon class="maintenance4" points="342.1,422 324.6,430 324.6,417.7 342.1,410.9 "></polygon>
          <polygon class="maintenance4" points="342.1,437.6 324.6,446.8 324.6,434.5 342.1,426.5 "></polygon>
          <circle id="svg-ico" class="maintenance2" cx="331.4" cy="207.9" r="1.1"></circle>
          <circle class="maintenance3" cx="335.4" cy="210.6" r="1.1"></circle>
          <circle class="maintenance3" cx="339.6" cy="213.2" r="1.1"></circle>
          <circle class="maintenance3" cx="327.2" cy="205.6" r="1.1"></circle>
          <circle class="maintenance3" cx="331.6" cy="224.3" r="1.1"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="335.6" cy="226.5" r="1.1"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.7s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="339.8" cy="228.9" r="1.1"></circle>
          <circle class="maintenance3" cx="327.4" cy="222.4" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="331.2" cy="240.8" r="1.1"></circle>
          <circle class="maintenance3" cx="335.3" cy="242.7" r="1.1"></circle>
          <circle class="maintenance3" cx="339.3" cy="244.4" r="1.1"></circle>
          <circle class="maintenance3" cx="327" cy="239.2" r="1.1"></circle>
          <circle class="maintenance3" cx="331.3" cy="257.5" r="1.1"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="335.5" cy="259.2" r="1.1"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.6s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <g>
<circle id="svg-ico" class="maintenance2" cx="339.4" cy="260.5" r="1.1"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.9s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="327.3" cy="256.1" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="331.4" cy="273.5" r="1.1"></circle>
          <circle class="maintenance3" cx="335.4" cy="275" r="1.1"></circle>
          <circle class="maintenance3" cx="339.6" cy="276.2" r="1.1"></circle>
          <circle class="maintenance3" cx="327.1" cy="272.4" r="1.1"></circle>
          <circle class="maintenance3" cx="331.3" cy="290.1" r="1.1"></circle>
          <circle class="maintenance3" cx="335.5" cy="291" r="1.1"></circle>
          <circle class="maintenance3" cx="339.6" cy="291.9" r="1.1"></circle>
          <circle class="maintenance3" cx="327.2" cy="289.2" r="1.1"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="331.3" cy="306.9" r="1.1"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.8s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle id="svg-ico" class="maintenance2" cx="335.3" cy="307.4" r="1.1"></circle>
          <circle class="maintenance3" cx="339.6" cy="307.9" r="1.1"></circle>
          <circle class="maintenance3" cx="327.2" cy="306.5" r="1.1"></circle>
          <circle class="maintenance3" cx="331.2" cy="323.1" r="1.1"></circle>
          <circle class="maintenance3" cx="335.5" cy="323.1" r="1.1"></circle>
          <circle class="maintenance3" cx="339.6" cy="322.9" r="1.1"></circle>
          <circle class="maintenance3" cx="327.1" cy="323.2" r="1.1"></circle>
          <circle class="maintenance3" cx="331.1" cy="339.7" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="335.4" cy="339.4" r="1.1"></circle>
          <circle class="maintenance3" cx="339.5" cy="339" r="1.1"></circle>
          <circle class="maintenance3" cx="327.1" cy="340" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="331.3" cy="355.9" r="1.1"></circle>
          <circle class="maintenance3" cx="335.3" cy="355.3" r="1.1"></circle>
          <circle class="maintenance3" cx="339.5" cy="354.7" r="1.1"></circle>
          <circle class="maintenance3" cx="327.2" cy="356.5" r="1.1"></circle>
          <circle class="maintenance3" cx="331.3" cy="372.6" r="1.1"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="335.2" cy="371.5" r="1.1"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.6s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle id="svg-ico" class="maintenance2" cx="339.5" cy="370.7" r="1.1"></circle>
          <circle class="maintenance3" cx="327.2" cy="373.5" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="331.3" cy="388.8" r="1.1"></circle>
          <circle class="maintenance3" cx="335.3" cy="387.3" r="1.1"></circle>
          <circle class="maintenance3" cx="339.6" cy="386.1" r="1.1"></circle>
          <circle class="maintenance3" cx="327.1" cy="390.1" r="1.1"></circle>
          <circle class="maintenance3" cx="331.4" cy="405.2" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="335.3" cy="403.8" r="1.1"></circle>
          <circle class="maintenance3" cx="339.7" cy="402.5" r="1.1"></circle>
          <circle class="maintenance3" cx="327.1" cy="406.5" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="331.3" cy="421.1" r="1.1"></circle>
          <circle class="maintenance3" cx="335.3" cy="419.7" r="1.1"></circle>
          <circle class="maintenance3" cx="339.6" cy="418.4" r="1.1"></circle>
          <circle class="maintenance3" cx="327.1" cy="422.5" r="1.1"></circle>
          <circle class="maintenance3" cx="331.3" cy="437.2" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="335.2" cy="435.3" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="339.6" cy="433.4" r="1.1"></circle>
          <circle class="maintenance3" cx="327.1" cy="439" r="1.1"></circle>
          <rect x="684.1" y="53.2" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1468.7096 616.8322)" class="maintenance1" width="100.5" height="510.4"></rect>
          <polygon class="maintenance1" points="628.6,528 684.1,563.6 684.1,53.2 628.6,93.8 "></polygon>
          <polygon class="maintenance1" points="634,523.9 678.2,549.7 678.2,64.6 634,96 "></polygon>
          <polygon class="maintenance1" points="641.4,136.4 672.2,119.3 672.2,96 641.4,113.6 "></polygon>
          <polygon class="maintenance1" points="641.4,163.2 672.2,149.1 672.2,125.8 641.4,143.4 "></polygon>
          <polygon class="maintenance1" points="641.4,190.4 672.2,178.6 672.2,155.3 641.4,170.6 "></polygon>
          <polygon class="maintenance1" points="641.4,218.8 672.2,208.5 672.2,185.9 641.4,197.7 "></polygon>
          <polygon class="maintenance1" points="641.4,245.8 672.2,237.6 672.2,215.1 641.4,225.8 "></polygon>
          <polygon class="maintenance1" points="641.4,272.8 672.2,267.8 672.2,245.3 641.4,252.8 "></polygon>
          <polygon class="maintenance1" points="641.4,411.2 672.2,417.4 672.2,395.7 641.4,390.2 "></polygon>
          <polygon class="maintenance1" points="641.4,438.5 672.2,446.6 672.2,424.8 641.4,417.4 "></polygon>
          <polygon class="maintenance1" points="641.4,466.3 672.2,477.4 672.2,455.6 641.4,446.6 "></polygon>
          <polygon class="maintenance1" points="641.4,492.7 672.2,506.8 672.2,485 641.4,473.1 "></polygon>
          <polygon class="maintenance1" points="641.4,520.3 672.2,536.6 672.2,514.8 641.4,500.6 "></polygon>
          <circle id="svg-ico" class="maintenance2" cx="660.3" cy="114.5" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="653.1" cy="119.2" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="645.8" cy="123.8" r="2"></circle>
          <circle class="maintenance3" cx="667.7" cy="110.5" r="2"></circle>
          <circle class="maintenance3" cx="660" cy="143.5" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="652.8" cy="147.4" r="2"></circle>
          <circle class="maintenance3" cx="645.5" cy="151.6" r="2"></circle>
          <circle class="maintenance3" cx="667.4" cy="140.2" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="660.7" cy="172.6" r="2"></circle>
          <circle class="maintenance3" cx="653.4" cy="176" r="2"></circle>
          <circle class="maintenance3" cx="646.3" cy="179" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="668" cy="169.7" r="2"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="660.4" cy="202.1" r="2"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.6s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <g>
<circle id="svg-ico" class="maintenance2" cx="653" cy="205.1" r="2"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.5s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="646.1" cy="207.5" r="2"></circle>
          <circle class="maintenance3" cx="667.5" cy="199.7" r="2"></circle>
          <circle class="maintenance3" cx="660.3" cy="230.4" r="2"></circle>
          <circle class="maintenance3" cx="653.2" cy="233.1" r="2"></circle>
          <circle class="maintenance3" cx="645.8" cy="235.1" r="2"></circle>
          <circle class="maintenance3" cx="667.8" cy="228.4" r="2"></circle>
          <circle class="maintenance3" cx="660.5" cy="259.6" r="2"></circle>
          <circle class="maintenance3" cx="653" cy="261.2" r="2"></circle>
          <circle class="maintenance3" cx="645.8" cy="262.9" r="2"></circle>
          <circle class="maintenance3" cx="667.7" cy="258.1" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="660.4" cy="405.5" r="2"></circle>
          <circle class="maintenance3" cx="653.5" cy="403.5" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="645.9" cy="402.1" r="2"></circle>
          <circle class="maintenance3" cx="667.7" cy="407.1" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="660.4" cy="434" r="2"></circle>
          <circle class="maintenance3" cx="653.5" cy="431.5" r="2"></circle>
          <circle class="maintenance3" cx="645.7" cy="429.2" r="2"></circle>
          <circle class="maintenance3" cx="667.9" cy="436.3" r="2"></circle>
          <circle class="maintenance3" cx="660.3" cy="463.1" r="2"></circle>
          <circle class="maintenance3" cx="653.4" cy="460.5" r="2"></circle>
          <circle class="maintenance3" cx="645.7" cy="458.2" r="2"></circle>
          <circle class="maintenance3" cx="667.8" cy="465.4" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="660.3" cy="491.2" r="2"></circle>
          <circle class="maintenance3" cx="653.4" cy="488.6" r="2"></circle>
          <circle class="maintenance3" cx="645.7" cy="486.4" r="2"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="667.8" cy="493.5" r="2"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.7s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="660.4" cy="519.6" r="2"></circle>
          <circle class="maintenance3" cx="653.5" cy="516.2" r="2"></circle>
          <circle id="svg-ico" class="maintenance2" cx="645.8" cy="512.9" r="2"></circle>
          <circle class="maintenance3" cx="667.8" cy="522.7" r="2"></circle>
          <polyline class="maintenance1" points="628.6,117.5 600.1,117.5 600.1,508.9 628.6,508.9 "></polyline>
          <polygon class="maintenance1" points="557.5,481.6 600.1,508.9 600.1,117.5 557.5,148.7 "></polygon>
          <polygon class="maintenance1" points="561.7,478.5 595.6,498.3 595.6,126.3 561.7,150.4 "></polygon>
          <polygon class="maintenance1" points="567.3,181.3 591,168.2 591,150.4 567.3,163.9 "></polygon>
          <polygon class="maintenance1" points="567.3,201.9 591,191 591,173.2 567.3,186.7 "></polygon>
          <polygon class="maintenance1" points="567.3,222.7 591,213.7 591,195.9 567.3,207.6 "></polygon>
          <polygon class="maintenance1" points="567.3,244.5 591,236.6 591,219.3 567.3,228.3 "></polygon>
          <polygon class="maintenance1" points="567.3,265.2 591,259 591,241.7 567.3,249.9 "></polygon>
          <polygon class="maintenance1" points="567.3,286 591,282.1 591,264.8 567.3,270.6 "></polygon>
          <polygon class="maintenance1" points="567.3,307.3 591,305.1 591,287.8 567.3,292 "></polygon>
          <polygon class="maintenance1" points="567.3,328.4 591,327.9 591,310.6 567.3,313.1 "></polygon>
          <polygon class="maintenance1" points="567.3,349.3 591,351.2 591,333.9 567.3,333.9 "></polygon>
          <polygon class="maintenance1" points="567.3,370.8 591,374 591,357.3 567.3,354.7 "></polygon>
          <polygon class="maintenance1" points="567.3,392.1 591,396.8 591,380.1 567.3,376 "></polygon>
          <polygon class="maintenance1" points="567.3,413 591,419.2 591,402.5 567.3,396.8 "></polygon>
          <polygon class="maintenance1" points="567.3,434.3 591,442.8 591,426.1 567.3,419.2 "></polygon>
          <polygon class="maintenance1" points="567.3,454.6 591,465.4 591,448.7 567.3,439.5 "></polygon>
          <polygon class="maintenance1" points="567.3,475.7 591,488.2 591,471.5 567.3,460.6 "></polygon>
          <circle class="maintenance3" cx="581.9" cy="164.5" r="1.5"></circle>
          <circle class="maintenance3" cx="576.3" cy="168.1" r="1.5"></circle>
          <circle class="maintenance3" cx="570.8" cy="171.7" r="1.5"></circle>
          <circle class="maintenance3" cx="587.5" cy="161.5" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="581.6" cy="186.8" r="1.5"></circle>
          <circle class="maintenance3" cx="576.1" cy="189.7" r="1.5"></circle>
          <circle class="maintenance3" cx="570.5" cy="193" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="587.3" cy="184.2" r="1.5"></circle>
          <circle class="maintenance3" cx="582.1" cy="209.1" r="1.5"></circle>
          <circle class="maintenance3" cx="576.5" cy="211.7" r="1.5"></circle>
          <circle class="maintenance3" cx="571.1" cy="214" r="1.5"></circle>
          <circle class="maintenance3" cx="587.8" cy="206.9" r="1.5"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="581.9" cy="231.7" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.8s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <g>
<circle id="svg-ico" class="maintenance2" cx="576.3" cy="234" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.6s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="571" cy="235.8" r="1.5"></circle>
          <circle class="maintenance3" cx="587.3" cy="229.9" r="1.5"></circle>
          <circle class="maintenance3" cx="581.8" cy="253.4" r="1.5"></circle>
          <circle class="maintenance3" cx="576.4" cy="255.4" r="1.5"></circle>
          <circle class="maintenance3" cx="570.7" cy="257" r="1.5"></circle>
          <circle class="maintenance3" cx="587.6" cy="251.9" r="1.5"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="582" cy="275.8" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.7s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle id="svg-ico" class="maintenance2" cx="576.2" cy="277" r="1.5"></circle>
          <circle class="maintenance3" cx="570.8" cy="278.3" r="1.5"></circle>
          <circle class="maintenance3" cx="587.5" cy="274.6" r="1.5"></circle>
          <circle class="maintenance3" cx="581.9" cy="298.7" r="1.5"></circle>
          <circle class="maintenance3" cx="576.6" cy="299.3" r="1.5"></circle>
          <circle class="maintenance3" cx="570.8" cy="300" r="1.5"></circle>
          <circle class="maintenance3" cx="587.5" cy="298.1" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="582.1" cy="320.5" r="1.5"></circle>
          <circle class="maintenance3" cx="576.2" cy="320.5" r="1.5"></circle>
          <circle class="maintenance3" cx="570.6" cy="320.3" r="1.5"></circle>
          <circle class="maintenance3" cx="587.6" cy="320.7" r="1.5"></circle>
          <circle class="maintenance3" cx="582.2" cy="343.1" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="576.5" cy="342.7" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="570.8" cy="342.2" r="1.5"></circle>
          <circle class="maintenance3" cx="587.6" cy="343.5" r="1.5"></circle>
          <circle class="maintenance3" cx="582" cy="365" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="576.6" cy="364.2" r="1.5"></circle>
          <circle class="maintenance3" cx="570.8" cy="363.4" r="1.5"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="587.5" cy="365.9" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.5s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="581.9" cy="387.7" r="1.5"></circle>

          <g>
<circle class="maintenance3" cx="576.6" cy="386.2" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.6s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="570.8" cy="385.1" r="1.5"></circle>
          <circle class="maintenance3" cx="587.5" cy="388.9" r="1.5"></circle>
          <circle class="maintenance3" cx="581.9" cy="409.6" r="1.5"></circle>
          <circle class="maintenance3" cx="576.6" cy="407.6" r="1.5"></circle>
          <circle class="maintenance3" cx="570.6" cy="405.9" r="1.5"></circle>
          <circle class="maintenance3" cx="587.6" cy="411.3" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="581.9" cy="431.8" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="576.5" cy="429.9" r="1.5"></circle>
          <circle class="maintenance3" cx="570.6" cy="428.1" r="1.5"></circle>
          <circle class="maintenance3" cx="587.6" cy="433.6" r="1.5"></circle>
          <circle class="maintenance3" cx="581.9" cy="453.4" r="1.5"></circle>
          <circle class="maintenance3" cx="576.5" cy="451.4" r="1.5"></circle>
          <circle class="maintenance3" cx="570.6" cy="449.7" r="1.5"></circle>
          <circle id="svg-ico" class="maintenance2" cx="587.6" cy="455.2" r="1.5"></circle>
          <circle class="maintenance3" cx="581.9" cy="475.2" r="1.5"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="576.6" cy="472.6" r="1.5"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.3s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle id="svg-ico" class="maintenance2" cx="570.7" cy="470.1" r="1.5"></circle>
          <circle class="maintenance3" cx="587.6" cy="477.6" r="1.5"></circle>
          <polyline class="maintenance4" points="557.5,173.2 528.3,173.2 528.3,460.6 557.5,460.6 "></polyline>
          <polygon class="maintenance4" points="497.1,440.6 528.3,460.6 528.3,173.2 497.1,196.1 "></polygon>
          <polygon class="maintenance4" points="500.1,438.3 525,452.8 525,179.6 500.1,197.3 "></polygon>
          <polygon class="maintenance4" points="504.3,220.1 521.6,210.4 521.6,197.3 504.3,207.2 "></polygon>
          <polygon class="maintenance4" points="504.3,235.2 521.6,227.2 521.6,214.1 504.3,224 "></polygon>
          <polygon class="maintenance4" points="504.3,250.5 521.6,243.8 521.6,230.7 504.3,239.3 "></polygon>
          <polygon class="maintenance4" points="504.3,266.5 521.6,260.6 521.6,247.9 504.3,254.6 "></polygon>
          <polygon class="maintenance4" points="504.3,281.7 521.6,277.1 521.6,264.3 504.3,270.4 "></polygon>
          <polygon class="maintenance4" points="504.3,296.9 521.6,294.1 521.6,281.4 504.3,285.6 "></polygon>
          <polygon class="maintenance4" points="504.3,312.6 521.6,310.9 521.6,298.2 504.3,301.3 "></polygon>
          <polygon class="maintenance4" points="504.3,328.1 521.6,327.7 521.6,314.9 504.3,316.8 "></polygon>
          <polygon class="maintenance4" points="504.3,343.4 521.6,344.8 521.6,332.1 504.3,332.1 "></polygon>
          <polygon class="maintenance4" points="504.3,359.2 521.6,361.6 521.6,349.3 504.3,347.3 "></polygon>
          <polygon class="maintenance4" points="504.3,374.8 521.6,378.3 521.6,366 504.3,363 "></polygon>
          <polygon class="maintenance4" points="504.3,390.2 521.6,394.7 521.6,382.5 504.3,378.3 "></polygon>
          <polygon class="maintenance4" points="504.3,405.8 521.6,412.1 521.6,399.8 504.3,394.7 "></polygon>
          <polygon class="maintenance4" points="504.3,420.7 521.6,428.6 521.6,416.4 504.3,409.6 "></polygon>
          <polygon class="maintenance4" points="504.3,436.2 521.6,445.4 521.6,433.1 504.3,425.1 "></polygon>
          <circle class="maintenance3" cx="515" cy="207.7" r="1.1"></circle>
          <circle class="maintenance3" cx="510.9" cy="210.4" r="1.1"></circle>
          <circle class="maintenance3" cx="506.8" cy="213" r="1.1"></circle>
          <circle class="maintenance3" cx="519.1" cy="205.5" r="1.1"></circle>
          <circle class="maintenance3" cx="514.8" cy="224.1" r="1.1"></circle>
          <circle class="maintenance3" cx="510.7" cy="226.2" r="1.1"></circle>
          <circle class="maintenance3" cx="506.6" cy="228.6" r="1.1"></circle>
          <circle class="maintenance3" cx="518.9" cy="222.2" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="515.2" cy="240.4" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="511" cy="242.4" r="1.1"></circle>
          <circle class="maintenance3" cx="507" cy="244.1" r="1.1"></circle>
          <circle class="maintenance3" cx="519.3" cy="238.8" r="1.1"></circle>
          <circle class="maintenance3" cx="515" cy="257" r="1.1"></circle>
          <circle class="maintenance3" cx="510.8" cy="258.7" r="1.1"></circle>
          <circle class="maintenance3" cx="506.9" cy="260.1" r="1.1"></circle>
          <circle class="maintenance3" cx="519" cy="255.7" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="514.9" cy="273" r="1.1"></circle>
          <circle class="maintenance3" cx="510.9" cy="274.5" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="506.8" cy="275.6" r="1.1"></circle>
          <circle class="maintenance3" cx="519.2" cy="271.8" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="515" cy="289.4" r="1.1"></circle>
          <circle class="maintenance3" cx="510.8" cy="290.3" r="1.1"></circle>
          <circle class="maintenance3" cx="506.8" cy="291.3" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="519.1" cy="288.6" r="1.1"></circle>
          <circle class="maintenance3" cx="515" cy="306.2" r="1.1"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="511.1" cy="306.7" r="1.1"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.4s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="506.8" cy="307.2" r="1.1"></circle>
          <circle class="maintenance3" cx="519.1" cy="305.8" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="515.1" cy="322.3" r="1.1"></circle>
          <circle class="maintenance3" cx="510.8" cy="322.3" r="1.1"></circle>
          <circle class="maintenance3" cx="506.7" cy="322.1" r="1.1"></circle>
          <circle class="maintenance3" cx="519.2" cy="322.4" r="1.1"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="515.2" cy="338.8" r="1.1"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.6s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <g>
<circle id="svg-ico" class="maintenance2" cx="511" cy="338.5" r="1.1"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.5s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="506.9" cy="338.2" r="1.1"></circle>
          <circle class="maintenance3" cx="519.2" cy="339.1" r="1.1"></circle>
          <circle class="maintenance3" cx="515.1" cy="354.9" r="1.1"></circle>
          <circle class="maintenance3" cx="511.1" cy="354.4" r="1.1"></circle>
          <circle class="maintenance3" cx="506.8" cy="353.8" r="1.1"></circle>
          <circle class="maintenance3" cx="519.1" cy="355.6" r="1.1"></circle>
          <circle class="maintenance3" cx="515" cy="371.6" r="1.1"></circle>
          <circle class="maintenance3" cx="511.1" cy="370.5" r="1.1"></circle>
          <circle class="maintenance3" cx="506.8" cy="369.7" r="1.1"></circle>
          <circle class="maintenance3" cx="519.1" cy="372.5" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="515" cy="387.6" r="1.1"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="511.1" cy="386.2" r="1.1"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.7s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="506.7" cy="384.9" r="1.1"></circle>
          <circle class="maintenance3" cx="519.2" cy="389" r="1.1"></circle>

          <g>
<circle id="svg-ico" class="maintenance2" cx="514.9" cy="404" r="1.1"></circle>
            <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="0.5s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
</animate>
</g>

          <circle class="maintenance3" cx="511.1" cy="402.6" r="1.1"></circle>
          <circle class="maintenance3" cx="506.7" cy="401.3" r="1.1"></circle>
          <circle class="maintenance3" cx="519.2" cy="405.3" r="1.1"></circle>
          <circle class="maintenance3" cx="514.9" cy="419.9" r="1.1"></circle>
          <circle class="maintenance3" cx="511.1" cy="418.4" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="506.7" cy="417.2" r="1.1"></circle>
          <circle class="maintenance3" cx="519.2" cy="421.2" r="1.1"></circle>
          <circle class="maintenance3" cx="515" cy="435.8" r="1.1"></circle>
          <circle id="svg-ico" class="maintenance2" cx="511.1" cy="433.9" r="1.1"></circle>
          <circle class="maintenance3" cx="506.8" cy="432.1" r="1.1"></circle>
          <circle class="maintenance3" cx="519.2" cy="437.6" r="1.1"></circle>
          <line id="svg-concept" class="maintenance5" x1="644.3" y1="272.4" x2="644.3" y2="323.3"></line>
          <line id="svg-concept" class="maintenance5" x1="648.9" y1="272.4" x2="648.9" y2="360"></line>
          <path class="maintenance1" d="M651.6,272.4c0,19.9,12,12,11.5-2.4"></path>
          <path id="svg-concept" class="maintenance5" d="M659.7,270.7c0.7,18.8,10.2,12.1,8.5-1.9"></path>
          <line class="maintenance1" x1="641.4" y1="389.2" x2="678.2" y2="389.2"></line>
          <line id="svg-concept" class="maintenance5" x1="275.3" y1="221.4" x2="275.3" y2="271.2"></line>
          <line class="maintenance1" x1="268" y1="218.7" x2="268" y2="247.7"></line>
          <path id="svg-concept" class="maintenance5" d="M261,215.9c0,19.2,11.4,21.6,11.4,4.4"></path>
          <line class="maintenance1" x1="173.9" y1="346.6" x2="184.9" y2="346.3"></line>
          <line class="maintenance1" x1="173.9" y1="377.2" x2="192.6" y2="375.5"></line>
          <g>
	<g>
		<path id="svg-concept" class="maintenance6" d="M377.4,548L377.4,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C378.6,547.5,378.1,548,377.4,548z"></path>
    <path id="svg-concept" class="maintenance6" d="M374.1,557.2L374.1,557.2c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C375.8,556.5,375.1,557.2,374.1,557.2z"></path>
    <path id="svg-concept" class="maintenance6" d="M419.4,548L419.4,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C420.6,547.5,420.1,548,419.4,548z"></path>
    <path id="svg-concept" class="maintenance6" d="M416.1,557.2L416.1,557.2c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C417.8,556.5,417,557.2,416.1,557.2z"></path>
    <path id="svg-concept" class="maintenance6" d="M461.4,548L461.4,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C462.6,547.5,462.1,548,461.4,548z"></path>
    <path id="svg-concept" class="maintenance6" d="M458.1,557.2L458.1,557.2c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C459.8,556.5,459,557.2,458.1,557.2z"></path>
    <path id="svg-concept" class="maintenance6" d="M491.6,550.2c0,3.6-2.9,6.5-6.4,6.5c-3.6,0-6.4-2.9-6.4-6.5c0-3.6,2.9-6.4,6.4-6.4    C488.7,543.8,491.6,546.7,491.6,550.2z"></path>
    <path id="svg-concept" class="maintenance6" d="M487.5,550.2c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4    C486.4,547.8,487.5,548.9,487.5,550.2z"></path>
    <line id="svg-concept" class="maintenance6" x1="491.6" y1="550.2" x2="487.5" y2="550.2"></line>
    <line id="svg-concept" class="maintenance6" x1="482.7" y1="550.2" x2="478.7" y2="550.2"></line>
    <path class="maintenance1" d="M515,559.9c0,3-2.2,5.4-5,5.4H334c-2.7,0-5-2.4-5-5.4v-19.3c0-3,2.2-5.4,5-5.4h176c2.7,0,5,2.4,5,5.4V559.9z"></path>
    <line class="maintenance7" x1="339.3" y1="535.2" x2="339.3" y2="565.2"></line>
    <line class="maintenance7" x1="504.7" y1="535.2" x2="504.7" y2="565.3"></line>
    <path class="maintenance7" d="M374.6,559.6h-27.9c-2.2,0-4-1.8-4-4v0c0-2.2,1.8-4,4-4h27.9c2.2,0,4,1.8,4,4v0    C378.6,557.8,376.8,559.6,374.6,559.6z"></path>
    <path class="maintenance7" d="M346.9,548L346.9,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C348.1,547.5,347.6,548,346.9,548z"></path>
    <path class="maintenance7" d="M351.8,548L351.8,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C353,547.5,352.5,548,351.8,548z"></path>
    <path class="maintenance7" d="M358.7,548L358.7,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C360,547.5,359.4,548,358.7,548z"></path>
    <path class="maintenance7" d="M346.9,542.7L346.9,542.7c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C348.1,542.2,347.6,542.7,346.9,542.7z"></path>
    <path class="maintenance7" d="M351.8,542.7L351.8,542.7c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C353,542.2,352.5,542.7,351.8,542.7z"></path>
    <path class="maintenance7" d="M377.4,542.7L377.4,542.7c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C378.6,542.2,378,542.7,377.4,542.7z"></path>
    <path class="maintenance7" d="M363.7,548L363.7,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C364.9,547.5,364.3,548,363.7,548z"></path>
    <path class="maintenance7" d="M368.5,548L368.5,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C369.7,547.5,369.2,548,368.5,548z"></path>
    <path class="maintenance7" d="M368.6,548L368.6,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C369.8,547.5,369.2,548,368.6,548z"></path>
    <path class="maintenance7" d="M368.6,542.7l-9.4,0c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2l9.4,0c0.7,0,1.2,0.5,1.2,1.2v0    C369.8,542.2,369.2,542.7,368.6,542.7z"></path>
    <line class="maintenance7" x1="355.4" y1="551.5" x2="355.4" y2="559.6"></line>
    <path class="maintenance7" d="M367.9,557.2L367.9,557.2c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C369.6,556.5,368.8,557.2,367.9,557.2z"></path>
    <path class="maintenance7" d="M416.6,559.6l-27.9,0c-2.2,0-4-1.8-4-4v0c0-2.2,1.8-4,4-4h27.9c2.2,0,4,1.8,4,4v0    C420.6,557.8,418.8,559.6,416.6,559.6z"></path>
    <path class="maintenance7" d="M388.9,548L388.9,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C390.1,547.5,389.6,548,388.9,548z"></path>
    <path class="maintenance7" d="M393.8,548L393.8,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C395,547.5,394.5,548,393.8,548z"></path>
    <path class="maintenance7" d="M400.7,548L400.7,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C401.9,547.5,401.4,548,400.7,548z"></path>
    <path class="maintenance7" d="M388.9,542.7L388.9,542.7c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C390.1,542.2,389.6,542.7,388.9,542.7z"></path>
    <path class="maintenance7" d="M393.8,542.7L393.8,542.7c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C395,542.2,394.5,542.7,393.8,542.7z"></path>
    <path class="maintenance7" d="M419.3,542.7L419.3,542.7c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C420.6,542.2,420,542.7,419.3,542.7z"></path>
    <path class="maintenance7" d="M405.6,548L405.6,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C406.9,547.5,406.3,548,405.6,548z"></path>
    <path class="maintenance7" d="M410.5,548L410.5,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C411.7,547.5,411.2,548,410.5,548z"></path>
    <path class="maintenance7" d="M410.6,548L410.6,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C411.8,547.5,411.2,548,410.6,548z"></path>
    <path class="maintenance7" d="M410.6,542.7l-9.4,0c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2l9.4,0c0.7,0,1.2,0.5,1.2,1.2v0    C411.8,542.2,411.2,542.7,410.6,542.7z"></path>
    <line class="maintenance7" x1="397.4" y1="551.5" x2="397.4" y2="559.6"></line>
    <path class="maintenance7" d="M409.9,557.2L409.9,557.2c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C411.6,556.5,410.8,557.2,409.9,557.2z"></path>
    <path class="maintenance7" d="M458.6,559.6h-27.9c-2.2,0-4-1.8-4-4v0c0-2.2,1.8-4,4-4h27.9c2.2,0,4,1.8,4,4v0    C462.6,557.8,460.8,559.6,458.6,559.6z"></path>
    <path class="maintenance7" d="M430.9,548L430.9,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C432.1,547.5,431.5,548,430.9,548z"></path>
    <path class="maintenance7" d="M435.8,548L435.8,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C437,547.5,436.5,548,435.8,548z"></path>
    <path class="maintenance7" d="M442.7,548L442.7,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C443.9,547.5,443.4,548,442.7,548z"></path>
    <path class="maintenance7" d="M430.9,542.7L430.9,542.7c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C432.1,542.2,431.5,542.7,430.9,542.7z"></path>
    <path class="maintenance7" d="M435.8,542.7L435.8,542.7c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C437,542.2,436.5,542.7,435.8,542.7z"></path>
    <path class="maintenance7" d="M461.3,542.7L461.3,542.7c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C462.6,542.2,462,542.7,461.3,542.7z"></path>
    <path class="maintenance7" d="M447.6,548L447.6,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C448.9,547.5,448.3,548,447.6,548z"></path>
    <path class="maintenance7" d="M452.5,548L452.5,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C453.7,547.5,453.1,548,452.5,548z"></path>
    <path class="maintenance7" d="M452.6,548L452.6,548c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C453.8,547.5,453.2,548,452.6,548z"></path>
    <path id="svg-concept" class="maintenance6" d="M500.6,555.9c0,1-0.9,1.9-1.9,1.9c-1,0-1.9-0.9-1.9-1.9c0-1,0.8-1.9,1.9-1.9    C499.7,554,500.6,554.9,500.6,555.9z"></path>
    <path class="maintenance7" d="M498.7,550.5L498.7,550.5c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C499.9,550,499.3,550.5,498.7,550.5z"></path>
    <path class="maintenance7" d="M498.7,545.1L498.7,545.1c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C499.9,544.6,499.3,545.1,498.7,545.1z"></path>
    <path class="maintenance7" d="M452.5,542.7l-9.4,0c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2l9.4,0c0.7,0,1.2,0.5,1.2,1.2v0    C453.8,542.2,453.2,542.7,452.5,542.7z"></path>
    <line class="maintenance7" x1="439.4" y1="551.5" x2="439.4" y2="559.6"></line>
    <path class="maintenance7" d="M451.9,557.2L451.9,557.2c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C453.6,556.5,452.8,557.2,451.9,557.2z"></path>
    <line class="maintenance7" x1="382.1" y1="535.2" x2="382.1" y2="565.3"></line>
    <line class="maintenance7" x1="334.9" y1="545.3" x2="334.9" y2="554.2"></line>
    <line class="maintenance7" x1="509.3" y1="545.3" x2="509.3" y2="554.2"></line>
    <line class="maintenance7" x1="423.8" y1="535.2" x2="423.8" y2="565.3"></line>
    <line class="maintenance7" x1="465.5" y1="535.2" x2="465.5" y2="565.3"></line>
    <line class="maintenance7" x1="507.8" y1="531.7" x2="510" y2="535.2"></line>
    <line class="maintenance7" x1="334" y1="535.2" x2="345" y2="519.8"></line>
    <path class="maintenance7" d="M503.7,565.3"></path>
    <path class="maintenance7" d="M341.1,565.3"></path>
	</g>
</g>
          <g>
	<g>
		<path id="svg-concept" class="maintenance6" d="M396.9,511.6L396.9,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C398.1,511,397.6,511.6,396.9,511.6z"></path>
    <path id="svg-concept" class="maintenance6" d="M393.6,520.8L393.6,520.8c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C395.3,520,394.5,520.8,393.6,520.8z"></path>
    <path id="svg-concept" class="maintenance6" d="M438.9,511.6L438.9,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C440.1,511,439.6,511.6,438.9,511.6z"></path>
    <path id="svg-concept" class="maintenance6" d="M435.6,520.8L435.6,520.8c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C437.3,520,436.5,520.8,435.6,520.8z"></path>
    <path id="svg-concept" class="maintenance6" d="M480.9,511.6L480.9,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C482.1,511,481.5,511.6,480.9,511.6z"></path>
    <path id="svg-concept" class="maintenance6" d="M477.6,520.8L477.6,520.8c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C479.3,520,478.5,520.8,477.6,520.8z"></path>
    <path id="svg-concept" class="maintenance6" d="M511,513.8c0,3.6-2.9,6.5-6.4,6.5c-3.6,0-6.4-2.9-6.4-6.5c0-3.6,2.9-6.4,6.4-6.4    C508.1,507.3,511,510.2,511,513.8z"></path>
    <path id="svg-concept" class="maintenance6" d="M507,513.8c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4C505.9,511.4,507,512.5,507,513.8z"></path>
    <line id="svg-concept" class="maintenance6" x1="511" y1="513.8" x2="507" y2="513.8"></line>
    <line id="svg-concept" class="maintenance6" x1="502.2" y1="513.8" x2="498.1" y2="513.8"></line>
    <path class="maintenance1" d="M534.4,523.4c0,3-2.2,5.4-5,5.4l-176,0c-2.7,0-5-2.4-5-5.4v-19.3c0-3,2.2-5.4,5-5.4h176c2.7,0,5,2.4,5,5.4    V523.4z"></path>
    <line class="maintenance7" x1="358.8" y1="498.7" x2="358.8" y2="528.7"></line>
    <line class="maintenance7" x1="524.2" y1="498.7" x2="524.2" y2="528.9"></line>
    <path class="maintenance7" d="M394.1,523.1h-27.9c-2.2,0-4-1.8-4-4v0c0-2.2,1.8-4,4-4h27.9c2.2,0,4,1.8,4,4v0    C398.1,521.3,396.3,523.1,394.1,523.1z"></path>
    <path class="maintenance7" d="M366.4,511.6L366.4,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C367.6,511,367,511.6,366.4,511.6z"></path>
    <path class="maintenance7" d="M371.3,511.6L371.3,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C372.5,511,372,511.6,371.3,511.6z"></path>
    <path class="maintenance7" d="M378.2,511.6L378.2,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C379.4,511,378.9,511.6,378.2,511.6z"></path>
    <path class="maintenance7" d="M366.4,506.3L366.4,506.3c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C367.6,505.7,367,506.3,366.4,506.3z"></path>
    <path class="maintenance7" d="M371.3,506.3L371.3,506.3c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C372.5,505.7,372,506.3,371.3,506.3z"></path>
    <path class="maintenance7" d="M396.8,506.3L396.8,506.3c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C398,505.7,397.5,506.3,396.8,506.3z"></path>
    <path class="maintenance7" d="M383.1,511.6L383.1,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C384.3,511,383.8,511.6,383.1,511.6z"></path>
    <path class="maintenance7" d="M388,511.6L388,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C389.2,511,388.6,511.6,388,511.6z"></path>
    <path class="maintenance7" d="M388,511.6L388,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C389.3,511,388.7,511.6,388,511.6z"></path>
    <path class="maintenance7" d="M388,506.3l-9.4,0c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2l9.4,0c0.7,0,1.2,0.5,1.2,1.2v0    C389.3,505.7,388.7,506.3,388,506.3z"></path>
    <line class="maintenance7" x1="374.9" y1="515.1" x2="374.9" y2="523.1"></line>
    <path class="maintenance7" d="M387.4,520.8L387.4,520.8c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C389,520,388.3,520.8,387.4,520.8z"></path>
    <path class="maintenance7" d="M436.1,523.1h-27.9c-2.2,0-4-1.8-4-4v0c0-2.2,1.8-4,4-4l27.9,0c2.2,0,4,1.8,4,4v0    C440.1,521.3,438.3,523.1,436.1,523.1z"></path>
    <path class="maintenance7" d="M408.4,511.6L408.4,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C409.6,511,409,511.6,408.4,511.6z"></path>
    <path class="maintenance7" d="M413.3,511.6L413.3,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C414.5,511,413.9,511.6,413.3,511.6z"></path>
    <path class="maintenance7" d="M420.2,511.6L420.2,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C421.4,511,420.9,511.6,420.2,511.6z"></path>
    <path class="maintenance7" d="M408.4,506.3L408.4,506.3c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C409.6,505.7,409,506.3,408.4,506.3z"></path>
    <path class="maintenance7" d="M413.3,506.3L413.3,506.3c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C414.5,505.7,413.9,506.3,413.3,506.3z"></path>
    <path class="maintenance7" d="M438.8,506.3L438.8,506.3c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C440,505.7,439.5,506.3,438.8,506.3z"></path>
    <path class="maintenance7" d="M425.1,511.6L425.1,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C426.3,511,425.8,511.6,425.1,511.6z"></path>
    <path class="maintenance7" d="M429.9,511.6L429.9,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C431.2,511,430.6,511.6,429.9,511.6z"></path>
    <path class="maintenance7" d="M430,511.6L430,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C431.2,511,430.7,511.6,430,511.6z"></path>
    <path class="maintenance7" d="M430,506.3l-9.4,0c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2l9.4,0c0.7,0,1.2,0.5,1.2,1.2v0    C431.2,505.7,430.7,506.3,430,506.3z"></path>
    <line class="maintenance7" x1="416.8" y1="515.1" x2="416.8" y2="523.1"></line>
    <path class="maintenance7" d="M429.4,520.8L429.4,520.8c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C431,520,430.3,520.8,429.4,520.8z"></path>
    <path class="maintenance7" d="M478.1,523.1h-27.9c-2.2,0-4-1.8-4-4v0c0-2.2,1.8-4,4-4h27.9c2.2,0,4,1.8,4,4v0    C482.1,521.3,480.3,523.1,478.1,523.1z"></path>
    <path class="maintenance7" d="M450.3,511.6L450.3,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C451.6,511,451,511.6,450.3,511.6z"></path>
    <path class="maintenance7" d="M455.3,511.6L455.3,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C456.5,511,455.9,511.6,455.3,511.6z"></path>
    <path class="maintenance7" d="M462.2,511.6L462.2,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C463.4,511,462.9,511.6,462.2,511.6z"></path>
    <path class="maintenance7" d="M450.3,506.3L450.3,506.3c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C451.6,505.7,451,506.3,450.3,506.3z"></path>
    <path class="maintenance7" d="M455.3,506.3L455.3,506.3c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C456.5,505.7,455.9,506.3,455.3,506.3z"></path>
    <path class="maintenance7" d="M480.8,506.3L480.8,506.3c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C482,505.7,481.5,506.3,480.8,506.3z"></path>
    <path class="maintenance7" d="M467.1,511.6L467.1,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C468.3,511,467.8,511.6,467.1,511.6z"></path>
    <path class="maintenance7" d="M471.9,511.6L471.9,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C473.2,511,472.6,511.6,471.9,511.6z"></path>
    <path class="maintenance7" d="M472,511.6L472,511.6c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C473.2,511,472.7,511.6,472,511.6z"></path>
    <path id="svg-concept" class="maintenance6" d="M520,519.5c0,1-0.9,1.9-1.9,1.9c-1,0-1.9-0.9-1.9-1.9c0-1,0.8-1.9,1.9-1.9C519.2,517.6,520,518.4,520,519.5z"></path>
    <path class="maintenance7" d="M518.1,514.1L518.1,514.1c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C519.4,513.6,518.8,514.1,518.1,514.1z"></path>
    <path class="maintenance7" d="M518.1,508.7L518.1,508.7c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0    C519.4,508.1,518.8,508.7,518.1,508.7z"></path>
    <path class="maintenance7" d="M472,506.3l-9.4,0c-0.7,0-1.2-0.5-1.2-1.2v0c0-0.7,0.5-1.2,1.2-1.2l9.4,0c0.7,0,1.2,0.5,1.2,1.2v0    C473.2,505.7,472.7,506.3,472,506.3z"></path>
    <line class="maintenance7" x1="458.8" y1="515.1" x2="458.8" y2="523.1"></line>
    <path class="maintenance7" d="M471.3,520.8L471.3,520.8c-0.9,0-1.7-0.8-1.7-1.7v0c0-0.9,0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7v0    C473,520,472.3,520.8,471.3,520.8z"></path>
    <line class="maintenance7" x1="401.5" y1="498.7" x2="401.5" y2="528.9"></line>
    <line class="maintenance7" x1="389.3" y1="470.7" x2="383.2" y2="470.7"></line>
    <line class="maintenance7" x1="387.8" y1="473" x2="381.9" y2="473"></line>
    <line class="maintenance7" x1="386.3" y1="475.5" x2="380.4" y2="475.5"></line>
    <line class="maintenance7" x1="384.7" y1="478" x2="378.9" y2="478"></line>
    <line class="maintenance7" x1="383.2" y1="480.4" x2="377.3" y2="480.4"></line>
    <line class="maintenance7" x1="354.3" y1="508.8" x2="354.3" y2="517.8"></line>
    <line class="maintenance7" x1="528.7" y1="508.8" x2="528.7" y2="517.8"></line>
    <line class="maintenance7" x1="443.2" y1="498.7" x2="443.2" y2="528.9"></line>
    <line class="maintenance7" x1="485" y1="498.7" x2="485" y2="528.9"></line>
    <path class="maintenance7" d="M353.5,498.7l22.5-31.5c1.3-2.1,3.4-3.8,7.5-3.8h110.6c4.1,0,5.9,1.5,7.5,3.8l27.8,31.5"></path>
    <path class="maintenance7" d="M523.2,528.9"></path>
    <path class="maintenance7" d="M360.6,528.9"></path>
    <path class="maintenance7" d="M472.5,477.9h23.1c1.4,0,2.9-1.2,2.5-2.5l-0.9-2.4c-0.5-1.7-1.7-2.4-3.1-2.4h-21.6c-1.4,0-2.5,1.1-2.5,2.5    v2.3C470,476.8,471.1,477.9,472.5,477.9z"></path>
	</g>
</g>
          <g>
	<path class="maintenance7" d="M465.7,179.6c-0.6-0.8-1-1.8-1.1-2.9c-0.4-3.2,2-6.2,5.2-6.5c2.2-0.2,4.2,0.7,5.4,2.4"></path>
            <line id="svg-concept" class="maintenance6" x1="473.1" y1="176" x2="470.5" y2="176.1"></line>
            <line class="maintenance7" x1="472.3" y1="170.5" x2="471.9" y2="171.7"></line>
            <line id="svg-concept" class="maintenance6" x1="475.2" y1="172.6" x2="474.1" y2="173.4"></line>
            <line id="svg-concept" class="maintenance6" x1="476.4" y1="176" x2="475" y2="176"></line>
            <line id="svg-concept" class="maintenance6" x1="475.3" y1="179.5" x2="474.1" y2="178.7"></line>
            <line class="maintenance7" x1="464.5" y1="176.1" x2="465.9" y2="175.9"></line>
            <line class="maintenance7" x1="465.7" y1="172.6" x2="466.8" y2="173.4"></line>
            <line class="maintenance7" x1="468.6" y1="170.5" x2="469.1" y2="171.8"></line>
            <line class="maintenance7" x1="465.7" y1="179.6" x2="466.7" y2="178.7"></line>
            <path id="svg-concept" class="maintenance8" d="M470.7,176.1c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2   C470.6,175.9,470.7,176,470.7,176.1z"></path>
            <path class="maintenance7" d="M465.2,164.6c-0.6-0.9-1.1-1.9-1.2-3c-0.4-3.4,2.1-6.5,5.5-6.9c2.3-0.3,4.4,0.8,5.7,2.5"></path>
            <line id="svg-concept" class="maintenance6" x1="469.3" y1="158.2" x2="470.2" y2="160.9"></line>
            <line class="maintenance7" x1="472.1" y1="155" x2="471.5" y2="156.4"></line>
            <line id="svg-concept" class="maintenance6" x1="475.2" y1="157.2" x2="473.8" y2="158"></line>
            <line id="svg-concept" class="maintenance6" x1="476.4" y1="160.8" x2="474.5" y2="160.8"></line>
            <line id="svg-concept" class="maintenance6" x1="475.2" y1="164.4" x2="474.1" y2="163.4"></line>
            <line class="maintenance7" x1="463.9" y1="160.9" x2="465.4" y2="160.9"></line>
            <line class="maintenance7" x1="465.1" y1="157.2" x2="466.1" y2="158"></line>
            <line class="maintenance7" x1="468.2" y1="155" x2="468.7" y2="156.3"></line>
            <line class="maintenance7" x1="465.1" y1="164.6" x2="466.2" y2="163.8"></line>
            <path id="svg-concept" class="maintenance8" d="M470.4,160.9c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2   C470.3,160.7,470.4,160.8,470.4,160.9z"></path>
            <path class="maintenance7" d="M464.9,149.2c-0.6-0.9-1.1-1.9-1.2-3.1c-0.4-3.5,2.1-6.6,5.6-7c2.3-0.3,4.5,0.8,5.8,2.5"></path>
            <line id="svg-concept" class="maintenance6" x1="471.6" y1="143.2" x2="470.1" y2="145.5"></line>
            <line class="maintenance7" x1="472" y1="139.4" x2="471.4" y2="140.5"></line>
            <line id="svg-concept" class="maintenance6" x1="475.1" y1="141.7" x2="474.2" y2="142.4"></line>
            <line id="svg-concept" class="maintenance6" x1="476.4" y1="145.4" x2="474.9" y2="145.4"></line>
            <line id="svg-concept" class="maintenance6" x1="475.2" y1="149.1" x2="474.1" y2="148.3"></line>
            <line class="maintenance7" x1="463.7" y1="145.5" x2="465.2" y2="145.5"></line>
            <line class="maintenance7" x1="464.9" y1="141.7" x2="466" y2="142.6"></line>
            <line class="maintenance7" x1="468.1" y1="139.4" x2="468.5" y2="140.8"></line>
            <line class="maintenance7" x1="464.9" y1="149.2" x2="465.9" y2="148.5"></line>
            <path id="svg-concept" class="maintenance8" d="M470.2,145.5c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2   C470.2,145.3,470.2,145.4,470.2,145.5z"></path>
            <path class="maintenance7" d="M392.2,91.1h89.6c4.4,0,8.1,3.6,8.1,8.1v84.6c0,4.4-3.6,8.1-8.1,8.1H367c-4.4,0-8.1-3.6-8.1-8.1V99.2   c0-4.4,3.6-8.1,8.1-8.1h10.1"></path>
            <path class="maintenance0" d="M515.4,174.1v-68.7c0-3.8,3.1-6.9,6.9-6.9h98"></path>
            <path class="maintenance0" d="M221.7,108.6v-3.2c0-3.8,3.1-6.9,6.9-6.9h98c3.8,0,6.9,3.1,6.9,6.9v72.2"></path>
            <polyline class="maintenance9" points="377.1,91.1 377.1,89.1 378.7,89.1  "></polyline>
            <polyline class="maintenance9" points="390.5,89.1 392.2,89.1 392.2,91.1  "></polyline>
            <line class="maintenance7" x1="371" y1="179.6" x2="454.8" y2="179.6"></line>
            <line class="maintenance7" x1="371" y1="179.6" x2="371" y2="134.3"></line>
            <path class="maintenance7" d="M432.5,131.6h-18.3V99.2h18.3V131.6z"></path>
            <path class="maintenance7" d="M429.7,103.9c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V103.9z"></path>
            <path class="maintenance7" d="M429.7,116.1c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V116.1z"></path>
            <path class="maintenance7" d="M429.7,121.9c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V121.9z"></path>
            <path class="maintenance7" d="M429.7,128.2c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V128.2z"></path>
            <path class="maintenance7" d="M429.7,110.2c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V110.2z"></path>
            <path class="maintenance7" d="M455.7,131.6h-18.3V99.2h18.3V131.6z"></path>
            <path class="maintenance7" d="M453,103.9c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V103.9z"></path>
            <path id="svg-concept" class="maintenance6" d="M453,116.1c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V116.1z"></path>
            <path class="maintenance7" d="M453,121.9c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V121.9z"></path>
            <path class="maintenance7" d="M453,128.2c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V128.2z"></path>
            <path id="svg-concept" class="maintenance6" d="M453,110.2c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V110.2z"></path>
            <path class="maintenance7" d="M478.9,131.6h-18.3V99.2h18.3V131.6z"></path>
            <path class="maintenance7" d="M476.2,103.9c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V103.9z"></path>
            <path id="svg-concept" class="maintenance6" d="M476.2,116.1c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V116.1z"></path>
            <path id="svg-concept" class="maintenance6" d="M476.2,121.9c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V121.9z"></path>
            <path class="maintenance7" d="M476.2,128.2c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V128.2z"></path>
            <path class="maintenance7" d="M476.2,110.2c0,0.2-0.2,0.4-0.3,0.4h-12.3c-0.2,0-0.3-0.2-0.3-0.4v-1.6c0-0.2,0.2-0.4,0.3-0.4h12.3   c0.2,0,0.3,0.2,0.3,0.4V110.2z"></path>
            <path id="svg-concept" class="maintenance6" d="M475.1,141.7c0.6,0.9,1.1,1.9,1.2,3c0.2,1.6-0.3,3.1-1.1,4.4"></path>
            <path class="maintenance7" d="M392.7,124.8c0-4,3-7.5,7.1-8c2.9-0.3,5.7,1,7.3,3.2"></path>
            <line id="svg-concept" class="maintenance6" x1="405.8" y1="123.2" x2="400.7" y2="124.7"></line>
            <line class="maintenance7" x1="403.1" y1="117.2" x2="402.9" y2="118"></line>
            <line id="svg-concept" class="maintenance6" x1="407.1" y1="120" x2="406.4" y2="120.5"></line>
            <line id="svg-concept" class="maintenance6" x1="408.6" y1="124.6" x2="407.8" y2="124.6"></line>
            <line class="maintenance7" x1="392.7" y1="124.8" x2="393.5" y2="124.8"></line>
            <line class="maintenance7" x1="394.2" y1="120.1" x2="394.9" y2="120.6"></line>
            <line class="maintenance7" x1="398.2" y1="117.2" x2="398.5" y2="118"></line>
            <path id="svg-concept" class="maintenance8" d="M400.9,124.7c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2   C400.8,124.5,400.9,124.6,400.9,124.7z"></path>
            <path id="svg-concept" class="maintenance6" d="M407.1,120c0.8,1.1,1.3,2.4,1.5,3.8c0,0.3,0,0.5,0,0.8"></path>
            <path class="maintenance7" d="M370.3,124.8c0-4,3-7.5,7.1-8c2.9-0.3,5.7,1,7.3,3.2"></path>
            <line id="svg-concept" class="maintenance6" x1="381.5" y1="120.3" x2="378.3" y2="124.7"></line>
            <line class="maintenance7" x1="380.7" y1="117.2" x2="380.4" y2="118"></line>
            <line id="svg-concept" class="maintenance6" x1="384.6" y1="120" x2="383.9" y2="120.5"></line>
            <line id="svg-concept" class="maintenance6" x1="386.2" y1="124.6" x2="385.4" y2="124.6"></line>
            <line class="maintenance7" x1="370.3" y1="124.8" x2="371.1" y2="124.8"></line>
            <line class="maintenance7" x1="371.8" y1="120.1" x2="372.5" y2="120.6"></line>
            <line class="maintenance7" x1="375.8" y1="117.2" x2="376" y2="118"></line>
            <path id="svg-concept" class="maintenance8" d="M378.5,124.7c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2   C378.4,124.5,378.5,124.6,378.5,124.7z"></path>
            <path id="svg-concept" class="maintenance6" d="M384.6,120c0.8,1.1,1.3,2.4,1.5,3.8c0,0.3,0,0.5,0,0.8"></path>
            <path id="svg-concept" class="maintenance6" d="M475.2,157.2c0.6,0.8,1,1.9,1.2,3c0.2,1.6-0.3,3.1-1.1,4.3"></path>
            <path id="svg-concept" class="maintenance6" d="M475.2,172.6c0.6,0.8,1,1.8,1.1,2.8c0.2,1.5-0.2,2.9-1,4.1"></path>
            <polyline class="maintenance7" points="371,157.9 374.1,156.7 377.8,158.9 381.5,155.9 384.9,159 388.2,154.5 392.4,164.6 397.2,150.4    401,157.9 404.6,134.8 409.7,174.7 414.2,143.8 417.7,163.5 422.6,151.8 427,157.9 430.5,154.6 434.5,157.9 438.2,160.5    442.4,155.6 446.9,159.2 450.5,157.9 455.7,157.9  "></polyline>
            <path class="maintenance7" d="M489.8,112.6h1.5c0.4,0,0.7,0.2,0.7,0.4v3c0,0.2-0.3,0.4-0.7,0.4h-1.5"></path>
            <path class="maintenance7" d="M489.8,106.7h1.5c0.4,0,0.7,0.2,0.7,0.4v3c0,0.2-0.3,0.4-0.7,0.4h-1.5"></path>

            <g>
		  <line id="svg-concept" class="maintenance6" x1="373.6" y1="85.3" x2="369.2" y2="85.3"></line>
      <line id="svg-concept" class="maintenance6" x1="400.1" y1="85.3" x2="395.7" y2="85.3"></line>
      <line id="svg-concept" class="maintenance6" x1="384.6" y1="69.7" x2="384.6" y2="74.2"></line>
      <line id="svg-concept" class="maintenance6" x1="392.7" y1="71.5" x2="390.1" y2="75.8"></line>
      <line id="svg-concept" class="maintenance6" x1="376.5" y1="71.5" x2="379.1" y2="75.9"></line>
      <line id="svg-concept" class="maintenance6" x1="398.5" y1="77.3" x2="394.2" y2="79.9"></line>
      <line id="svg-concept" class="maintenance6" x1="370.8" y1="77.3" x2="375.1" y2="79.9"></line>
      <animate accumulate="none" additive="replace" attributeName="opacity" calcMode="linear" dur="1s" fill="remove" repeatCount="indefinite" restart="always" values="0;1;0">
		  </animate>
	  </g> 
      <path id="svg-ico" class="maintenance2" d="M456.3,179.6c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5   C455.6,178.1,456.3,178.8,456.3,179.6z"></path>

    <g>
      <line id="svg-concept" class="maintenance5" x1="388.1" y1="134.8" x2="388.1" y2="174.9"></line>
      <animateMotion accumulate="none" additive="replace" begin="0s" calcMode="paced" dur="8s" fill="remove" path="M0, 0 60 0 0 0" repeatCount="indefinite" restart="always">
      </animateMotion>
    </g> 
    <path id="svg-concept" class="maintenance5" d="M390.5,85.2l0,3.8l-11.8,0v-3.8c0-3.3,2.6-5.9,5.9-5.9C387.9,79.3,390.5,82,390.5,85.2z"></path>
    <path class="maintenance0" d="M505.9,183.8V99.2c0-4.4,3.6-8.1,8.1-8.1h10.1"></path>
    <line class="maintenance0" x1="539.2" y1="91.1" x2="624.1" y2="91.1"></line>
    <polyline class="maintenance0" points="524.1,91.1 524.1,89.1 525.7,89.1  "></polyline>
    <polyline class="maintenance0" points="537.5,89.1 539.2,89.1 539.2,91.1  "></polyline>
    <path class="maintenance1" d="M537.5,85.2l0,3.8l-11.8,0v-3.8c0-3.3,2.6-5.9,5.9-5.9S537.5,82,537.5,85.2z"></path>
    <path class="maintenance0" d="M220.1,91.1h10.1"></path>
    <path class="maintenance0" d="M245.3,91.1h89.6c4.4,0,8.1,3.6,8.1,8.1v84.6"></path>
    <polyline class="maintenance10" points="230.2,91.1 230.2,89.1 231.9,89.1  "></polyline>
    <polyline class="maintenance10" points="243.7,89.1 245.3,89.1 245.3,91.1  "></polyline>
    <path class="maintenance7" d="M343,112.6h0.9c0.2,0,0.4,0.2,0.4,0.4v3c0,0.2-0.2,0.4-0.4,0.4H343"></path>
    <path class="maintenance7" d="M343,106.7h0.9c0.2,0,0.4,0.2,0.4,0.4v3c0,0.2-0.2,0.4-0.4,0.4H343"></path>
    <path class="maintenance1" d="M243.7,85.2l0,3.8l-11.8,0v-3.8c0-3.3,2.6-5.9,5.9-5.9C241,79.3,243.7,82,243.7,85.2z"></path>
    <line class="maintenance0" x1="534.3" y1="108.2" x2="526.5" y2="116"></line>
    <line class="maintenance0" x1="541.5" y1="107.5" x2="525.8" y2="123.2"></line>
    <line class="maintenance0" x1="324.7" y1="116" x2="316.9" y2="108.2"></line>
    <line class="maintenance0" x1="325.4" y1="123.2" x2="309.6" y2="107.5"></line>
  </g>
  <g>
	<path class="maintenance7" d="M401.4,337c-3-4.9-9.6-15.3-12.3-18.3c-0.6-0.6-1.3-1.5-2.1-2.5"></path>
  <path class="maintenance7" d="M402.1,337.4c-1.4-0.2-2.3-0.4-2.3-0.4l-1.4,6.7c0,0,2.6,2.8,4.7,4.4c2.1,1.5,5,1.9,5,1.9s3.1-2.5,3.7-3.9   c0.6-1.4,1.6-3.3,1.6-3.3s-0.7-1-1.5-1.9"></path>
  <path class="maintenance7" d="M490.7,256.6c4.4-7.9-10-13-15.1-15.2c-5.1-2.2-25.2-7-25.2-7l-2.7,4.9c0,0,13.8,8.4,23.6,14"></path>
  <path class="maintenance7" d="M438.1,260.1c-0.6-3.3-1.6-9.1-1.6-9.1l-7.4,0.5v-0.9l-7.4,0c0,0-1.1,6.6-1.7,9.5"></path>
  <path class="maintenance7" d="M429.2,366.4c0,0,2,43.7,2,51.9c0,5.2,0,27.7,0,43.9"></path>
  <path class="maintenance7" d="M449.3,462.2c0.7-6.8,1.3-14.2,1.4-19.5c0.3-12.5,0-25.2,0-29.3c0-4.1,4.2-41.8,3.7-50.4   c-0.5-8.6-3.7-24.4-3.7-24.4l-29.3,0.5"></path>
  <path class="maintenance7" d="M414.9,460.6c1.6-17.3,3.5-36.5,4-41.2c0.9-8.2,10.4-51.7,10.4-51.7"></path>
  <path class="maintenance7" d="M404.6,349.4c-1.2,4.3-2.4,9-2.9,12.5c-1.4,8.5-1.2,46.4-1.6,50.5c-0.4,4.1-2,16.7-3.1,29.2   c-0.4,4.9-0.6,10.4-0.6,18.1"></path>
  <path class="maintenance7" d="M421.2,252.3c-1.7-1.7-3.1-3.9-4.1-6.5"></path>
  <path class="maintenance7" d="M437,251.8c2.8-3,5.1-7.4,5.7-12.3c0.1-0.8,0.1-1.6,0.1-2.4"></path>
  <path class="maintenance7" d="M433.9,221.5c-1.2-0.3-2.6-0.5-4.1-0.5c-4.2-0.1-11.2,0.4-13.4,6.1c-2.2,5.6-1,10.5-1,10.5"></path>
  <path class="maintenance7" d="M445.2,237.8c1.5,1.2,3.3,1.9,3.3,1.9l2.6-5.3c0,0-3.6-5.7-4-6.6"></path>
  <path class="maintenance7" d="M443.2,231.4c-0.5,0.1-0.6,1-1.1,1.2c-0.8,0.3-1.7-0.4-2.8-0.5c-1.1-0.1-1.4,0.9-1.2,1.1   c0.3,0.2,5.4,2,6.1,3.8"></path>
  <path class="maintenance7" d="M447,227.6c-0.1-0.3-0.2-0.7-0.3-1c-0.1-0.2-0.5-0.5-0.6-0.7c-0.1-0.2-0.1-0.4-0.2-0.6   c-0.1-0.2-0.5-0.4-0.7-0.7c-0.1-0.2-0.2-0.6-0.3-0.8c-0.3-0.7-0.8-0.5-1-0.7c-0.4-0.7-0.5-0.9-0.6-1c-0.5-0.5-3.7-1-4.2-0.9   c-0.5,0.1-3.5-0.2-3.6,0.1c-0.1,0.3,0.4,1.4,2,1.7c1.6,0.3,3.5,0.8,3.2,0.9c0.4,0.2,1,0.5,1.3,1c0.7,1.1,1.1,4.4,1.1,4.4   s0.1,2,0.1,2.1"></path>
  <path class="maintenance7" d="M414.1,238.2c-0.3,1.9,1.6,7.6,2.7,7.6c0.4,0,0.2-6.3-0.6-7.6C415.5,237,414.2,237.8,414.1,238.2z"></path>
  <path class="maintenance7" d="M458.7,284.9c-1.5,7.2-3.8,15.9-6.9,24.7c-1,2.9,1.6,22.9-0.9,28.9"></path>
  <path class="maintenance7" d="M397.9,271.4c1-1.6,6.3-3,11.2-4.5c4.3-1.3,7.8-3.2,7.8-3.2l24.4,0.1c0,0,4.3,2.1,8.8,2.4c1.9,0.1,3.7,0,5.3,0   "></path>
  <path class="maintenance7" d="M407.3,334.9c-0.8-8.5,0.5-23.1-0.4-25.6c-0.4-1.3-2.5-8.1-4.6-15.7"></path>
  <polyline class="maintenance7" points="444.3,265 439.9,260.2 418.5,260.1 414,265.1  "></polyline>
  <path class="maintenance7" d="M458.6,289.1l27-23.8c0,0-3.2-4.3-7.2-6.5c-4-2.2-8.2-3.4-8.2-3.4s-13.6,8.8-14.7,11"></path>
  <path class="maintenance7" d="M397.6,272.1c-1.6,1.2-4.3,3.5-6.8,7.4c-3.7,5.9-14.8,22.8-14.8,22.8s2.7,5.3,8.2,8.5c4.8,2.8,6.4,2.2,6.4,2.2   l13-14.7"></path>
  <path class="maintenance7" d="M412.3,336.1c7.3-4.6,20.6-13.1,20.9-14.5c0.4-2-7.5-3.8-6.7-5.1c0.8-1.3,11.1-2.9,14.2-4.8   c3-1.9,10.9-26.8,14.8-29.8"></path>
  <path class="maintenance7" d="M412,340.9c1.9,0.5,3.5-0.7,3.3-1.7c-0.2-0.9-2.1,0.2-4.7-1.1c-1.2-0.6-2.5-1.2-4.3-1.5   c-1.8-0.3-4.2,0.8-4.2,0.8"></path>
  <path class="maintenance7" d="M442,224.9c0,0-1.9-0.2-2.9,1.2"></path>
  <line class="maintenance7" x1="490.7" y1="256.6" x2="484.2" y2="263.7"></line>
  <line class="maintenance7" x1="472.8" y1="254.6" x2="471.1" y2="255.7"></line>
  <path class="maintenance7" d="M377.3,304.4l-5.5,5.3l-0.6,0.6c-2.2,2.7-2.6,6.1,4.1,13c5.7,5.9,19.3,16.7,23.2,19.8"></path>
  <line class="maintenance7" x1="388.1" y1="312.6" x2="386" y2="315.1"></line>
</g>
</svg>
      </div>
    </div>
    <div class="col-md-12 text-center pt-5">
      <h1 class="text-white"><strong>Website is Under MAINTENANCE</strong></h1>
      <p class="text-muted"> We are improving our website. We'll be back soon with new awesome features</p>
    </div>

  </div>
  <!-- <div class="new_class copyright">© Copyright 2023 | Vezra Domain Company<br>All rights reserved</div> -->
  </section>
</template>
<script>
import Logo from '@/components/Logo'
export default {
  components:{Logo}
}
</script>
<style>
@media screen and (max-width: 767px) {
  .main_cont{
    margin-top: 110px;
  }

}

.maintenance0{fill:none;stroke:#414142;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.maintenance1{fill:none;stroke:#808080;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.maintenance2{fill:#EE5586;}
.maintenance3{fill:#808080;}
.maintenance4{fill:none;stroke:#414142;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.maintenance5{fill:none;stroke:#EE5586;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.maintenance6{fill:none;stroke:#EE5586;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.maintenance7{fill:none;stroke:#808080;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.maintenance8{fill:#FFFFFF;stroke:#EE5586;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.maintenance9{fill:none;stroke:#808080;stroke-miterlimit:10;}
.maintenance10{fill:none;stroke:#414142;stroke-miterlimit:10;}
</style>