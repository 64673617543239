import Vue from "vue";
import App from './App.vue' 
import axios from "axios";
import router from './router'
import store from './store' 
import VueI18n from 'vue-i18n'; 
import messages from "@/locales/lang.js";
import BottomSvg from '@/components/BottomSvg'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
Vue.component("flatPickr", flatPickr);
Vue.component('BottomSvg',BottomSvg)
Vue.use(VueI18n);
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) { 
      router.push("/login").catch(() => {});
    }
    return Promise.reject(error);
  }
);
let lang = localStorage.getItem("lang") && ["en", "tr"].includes(localStorage.getItem("lang")) ? localStorage.getItem("lang") : "en";
const i18n = new VueI18n({
  locale: lang,
  messages,
  silentTranslationWarn: true,
});
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer' + " " + token;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Accept'] = 'application/json';
}
axios.defaults.headers.common['accept-language'] = lang
Vue.mixin({
  filters:{
    formatDate(dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().replace("T", " ").slice(0, -1);
      const lastIndex = formattedDate.lastIndexOf(".");
      return formattedDate.substring(0, lastIndex);
    },
  },
  data(){
    return{
      languages:[
        {id:1, name:"English",locale:'en', description: "Change language to english"},
        {id:2, name:"ქართული",locale:'ka', description: "შეცვალე ენა ქართულით"}
      ],
      available_languages : ['en','ka'],
    }
  },
  methods:{
    getLastDay(d = 0, time) {
      let day = new Date();
      day.setDate(day.getDate() - d);
      return ( String(day.getFullYear()) +"-" + String(day.getMonth() + 1).padStart(2, "0") + "-" + String(day.getDate()).padStart(2, "0") + time );
    },
    UserAgent(userAgent){
      const UAParser = require('ua-parser-js');  
      const parser = new UAParser();
      let result = parser.setUA(userAgent).getResult(); 
      result = 
        "<strong>"+this.$t('Browser name')+" : </strong> " +result.browser.name + "<br>" +  
        "<strong>"+this.$t('Browser version')+" : </strong> " +result.browser.version + "<br>" + 
        "<strong>"+this.$t('Browser major')+" : </strong> " +result.browser.major + "<br>" + 
        "<strong>"+this.$t('Engine name ')+": </strong> " +result.engine.name + "<br>" + 
        "<strong>"+this.$t('Engine version')+" : </strong> " +result.engine.version + "<br>" + 
        "<strong>"+this.$t('OS name')+" : </strong> " +result.os.name + "<br>" + 
        "<strong>"+this.$t('OS version')+" : </strong> " +result.os.version + "<br>" + 
        "<strong>"+this.$t('Device vendor')+" : </strong> " +result.device.vendor + "<br>" + 
        "<strong>"+this.$t('Device model')+" : </strong> " +result.device.model 
      return result
    },
    getMonitorSize(){
      if (window.innerWidth < 550) return "mobile";
      if (window.innerWidth > 549 && window.innerWidth < 1200) return "tablet";
      if (window.innerWidth > 1199) return "web";
    },
    back(){
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    changeLanguage(lang){
      this.$i18n.locale = this.available_languages.includes(lang) ? lang : 'en'
      localStorage.setItem('lang',this.$i18n.locale)
    },
  },
})
new Vue({ 
  router, 
  store, 
  i18n, 
  render: (h) => h(App) 
}).$mount("#app");