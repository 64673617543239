<template>
  <div class="box-container limit-width">
    <Marquee v-if="marqueeText" :text="marqueeText"/>
    <Header 
      v-if="!maintenance && !soon"
      :isLoggedIn="isLoggedIn"
      :logo="logo"
      :updateCart="updateCart"
      @UpdateAgainCart="updateCart=false"
      @openLoading="loading=true"
      @closeLoading="loading=false"
    /> 
    <Maintenance v-if="maintenance" :isLoggedIn="isLoggedIn"/>
    <Soon v-if="soon" :isLoggedIn="isLoggedIn"/>
    <router-view 
      v-if="!maintenance && !soon"
      :isLoggedIn="isLoggedIn"
      @closeLoading="loading=false"
      @openLoading="loading=true"
      @updateCart="updateCart=true"
    />
    <Loading :show="loading"/>
    <Footer v-if="!maintenance && !soon" :logo="logo" :isLoggedIn="isLoggedIn"/>
    <a v-if="!maintenance && !soon" href="#0" class="cd-top"> <i class="fas fa-angle-up"></i> </a>
  </div>
</template>
<script>
import Header from '@/Layouts/Header'
import Footer from '@/Layouts/Footer'
import Soon from "@/components/Soon";
import Maintenance from "@/components/Maintenance";
import Loading from '@/components/Loading'
import Marquee from '@/components/Marquee'
export default {
  components:{
    Header,
    Footer,
    Soon,
    Maintenance,
    Loading,
    Marquee
  }, 
  computed:{
    isLoggedIn : function(){  
      return this.$store.getters.isLoggedIn
    }
  },
  watch:{
    "$props.isLoggedIn":{
      handler(oldValue,newValue){ 
        if(newValue){ 
          return newValue
        }
      },
      immediate:true,
      deep:true
    }
  },
  data(){
    return{
      updateCart:false,
      marqueeText:"🚀 We are currently in test mode to ensure the best experience for you. Stay tuned for our grand launch!  🎉 Thank you for your patience!  🌐",
      loading:true,
      logo:'/assets/img/logo/logo.png',
      soon:false,
      maintenance:false
    }
  },  
  mounted(){
    if(this.isLoggedIn){
      this.$store.dispatch('refresh')
    }
    
  }
}
</script>
