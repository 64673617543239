<template>
  <div class="box-container limit-width">
    <div class="bg-colorstyle">
      <div class="top-header item17 overlay">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="wrapper">
                <h1 class="heading">{{$t('My Cart')}}</h1>
                <h3 class="subheading">{{$t('This is your cart page')}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper sec-normal">
        <div class="content bg-colorstyle noshadow nopadding">
          <div class="inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <section id="main-body">
                  <div class="container">
                    <div class="main-content">
                      <div class="row">
                        <div class="col-xs-12">
                          <div id="order-standard_cart">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="header-lined">
                                  <div class="text-center" style="padding-top: 5rem">
                                    <h1 class="section-heading mergecolor border-0">Review &amp; Checkout</h1>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-50">
                                <!-- <p class="domain-price" style="display: block;">
                                  <span class="price mergecolor"></span>
                                  <button class="btn btn-primary btn-add-to-cart" data-whois="0" data-domain="">
                                    <span class="to-add" style="display: inline;">Add to Cart <i class="fas fa-arrow-circle-right"></i>
                                    </span>
                                    <span class="loading" style="display: block;">
                                      <i class="fas fa-spinner fa-spin"></i> Loading... </span>
                                    <span class="added" style="display: block;">
                                      <i class="far fa-shopping-cart"></i> Checkout </span>
                                    <span class="unavailable" style="display: block;">Taken</span>
                                  </button>
                                </p> -->
                                <p class="domain-error domain-checker-unavailable" v-if="errorMessage" style="display: block;">{{errorMessage}}</p>
                                <div class="sec-main bg-colorstyle sec-bg1 nopadding noshadow" v-if="list.length">
                                  <div class="sidebar-collapsed">
                                    <div class="panel card panel-default">
                                      <div class="m-0 panel-heading card-header">
                                        <h3 class="panel-title">
                                        <i class="fas fa-shopping-cart"></i>
                                        &nbsp; Categories
                                        </h3>
                                      </div> 
                                    </div>
                                    <div class="panel card panel-default">
                                      <div class="m-0 panel-heading card-header">
                                        <h3 class="panel-title">
                                        <i class="fas fa-plus"></i>
                                        &nbsp; Actions
                                        </h3>
                                      </div>
                                      <div class="panel-body card-body">
                                        <div>
                                          <select class="form-control custom-select">
                                            <option class="list-group-item">Renew Domains </option>
                                            <option class="list-group-item">Register a New Domain </option>
                                            <option class="list-group-item">Transfer in a Domain </option>
                                            <option class="list-group-item">View Cart </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-8"> 
                                      <div class="view-cart-items pb-3" v-for="(k,i) in list" :key="i"> 
                                        <div class="item bg-seccolorstyle bg-pratalight border-0 noshadow">
                                        <div class="item-header">
                                          <a class="badge feat bg-danger mr-30" type="button" @click="deleteComponent=true;itemId=k.id">
                                            <i class="ico-trash-2 f-16"></i>
                                          </a> 
                                          <a class="badge feat bg-default mr-65">
                                            <i class="ico-tool f-16"></i>
                                          </a>
                                          <div class="row">
                                            <div class="col-md-12">
                                              <span class="item-title mergecolor"> {{k.type}} </span>
                                              <span class="item-domain-addons mergecolor"></span>
                                            </div>
                                            <div class="col-sm-5 item-price">
                                              <span>$ {{ k.price }} USD</span>
                                              <span class="renewal cycle mergecolor">
                                                Renewal <span class="renewal-price cycle">$12.00/1yr</span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row item-footer">
                                          <div class="row">
                                            <div class="col-md-8 col-xs-12">
                                              <span class="mergecolor">Your Domains:</span>
                                              <span class="item-domain mergecolor"> {{k.domain}} </span>
                                            </div>
                                            <div class="col-md-4 col-xs-12">
                                              <div class="dropdown float-right open ">
                                                <button type="button"
                                                class="btn btn-default-yellow-fill btn-xs dropdown-toggle domainyarsselect"
                                                  @click="activeDropDownId = k.id; display==='block'?'none':'block'"  >
                                                  {{k.time}}<span class="caret"></span>
                                                </button>
                                                <ul class="dropdown-menu bg-seccolorstyle":style="{display: activeDropDownId === k.id ? 'block' : 'none'}">
                                                  <li><a href="#">1 Year @ $10.00 USD</a></li>
                                                  <li><a href="#">2 Years @ $18.00 USD</a></li>
                                                  <li><a href="#">3 Years @ $25.00 USD</a></li>
                                                  <li><a href="#">4 Years @ $33.00 USD</a></li>
                                                  <li><a href="#">5 Years @ $42.00 USD</a></li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        </div>
                                      </div> 
                                      <div class="promo-code-cart-container"> 
                                        <div class="form-group">
                                          <input type="text" class="field" placeholder="Enter promo code if you have one">
                                          <button type="submit" class="btn-order-embeded" value="Validate Code">
                                            <i class="ico-check f-16"></i>
                                          </button>
                                        </div> 
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="order-summary" style="margin-top: 0px;">
                                        <div class="badge feat bg-danger empty-cart mr-20 mt-10" type="button">
                                          <i class="ico-trash-2 f-16"></i>
                                        </div>
                                        <div class="loader" style="display: block;">
                                          <i class="fas fa-fw fa-sync fa-spin"></i>
                                        </div>
                                        <h2>Order Summary</h2>
                                        <div class="summary-container">
                                          <div class="subtotal clearfix">
                                            <span class="pull-left">Subtotal</span>
                                            <span id="subtotal" class="pull-right">$50.00 USD</span>
                                          </div>
                                          <div class="recurring-totals clearfix">
                                            <span class="pull-left">Totals</span>
                                            <span class="pull-right recurring-charges">
                                              <span style="display:block;">
                                                <span class="cost"></span> Monthly <br>
                                              </span>
                                              <span style="display:block;">
                                                <span class="cost"></span> Quarterly <br>
                                              </span>
                                              <span style="display:block;">
                                                <span class="cost"></span> Semi-Annually <br>
                                              </span>
                                              <span>
                                                <span class="cost">$50.00 USD</span> Annually <br>
                                              </span>
                                              <span style="display:block;">
                                                <span class="cost"></span> Biennially <br>
                                              </span>
                                              <span style="display:block;">
                                                <span class="cost"></span> Triennially <br>
                                              </span>
                                            </span>
                                          </div>
                                          <div class="total-due-today total-due-today-padded">
                                            <span class="amt">$50.00 USD</span>
                                            <span>Total Due Today</span>
                                          </div>
                                          <div class="express-checkout-buttons"></div>
                                          <div class="text-right">
                                            <a href="#" class="btn btn-default-fill btn-link btn-continue-shopping" id="continueShopping">
                                            <i class="ico-chevron-left"></i> Continue Shopping </a>
                                            <a href="#" class="btn btn-default-yellow-fill btn-lg btn-checkout" id="checkout">
                                              Checkout <i class="ico-chevron-right w-icon"></i>
                                            </a>
                                            <br>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> 
                            <Delete v-if="deleteComponent" @close="deleteComponent=false" :id="itemId" @updateCart="updateList"/>
                           <!--    <div class="modal fade modal-remove-item">
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <button type="button" class="close">
                                      <span>×</span>
                                    </button>
                                    <h4 class="modal-title">
                                      <i class="ico-trash-2 f-54 c-grey"></i>
                                      <span class="c-grey">Empty Cart</span>
                                    </h4>
                                  </div>
                                  <div class="modal-body"> Are you sure you want to empty your shopping cart? </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-sm btn-default">No</button>
                                    <button type="submit" class="btn btn-sm btn-danger">Yes</button>
                                  </div>
                                </div>
                              </div>
                            </div>  -->
                          </div>
                            <!-- <div class="modal fade">
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h4 class="float-left pull-left"> Recommendations </h4>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="clearfix"></div>
                                  </div>
                                  <div class="modal-body">
                                    <div class="product-recommendations-container">
                                      <div class="product-recommendations">
                                        <p>Based on your order, we recommend:</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <a class="btn btn-primary" href="#" id="btnContinueRecommendationsModal" data-dismiss="modal" role="button">
                                    <span class="w-hidden hidden">
                                    <i class="fas fa-spinner fa-spin"></i>&nbsp; </span>Continue </a>
                                  </div>
                                </div>
                              </div>
                              <div class="product-recommendation clonable w-hidden hidden">
                                <div class="header">
                                  <div class="cta">
                                    <div class="price">
                                      <span class="w-hidden hidden">FREE!</span>
                                      <span class="breakdown-price"></span>
                                      <span class="setup-fee">
                                        <small>&nbsp;Setup Fee</small>
                                      </span>
                                    </div>
                                      <button type="button" class="btn btn-sm btn-add">
                                        <span class="text">Add to Cart</span>
                                        <span class="arrow">
                                          <i class="fas fa-chevron-right"></i>
                                        </span>
                                      </button>
                                    </div>
                                    <div class="expander">
                                      <i class="fas fa-chevron-right rotate" data-toggle="tooltip" data-placement="right" title="Click to learn more."></i>
                                    </div>
                                    <div class="content">
                                      <div class="headline truncate"></div>
                                      <div class="tagline truncate"> A description (tagline) is not available for this product. </div>
                                    </div>
                                  </div>
                                  <div class="body clearfix">
                                    <p></p>
                                  </div>
                                </div>
                            </div> -->
                          </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Delete from '@/components/Cart/Delete'
export default {
  components:{
    Delete
  },
  data(){
    return{
      list:[],
      errorMessage:"",
      activeDropDownId:0,
      display:"none",
      deleteComponent:false,
      itemId:"",
    }
  },
  methods:{
    updateList(){
      this.$emit('updateCart')
      this.deleteComponent=false
      this.list = JSON.parse(localStorage.getItem("cart_items"))
    },
    getCartData(){ 
      this.list = JSON.parse(localStorage.getItem("cart_items")) 
      if(!this.list.length){
        this.errorMessage = this.$t('Cart is empty')
      }
      // let data = [{"id":1,"type":"Domain Registration","price":"$12.00","time":"1 year","domain":"vezra.net"},{"id":2,"type":"Domain Registration","price":"$12.00","time":"1 year","domain":"vezra.net"},{"id":3,"type":"Domain Registration","price":"$12.00","time":"1 year","domain":"vezra.net"},{"id":4,"type":"Domain Registration","price":"$12.00","time":"1 year","domain":"vezra.net"}]
      // localStorage.setItem('cart_items',JSON.stringify(data))
      this.$emit('closeLoading')
    },
  },
  mounted(){     
    this.getCartData()
  }
}
</script>