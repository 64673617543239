<template>
  <div class="box-container limit-width">
    <section class="sec-normal notfound pt-150 scrollme">
      <div class="total-grad-pink-blue-intense"></div>
      <div class="container animateme" data-when="exit" data-from="0" data-to="0.75" data-opacity="1" data-translatey="-50" style="opacity: 1; transform: translate3d(0px, -50px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);">
        <div class="row justify-content-center">
          <div class="col-9">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0" y="0" viewBox="0 0 853.5 435.7" xml:space="preserve" class="svg" src="assets/patterns/notfound.svg" alt="provisioning">
              <path class="nfound0" d="M386.6 227l10.4 6.9M400.7 207.5l12.1 8"></path>
              <path id="svg-concept" class="nfound1" d="M267.8 137l17.1 17.7c.8.9 2.2.8 2.9-.2l10.5-14.9c.7-1 .3-2.4-.8-2.8l-22.2-9.9c-.8-.4-1.8-.1-2.3.6l-5.3 7.1c-.7.7-.6 1.8.1 2.4zM270.4 130.7l60.5 42.3M298.3 139.6l52.6 4.6M310.9 201.2l-22.6-47.5"></path>
              <path class="nfound2" d="M238.7 299.3l47.9-6.4M265.1 264.5l43.3.9M297.8 219.6l36.8 10.9M368.3 118.8l22 31.9M398.2 74.1l14.7 41.5M420.3 39.8l10.2 46M437 8.4l6.4 52.4C423.2 109 318 259.6 266.3 316.8c0 0-53.1 10.9-52.5 10.5"></path>
              <path class="nfound2" d="M443.4 60.8l-2.3 62.2-21.9 55.7-22 33.6-7.9 10.9-24.5 33.6-42.4 36.2-56.1 23.8M322.4 293c30.3-31.9 102.3-134.4 118.7-170"></path>
              <path id="svg-concept" class="nfound1" d="M333.9 142.7C389 64.8 433.9 6.3 437 8.4c3.4 2.4-43.9 75.7-105.5 163.7S217.2 329.6 213.8 327.2c-3.1-2.2 36.1-63.5 89.9-141.3"></path>
              <g>
                <path class="nfound0" d="M414 229.6c0 2.7-2.2 4.8-4.8 4.8-2.7 0-4.8-2.2-4.8-4.8s2.2-4.8 4.8-4.8c2.7 0 4.8 2.1 4.8 4.8z"></path>
                <path class="nfound0" d="M411 229.6c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8zM414 229.6h-3M407.4 229.6h-3"></path>
                <path class="nfound0" d="M416.3 254.9H402c-2.8 0-5.1-2.3-5.1-5.1v-29.3c0-2.8 2.3-5.1 5.1-5.1h14.3c2.8 0 5.1 2.3 5.1 5.1v29.3c0 2.9-2.3 5.1-5.1 5.1zM402.6 254.9h13.2v87.2h-13.2z"></path>
                <path class="nfound0" d="M423.6 318.2v-6.4l-7.8-3h-13.2l-7.5 1.8-.1 7.8M402.6 327.3h13.2M395 315.8l-72.4 44.4M332.4 362.3l-9.8-2.1M386.1 328.3l-53.7 34M311.9 360.7v4.4M333.8 370l-21.9-4.9M326 358c-.2.1-3.2-.5-3.2-.5l-10.9 3.2 22 4.9"></path>
                <path class="nfound0" d="M336.2 359.9l6.6.8v4.2l-9 5.1v-4.4l9-4.9M395.2 318.2L327 394.7M402.6 317L334 395.6M402.6 327.3l-56.5 64.8M395.2 318.2l7.4-1.6M327 394.7l6.9 1.1M346.1 392.1l-12.2 3.7M316 395.5v5.2l16.1 3.4v-5.5z"></path>
                <path class="nfound0" d="M332.1 404.1s19.2-5.3 19.4-5.7v-5.2l-19.4 5.4M329.8 391.5l-13.8 4M346.1 392.1l5.5 1.1M415.8 327.3l75.2 62.8M415.8 317l90.2 76M423.6 318.2l87 72.8M477.6 357.8l-54-39.6M486.7 357.8l-11.2-1.6M474.4 360.8l12.3-3M486.7 361.9v-4.1M478.8 363.7l7.9-1.8M477.6 357.8l-4.8 1.2M510.6 391l-4.6 2M491 390.1l15 2.9M482.8 391.6s-.1 5.7 0 5.6 26.1 5.7 26.1 5.7V397l-26.1-5.4zM489.8 389.1l-7 2.5M522.9 390.1l-14 6.9M522.9 395.2l-14 7.7M522.9 390.1v5.1M508.3 388.7l14.6 1.4M402.6 342.2l-5.3 1.5v5.9l8.5 2.3h6.4c.6 0 8.7-2.6 8.7-2.6v-5.1l-5.1-2M402.6 351v-8.8M415.8 342.2v8.8M415.8 317l7.8 1.2"></path>
              </g>
              <g>
                <path class="nfound0" d="M794 421.9H486.3M813.1 398H576.6M583.5 375h165.3M759.2 375h3.9M828.2 398h14.3M806.7 421.9h15.1M833.8 421.9h5.5M54.4 422h279.4M34.3 398.1h209.6M235 375H98.6M28 398.1H13.6"></path>
              </g>
              <g>
                <path id="svg-concept" class="nfound3" d="M448.7 269.7l-6.2 6.8M450.1 276.5h-7.6M448.7 274.9v4.5M467.3 269.7l-6.1 6.8M468.7 276.5h-7.5M467.3 274.9v4.5"></path>
                <ellipse id="svg-concept" class="nfound3" cx="455.7" cy="274.6" rx="3.4" ry="4.9"></ellipse>
              </g>
              <g>
                <ellipse id="svg-concept" transform="rotate(-53.786 245.3484 119.489)" class="nfound0" cx="245.3" cy="119.5" rx="67.9" ry="11.3"></ellipse>
                <animate attributeName="opacity" values="0;3;0" dur="2s" begin="1s" repeatCount="indefinite"></animate>
              </g>
              <g>
                <ellipse id="svg-concept" transform="rotate(-53.786 212.8327 86.9739)" class="nfound0" cx="212.8" cy="87" rx="30.3" ry="5"></ellipse>
                <animate attributeName="opacity" values="0;3;0" dur="2s" begin="2s" repeatCount="indefinite"></animate>
              </g>
            </svg>
          </div>
        </div>
        <div class="col-md-12 text-center pt-5">
          <p class="text-white">Sorry, it appears that the page you’ve requested cannot be found.<br> Please press the button below to go home page or <a href="contact" class="golink">contact us</a></p>
          <a href="/" class="btn btn-default-grad-purple-fill mt-3">Go Home Page</a>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  mounted(){
    this.$emit('closeLoading')
      // this.$emit('openLoading')
  }
}
</script>
<style scoped>
.nfound0{
  fill:none;
  stroke:gray;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-miterlimit:10
}
.nfound1{
  stroke:#ee5586;
  stroke-width:2
}
.nfound1,
.nfound2,
.nfound3{
  fill:none;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-miterlimit:10
}
.nfound2{
  stroke-width:2;stroke:gray
}
.nfound3{
  stroke:#ee5586
}
</style>