<template>
    <ul class="pagination"> 
    <li :class="{ active: i === currentPage }" @click="nextPage(i)" 
      v-for="(i, index) in currentPage > 5 ? pages.slice(currentPage - 5, currentPage + 5) : pages.slice(0, lastPage <= 10 ? lastPage : 10)" 
      :key="index">
      <a class="page-list">{{ i }}</a>
    </li> 
  </ul>
</template>
<script>
export default { 
  props: {
    currentPage: {
      required: true,
      type: Number,
    },
    totalItems: {
      required: true,
      type: Number,
    },
    pageSize: {
      required: true,
      type: Number,
    },
    lastPage: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      pages: [],
      pageCount: 0,
      firstNum: 0,
      lastNum: 0,
    }
  },
  watch: {
    lastPage: function () {
      let totalItems = this.$props["totalItems"];
      let pageSize = this.$props["pageSize"];
      let paginate = this.paginate(totalItems, 1, pageSize, this.lastPage);
      this.pages = paginate.pages;
    },
  },
  methods: {
    nextPage(i) {
      if (i <= this.pages.length) {
        let paginate = this.paginate(
          this.totalItems,
          1,
          this.pageSize,
          this.lastPage
        );
        this.pages = paginate.pages;
        this.$emit("input", i);
        this.$emit("change", i);
        this.currentPage = i;
      }
    },
    paginate(totalItems, currentPage = 1, pageSize = 10, maxPages = 10) {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);
      // ensure current page isn't out of range
      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > totalPages) {
        currentPage = totalPages;
      }
      let startPage, endPage;
      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }
      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
      );
      // return object with all pager properties required by the view
      return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages,
      };
    },
  },
  mounted() {
    let totalItems = this.$props["totalItems"];
    let pageSize = this.$props["pageSize"];
    let paginate = this.paginate(totalItems, 1, pageSize, this.lastPage);
    this.pages = paginate.pages;
  },
};
</script>
<style scoped>
.box-container .pagination>li>a {
  padding: 20px !important; 
}
</style>