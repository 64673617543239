<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-top">
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <div class="heading">Hosting</div>
            <ul class="footer-menu">
              <li class="menu-item"><router-link to="/guide-center/deletion-renewal-procedures">Domain Deletion : Renewal Procedures</router-link></li>
              <li class="menu-item"><router-link to="/guide-center/return-policy">Return Policy</router-link></li>
              <li class="menu-item"><router-link to="/guide-center/privacy-polocy">Privacy Policy</router-link></li>
              <li class="menu-item"><router-link to="/guide-center/cookie-policy">Cookie Policy</router-link></li> 
              <li class="menu-item"><router-link to="/guide-center/registrants-responsibilities">Registrants' Responsibilities</router-link></li>
              <li class="menu-item"><router-link to="/guide-center/user-agreement">User Agreement</router-link></li>
            </ul>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="heading">Guide center</div>
            <ul class="footer-menu">
              <li class="menu-item"><router-link to="/contact">Contact Us</router-link></li>
              <li class="menu-item"><router-link to="/about-us">About Us</router-link></li>
              <li class="menu-item"><router-link to="/report-abuse">Report Abuse</router-link></li>
              <li class="menu-item"><router-link to="/domains/whois-inquiry">WHOIS</router-link></li>
            </ul>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="heading">Domains</div>
            <ul class="footer-menu">
              <li class="menu-item"><router-link to="/domains/register-domain">Register domain</router-link> </li>
              <li class="menu-item"><router-link to="/domains/domain-prices">Domain prices</router-link></li>
              <li class="menu-item"><router-link to="/domains/domain-transfer">Domain transfer</router-link></li> 
            </ul>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="logo ">
              <a href="javascript:void(0)">
                <img :src="logo" >
              </a>
            </div>
            <a>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 339.7 85" style="enable-background:new 0 0 339.7 85;" xml:space="preserve" class="svg logo-footer d-none" src="/assets/img/logo-light.svg" alt="logo">
                <path class="logo0" d="M339.3,74.6v5.7h-225v-5.7H339.3z"></path>
                <g>
                  <circle id="svg-ico" class="logo1" cx="119.5" cy="77.4" r="5"></circle>
                  <animateMotion accumulate="none" additive="replace" begin="0s" calcMode="paced" dur="6s" fill="remove" path="M215,0 0 0 215,0" repeatCount="indefinite" restart="always">
                  </animateMotion>
                </g>
                <g>
                  <path d="M174.4,65.5V28.2c6-3,12.3-4.5,18.9-4.5c5.7,0,10.2,1.5,13.5,4.5c3.2,3,4.9,7.2,4.9,12.6v24.7h-9.9V39.9   c0-1.1-0.1-2-0.3-2.8c-0.2-0.7-0.6-1.5-1.2-2.3c-0.6-0.8-1.6-1.4-3-1.8c-1.4-0.4-3.1-0.6-5.3-0.6c-2.4,0-5,0.2-7.7,0.7v32.4H174.4z"></path>
                  <path d="M217.1,32.2v-8.5h6.9V13.4c0,0,9.9-3.6,9.9-1c0,2.2,0,11.2,0,11.2h9.8v8.5h-9.8v20.3c0,1.8,0.4,3,1.1,3.6   c0.7,0.6,2,0.9,3.9,0.9c1.8,0,3.3-0.3,4.7-0.9v8.2c-1.9,0.8-4,1.2-6.3,1.2c-0.1,0-0.1,0-0.2,0c-4.1,0-7.3-1.2-9.6-3.6   s-3.5-5.7-3.5-10V32.2H217.1z"></path>
                  <path d="M148,12.7c-1-2.8-13.4,1.7-13.4,1.7l-20.3,51.1h10.6l5-13.3h23.3l5,13.3h10.6L148,12.7z M133.4,43.3l8.3-21.6l8.2,21.6   H133.4z"></path>
                  <path d="M253.2,65.5V14c0,0,9.9-3.6,9.9-1c0,2.2,0,52.4,0,52.4H253.2z"></path>
                  <path d="M272.7,44.7c0-6.6,1.5-11.9,4.4-15.8c2.9-3.9,7.6-5.9,14-5.9c6,0,10.6,1.9,13.7,5.8c3.1,3.8,4.7,8.6,4.7,14.2   c0,2.3-0.1,4-0.2,5.1h-26.7c0.3,3.6,1.3,6,3.2,7.2c1.9,1.2,4.3,1.8,7.3,1.8c4.8,0,9.5-1.1,14-3.2v8c-4.6,2.5-9.7,3.7-15.5,3.7   c-1.8,0-3.5-0.1-5.2-0.4c-1.6-0.3-3.3-0.9-5-1.9c-1.7-0.9-3.2-2.1-4.5-3.6c-1.2-1.5-2.3-3.5-3.1-6C273.1,51,272.7,48,272.7,44.7z    M282.6,41.1h16.6c-0.3-2.8-1.1-5.2-2.5-7.2c-1.4-1.9-3.4-2.9-5.8-2.9c-2.5,0-4.4,0.8-5.8,2.4C283.7,35,282.9,37.6,282.6,41.1z"></path>
                  <path d="M319.1,65.5V28c0.7-0.3,1.6-0.7,2.8-1.1c1.2-0.4,3.6-1.1,7.1-1.9c3.6-0.8,7.1-1.2,10.6-1.3v8.5c-4.1,0.1-7.7,0.5-10.6,1.4   v31.9H319.1z"></path>
                </g>
                <path id="svg-ico" class="logo2" d="M56.6,58.2c3.3,0,5.8-1.5,6.9-5.5l7.3-26c0.5-1.7-8.1,0-8.6,1.7l-6.4,21.9H44.1l5.5-18.5  c0.5-1.7-8.2,0.1-8.7,1.7l-5.6,16.7H24.3L28.2,37c0.5-1.7-8.1,0.1-8.6,1.8l-3.4,11.6H0.9c-0.5-2.6-0.7-5.7-0.7-7.9v0  c0-23.4,19-42.4,42.4-42.4s42.4,19,42.4,42.4v0c0,23.4-19,42.4-42.4,42.4C24.6,84.8,9.3,74,3.2,58.2H56.6z"></path>
              </svg>
            </a>
            <div class="copyright">© Copyright 2024 | Vezra Domain Company<br>All rights reserved</div>
            <div class="soc-icons">
              <a href=""><i class="fab fa-facebook-f withborder noshadow"></i></a>
              <a href=""><i class="fab fa-youtube withborder noshadow"></i></a>
              <a href=""><i class="fab fa-twitter withborder noshadow"></i></a>
              <a href=""><i class="fab fa-instagram withborder noshadow"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="subcribe news">
      <div class="container">
        <div class="row">
          <div>
            <div class="col-md-6 offset-md-3">
              <div class="general-input">
                <input class="fill-input" type="email" name="email" data-i18n="[placeholder]header.login" placeholder="Client Area">
                <input type="submit" value="SUBSCRIBE" class="btn btn-default-yellow-fill initial-transform">
              </div>
            </div>
            <div class="col-md-6 offset-md-3 text-center pt-4">
              <p>Subscribe to our newsletter to receive news and updates</p>
            </div>
          </form>
        </div>
      </div>
    </div> -->
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <ul class="footer-menu">
              <!-- <li id="drop-lng" class="btn-lang-footer btn-group btn-group-toggle">
                <label data-lng="en-US" for="option1" class="btn btn-secondary me-1 styletextDirection xpto active" data-value="ltr">
                  <input type="radio" name="options" id="option1" checked=""> EN
                </label>
                <label data-lng="pt-PT" for="option2" class="btn btn-secondary me-1 styletextDirection" data-value="ltr">
                  <input type="radio" name="options" id="option2"> PT
                </label>
                <label data-lng="ar-AR" for="option3" class="btn btn-secondary me-1 styletextDirection" data-value="rtl">
                  <input type="radio" name="options" id="option3"> AR
                </label>
                <label data-lng="es-ES" for="option4" class="btn btn-secondary me-1 styletextDirection" data-value="ltr">
                  <input type="radio" name="options" id="option4"> ES
                </label>
              </li> -->
              <!-- <li class="menu-item by">Portosoftware © 
                <a href="http://portosoftware.com/" target="_blank">2024</a>
              </li> -->
            </ul>
          </div>
          <!-- <div class="col-lg-6">
            <ul class="payment-list">
              <li><p>Payments We Accept</p></li>
              <li><i class="fab fa-cc-paypal"></i></li>
              <li><i class="fab fa-cc-visa"></i></li>
              <li><i class="fab fa-cc-mastercard"></i></li>
              <li><i class="fab fa-cc-apple-pay"></i></li>
              <li><i class="fab fa-cc-discover"></i></li>
              <li><i class="fab fa-cc-amazon-pay"></i></li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props:['logo']
}
</script>
<style type="text/css">
  .logo0{fill:#E2E4E9;}
  .logo1{fill:#EE5586;}
  .logo2{fill:#E95585;}
  @media only screen and (max-width: 991px){
    .logo {
    width: 180px;
    margin: 0 auto;
}
  }

</style>