<template>
<div>
    <p class="domain-unavailable domain-checker-unavailable domain-checker-available" v-if="message" style=" display: block; padding: 20px; color: white;  background-color: green!important;">{{message}} </p>
    <p class="domain-unavailable domain-checker-unavailable" v-if="errorMessage" style="display: block; padding: 20px; color: white; ">{{errorMessage}} </p> 
    <MobileCode v-if="showModal" :phone="phone" :phone_prefix="phone_prefix" @close="showModal=false" @openLoading="$emit('openLoading')" closeLoading="$emit('closeLoading')" />

    <div class="col-12 d-flex"> 
    <div class="col-md-4">
      <select class=" form-control mb-2" v-model="phone_prefix">
      <option v-for="(k,i) in phone_codes"  :key="i">{{k.phone_code}}</option>
    </select> 
    </div>
    <div class="col-md-4 ">
      <input class="form-control" placeholder="Enter mobile" v-model="phone">
    </div>
    <div class="col-md-4 input-group-btn"> 
      <button type="submit" @click="resetPasswordMobile" class="btn btn-default-yellow-fill mb-1 disable-on-click spinner-on-click">
        {{ $t('Send code') }}
      </button>
    </div>
  </div>
</div>
</template>
<script>
import MobileCode from './MobileCode'
export default{
  components:{MobileCode },
  data(){
    return{
      showModal:false,
      errorMessage:"",
      message:"",
      phone_prefix:"995",
      phone:"",
      phone_codes:[],
    }
  },
  methods:{
    resetPasswordMobile(){
      this.$emit('openLoading')
      this.errorMessage = ''
      this.showModal = false
      this.message = ''
      let data = {
        type:"phone",
        phone_prefix:this.phone_prefix,
        phone:this.phone
      }
      this.$store.dispatch('send_link',data).then(()=>{  
        this.message = this.$store.state.auth.message
        this.showModal = true
        this.$emit('closeLoading')
      }).catch(()=>{
        this.errorMessage = this.$store.state.auth.error_message
        this.$emit('closeLoading')
      })
    }
  },
  mounted(){
    this.$emit('openLoading')
    this.$store.dispatch('register_data').then(()=>{
      this.phone_codes = this.$store.state.register_data.data.phone_codes
      this.$emit('closeLoading')
    }).catch(()=>{
      this.$emit('closeLoading')
    })
  }
}
</script>