<template>
  <div class="box-container limit-width">
    <div class="bg-colorstyle">
      <div class="top-header item17 overlay">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="wrapper">
                <h1 class="heading">{{$t('Verify email')}}</h1> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper sec-normal">
        <div class="content bg-colorstyle noshadow nopadding">
          <div class="inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <section id="main-body">
                  <div class="container">
                    <div class="main-content">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="container">
                            <div class="domain-checker-container overlay mt-50">
                              <div class="domain-checker-bg clearfix">
                                <div class="row">
                                  <div class="col-md-8 col-md-offset-2 col-xs-10 col-xs-offset-1" style="left: 14rem; position: relative;">
                                    <p class="domain-unavailable domain-checker-unavailable domain-checker-available" v-if="message" style="display: block;  background-color: green!important;">{{message}} </p>
                                    <p class="domain-unavailable domain-checker-unavailable" v-if="errorMessage" style="display: block;">{{errorMessage}} </p> 
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="dropnav-header-lined">
                              <div class="dropdown-menu bg-seccolorstyle" >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
import { mapGetters } from 'vuex'
export default{
  computed:{
    ...mapGetters(['profile']),
  },
  data(){
    return{
      message:"",
      errorMessage:"",
    }
  },
  methods:{
    checkVerification(){  
      let email_verified_at = typeof this.$store.getters.profile === 'string'  ? JSON.parse(this.$store.getters.profile).email_verified_at : this.$store.getters.profile.email_verified_at
      if(email_verified_at !== null){ 
        this.$router.push('/account/my-account').catch()
      }
    }
  },
  mounted(){
    this.checkVerification()
    this.$emit("openLoading")
    let id = this.$route.params.id
    let token = this.$route.params.token
    let params = {
      expires:this.$route.query.expires,
      signature:this.$route.query.signature
    }
    let queryString = Object.keys(params).map((key) => (params[key] ? key + "=" + params[key] : "")).join("&");
    this.$store.dispatch('verify_email_address',{id,token,queryString}).then(()=>{
      this.$store.dispatch('profile').then(()=>{
        this.errorMessage = ''
        this.message = this.$store.state.profile.message
        this.$emit("closeLoading")
      })
    }).catch(()=>{
      this.message = ''
      this.errorMessage = this.$store.state.profile.error_message
      this.$emit("closeLoading")
    })
  }
}
</script>