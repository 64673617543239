<template>
  <div>
    <div class="bg-colorstyle">
      <div class="top-header item17 overlay">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="wrapper">
                <h1 class="heading">Reset passwod</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper sec-normal">
        <div class="content bg-colorstyle noshadow nopadding">
          <div class="inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <section id="main-body">
                  <div class="container">
                    <div class="main-content ">
                      <div class="row"> 
                        <div class="col-xs-12">  
                          <div id="order-standard_cart">
                            <div class="domain-checker-container overlay mt-50">
                              <div class="domain-checker-bg clearfix">
                                <div class="row">
                                  <div class="col-md-8 col-md-offset-2 col-xs-10 col-xs-offset-1" style="left: 14rem; position: relative;">
                                    <strong style="text-align: center; display: block; padding-bottom: 2rem" class="section-subheading mergecolor">Reset Password</strong>
                                    <p class="domain-unavailable domain-checker-unavailable domain-checker-available" v-if="message" style="display: block;  background-color: green!important;">
                                      {{message}}  Wait {{ sec }} second
                                    </p>
                                    <p class="domain-unavailable domain-checker-unavailable" v-if="errorMessage" style="display: block;">{{errorMessage}} </p> 
                                    <input type="email"    class="col-md-4 form-control mb-4" @keyup.enter="ResetPassword" placeholder="Email" v-model="email">
                                    <input type="password" class="col-md-4 form-control mb-4" @keyup.enter="ResetPassword" placeholder="Password" v-model="password">
                                    <input type="password" class="col-md-4 form-control mb-4" @keyup.enter="ResetPassword" placeholder="Password confirmation" v-model="password_confirmation"> 
                                    <button @click="ResetPassword" type="submit" class="btn btn-default-yellow-fill mb-1 disable-on-click spinner-on-click">Reset</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>    
export default { 
  data(){
    return{ 
      sec:5,
      type:"email",
      email: "",
      password: "",
      password_confirmation: "",
      message:"",
      errorMessage:"",
    }
  },
  methods:{
    ResetPassword(){
      this.$emit('openLoading')
      this.errorMessage = ''
      this.message = ''
      let data = {
        token:  this.$route.params.token,
        email: this.email,
        password:  this.password,
        password_confirmation: this.password_confirmation,
        type:"email"
      }
      this.$store.dispatch('reset_password_confirmation',data).then(()=>{  
        this.message = this.$store.state.auth.message
        this.$emit('closeLoading') 
        let interval = setInterval(()=>{ 
          this.sec -= 1
          if(this.sec === 0){
            clearInterval(interval)
            this.$router.push("/login").catch()
          }
        },1000) 
      }).catch(()=>{
        this.errorMessage = this.$store.state.auth.error_message
        this.$emit('closeLoading')
      })
    }, 
  },
  mounted(){
    this.$emit('closeLoading') 
  }
}
</script> 