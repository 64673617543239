import axios from 'axios'
import Vue from 'vue'
const auth = {
  state: {
    token : localStorage.getItem("token") || "",
    profile: localStorage.getItem("profile") || {}, 
    message:'', 
    google2fa:"",
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    profile: (state) => state.profile,
  },
  mutations: {
    set_refresh(state,data){
      state.token = data.data.access_token 
      axios.defaults.headers.common["Authorization"] = "Bearer " + data.data.access_token;
    },
    auth_success(state, data) {
      state.profile = data.profile
      state.token = data.access_token 
    },
    logout(state) {
      state.profile = {};
      state.token = ""; 
    },
    set_success_message(state,data){
      state.message = data
    },
    set_user(state,data){

    },
    del_user(){
      localStorage.removeItem('token')
      localStorage.removeItem('profile') 
    },
    check_two_fa(state,bool){
      state.google2fa = bool
    },
    set_error_message(state, response) {
      const errorMap = {
        404: "data_not_found",
        400: response.data.message,
        401: () => { this.commit('del_user') },
        403: "forbidden",
        405: "method_not_allowed",
        429: "too_many_requests",
        422: (response) =>
        response.data.success === false
          ? response.data.data.message
          : Object.values(response.data)[0][0],
        500: "please_try_again",
      };
      state.error_message = errorMap[response.status] instanceof Function
        ? errorMap[response.status](response) : errorMap[response.status] || "unknown_error";
    },
  },
  actions: {

    login({commit},data){
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'auth/login', data:data, method:"POST"}).then(resp => {
          const profile = resp.data.data.profile
          const access_token = resp.data.data.access_token
          axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
          const refresh_token = resp.data.data.refresh_token
          localStorage.setItem("profile", JSON.stringify(profile));
          localStorage.setItem("token", access_token);
          localStorage.setItem("refresh_token", refresh_token);
          commit("auth_success", resp.data.data);
          //refresh token logic
          resolve(resp) 
        }).catch(err => {
          err.response.data.status_code === 4001 ? commit("check_two_fa",true) : commit("check_two_fa",false)
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },

    refresh({commit}){ 
      return new Promise((resolve, reject) => { 
        let refresh_token = localStorage.getItem('refresh_token')
        axios({url: process.env.VUE_APP_API_ENDPOINT+'auth/refresh-token',headers:{Authorization:"Bearer "+refresh_token}, method:"GET"}).then(resp => {
          commit('set_refresh',resp.data) 
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },

    logout({commit}){ 
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'auth/logout', method:"POST"}).then(resp => {
          localStorage.removeItem("profile");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
           axios.defaults.headers.common["Authorization"]
          commit('logout')
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },

    register({ commit },data) {
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_API_ENDPOINT + "auth/register", method: "POST", data:data }).then((resp) => {
          commit("set_user", resp.data);
          resolve(resp);
        }).catch((err) => {
          commit("set_error_message", err.response);
          reject(err);
        });
      });
    },

    send_link({commit},data){
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'auth/reset-password', data:data, method:"POST"}).then(resp => {
          commit('set_success_message',resp.data.data)
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },
   
    reset_password_confirmation({commit},data){
      return new Promise((resolve, reject) => {
        axios({url: process.env.VUE_APP_API_ENDPOINT+'auth/reset-password-confirmation', data:data, method:"POST"}).then(resp => {
          commit('set_success_message',resp.data.data)
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },

    send_mobile_code({commit},data){   
      return new Promise((resolve, reject) => { 
        axios({url: process.env.VUE_APP_API_ENDPOINT+'auth/send-phone-verification',data:data, method:"POST"}).then(resp => {
          commit('set_success_message',resp.data.data)
          resolve(resp)
        }).catch(err => {
          commit("set_error_message", err.response);
          reject(err)
        })
      })
    },

  },
  modules: {}
}

export default auth;
