<template>
  <div class="col-md-12">
    <div class="alert alert-danger" v-if="errorMessage"> {{ errorMessage }} </div>
    <div class="alert alert-success" v-if="meesage"> {{ meesage }} </div>
    <div class="table-container clearfix">
      <div class="dataTables_wrapper form-inline dt-bootstrap no-footer">
        <div class="listtable table-responsive pb-4"> 
          <div class="row mb-3">
            <div class="col-2 col-md-2">
              <select class="form-control" @change="changeUrl('per_page')" v-model="per_page">
                <option v-for="(k,i) in per_pages" :key="i">{{ k }} </option>
              </select>
            </div>
            <div class="col-4 col-md-4">
            <flat-pickr class="form-control" :config="configFromDateTimePicker" v-model="start_date" :placeholder="$t('start Date')" @input="changeUrl('login_after')"/>
            </div>
            <div class="col-4 col-md-4">
            <flat-pickr class="form-control" :config="configToDateTimePicker" v-model="end_date" :placeholder="$t('End Date')" @input="changeUrl('login_before')"/>
            </div>
            <div class="col-2 col-md-2"> 
              <button class="btn btn-default" :disabled="disabled" @click="getData"> {{ $t("Search") }} </button> 
            </div>
          </div>
          <table class="table table-list" v-if="data.length">
            <thead>
              <tr role="row">
                <th class="sorting_desc">ID#</th>
                <th class="sorting">IP</th>
                <th class="sorting">{{$t('Active session')}}</th> 
                <th class="sorting">{{ $t('Login time') }}</th>
                <th class="sorting_disabled">{{ $t('Logout time') }}</th>
                <th class="sorting_disabled">{{ $t('User agent') }}</th>
                <th class="sorting_disabled">{{ $t('Created at') }}</th>
                <th class="responsive-edit-button sorting"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="odd" v-for="(k,i) in data" :key="i">
                <td valign="top" colspan="1" class="dataTables_empty">{{k.id}}</td>
                <td valign="top" colspan="1" class="dataTables_empty">{{k.ip}}</td>
                <td valign="top" colspan="1" class="dataTables_empty">{{k.active_session}}</td>
                <td valign="top" colspan="1" class="dataTables_empty">{{k.login_time}}</td>
                <td valign="top" colspan="1" class="dataTables_empty">{{k.logout_time}}</td>
                <td valign="top" colspan="1" class="dataTables_empty" v-html="UserAgent(k.user_agent)"></td>
                <td valign="top" colspan="1" class="dataTables_empty">{{k.created_at| formatDate}}</td>
              </tr>
            </tbody>
          </table>
          <div class="dataTables_paginate paging_simple_numbers mt-4"> 
            <XzPagination
              v-if="total > per_page"
              :total-items="total"
              :page-size="per_page"
              :current-page="current_page"
              v-model="current_page"
              @change="changeUrl('pagination')"
              :last-page="last_page"
            />
          </div>
        </div>
      </div>
      &nbsp;
    </div>
  </div>
</template>
<script>
import XzPagination from "@/components/XzPagination";
export default{
  components:{ XzPagination },
  watch:{
    $route:{
      handler(n,o){
        if(n){
          this.getUrl()
          this.getData()
        }
      },
      immediate:true,
      deep:true
    }
  },
  data(){
    return{
      disabled:false,
      configFromDateTimePicker: {
        minDate: null,
        maxDate: null,
        enableTime: true,
        enableSeconds: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i:S",
      },
      configToDateTimePicker: {
        minDate: null,
        maxDate: null,
        enableTime: true,
        enableSeconds: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i:S",
      },
      start_date:"",
      end_date:"",
      haveError:false,
      errorMessage:"",
      meesage:"",
      data:[], 
      page:1,
      current_page:1,
      from:1,
      last_page:1,
      per_page:10,
      per_pages:[10,20,50,100],
      to:1,
      total:1,
      url:new URL(window.location.href),
    }
  },
  methods:{
    changeUrl(type){ 


      if(type === 'per_page' && this.per_pages.includes(this.per_page))this.url.searchParams.set("per_page",this.per_page) 
      if(type === 'pagination'){
        this.page = this.current_page
        this.url.searchParams.set("page", this.page); this.getData()
      }else{
        this.page = 1
        this.current_page = 1
        this.url.searchParams.set("page", this.page);
      }
      if(type === 'login_after')  this.url.searchParams.set("filter[login_after]",this.start_date)
      if(type === 'login_before') this.url.searchParams.set("filter[login_before]",this.end_date) 
      window.history.replaceState(null, "", this.url); 
    },
    getUrl(){
      this.url.searchParams.has("page") ? this.page = this.url.searchParams.get("page") : this.url.searchParams.set("page",this.current_page); 
      this.url.searchParams.has("per_page") ? this.per_page = this.url.searchParams.get("per_page") : this.url.searchParams.set("per_page",this.per_page); 
      this.url.searchParams.has("filter[login_after]") ? this.start_date = this.url.searchParams.get("filter[login_after]") : this.url.searchParams.set("filter[login_after]",this.start_date); 
      this.url.searchParams.has("filter[login_before]") ? this.end_date = this.url.searchParams.get("filter[login_before]") : this.url.searchParams.set("filter[login_before]",this.end_date)
      window.history.replaceState(null, "", this.url);
    }, 
    getData(){
      this.$emit('openLoading')
      this.disabled = true
      this.errorMessage = ''
      let params = { per_page: this.per_page, page: this.page }
      let queryString = Object.keys(params).map((key) => (params[key] ? key + "=" + params[key] : "")).join("&");
      queryString += "&filter[login_after]="+this.start_date+"&filter[login_before]="+this.end_date
      this.$store.dispatch('histories',{url:"sessions",queryString}).then(()=>{
        this.data = this.$store.state.profile.data.data.data 
        if(!this.data.length){
          this.errorMessage = this.$t('Data not found')
        }
        this.current_page = this.$store.state.profile.data.data.meta.current_page
        this.from = this.$store.state.profile.data.data.meta.from
        this.last_page = this.$store.state.profile.data.data.meta.last_page
        this.per_page = this.$store.state.profile.data.data.meta.per_page
        this.to = this.$store.state.profile.data.data.meta.to
        this.total = this.$store.state.profile.data.data.meta.total
        this.disabled = false
        this.$emit('closeLoading')
      }).catch(()=>{ 
        this.$emit('closeLoading') 
        this.errorMessage = this.$store.state.profile.error_message
        this.disabled = false
      }) 
    }
  },
  mounted(){
    this.start_date = this.getLastDay(0, " 00:00:00");
    this.end_date = this.getLastDay(0, " 23:59:59");
    this.$emit('openLoading')
  }
}
</script>